// @ts-strict-ignore
import { getWeekOfMonth, isSameYear } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import es from 'date-fns/locale/es';
import { ShadedTextUtils } from 'phoenix/assets/lang/ShadedTextUtils';
import { AppliedAppTheme } from 'phoenix/constants/AppliedAppTheme';
import { PremiumProducts } from 'phoenix/constants/PremiumProducts';
import { GetPriceFormatInfo } from 'phoenix/hooks/UsePriceFormatInfo';
import { AssetClass } from 'phoenix/models/AssetClasses/AssetClass';
import { TransferRequest } from 'phoenix/models/TransferRequest';
import { CryptoSymbol } from 'phoenix/redux/models/Crypto/CryptoSymbol';
import { FundingLimits } from 'phoenix/redux/models/Funding/FundingLimits';
import { FundingSource } from 'phoenix/redux/models/Funding/FundingSource';
import { isSecurityMetadata, isSecurityMetadataV2, SecurityMetadataMix } from 'phoenix/stores/SecurityMetadataV2Store';
import { Environment } from '../../constants';
import { IndustryGroups } from '../../constants/IndustryGroups';
import { SecurityTypes } from '../../constants/SecurityTypes';
import { OrderTypes } from '../../constants/Trade';
import { Account } from '../../redux/models/Accounts/Account';
import { SnexDocument } from '../../redux/models/Documents/SnexDocument';
import { FundingManagementLimits } from '../../redux/models/Funding/FundingManagementLimits';
import { FuturesSymbol } from '../../redux/models/Futures/FuturesSymbol';
import { OptionSymbol } from '../../redux/models/Options/OptionSymbol';
import { Order } from '../../redux/models/Orders/Order';
import { OptionsPutCallAbbreviation, OrderType, TradeableSecurityType, TradeRequest } from '../../redux/models/Trading/TradeRequest';
import { Transaction } from '../../redux/models/Transactions/Transaction';
import { SpokenJoin } from '../../util/ArrayMutations';
import { SafeFormat, SafeFormatLocale, SafeFormatToNow } from '../../util/DateFormatting';
import { DetermineOrderType } from '../../util/DetermineOrderType';
import { FormatNumber } from '../../util/FormatNumber';
import { DecodeSecurityMasterType } from '../../util/IsMutualFund';
import { QualifiedId } from '../../util/QualifiedId';
import { disclosures, proDisclosures } from './disclosures';
import { ActionSummaryProps, getActionSummary } from './helpers';
import { Dark, Light, ShadedTextList, Snex1LanguagePack } from './Snex1LanguagePack';

const DelayedParagraph = [{ text: 'Update frequency: 15-min delay' }];
const nums = ['cero', 'uno', 'dos', 'tres', 'cuatro', 'cinco', 'seis', 'siete', 'ocho', 'nueve', 'diez'];

export const Snex1Spanish: Partial<Snex1LanguagePack> = {
    funding: {
        delete: {
            title: 'Borrar cuenta',
            message: '¿Estás seguro de que deseas eliminar esta cuenta?',
            submitButton: 'Eliminar cuenta',
            cancelButton: 'No, mantener cuenta'
        }
    },
    errorBoundary: {
        title: '¡Vaya!',
        sorryMessage: '¡Lo siento! Parece que algo salió mal.',
        oopsSomethingWentWrong: 'Huy! Algo salió mal.',
        tryAgain: 'Intentar otra vez',
        contactSupport: 'Soporte de contacto',
        onboardingErrorOccurred: 'Ocurrió un error',
        onboardingTryAgainOrContactSupport: 'Inténtelo de nuevo o comuníquese con Soporte si el problema persiste'
    },
    assetClass: {
        futures: 'Futuros',
        cryptocurrency: 'Criptomoneda',
        crypto: 'Cripto',
        futuresOptions: 'Opciones sobre Futuros',
        future: 'Futuro',
        equitiesOption: 'Opción sobre Acciones',
        otcOption: 'Opción OTC',
        etf: 'ETF',
        mutualFund: 'Fondo Mutuo',
        oilGasReit: 'REIT de Petróleo y Gas',
        escrowReceiptsLetterGuaranty: 'Recibo de Depósito / Carta de Garantía',
        treasury: 'Tesorería',
        municipleBond: 'Bonos Municipales',
        corporateUit: 'UIT Corporativo',
        municipalUit: 'UIT Municipal',
        unit: 'Unidad',
        preciousMetals: 'Metales Preciosos',
        commonStock: 'Acción Común',
        annuity: 'Anualidad',
        miscellaneous: 'Misceláneo',
        offShoreMutualFund: 'Fondo mutuo extraterritorial',
        brokerage: 'Cepo'
    },
    welcome: {
        skipTour: 'Omitir recorrido',
        showMeAround: '¡Muéstrame todo!',
        pickLang: 'Elige el idioma con el que te sientas más cómodo',
        successText: '¡Tu cuenta ha sido creada exitosamente!',
        loadingText: 'Comprobando el estado de su cuenta...',
        errorText: `Hubo un problema al localizar su cuenta. Es posible que su cuenta tarde unos minutos en inicializarse. Si no está disponible después de 10 minutos, comuníquese con nuestro soporte al`,
        noAccountsText:
            'Parece que tu cuenta aún no está lista. Es posible que su cuenta tarde unos minutos en inicializarse. Si no está disponible después de 10 minutos, comuníquese con nuestro soporte al'
    },
    webTos: {
        title: 'Aceptar terminos y condiciones',
        url: 'https://snex1storage.blob.core.windows.net/$web/docs/StoneX_Client_TC.pdf',
        linkText: 'Ver términos y condiciones',
        agreeButtonLabel: 'Acepto'
    },
    general: {
        at: 'en',
        around: 'alrededor de',
        above: 'por encima de',
        below: 'por debajo de',
        to: 'a',
        ai: 'IA',
        languageNameEnglish: 'Spanish',
        languageNameLocal: 'Español',
        dateFnsLocale: es,
        localeShort: 'es',
        localeLong: 'es-US',
        news: (showMI?: boolean) => ` Noticias${showMI ? ' e Inteligencia de Mercado' : ''}`,
        search: 'Buscar',
        trade: 'Comerciar',
        cancel: 'Cancelar',
        submit: 'Enviar',
        continue: 'Continuar',
        acceptRateAndSubmit: 'Aceptar Calificar y Enviar',
        acceptTermsAndSubmit: 'Aceptar términos y enviar',
        submitting: 'Sumisión...',
        done: 'Hecho',
        none: 'Ninguna',
        dismiss: 'Despedir',
        confirmAndContinue: 'Confirmar y Continuar',
        contactSupport: 'Soporte de contacto',
        writtenInteger0Thru10: (n) => (n >= 0 && n <= 10 ? nums[n] : FormatNumber.toCommas(n)),
        unavailable: 'Indisponible',
        loading: 'Cargando',
        open: 'Abierto',
        dateUnknown: 'Fecha desconocido',
        unknown: 'Desconocido',
        browseDataUnavailable: 'Lo sentimos, los datos de navegación no están disponibles actualmente.',
        unableToAuthenticate: 'Lo sentimos, no pudimos autenticarlo',
        yesLeave: 'Sí, Salir',
        noStayHere: 'No, quédate aquí',
        returningToStonex: 'Regresando a StoneX One',
        aboutToReturnToStonex: 'Está a punto de regresar a StoneX One. ¿Está seguro?',
        months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        monthsShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        quantity: 'Cantidad',
        strikes: 'Huelgas',
        shares: (n = 0) => (Math.abs(n) === 1 ? 'Acción' : 'Acciones'),
        fundShares: (n = 0) => (Math.abs(n) === 1 ? 'Participación' : 'Participaciones'),
        contracts: (n = 0) => (Math.abs(n) === 1 ? 'Contrato' : 'Contratos'),
        bonds: (n = 0) => (Math.abs(n) === 1 ? 'Bono' : 'Bonos'),
        coins: (n = 0) => (Math.abs(n) === 1 ? 'Moneda' : 'Monedas'),
        unit: (symbol: string, quantity = 0, typeHint: string = null) => {
            if (FuturesSymbol.IsFuturesSymbol(symbol)) return Snex1Spanish.general.contracts(quantity);
            if (OptionSymbol.IsOptionSymbol(symbol) || DecodeSecurityMasterType(typeHint) === 'option') return Snex1Spanish.general.contracts(quantity);
            if (CryptoSymbol.IsCryptoSymbol(symbol)) return Snex1Spanish.general.coins(quantity);
            const torchType = typeHint;
            if (torchType === 'Fixed Income' || torchType === 'Debt') return Snex1Spanish.general.bonds(quantity);
            return Snex1Spanish.general.shares(quantity);
        },
        symbolName: (symbol: string, optionVariant: 'short' | 'extra-short' | 'long' | 'no-exp' | 'symbol-putcall' = 'short', quantity = 1, secMeta) => {
            const fsym = new FuturesSymbol(symbol);
            const optSym = new OptionSymbol(symbol);

            if (fsym.isFuture && !optSym.isOption) return fsym.noPrefix;

            if (optSym.isOption) {
                const { underlyingSymbol, expDate } = optSym;
                const strike = isSecurityMetadataV2(secMeta) ? secMeta?.option?.strikePrice : isSecurityMetadata(secMeta) ? secMeta?.strikePrice : optSym.strike;
                const tickSize = isSecurityMetadataV2(secMeta) ? secMeta?.sizing?.minimumIncrement : isSecurityMetadata(secMeta) ? secMeta?.tickSize : undefined;
                const term = getWeekOfMonth(expDate, { weekStartsOn: 6 }) !== 3 ? 'semanal' : 'mensual';
                const putness = Snex1Spanish.general.options.putCallWord(optSym.putCall);
                const strikeFormat = secMeta ? GetPriceFormatInfo(symbol, tickSize) : undefined;
                const strikeStr = FormatNumber.toMoneyOpt2(strike, strikeFormat?.moneyFormatOptions);
                const { exp } = Snex1Spanish.general.options;
                const locale = Snex1Spanish.general.dateFnsLocale;

                switch (optionVariant) {
                    case 'long':
                        return `${underlyingSymbol} ${SafeFormatLocale({ date: expDate, formatString: 'dd MMMM yyyy', locale })} ${term} ${strikeStr} ${putness}${
                            quantity === 1 ? '' : 's'
                        }`;
                    case 'no-exp':
                        return `${underlyingSymbol} ${strikeStr} ${putness}${quantity === 1 ? '' : 's'}`;
                    case 'symbol-putcall':
                        return `${optSym.underlyingSymbol} ${putness}`;
                    case 'extra-short': {
                        const dateFmt = isSameYear(expDate, new Date()) ? 'MM/dd' : 'MM/dd/yy';
                        return `${optSym.underlyingSymbol} ${strikeStr}${optSym.putCall} ${SafeFormatLocale({ date: expDate, formatString: dateFmt, locale })}`;
                    }
                    case 'short':
                    default:
                        return `${underlyingSymbol} ${strikeStr} ${putness}, ${exp} ${SafeFormatLocale({ date: expDate, formatString: 'MM/dd/yyyy', locale })}`;
                }
            }

            return QualifiedId.RemovePrefix(symbol);
        },
        spokenJoin: (items: string[]) => {
            if (!items?.length || !items[0].length) return '';
            const lastWord = items[items.length - 1][0];
            const and = /^(hi|i)/i.test(lastWord) ? 'e' : 'y';
            return SpokenJoin(items, and);
        },
        isShort: 'Esta es una posición corta',
        buyingPower: 'Poder adquisitivo',
        availableBalance: 'Saldo disponible',
        balance: 'Saldo',
        pendingApproval: 'Aprobación pendiente',
        pending: 'Pendiente',
        maintenanceCall: 'Llamada de mantenimiento',
        maintenanceCallForAccounts: 'Llamada de mantenimiento para una o más cuentas',
        maintenanceCallTooltip:
            'Una llamada de margen ocurre cuando el valor de los valores en una cuenta de corretaje cae por debajo de un cierto nivel, conocido como el margen de mantenimiento, que requiere que el titular de la cuenta deposite efectivo o valores adicionales para cumplir con los requisitos de margen.',
        fedCallTooltip:
            'Cuando un inversionista compra acciones y no tiene suficiente capital en la cuenta para cumplir con el requisito de capital del 50%, se activa una llamada de margen federal.',
        dayTradingFlag: 'Esta cuenta ha sido marcada para transacciones diarias.',
        fplEligible: 'Esta cuenta es elegible para inscribirse en préstamos totalmente pagados',
        fplEnabled: 'Esta cuenta está inscrita en Préstamos totalmente pagados',
        ninetyDayRestriction: {
            description: 'Un cliente puede ser colocado en una restricción de 90 días por las siguientes razones:',
            forAccounts: 'Restriccion de 90 días para una o más cuentas',
            list: [
                'Impago de una obligación REG T',
                'Cualquier actividad que no sea el depósito en efectivo durante la extensión (liquidación, cancelación, etc.)',
                'Conducción libre',
                'res violaciones de buena fe en un período continuo de 12 meses',
                'Falta de pago de una llamada de comercio diario'
            ],
            secondParagraph:
                'Mientras que en la restricción de 90 días, un cliente está restringido a abrir transacciones solo con fondos liquidados. Un cliente puede ser bloqueado para abrir transacciones cuando no hay fondos liquidados suficientes. Las operaciones que excedan los fondos liquidados también pueden estar sujetas a cancelación.',
            title: 'Restriccion de 90 días',
            withDate: (date) => `Restriccion de 90 días${date ? ` caduca ${SafeFormat(date, 'MM/dd/yyyy')}` : ''}`
        },
        options: {
            call: 'Llamar',
            exp: 'Exp',
            put: 'Poner',
            putCallWord: (putCall: OptionsPutCallAbbreviation) => {
                switch (putCall) {
                    case 'C' as OptionsPutCallAbbreviation:
                        return Snex1Spanish.general.options.call;
                    case 'P' as OptionsPutCallAbbreviation:
                        return Snex1Spanish.general.options.put;
                    default:
                        return Snex1Spanish.general.unknown;
                }
            },
            adjustedOptionsSubtext: (deliverableCount: number) => {
                return `${deliverableCount} acciones por Contrato`;
            }
        },
        productTypes: {
            'offshore-mutual-fund': {
                singular: 'fundo mútuo',
                plural: 'los fondos de inversión'
            },
            'mutual-fund': {
                singular: 'fundo mútuo',
                plural: 'los fondos de inversión'
            },
            adr: { singular: 'ADR', plural: 'ADRs' },
            crypto: { singular: 'criptomoneda', plural: 'criptomonedas' },
            equity: { singular: 'equidad', plural: 'acciones' },
            etf: { singular: 'ETF', plural: 'ETFs' },
            future: { singular: 'futuro', plural: 'futuros' },
            option: { singular: 'opción', plural: 'opciones', upperSingular: 'Opción' },
            unknown: { singular: 'seguridad', plural: 'valores' }
        },
        assetClassLabels: {
            equities: 'Acciones',
            futures: 'Futuros',
            cryptos: 'Criptomonedas'
        },
        sorrySomethingWentWrong: 'Perdón, algo salió mal',
        notApplicable: 'N/A',
        learnMore: 'Aprende más',
        expiresSoon: 'Expira pronto',
        expiresToday: 'Vence hoy',
        expiresTomorrow: 'Expira mañana',
        daily: 'A diario',
        oneTime: 'Una vez',
        repeatDaily: 'Repetir diariamente',
        numberBought: '# Compró',
        numberSold: '# Vendido',
        avgBuyPrice: 'Precio de compra promedio',
        avgSellPrice: 'Precio de venta promedio',
        topDayActivity: 'Actividad del día principal',
        ascending: 'Ascendente',
        descending: 'Descendente',
        delayedPricing: 'Datos de precios con retraso',
        delayedPricingTitle: 'Precios Retrasados'
    },
    webRegistration: {
        verifySMSCodeTitle: 'Verifica con tu teléfono',
        enterCodeAndLogin: 'Ingrese su código a continuación y ¡comencemos!',
        verifySMSCodeSubtitle: (phone: string) => `Enviar un código vía SMS al ${phone}`,
        messageChargesAlert: 'Es posible que se apliquen cargos por mensajería del operador',
        sendingSMSCode: 'Código de validación',
        invalidSMSCode: 'Código de verificación por SMS no válido',
        resentSMSCode: 'Reenviar código de verificación por SMS',
        getSMSCode: 'Recibe un código vía SMS',
        submitSMSCode: 'Verificar código',
        alternateMFA: 'Elija un método MFA diferente',
        welcome: 'Bienvenido!',
        getAnAccount: 'Vamos a conseguirte una cuenta de StoneX One',
        getUserInfo: 'Vamos a obtener su nombre y correo electrónico',
        nextButton: 'Próximo',
        firstName: 'Nombre de pila',
        lastName: 'Apellido',
        phoneNumber: 'Número de teléfono',
        verifyEmail: 'Verificar correo electrónico',
        emailVerified: 'Correo electrónico verificado!',
        email: 'Correo electrónico',
        confirmEmail: 'Confirmar correo electrónico',
        userExists: (email: string) => `El usuario con correo electrónico ${email || ''} ya existe.`,
        personalInfo: 'Información personal',
        usCitizenQuestion: '¿Eres ciudadano estadounidense?',
        yes: 'Sí',
        no: 'No',
        usCitizenAnswer: 'Soy ciudadano estadounidense',
        nonUSCitizenAnswer: 'No soy ciudadano estadounidense',
        usCitizenAlert: 'Actualmente debes ser ciudadano estadounidense para abrir una cuenta.',
        createPassword: 'Vamos a crear una contraseña',
        createUser: 'Create User',
        passwordRequirement: 'La contraseña debe tener 14 caracteres o más',
        password: 'Contraseña',
        confirmPassword: 'confirmar Contraseña',
        verifyYourEmail: 'Verifiquemos tu correo electrónico',
        sendingVerificationCode: 'Estamos enviando un código de verificación al correo electrónico proporcionado anteriormente.',
        enterInBoxBelow: 'Por favor, introdúzcalo en el cuadro de abajo.',
        invalidVerificationCode: 'Código de verificación invalido',
        resentVerificationCode: 'Reenviar el código de verificación',
        verificationCode: 'Código de verificación',
        submit: 'Entregar',
        resendCode: 'Reenviar codigo',
        completeTitle: 'Registro completo',
        completeSubtitle: 'Haga clic en el botón a continuación e inicie sesión en StoneX One',
        login: 'Acceso',
        alreadyExists: 'Usuario existente encontrado',
        wouldYouLikeToLogin: '¿Le gustaría iniciar sesión en StoneX One?',
        steps: {
            welcome: '¡Bienvenido',
            userInfo: 'Información de usuario',
            personalInfo: 'Información personal',
            setupPassword: 'Contraseña de configuración',
            enrollMFA: 'Inscribirse MFA',
            verifyCreate: 'Verificar correo electrónico',
            login: 'Acceso'
        },
        errors: {
            required: 'Field is required',
            userInfo: {
                invalidPhone: 'Numero de telefono invalido.',
                invalidEmail: 'Invalid email',
                emailDoesNotMatch: 'Email and confirmEmail do not match',
                emailExists: 'Email already exists'
            },
            password: {
                passwordsDoNotMatch: 'Password entries do not match',
                invalidPassword: 'Passwords must be at least 14 characters'
            }
        }
    },
    appReview: {
        yes: 'Sí',
        no: 'No',
        enjoyingStoneX: '¿Estás disfrutando de StoneX One?',
        rateApp: '¿Te gustaría calificar la aplicación?',
        later: 'Luego',
        noThanks: 'No, gracias',
        feedback: 'Gracias por tus comentarios. ¿Te gustaría hablar con el soporte de chat?'
    },
    connectionStatus: {
        title: 'Sin conexión',
        message: 'Parece que no estás conectado a Internet',
        retryConnection: 'Reintentar Conexión'
    },
    premiumModal: {
        addTheseTools: 'Agregue estas herramientas a su arsenal:',
        ready: '¿Listo para obtener esa ventaja adicional?',
        unlock: 'Desbloquee el análisis como ningún otro.',
        upgradeToday: 'Actualice a Premium hoy',
        deepAnalysis: {
            title: 'Acceso a Deep Analysis',
            content: 'Navegue por los mercados con datos de mercado en tiempo real y conocimientos exclusivos de la empresa combinados en una vista única e integral.'
        },
        multiCharts: {
            title: 'Múltiples Gráficos, Una Pantalla',
            content:
                'Realice análisis técnicos en múltiples gráficos, todo desde una sola vista. Analice y compare patrones para diferentes valores, todo desde una sola vista.'
        },
        streamingNews: {
            title: 'Transmisión de Noticias',
            content: 'Muévase cuando el mercado se mueve con la transmisión de noticias. Los nuevos artículos aparecerán en la parte superior de sus flujos de noticias.'
        },
        alerts: {
            title: 'Alertas de Precio',
            content: 'Controle los valores más importantes para usted con alertas personalizadas. Nunca pierdas una oportunidad.',
            isAbove: 'está arriba',
            isAboveOrEqual: 'es superior o igual a',
            isBelow: 'Esta abajo',
            isBelowOrEqual: 'es inferior o igual a'
        },
        miPremium: {
            title: 'Market Intelligence',
            content:
                'Noticias, datos y comentarios sobre todos nuestros mercados con perspectivas directas de corredores y analistas. ¡Incluye información procesable para mantenerlo un paso adelante!'
        }
    },
    marketTimeBadge: {
        open: 'El mercado de renta variable estadounidense está abierto',
        premarket: 'Horas previas al mercado de renta variable estadounidense',
        holiday: 'Vacaciones comerciales',
        postmarket: 'Horas posteriores al mercado de renta variable estadounidense',
        closed: 'El mercado de renta variable estadounidense está cerrado',
        loading: 'Cargando'
    },

    blankViews: {
        holdingsTable: {
            main: 'No se encontraron posiciones',
            secondary: 'Intente cambiar los filtros en la parte superior derecha',
            notFundedMain: 'Bienvenido a la pantalla de posiciones',
            notFundedSecondary: 'Una vez que haya financiado su cuenta, encontrará sus posiciones y saldos aquí',
            notFundedAction: 'Añadir fondos'
        },
        documents: {
            main: (documentType: string) => `No tienes ${Snex1Spanish.blankViews.documents.mainDocumentTypes[documentType] || documentType}`,
            secondary: (documentType: string) =>
                Snex1Spanish.blankViews.documents.secondaryDocumentText[documentType] || `Los ${documentType} se encontrarán aquí una vez que estén disponibles.`,
            mainDocumentTypes: {
                'Trade Confirmations': 'Confirmaciones de Operaciones',
                '1099s + Tax Documents': 'Documentos Fiscales',
                Miscellaneous: 'Documentos diversos',
                Checks: 'Cheques',
                'Account Statements': 'Extractos de cuenta'
            },
            secondaryDocumentText: {
                'Trade Confirmations': 'Las Confirmaciones de Operaciones se encontrarán aquí una vez que estén disponibles.',
                '1099s + Tax Documents': 'Los documentos fiscales se encontrarán aquí una vez que estén disponibles.',
                Miscellaneous: 'Los documentos diversos se encontrarán aquí una vez que estén disponibles.',
                Checks: 'Los cheques se encontrarán aquí una vez que estén disponibles.',
                'Account Statements': 'Los extractos de cuenta se encontrrarán  aquí una vez que estén disponibles.'
            }
        },
        transactions: {
            main: 'Parece que no tienes ningunas transacciones',
            secondary: 'Prueba con otros filtros o vuelve a consultar más tarde;'
        },
        projectedIncome: {
            main: 'No hay ingresos proyectados para esta cuenta',
            secondary: 'Prueba con otra cuenta o vuelve a consultar más tarde'
        },
        tradeHistory: {
            main: 'Parece que no tiene transacciones registradas que coincidan con sus criterios de búsqueda',
            secondary: 'Busque una acciòn y coloque un trade para verlo aquí'
        },
        messages: {
            main: 'No hay mensajes',
            secondary: 'Encontrarà los mensajes que ha recibido aquí. Vuelva màs tarde para ver si hay algo nuevo'
        }
    },
    premiumScreen: {
        createAlert: (symbol: string) => `Create an Alert for ${symbol}`,
        deepAnalysisAlt: 'Vista previa de la pantalla de análisis profundo',
        returnToSummary: 'Volver al Resumen',
        subHeader: 'Acabas de mejorar tu juego. Usa los botones a continuación para ver tus nuevos superpoderes.',
        viewCharts: (symbol: string, symbol2: string) => `Comparar ${symbol} y ${symbol2}`,
        viewChartsAlt: 'Vista previa de varios gráficos',
        viewDeepAnalysis: (symbol: string) => `Ver ${symbol} Análisis Profundo`,
        viewMi: (symbol: string) => `¡Ver contenido de inteligencia de mercado para ${symbol} ahora!`,
        viewMiAlt: 'Imágenes de materias primas de futuros',
        welcomeHeader: (name: string) => `Bienvenido a ${name}!`,
        checkout: {
            title: 'Verificar',
            changePlan: 'Cambiar plan',
            nextBilled: (date: Date) => `La próxima vez se le facturará el ${format(date, 'dd MMM, yyyy')}`,
            cancelAnytime: 'Cancelar en cualquier momento',
            offerTerms: 'Se aplican los términos de la oferta',
            details: {
                title: 'Importante',
                body: (productName: string) => `Los pagos de ${productName} se toman automáticamente a través de su cuenta de StoneX.`,
                multipleAccounts:
                    ' Como tiene varias cuentas, deberá decirnos qué cuenta le gustaría usar. Hágalo a continuación (siempre puede cambiar esto más adelante):'
            },
            selectAccount: (hasMultipleAccounts?: boolean) =>
                hasMultipleAccounts ? 'Seleccione una cuenta de corretaje para debitar' : 'Cuenta de corretaje para débito',
            buttonText: 'Iniciar suscripción'
        },
        selectProduct: {
            goPremium: 'Hazte Premium',
            or: 'O',
            pickYourPackage: 'Elija su Paquete',
            perMonth: 'por mes',
            comingSoon: '¡Muy pronto!',
            comingSoonToStonexOne: 'Próximamente en StoneX One:',
            select: 'Seleccionar',
            bestValue: '¡Mejor valor!',
            jumbotron: {
                ourBestDeal: '¡Nuestra Mejor Oferta!',
                title: [
                    // [
                    // { text: 'StoneX One Premium', weight: 'bold', size: 'title' },
                    // { text: ' and ', size: 'title' },
                    // { text: 'Market Intelligence', weight: 'bold', size: 'title' },
                    // { text: ' in one convenient bundle.', size: 'title' }
                    // ]
                ],
                perMonthAbbr: '/mes',
                moreOptions: 'Mas Opciones',
                getStarted: 'Empezar'
            },
            products: {
                sx1Premium: 'StoneX One Premium',
                miPremium: 'Market Intelligence Premium',
                sx1miBundle: 'StoneX One + MI Bundle'
            },
            features: {
                deepAnalysis: {
                    title: 'Deep Analysis',
                    subtitle: '¡Información privilegiada, llamadas de ganancias y más!'
                },
                multiChart: { title: 'Múltiples gráficos' },
                customAlerts: { title: 'Alertas Personalizadas' },
                miPremiumArticles: { title: 'Investigación de Materias Primas Premium' }
            }
        }
    },

    managePremiumScreen: {
        premium: 'Premium',
        havePremiumTitle: 'You have StoneX One Premium!',
        havePremiumSubtitle: 'Rock on!',
        dontHavePremiumTitle: "You don't have Premium",
        dontHavePremiumSubtitle: 'Unlock analysis like no other.',
        features: {
            earningsCalls: 'Listen to recent and historical earnings calls for all equities, with transcripts.',
            financials:
                'Gain access to financials like sentiment analysis, balance sheets, income statements, cash flow reports, projected revenue, dividend history, and more for all stocks.',
            institutions: 'See which institutions, funds, and leaders are trading the most shares for any company.'
        },
        startForJust: (price: string | number) => `Start for just $${price}/mo.`,
        howPaymentsAreMade: 'Payments are made directly from your StoneX account, no card required.',
        startPremiumNow: 'Start Premium Now!',
        paymentAccount: 'Payment Account',
        selectPaymentAccount: 'Select Payment Account',
        nextBill: 'Next Bill',
        contactSupportForBillingHistory: 'Please contact suppory with inquiries about your billing history',
        contactSupport: 'Contact Support',
        cancelMyPremium: 'Cancel My Premium',
        cancelAreYouSure: 'Are you sure you want to cancel?',
        cancelYouCanUncancel: (ex: Date) => `You can choose to undo your cancelation anytime before ${SafeFormat(ex, 'MMMM d')} to keep your premium.`,
        yesCancelMyPremium: 'Yes, Cancel My Premium',
        noKeepMyPremium: 'No, Keep My Premium',
        premiumEnd: 'Premium End',
        canceledTitle: (ex: Date) => `Your premium will end on ${SafeFormat(ex, 'MMMM d')}`,
        canceledSubtitle: 'Undo your cancelation below to keep your premium',
        undoCancelation: 'Undo Cancelation'
    },

    weather: {
        sectionTitle: 'Clima',
        current: {
            chanceOfPrecipitation: 'Presipitacion',
            dewPoint: 'Punto de rocío',
            humidity: 'Humedad',
            visibility: 'Visibilidad',
            feelsLike: 'Se siente como',
            airPressure: 'Presión del aire',
            wind: 'Viento',
            gusts: 'Ráfagas',
            sprayCondition: 'Pulverización'
        },
        hourly: {
            hour: 'Hora',
            climate: 'Clima',
            rainChance: 'Probabilidad de lluvia',
            rainAmount: 'Cantidad de lluvia',
            temperature: 'Temperatura',
            windGust: 'Ráfaga de viento'
        }
    },
    misc: {
        backToTorch: 'Ver Versión Anterior',
        selectAnAccount: 'Seleccione una cuenta',
        manageAccountApplications: 'Manage Account Applications',
        account: 'Cuenta',
        allAccounts: 'Todas Cuentas',
        showFewer: 'Ver menos',
        showLess: 'Ver menos',
        showXMore: (n: number) => `Ver ${n} más`,
        showMore: 'Ver más',
        accounts: (n: number) => `Mi${n === 1 ? '' : 's'} cuenta${n === 1 ? '' : 's'}`,
        heldBy: 'mantenido por',
        addAccountButton: 'Añadir cuenta',
        addAccount: 'Agregar cuenta de capital',
        addEquitiesAccount: 'Agregar cuenta de acciones',
        addFuturesAccount: 'Agregar cuenta de futuros',
        noResults: 'No hay resultados',
        noMoreResults: 'No hay más resultados',
        errorLoadingResults: 'Error al cargar resultados',
        tryAgain: 'Intentar otra vez',
        today: 'Hoy',
        ranges: {
            oneDay: '1D',
            twentyFourHours: '24h',
            fiveDay: '5D',
            oneWeek: '1W',
            oneMonth: '1M',
            threeMonths: '3M',
            sixMonths: '6M',
            oneYear: '1A',
            fiveYear: '5A',
            all: 'ALL'
        },
        search: {
            noResults: 'No se encontraron resultados, intente otra búsqueda',
            placeholder: 'Buscar seguridad...'
        },
        news: {
            noTitle: 'Sin titulo',
            noSummaryAvailable: 'Resumen no Disponible'
        },
        tradeTypeButton: {
            buyToOpen: 'compra para abrir',
            sellToOpen: 'vender para abrir',
            sellToClose: 'vender para cerrar',
            buyToClose: 'compra para cerrar',
            buy: 'Comprar',
            sell: 'Vender',
            shortSell: 'Venta corta',
            buySell: 'Compra-Venta',
            sellBuy: 'Venta-Compra',
            liquidate: 'Liquidar',
            liquidatePosition: (securityId: string) => `O bien, puede liquidar su posición, lo que venderá todas sus acciones en ${securityId}.`
        },
        chatWithSupport: 'Habla con nosotros',
        showVolume: 'Mostrar Volumen',
        showWorkingOrders: 'Mostrar órdenes de trabajo',
        loggingOut: 'Cerrando sesión...',
        amountEstimate: 'Esta cantidad es una estimación. Su orden se ejecutará al mejor precio posible',
        newIPO: 'Nuevas OPI en el horizonte.',
        offeringPrice: 'Precio oferta:',
        screenNotExist: 'Esta pantalla no existe.',
        goHomeScreen: '¡Ir a la pantalla de inicio!',
        stopAllStreaming: 'Detener todas las transmisiones'
    },

    header: {
        alerts: 'Alertas',
        tip: 'Consejo',
        trySearch: 'Intente buscar su acción favorita',
        summary: 'Resumen',
        positions: 'Posiciones',
        banking: 'Bancos',
        messages: 'Mensajes',
        profile: 'Perfíl',
        help: 'Ayuda',
        legalMore: 'Legal y más',
        goPaperless: 'Ir sin papel',
        documents: 'Documentos',
        tradeHistory: 'Pedidos',
        fixedPlus: 'Fixed+',
        transactions: 'Transacciones',
        projectedIncome: 'Ingresos proyectados',
        disclosures: 'Divulgación',
        logOut: 'Cerrar sesión',
        buyingPower: 'Poder adquisitivo',
        upcomingEarnings: 'Próximas Ganancias',
        upcomingIpos: 'IPOs',
        portfolioValue: 'Valor de la cartera',
        holdings: 'Valores en cartera',
        searchForAnything: 'Buscar cualquier cosa para intercambiar...',
        addToList: 'Añadir a una lista',
        exploreMarkets: 'Explora los mercados',
        optionsHint: 'para opciones',
        chatWithUs: 'Chatear con nosotros',
        emailUs: 'Envíanos un correo electrónico',
        resetTours: 'Ver Tour',
        verifyResetTours: '¿Está seguro de que desea restablecer el progreso de su recorrido?',
        amex: '¡Prueba AMEX hoy!',
        helpPopup: {
            contactUs: 'Contáct enos',
            liveChat: 'Chat en vivo'
        }
    },

    notFoundSceen: {
        header: 'Parece que esta página no existe',
        securityHeader: 'Parece que esta seguridad no existe...',
        otherOptions: 'Aquí hay algunas otras opciones que pueden gustarle',
        portfolioButton: 'Mi portafolio',
        positionsButton: 'Mis posiciones',
        mobile: {
            wrongTurn: 'Lo sentimos, ¡parece que nos hemos equivocado de dirección!',
            backToStoneX: 'Volver a StoneX'
        }
    },

    watchlistScreen: {
        items: (numItems?: number) => `${numItems} ${numItems === 1 ? 'artículo' : 'elementos'}`,
        notFound: {
            primary: 'Parece que esta lista de vigilancia no existe',
            secondary: "Seleccione otra lista de seguimiento o cree una haciendo clic en ' + ' a la derecha"
        },
        noItems: {
            primary: 'Parece que esta lista está vacía',
            secondary: (name: string) => `Todos los elementos agregados a '${name}' aparecerán aquí.`
        },
        marketCap: {
            header: '¿Qué es la capitalización de mercado?'
        },
        noLists: {
            noListTitle: 'Sin listas',
            noListSubtitle: 'Vigile sus valores favoritos con una lista. Crea tu primera lista a continuación.',
            createNewList: 'Crear nueva lista',
            addToList: 'Añadir a una lista',
            emptyListTitle: 'Parece que esta lista está vacía',
            emptyListSubtitle: 'Manténgase al día con sus empresas favoritas agregándolas a esta lista.',
            addMore: 'Añadir más'
        },
        defaultDescription: 'Agregar una descripción de la lista de vigilancia',
        createNewWatchlist: 'Crear nueva lista de vigilancia',
        addToWatchlist: (qsi: string) => `Añadir ${qsi} a la lista de vigilancia`,
        editWatchlist: 'Editar el nombre de la lista de seguimiento',
        createWatchlist: 'Crear lista de vigilancia',
        watchlistName: 'Nombre de la lista de seguimiento',
        create: 'Crear',
        update: 'Actualizar',
        topStories: 'Principales Noticias'
    },

    portfolioScreen: {
        skipToWatchlists: 'Saltar a las listas de observación',
        portfolio: 'Portafolio',
        buyingPower: {
            buyingPower: 'Poder Adquisitivo',
            marginCashAvailable: 'Margen Dinero Disponible',
            cashAvailable: 'Dinero Disponible',
            marginAvailable: 'Margen Disponible',
            nyseExcess: 'Exceso del NYSE',
            startUsingMargin: 'Empezar a usar margen',
            totalAvailableForTrading: 'Total Disponible',
            whichAccountToAddMargin: '¿A qué cuenta desea agregar margen?',
            initialMargin: 'Margen Inicial',
            netLiquidity: 'Liquidez Neta'
        },
        summary: {
            allAccounts: 'Todas mis cuentas',
            assetsHeldAway: 'Valor de Cartera Externa',
            buyingPower: 'Poder Adquisitivo',
            cash: 'Dinero',
            cashAndEquivalents: 'Fondos de dinero y efectivos',
            initialMargin: 'Margen Inicial',
            longMarketValue: 'Valor de Mercado',
            longOptionValue: 'Valor de la Opción Larga',
            totalAccountValue: 'Valor Total de la Cuenta',
            netLiq: 'Valor Neto de Liquidación',
            netOptionValue: 'Valor Neto de la Opción',
            openPnL: 'PyG Abierto',
            realizedPnL: 'P&L de hoy (realizado)',
            shortMarketValue: 'Valor de Mercado al Descubierto',
            shortOptionValue: 'Valor de la Opción Corta',
            summary: 'Resumen',
            totalPnL: 'PyG totales',
            unrealizedPnL: 'PyG abiertas (no realizadas)',
            viewProjectedIncome: 'Ver los ingresos proyectados'
        },
        portfolioBreakdown: {
            cashAndEquivalents: 'Dinero en efectivo',
            equities: 'Acciones',
            futures: 'Futuros',
            stocks: 'Acciones',
            mutualFunds: 'Fondos Mutuos',
            fixedIncome: 'Renta Fija',
            rightsAndWarrants: 'Derechos y Warrant',
            options: 'Opciones',
            uits: 'UITs',
            others: 'Otros',
            preciousMetals: 'Metales preciosos',
            entertainment: 'Metales preciosos',
            miscellaneous: 'Varios',
            telecommunications: 'Varios',
            broadcastingRadioAndTv: 'Radiodifusión - Radio y TV',
            automobileAndTrucks: 'Automòviles / Camiones',
            instrumentation: 'Automòviles / Camiones',
            notGrouped: 'No agrupado',
            investmentCompanies: 'Empresas de inversión',
            portfolioBreakdown: 'Distribución del Portafolio',
            holdingsBreakdown: 'Desglose de participaciones',
            contractsBreakdown: 'Desglose de contratos',
            accountBreakdown: 'Desglose de la Cuenta',
            shares: 'Acciones',
            breakdown: 'Desglose',
            sectorExposure: 'Exposición al sector',
            top10holdings: 'Principales 10 participaciones',
            investing: 'Invertir',
            representingAssets: (number: string) => `Representando el ${number} de los Activos Totales`
        },
        sectors: {
            sectors: 'Sectores',
            asOf: (date: Date) => `Desde ${SafeFormat(date, 'dd MMM yyyy, hh:mm bb')}`,
            customerDiscretionary: 'Consumo discrecional',
            healthCare: 'Salud',
            technology: 'Tecnología',
            realEstate: 'Mercado inmobiliario',
            consumerStaples: 'Productos de primera necesidad',
            utilities: 'Servicios públicos',
            communicationServices: 'Servicios de comunicación',
            financials: 'Finanzas',
            materials: 'Materiales',
            industrials: 'Industrial',
            energy: 'Energía',
            addToWatchlist: 'Agregar a la lista de seguimiento',
            noSecurities: 'Sin valores',
            emptyList: 'Parece que esta lista está vacía.'
        },
        topMovers: {
            futuresTopMovers: 'Elementos más variables del primer mes',
            topMovers: 'Valores más activos',
            topGainers: 'Acciones en alza',
            topLosers: 'Perdedores',
            unavailable: 'Datos no disponibles',
            topMoversUnavailable: 'Los más variables no están disponibles actualmente',
            frontMoversUnavailable: 'Los líderes del mercado no están disponibles en este momento',
            topGainersUnavailable: 'Los principales ganadores no están disponibles en este momento',
            topLosersUnavailable: 'Los principales perdedores no están disponibles en este momento'
        },
        glance: {
            title: 'Condiciones de inversión',
            portfolioValue: 'Valor de la cartera',
            accountValue: 'Valor de la cuenta',
            amountInvested: 'Cantidad invertida',
            amountInvestedInfo:
                'Esta cantidad incluye las posiciones de valores abiertas de su cuenta de corretaje, así como la base de efectivo y costo de su cuenta de futuros.',
            todaysGainLoss: 'Ganancia / Pérdida de Hoy',
            todaysGainLossInfo: 'Este cálculo no incluye ninguna posición de Futuros.',
            unrealizedGainLoss: 'Ganancia / Pérdida abierta',
            unrealizedGainLossInfo: 'G/P no realizadas actuales de todas las posiciones abiertas.',
            buyingPower: 'Poder de Compra',
            cash: 'Dinero',
            asOfPreviousClose: '(desde cerrado previo)',
            lastUpdated: (updated: string) => `Última actualización ${updated}`
        },
        sidebar: {
            stocks: 'Acciones',
            mutualFunds: 'Fondos mutuos',
            fixedIncome: 'Renta Fija',
            options: 'Opciones',
            shares: 'Acciones',
            watchlists: 'Listas de observación',
            sortBy: 'Ordenar por',
            price: 'Precio',
            value: 'Valor',
            display: 'Monitor',
            delete: 'Eliminar',
            add: 'Agregar',
            edit: 'Editar detalles...',
            expires: (date: Date) => `Expire ${SafeFormat(date, 'dd MMM yyyy')}`,
            strike: 'Precio de Ejercicio'
        },
        warnings: {
            pleaseCheckLater: 'Vuelva a consultar más tarde para obtener más información sobre este tema.',
            systemBulletin: 'Boletín del sistema',
            resolvingIssues: 'Estamos resolviendo una incidencia que afecta a la disponibilidad de nuestro ',
            system: 'sistema',
            systems: 'sistemas',
            weAreResolvingIssues: 'Estamos resolviendo una incidencia del sistema.',
            theseSystems: 'estos sistemas',
            theSystem: 'el sistema',
            incidentFirstReported: ' Este incidente fue reportado por primera vez ',
            statusEvaluated: (systems: string) => ` El estado de ${systems} fue evaluado por última vez `
        },
        totalPortfolioValue: 'Valor total de la cartera',
        asOfDate: 'Fecha',
        commonStock: 'Acción común',
        preferredStock: 'Acción preferente',
        newListName: 'Nombres de la lista nueva',
        create: 'Crear',
        documents: 'Documentos',
        depositFunds: 'Depositar fondos',
        readyToStart: (isBeta: boolean) => (isBeta ? '¿Listo para empezar?' : '¿Estás listo para operar en vivo?'),
        getStarted: 'Empezar por colocar su primera orden.',
        myPositions: 'Mis posiciones',
        topStories: 'Historias destacadas'
    },

    accountScreen: {
        accountBreakdown: 'Desglose de la cuenta',
        summaryDeficientChartPoints:
            'Actualmente, el gráfico de resumen no incluye uno o más de los valores de sus cuentas. Esto no tiene impacto en sus cuentas reales o poder adquisitivo.',
        accountDeficientChartPoints:
            'Actualmente, el cuadro de cuenta no tiene suficientes datos para presentar. Esto no tiene impacto en su cuenta real o poder adquisitivo.',
        pending: {
            title: (plural?: boolean) => `${plural ? 'Accounts' : 'Account'} Pending Approval`,
            info: (plural?: boolean) =>
                `Your ${
                    plural ? 'accounts are' : 'account is'
                } in the process of getting approval from one of our specialists. Please check back later to see if this status has been updated.`,
            extrasHeader: 'Mientras esperas',
            explore: 'Explora los mercados',
            getHelp: 'Obtenga ayuda de nuestro equipo de soporte'
        },
        viewDetails: 'Ver Detalles',
        noAssetBreakdownAvailable: 'No hay desglose de activos disponible para esta cuenta.',
        noIndustryBreakdownAvailable: 'No hay desglose por industria disponible para esta cuenta.',
        byAsset: 'Por activo',
        byIndustry: 'Por industria',
        buyingPower: {
            initialMargin: 'Margen inicial',
            netLiquidity: 'Liquidez Neta'
        },
        myAccountStatusApplication: {
            docsRequired: {
                title: 'Documentos Requeridos',
                info: 'Necesitamos algunos documentos de usted para procesar su solicitud.'
            },
            inProgress: {
                title: 'Solicitud en Revisión',
                info: 'Su cuenta está siendo revisada actualmente por uno de nuestros especialistas. Vuelve más tarde para ver si tu cuenta ha sido aprobada.'
            },
            rejected: {
                title: 'Solicitud Rechazada',
                info: 'Su solicitud de cuenta ha sido rechazada. Vea la solicitud de su cuenta o comuníquese con nuestro equipo de soporte a continuación.'
            },
            verificationFailed: {
                title: 'Verificación de Cuenta Fallida',
                info: 'No pudimos verificar cierta información en nuestra aplicación. Vea la solicitud de su cuenta o comuníquese con nuestro equipo de soporte a continuación.'
            },
            submitted: {
                title: 'Solicitud Presentada',
                info: '¡Su solicitud de cuenta ha sido enviada! Vuelve más tarde para ver si tu cuenta ha sido aprobada.'
            },
            viewApplication: 'Ver el estado de la solicitud'
        }
    },

    positionsScreen: {
        account: 'Cuenta',
        allIndustries: 'Todos los Sectores',
        allTypes: 'Todos Tipos',
        averageCost: 'Promedio Costo',
        change: 'Cambio',
        changePercent: 'Cambio %',
        closed: 'Cerrada',
        cost: 'Costo',
        date: 'Fecha',
        downloadAll: 'Descargar',
        downloadClosed: 'Bajar posiciones cerradas',
        downloadClosedFileName: () => `posiciones-cerrados-${SafeFormat(new Date(), 'yyyyMMdd-hhmmss')}.csv`,
        downloadFilename: () => `posiciones-${SafeFormat(new Date(), 'yyyyMMdd-hhmmss')}.csv`,
        hide: 'Ruducir',
        longAmount: 'Quantidade longa',
        marketValue: 'Valor de mercado',
        marketValueFull: 'Valor de mercado',
        name: 'Nombre',
        noOpenLots: 'No hay lotes abiertos para este puesto.',
        notGrouped: 'No agrupado',
        open: 'Abierta',
        openPnLPercent: 'PyG Abierto %',
        openPnL: 'PyG Abierto',
        positions: 'Posiciones',
        price: 'Precio',
        quantity: 'Cantidad',
        realizedPnlFor: (thingItsFor: string) => `Ganancias y pérdidas realizadas para ${thingItsFor}`,
        rollPosition: 'Rodar posición',
        shortAmount: 'Corto',
        show: 'Expandir',
        showClosedLots: 'Carga lotes cerrados',
        taxLotPreviousDayDisclosure: 'Los valores de costo y ganancias y pérdidas se calculan usando el costo ajustado donde esté disponible.',
        taxLotsFor: (thingItsFor: string) => `Lotes de impuesto para ${thingItsFor}`,
        taxYear: 'Año Fiscal',
        term: 'Término',
        todaysGainLoss: 'G/P de Hoy',
        todaysGainLossPercent: 'G/P %',
        todaysGainLossTab: 'Ganancia / Pérdida de Hoy',
        totalCost: 'Costo Total',
        totalGainLoss: 'G/P Total',
        totalGainLossPercent: 'G/P Total %',
        totalGainLossTab: 'Ganancia / Pérdida Total',
        type: 'Tipo',
        unrealizedProfitLoss: 'Ganancias/pérdidas no realizadas',
        sortBy: 'Ordenar por',
        industryGroups: {
            [IndustryGroups.cashAndEquivalents]: 'Dinero en efectivo',
            [IndustryGroups.automotiveAndTrucks]: 'Automòviles / Camiones',
            [IndustryGroups.broadcastingRadioAndTv]: 'Radio y Televisiòn',
            [IndustryGroups.conglomerates]: 'Conglomerados',
            [IndustryGroups.metalsAndMining]: 'Metales - Minería',
            [IndustryGroups.miscellaneous]: 'Variado',
            [IndustryGroups.mutualFunds]: 'Fondos Mutuos',
            [IndustryGroups.consumerProducts]: 'Productos de Consumo',
            [IndustryGroups.commercialPaper]: 'Pagaré',
            [IndustryGroups.treasuryBillsFlat]: 'Letras del Tesoro',
            [IndustryGroups.corporateBonds]: 'Bonos Corporatiovos',
            [IndustryGroups.mortgageBackedBonds]: 'Bonos respaldados por Hipotecas',
            [IndustryGroups.govermentBonds]: 'Bonos del Estado',
            [IndustryGroups.municipalBonds]: 'Bonos Municipales',
            [IndustryGroups.municipalBondsPut]: 'Bonos Municipales - Put',
            [IndustryGroups.preferredStocks]: 'Acciones Preferentes',
            [IndustryGroups.aerospace]: 'Aeroespacial',
            [IndustryGroups.advertising]: 'Publicidad',
            [IndustryGroups.airTransport]: 'Transporte Aéreo',
            [IndustryGroups.aluminum]: 'Aluminio',
            [IndustryGroups.autoParts]: 'Piezas De Automóvil',
            [IndustryGroups.banks]: 'Bancos',
            [IndustryGroups.beveragesBrewers]: 'Bebidas - Cerveceras',
            [IndustryGroups.beveragesDistillers]: 'Bebidas - Destiladoras',
            [IndustryGroups.beveragesSoftDrinks]: 'Bebidas-Bebidas Sin Alcohol',
            [IndustryGroups.biotechnology]: 'Biotecnología',
            [IndustryGroups.brokerageFirms]: 'Firma de Corretaje',
            [IndustryGroups.buildingMaterials]: 'Materiales de Construcción',
            [IndustryGroups.businessServices]: 'Servicios Empresariales',
            [IndustryGroups.cement]: 'Cemento',
            [IndustryGroups.chemicalDiversified]: 'Química - Diversificada',
            [IndustryGroups.chemicalSpecialties]: 'Química - Especialidades',
            [IndustryGroups.coal]: 'Carbón',
            [IndustryGroups.copper]: 'Cobre',
            [IndustryGroups.containersMetalGlass]: 'Contenedores- Metales / Vidrio',
            [IndustryGroups.containersPlastic]: 'Contenedores - Plástico',
            [IndustryGroups.cosmetics]: 'Cosméticos',
            [IndustryGroups.dataProcessing]: 'Procesamiento de Datos',
            [IndustryGroups.drugs]: 'Medicamentos',
            [IndustryGroups.electricEquipment]: 'Equipos Eléctricos',
            [IndustryGroups.ermergingGrowth]: 'Mercados en Crecimiento',
            [IndustryGroups.entertainment]: 'Entretenimiento',
            [IndustryGroups.finance]: 'Finanzas',
            [IndustryGroups.fertilizers]: 'Fertilizantes',
            [IndustryGroups.foods]: 'Alimentos',
            [IndustryGroups.foodServiceAndVending]: 'Servicios de alimentos y máquinas expendedoras',
            [IndustryGroups.forestProducts]: 'Productos Forestales',
            [IndustryGroups.gaming]: 'Empresas de Juegos',
            [IndustryGroups.healthCareFacilities]: 'Centros Médicos',
            [IndustryGroups.homeFurnishings]: 'Muebles',
            [IndustryGroups.hospitalSupplies]: 'Suministros Médicos',
            [IndustryGroups.hotelsMotels]: 'Hoteles - Moteles',
            [IndustryGroups.householdAppliances]: 'Electrodomésticos',
            [IndustryGroups.industrialServices]: 'Servicios Industriales',
            [IndustryGroups.instrumentation]: 'Instrumentación',
            [IndustryGroups.insurance]: 'Seguro',
            [IndustryGroups.investmentCompanies]: 'Empresa de Inversión',
            [IndustryGroups.leisureTime]: 'Tiempo Libre',
            [IndustryGroups.machinery]: 'Maquinaria',
            [IndustryGroups.machineTools]: 'Herramientas Mecánicas',
            [IndustryGroups.manufacturing]: 'Industria Manufacturera',
            [IndustryGroups.metalsFabricating]: 'Metales - Fabricación',
            [IndustryGroups.mobileHomes]: 'Casas Móviles',
            [IndustryGroups.officeEquipment]: 'Equipos Ofimáticos',
            [IndustryGroups.oilAndGas]: 'Petróleo y Gas',
            [IndustryGroups.oilService]: 'Servicio Petrolero',
            [IndustryGroups.paper]: 'Papel',
            [IndustryGroups.photography]: 'Fotografía',
            [IndustryGroups.printingAndPublishing]: 'Imprenta y Edición',
            [IndustryGroups.railroads]: 'Ferrocarriles',
            [IndustryGroups.railroadEquipment]: 'Equipos Ferroviarios',
            [IndustryGroups.realEstate]: 'Bienes Raíces',
            [IndustryGroups.restaurants]: 'Restaurantes',
            [IndustryGroups.retailStores]: 'Tienda minorista',
            [IndustryGroups.retailFoodChains]: 'Cadenas de Alimentación',
            [IndustryGroups.retailDrugChains]: 'Cadenas de Farmacias',
            [IndustryGroups.retailMerchandising]: 'Comercialización',
            [IndustryGroups.savingsAndLoan]: 'Ahorro y Préstamo',
            [IndustryGroups.shoes]: 'Zapatos',
            [IndustryGroups.soaps]: 'Jabones',
            [IndustryGroups.steels]: 'Acero',
            [IndustryGroups.telecommunications]: 'Telecomunicaciones',
            [IndustryGroups.textiles]: 'Textiles',
            [IndustryGroups.tiresAndRubber]: 'Llantas y Caucho',
            [IndustryGroups.tobacco]: 'Tabaco',
            [IndustryGroups.toys]: 'Juguetes',
            [IndustryGroups.transportation]: 'Transporte',
            [IndustryGroups.trucking]: 'Industria Camionera',
            [IndustryGroups.utilitiesElectric]: 'Servicios Públicos - Eléctrico',
            [IndustryGroups.utilitiesGas]: 'Servicios públicos - Gas',
            [IndustryGroups.utilitiesOther]: 'Utilidades - Otras',
            [IndustryGroups.options]: 'Opciones'
        },
        closedPositions: {
            parentHeader: {
                symbolId: 'Simbolo / ID',
                description: 'Descripción',
                totalQuantity: 'Cantidad Total',
                totalCost: 'Coste Total',
                totalProceeds: 'Ingresos Totales',
                totalRealizedGainLoss: 'Realizado G/P',
                totalRealizedGainLossPercent: 'Realizado G/P %'
            },
            childHeader: {
                adjustedCost: 'Costo Ajustado',
                closeDate: 'Fecha de Cierre',
                closePrice: 'Precio de Cierre',
                cost: 'Coste',
                exchange: 'Intercambio',
                openDate: 'Cita abierta',
                price: 'Precio',
                priceOf: (qsi: string) => `Precio de ${qsi}`,
                proceeds: 'Producto',
                quantity: 'Cantidad',
                realizedGainLoss: 'Ganancia / Pérdida Realizada',
                realizedGainLossPercent: '% de Ganancia / Pérdida Realizada',
                realizedGl: 'Ganancia/pérdida realizada',
                side: 'Lado',
                term: 'Término',
                totalCost: 'Coste Total',
                tradeDate: 'Fecha de negociación'
            }
        }
    },

    bankingScreen: {
        errors: {
            ainsIdentity: {
                title: 'No se puede verificar la identidad.',
                description:
                    'Lo sentimos, pero no pudimos verificar su identidad. Comuníquese con nosotros a continuación y haremos todo lo posible para solucionar las cosas.'
            },
            ainsMax: 'Lo sentimos, ha alcanzado el número máximo de cuentas bancarias vinculadas permitidas. Elimina uno para continuar.',
            ainsMaxVoid: 'Lo sentimos, la eliminación de la cuenta bancaria está limitada a una eliminación cada dos semanas. Por favor, inténtelo de nuevo más tarde.',
            betaDowntimeWindow: 'Las transferencias no están disponibles de 5pm a 8pm EST.',
            transfersUnavailable: 'Las transferencias no se pueden enviar en este momento.',
            insufficientWithdrawalBalanceTitle: 'Fondos Insuficientes',
            insufficientWithdrawalBalance:
                'No tiene fondos suficientes para la transferencia que ha solicitado. Si recientemente vendió una o más posiciones, es posible que deba esperar hasta el siguiente día de negociación para que sus fondos se liquiden y estén disponibles para la transferencia.',
            noAccountsAvailable: {
                title: 'No hay cuentas disponibles',
                description:
                    'Lo sentimos, pero no hay cuentas disponibles para agregar. Comuníquese con nosotros a continuación y haremos todo lo posible para solucionar las cosas.'
            },
            pendingAccount: 'Cuenta pendiente',
            retrievingAccountsFailed: {
                title: 'Lo sentimos, hubo un error al recuperar sus cuentas. Inténtelo de nuevo más tarde o ',
                actionTitle: 'comuníquese con el soporte.'
            }
        },
        nextVoidAllowed: (nextVoid: Date) => `Puedes eliminar esta cuenta el ${format(nextVoid, 'DD/MM/YYYY')}.`,
        maxReached: (maxAccounts: number) => `Se alcanzó un máximo de ${maxAccounts} cuentas.`,
        fundingError: {
            title: 'Ocurrió un error',
            subtitle: 'Vuelva a intentarlo o póngase en contacto con nosotros para obtener ayuda.',
            tryAgain: 'Intentar otra vez'
        },
        tryAgainIn: (dateForUnlock: Date) => `Intentó de nuevo ${SafeFormatToNow(dateForUnlock)}`,
        tryAgainTomorrow: 'Intenta de nuevo mañana',
        tryAgainOnMonday: 'Inténtalo de nuevo el lunes',
        dontSeeAccount: '¿No ve la cuenta que desea agregar? Comuníquese con nosotros a continuación y arreglaremos las cosas.',
        history: 'Historial',
        transactionHistory: 'Historial de transacciones',
        requests: 'Requests',
        disclaimer:
            'La información anterior es para información complementaria y solo con fines informativos. Consulte su estado de cuenta diario para el registro oficial de su cuenta.',
        transferFunds: 'Transferir Fondos',
        depositVerb: 'Depositar',
        withdrawVerb: 'Retirar',
        depositNoun: 'Deposito',
        withdrawalNoun: 'Retiro',
        fundsWillBeCredited: 'Los fondos serán acreditados a su cuenta bancaria en los proximos días.',
        fundsWillBeDebited: 'Su fondo estará disponible en breve.',
        from: 'Desde',
        to: 'Para',
        cashHeader: 'Dinero',
        depositFunds: 'Deposita fondos directamente en tu cuenta StoneX desde una cuenta bancaria vinculada.',
        withdrawFunds: 'Retirar fondos de su cuenta StoneX a una cuenta bancaria vinculada.',
        selectLinkedBankAcct: 'Seleccione la cuenta bancaria vinculada que le gustaría usar.',
        retrievingBankAcct: 'Recuperando sus cuentas bancarias...',
        linkBankAcctWith: 'Vincular cuenta bancaria con',
        amountMayNotExceed: (limit: number) => `La cantidad no puede exceder ${limit}.`,
        amountMustBeAtleast: (limit: number) => `La cantidad debe ser de al menos ${limit}.`,
        dailyLimitText: (limits) => [
            Light('Límite diario restante '),
            Dark(FormatNumber.toMoney(limits?.remainingClearance)),
            Light(' de '),
            Dark(FormatNumber.toMoney(limits?.dailyMaximum))
        ],
        dailyMaximumText: (limits: FundingLimits, isDeposit: boolean) => [
            Light('Límite diario restante '),
            Dark(FormatNumber.toMoney(isDeposit ? limits?.remainingDepositAmount : limits?.remainingWithdrawAmount)),
            Light(' de '),
            Dark(FormatNumber.toMoney(isDeposit ? limits?.maxDailyDepositAmount : limits?.maxDailyWithdrawAmount))
        ],
        dailyLimitText2: (limits) => {
            const cented = (money: number) => FormatNumber.toMoney(money).replace(/\.00$/, '');
            return `${cented(limits.transferredToday)} of ${cented(limits.dailyMaximum)} transferred today.`;
        },
        minimumAllowedDepositText: (isDeposit: boolean, minAllowedDeposit: number | null) => {
            const minAllowedDepositText =
                !!isDeposit && !!minAllowedDeposit ? [Light(' ( '), Dark(minAllowedDeposit ? FormatNumber.toMoney(minAllowedDeposit) : null), Light(' mínimo )')] : [];
            return minAllowedDepositText;
        },
        managementLimitText: (limits: FundingManagementLimits | null) => {
            if (!limits) return '';
            if (!limits.canAdd) {
                if (!limits.canVoid) return 'To add or remove linked accounts, please contact support';
                else return 'To add a linked account, please contact support';
            } else if (!limits.canVoid) return 'To remove a linked account, please contact support';
            else return '';
        },
        fundingAvailableBalance: (availableBalance?: number) => [Light('Disponible para retirar: '), Dark(FormatNumber.toMoneyOpt2(availableBalance))],
        amountToTransfer: (transferDirection?: 'deposit' | 'withdraw') => `Amount to ${transferDirection === 'deposit' ? 'Deposit' : 'Withdraw'}`,
        amount: 'Monto',
        review: 'Revisar',
        reviseTransfer: 'Revisar transferencia',
        depositsLocked: (days: number) =>
            days === 1
                ? 'Depósitos bloqueados por demasiados intentos fallidos. Vuelva a intentarlo mañana o póngase en contacto con el servicio de asistencia.'
                : `Depósitos bloqueados por demasiados intentos fallidos. Vuelva a intentarlo en ${days} días o póngase en contacto con el servicio de asistencia.`,
        withdrawLocked: (days: number) =>
            days === 1
                ? 'Retiros bloqueados por demasiados intentos fallidos. Vuelva a intentarlo mañana o póngase en contacto con el servicio de asistencia.'
                : `Retiros bloqueados por demasiados intentos fallidos. Vuelva a intentarlo en ${days} días o póngase en contacto con el servicio de asistencia.`,
        back: 'Regresar',
        amountExceedsLimit: 'Cantidad excede el límite',
        amountExceedsBalance: 'La cantidad excede el saldo bancario',
        amountExceedsAccountBalance: 'La cantidad excede el saldo de la cuenta',
        accountIsPending: 'La cuenta esta pendiente',
        plaidReauthNeeded: 'Se requiere reautenticación con Plaid',
        plaidAuthenticationBroken: 'Plaid autenticación rota, comuníquese con el soporte',
        addYourBankAccount: 'Añadir su cuenta de banco',
        addABankAccount: 'Agregar una Cuenta Bancaria',
        addASandboxBankAccount: 'Agregar una cuenta bancaria Sandbox',
        pleaseWait: 'Espere, por favor...',
        validatingTransfer: 'Validando Transferencia',
        thereWasAProblem: 'Hubo un problema',
        unknownError: {
            title: 'Algo inesperado salió mal.',
            subtitle: 'Póngase en contacto con el soporte.'
        },
        fundingNotAllowed: {
            title: 'No se permite la financiación.',
            subtitle: 'Póngase en contacto con el soporte si cree que ha recibido esto por error.'
        },
        failedDailyFundingLimitCheck: {
            title: 'Límites diarios excedidos.',
            subtitle: 'Póngase en contacto con el soporte si cree que ha recibido esto por error.'
        },
        cosmosPlaidAccessFailed: {
            title: 'No se pudieron recuperar los datos de Plaid.',
            subtitle: 'Póngase en contacto con el soporte o inténtelo de nuevo.'
        },
        plaidBalanceCheckFailed: {
            title: 'No se pueden verificar los fondos disponibles.',
            subtitle: 'Póngase en contacto con el soporte si cree que ha recibido esto por error.'
        },
        cosmosPlaidLinkIdMatchFailed: {
            title: 'Error de búsqueda de cuenta vinculada.',
            subtitle: 'Póngase en contacto con el soporte o inténtelo de nuevo.'
        },
        plaidIdentityInfoFailed: {
            title: 'No se pudieron recuperar los datos bancarios de Plaid.',
            subtitle: 'Póngase en contacto con el soporte o inténtelo de nuevo.'
        },
        likeKindNameCheckFailed: {
            title: 'No se puede verificar la propiedad de la cuenta.',
            subtitle: 'Póngase en contacto con el soporte.'
        },
        previouslyLinkedAccountIdLookupFailed: {
            title: 'No se pudo vincular la cuenta de Plaid con la cuenta de StoneX One.',
            subtitle: 'Póngase en contacto con el soporte o inténtelo de nuevo.'
        },
        failedToLinkAccount: {
            title: 'No se pudo vincular la cuenta de Plaid con la cuenta de StoneX One.',
            subtitle: 'Póngase en contacto con el soporte o inténtelo de nuevo.'
        },
        transferWindowClosed: {
            title: 'El sistema de transferencia está fuera de servicio por mantenimiento.',
            subtitle: 'Póngase en contacto con el soporte si cree que ha recibido esto por error.'
        },
        transferFailed: {
            title: 'Transferencia fallida.',
            subtitle: 'Póngase en contacto con el soporte o inténtelo de nuevo.'
        },
        transferRequestFailed: 'Request failed',
        submittedRequestSuccessfully: 'Request submitted',
        asyncInfo: 'You will be notified once this request is processed. Please do not logout until this request has processed.',
        depositsLockedError: {
            title: 'Depósitos bloqueados',
            subtitle: 'Demasiados intentos fallidos.'
        },
        minimumDepositNotMet: {
            title: (amt: number) => `Monto mínimo de depósito ${FormatNumber.toMoneyOpt2(amt)} no alcanzado.`,
            subtitle: 'Póngase en contacto con el soporte si cree que ha recibido esto por error.'
        },
        maximumAccountsReached: {
            title: 'No se puede agregar la cuenta. Número máximo de cuentas alcanzado',
            subtitle: 'Póngase en contacto con el soporte si cree que ha recibido esto por error.'
        },
        ainsInstructionsInactive: {
            title: 'No se puede recuperar la información de la cuenta bancaria para la transferencia.',
            subtitle: 'Póngase en contacto con el soporte.'
        },
        maximumDepositsReached: {
            title: 'Depósitos bloqueados',
            subtitle: (amt: number, days: number) =>
                days === 1 ? `Solo se pueden hacer ${amt} depósitos por día.` : `Solo se pueden hacer ${amt} depósitos cada ${days} días.`
        },
        maximumWithdrawalsReached: {
            title: 'Retiros bloqueados',
            subtitle: (amt: number, days: number) =>
                days === 1 ? `Sólo se puede realizar ${amt} retiro por día.` : `Solo se puede realizar ${amt} retiro cada ${days} días.`
        },
        pleaseTryAgain: 'Inténtalo de nuevo ',
        submittedSuccessfully: 'Transferencia enviada',
        operationSubmittedSuccessfully: (transfer) => {
            const operation = (() => {
                switch (transfer.transferDirection) {
                    case 'deposit':
                        return 'Deposit';
                    case 'withdraw':
                        return 'Withdrawal';
                    default:
                        return '';
                }
            })();

            return `${operation} Submitted`;
        },
        startAnotherTransfer: 'Iniciar otra transferencia',
        returnToSummary: 'Regresar al Resumen',
        bankingUnavailable: 'Banca no Disponible',
        pleaseComeBackLaterOrContactUs: 'Lo sentimos, las funciones bancarias no están disponibles actualmente. Vuelve a intentarlo más tarde o comunícate con nosotros.',
        depositUnavailableExplanation:
            'Lo sentimos, el depósito no está disponible actualmente. Vuelva a intentarlo más tarde o comuníquese con nosotros a continuación y nos encargaremos de todo.',
        depositUnavailableDueToTimeExplanation:
            'Lo sentimos, el depósito no está disponible entre las 2PM y las 8AM EST. Vuelva a intentarlo fuera de este horario o comuníquese con nosotros a continuación y nos encargaremos de todo.',
        depositUnavailableDueToTimeExplanationShort: 'El depósito no está disponible entre las 5PM y las 6PM EST',
        depositUnavailableDueHolidaysExplanationShort: 'El depósito no está disponible durante las vacaciones comerciales',
        withdrawUnavailableExplanation:
            'Lo sentimos, el retiro no está disponible entre las 2PM y las 7AM EST. Vuelva a intentarlo fuera de este horario o comuníquese con nosotros a continuación y nos encargaremos de todo.',
        withdrawUnavailableExplanationShort: 'El retiro no está disponible entre las 2PM y las 7AM EST',
        withdrawUnavailableWeekendsExplanation: 'El retiro no está disponible durante los fines de semana',
        withdrawUnavailableHolidaysExplanation: 'El retiro no está disponible durante las vacaciones',
        accountPending: 'Your selected StoneX account is pending approval',
        billPay: 'Pago de Facturas',
        creditCardDirect: 'Tarjeta de Crédito Directa',
        wiringInstructions: 'Instrucciones de Transferencia Bancaria',
        viewWiringInstructions: 'Ver Instrucciones de Transferencia Bancaria',
        wiringInstructionsPdfUrl: 'https://snex1storage.blob.core.windows.net/$web/docs/en-US/WiringInstructions.pdf',
        verificationStatus: (status) => (status.includes('pending') ? 'Verificación Pendiente' : 'Fallo en la Verificación'),

        // Mobile
        selectAccount: 'Seleccionar Cuenta',
        forAddedSecurity: 'Para mayor seguridad, se le pedirá que vuelva a ingresar sus credenciales bancarias',
        cannotAddLimitReachedTitle: 'No se puede agregar otra cuenta',
        cannotOpenPlaid: 'No se puede abrir Plaid, no se proporcionó token de enlace',
        cannotAddLimitReachedExplainer: 'Solo se pueden vincular dos cuentas bancarias a la vez',
        linkAcctContactSupport: 'Para agregar una cuenta vinculada, comuníquese con soporte',
        confirmText: (transfer: Partial<TransferRequest>, snexAccount: Account, fundingSource?: FundingSource) => {
            const isDeposit = transfer.transferDirection === 'deposit';
            const extPreposition = isDeposit ? 'from' : 'into';
            const snexPreposition = isDeposit ? 'into' : 'from';
            const source = fundingSource || transfer.fundingSource;

            return [
                { text: `You are ${isDeposit ? 'depositing' : 'withdrawing'}`, shade: 'light' },
                { text: FormatNumber.toMoneyOpt2(transfer.transferAmount), shade: 'dark' },
                { text: `${extPreposition} your`, shade: 'light' },
                { text: transfer.fundingSource?.name, shade: 'dark' },
                { text: 'account ending in', shade: 'light' },
                { text: `****${transfer?.fundingSource?.mask}`, shade: 'dark' },
                { text: `${snexPreposition}`, shade: 'light' },
                { text: snexAccount?.nickname || snexAccount?.name, shade: 'dark' }
            ];
        },
        lost: {
            title: 'No Encontrada',
            subtitle: '¡Parece que hicimos un giro equivocado! Vuelva a intentarlo y, si el problema persiste, comuníquese con nosotros.',
            startOver: 'Intentar Otra Vez'
        },

        limitReached: 'Límite Alcanzado',
        linkYourBankAccount: 'Vincule Su Cuenta Bancaria',
        limitReachedExplainer: (limits: FundingManagementLimits): ShadedTextList => {
            const maxLinked = limits?.maxLinkedCount;
            const maxVoid = limits?.maxVoidCount;
            const voidDays = limits?.maxVoidFrequencyDays;

            const s = (n: number) => (n === 1 ? '' : 's');
            const num = Snex1Spanish.general.writtenInteger0Thru10;

            const addRule: ShadedTextList = [
                {
                    text: 'As a security measure, you are only allowed to add ',
                    shade: 'light'
                },
                {
                    text: `${num(maxLinked)} bank account${s(maxLinked)}`,
                    shade: 'dark'
                },
                { text: '. ', shade: 'light' }
            ];

            const voidRule: ShadedTextList = [
                {
                    text: 'Be advised that you also may only remove ',
                    shade: 'light'
                },
                { text: `${num(maxVoid)} account${s(maxVoid)}`, shade: 'dark' },
                { text: ' every ', shade: 'light' },
                { text: `${num(voidDays)} day${s(voidDays)}`, shade: 'dark' },
                { text: '. ', shade: 'light' }
            ];

            const contactSupport: ShadedTextList = [
                {
                    text: 'If you wish to add or remove an account, please contact support below.',
                    shade: 'light'
                }
            ];

            return [...addRule, ...voidRule, ...contactSupport];
        },
        limitReachedExplainerV2: (maxAdd: number, maxVoid: number, voidDays: number): ShadedTextList => [
            { text: 'As a security measure, you are only allowed to add', shade: 'light' },
            { text: `${maxAdd} bank account${maxAdd === 1 ? '' : 's'}`, shade: 'dark' },
            { text: '. Be advised that you may also only remove ', shade: 'light' },
            { text: `${maxVoid} account${maxVoid === 1 ? '' : 's'}`, shade: 'dark' },
            { text: ' every ', shade: 'light' },
            { text: `${voidDays} day${voidDays === 1 ? '' : 's'}`, shade: 'dark' },
            { text: '. If you wish to add or remove an account, please reach out below.', shade: 'light' }
        ],
        youMayLinkNMoreAccounts: (n) => {
            if (n <= 0) return 'You may not link any more bank accounts';
            return `You may add ${n} more bank account${n === 1 ? '' : 's'}`;
        },
        linkYourAccountToAddFunds: 'Vincule su cuenta bancaria para agregar fondos a su cuenta StoneX.',
        authenticateWithPlaid: 'Autenticar con Plaid',
        noBankAccounts: 'Sin Cuentas Bancarias',
        linkAnotherBankAccount: 'Vincular Otra Cuenta Bancaria',

        acats: {
            acatsComingSoon: {
                title: 'Próximamente transferencias de cuenta!',
                subtitle: 'Por favor, póngase en contacto con nosotros con cualquier pregunta.'
            },
            infoIcon: 'Rechazos ACATS - Causas Más Comunes',
            transferAccount: 'Cuenta de Transferencia',
            introText:
                'Transfiera efectivo e inversiones de otro corredor a su cuenta StoneX One, ya sea total o parcialmente. Envíe su transferencia en minutos y vea que los activos transferidos aparecen en su cuenta en 2 o 3 semanas.',
            from: 'De',
            accountNumber: 'Número de cuenta',
            accountTitle: 'Titulo de Cuenta',
            accountSubtitle: 'Subtítulo de Cuenta',
            to: 'A',
            start: 'Comenzar',
            continue: 'Continuar',
            skip: 'Saltar',
            back: 'Regresa',
            submit: 'Entregar',
            selectTransferType: 'Seleccionar Tipo de Transferencia',
            fullTransfer: 'Transferencia Completa',
            fullTransferDescription: 'Transfiera todo el efectivo y las posiciones desde su cuenta externa a su cuenta StoneX One',
            partialTransfer: 'Transferencia Parcial',
            partialTransferDescription: 'Seleccione el efectivo y las posiciones que le gustaría mover a su cuenta de StoneX',
            cashOptions: 'Opciones de Efectivo',
            transferAllCash: 'Transferir Todo el Efectivo',
            transferNoCash: 'Transferir Sin Efectivo',
            transferCustomCash: 'Transferir Monto Personalizado',
            positions: 'Posiciones',
            transferNoPositions: 'Transferencia Sin Posiciones',
            addPosition: 'Agregar Posiciones',
            positionAccountType: 'Tipo de Cuenta',
            cash: 'Efectivo',
            margin: 'Margen',
            all: 'Todo',
            custom: 'Costumbre',
            or: 'O',
            positionQuantity: 'Cantidad',
            done: 'Hecho',
            transferringAllCash: 'Todo en Efectivo',
            transferringNoneCash: 'Sin Efectivo',
            transferringCustomCash: (amount: number) => `Transferir ${FormatNumber.toMoney(amount)}`,
            transferringNoPositions: 'Sin Posiciones',
            confirmTransfer: (type: 'Full' | 'Partial' | 'Unknown') => {
                if (type === 'Full') return 'Confirmar Transferencia Completa';
                else if (type === 'Partial') return 'Confirmar Transferencia Parcial';
                else return 'Confirmar Transferencia';
            },
            transferringFull: 'Todos los activos se transferirán a su cuenta de StoneX.',
            assets: 'Activos',
            noAssetsSelected: {
                title: 'No se puede enviar la transferencia; no hay activos seleccionados',
                subtitle: 'Regrese y agregue efectivo o posiciones a su transferencia.',
                switchToFull: 'Cambiar a transferencia completa'
            },
            submitting: {
                title: 'Enviar tu transferencia',
                subtitle: 'Espere por favor...'
            },
            success: {
                title: '¡Transferencia enviada!',
                subtitle: 'Su cuenta debe transferirse dentro de los próximos 14 a 21 días hábiles.'
            },
            failure: {
                title: 'Algo salió mal',
                subtitle: 'Póngase en contacto con nosotros y arreglaremos las cosas.'
            },
            returnToSummary: 'Regresar al Resumen'
        }
    },

    newVersionScreen: {
        newVersion: 'Nueva versión',
        nowAvailable: 'Ya disponible',
        updateAndSee: '¡Actualice y vea cómo podemos brindarle una mejor experiencia para su viaje financiero!',
        update: 'Actualizar',
        notNow: 'Ahora No',
        accountInfoNotShared: 'Cuando actualice, la información de su cuenta no se compartirá con ninguna entidad de terceros.',
        privacyPolicyLearnMore: 'Obtenga más información sobre nuestra política de privacidad.'
    },

    messagesScreen: {
        messages: 'Mensajes',
        noMessages: 'No hay mensajes',
        errorFetchingMessages: 'Lo sentimos, no pudimos recibir sus mensajes en este momento',
        viewAll: 'Ver todos',
        read: 'Leído',
        messageId: 'ID',
        stoneXOneTeam: 'Equipo StoneX One',
        unknownSender: 'Remitente desconocido',
        backToAllMessages: 'Volver',
        deleteAll: 'Eliminar Todos',
        noMessagesTitle: 'Tus mensajes aparecerán aquí',
        noMessagesSubtitle: 'Su bandeja de entrada está vacía. Una vez que reciba los mensajes, aparecerán aquí.',
        newMessage: 'Nuevo mensaje'
    },

    profileScreen: {
        profile: 'Perfíl',
        logOut: 'Cerrar sesión',
        copyClipBoard: 'Copiar al Portapapeles',
        emailSettings: 'Configuración de correo electrónico',
        settings: 'Ajustes',
        about: 'Sobre',
        build: 'Estructura',
        version: 'Versión',
        snex1Premium: 'StoneX One Premium',
        deactivate: 'Desactivar Membresía',
        errorDuplicateValue: 'El valor ingresado coincide con el valor registrado. No se requieren mas acciones.',
        tooltip: {
            oneProNetwork:
                'One Pro Network ofrece análisis en profundidad, alertas de escritorio en tiempo real y videos informativos sobre acciones, materias primas, divisas y tendencias macroeconómicas, todo preparado por analistas de StoneX. Esta información completa le permite tomar decisiones comerciales más informadas.'
        },
        tabs: {
            personalInfo: 'Información Personal',
            accounts: 'Cuentas',
            paperless: 'Preferencias de Communicacion',
            local: 'Local'
        },
        themes: {
            dark: 'Oscuro',
            light: 'Ligero',
            system: 'Preferencia del Sistema',
            marketHours: 'Horario del Mercado de Renta Variable Estadounidense'
        },
        personalInfo: {
            tabs: {
                profilePicture: 'Foto',
                personalDetails: 'Detalles Personales',
                preferences: 'Preferencias',
                notifications: 'Configuración de Notificaciones',
                taxInformation: 'Información fiscal',
                premiumSubscription: 'Su suscripción'
            },
            viewPersonalInfo: {
                title: 'Detalles Personales',
                name: 'Nombre',
                email: 'Correo electrónico',
                birthDate: 'Fecha de nacimiento'
            },
            loginForm: {
                signup: 'Inscribirse',
                next: 'Siguiente',
                code: 'Código',
                codeSent: 'Código enviado',
                phoneNumber: 'Número de Teléfono',
                tosAndPrivacyConsentText: [
                    { text: 'Al iniciar sesión, usted acepta nuestra ', shade: 'light' },
                    { text: 'Términos de Servicio', shade: 'dark', id: 'tos' },
                    { text: ' y ', shade: 'light' },
                    { text: 'Política de Privacidad', shade: 'dark', id: 'privacy' },
                    { text: '.', shade: 'light' }
                ],
                verificationCodeSentMessage: 'Hemos enviado un código de verificación a su dirección de correo electrónico. Por favor ingrese el código aquí.',
                confirmPassword: 'Confirmar contraseña',
                logIn: 'Acceso',
                startTradingMessage: 'Comenzar a operar',
                getStarted: 'Empezar',
                wantMore: '¿Quieren más?',
                startTrading: (qsi: string) => `Empezar a negociar ${qsi}`,
                couldNotLogIn: 'Lo sentimos, hubo un problema al iniciar sesión',
                couldNotResetYourPassword: 'Lo sentimos, no pudimos restablecer su contraseña',
                employeeEnterCode: 'Ingrese su código de Okta Verify a continuación.',
                startupSteps: {
                    fetching: 'Obteniendo estado de solicitud de cuenta...',
                    featureFlags: 'Obteniendo indicadores de características...',
                    fallback: 'Ejecutando inicio de sesión alternativo...',
                    recording: 'Token de grabación...',
                    loading: 'Cargando'
                },
                username: {
                    email: 'Correo electrónico'
                },
                confirmEmployeeEmail: {
                    title: 'Aviso de Inicio de Sesión del Empleado',
                    subtitle:
                        'Se alienta a los empleados de StoneX a utilizar un correo electrónico que no sea de la empresa para acceder a StoneX One. Si continúa, será dirigido a nuestro portal de inicio de sesión en línea.',
                    androidInstructionsTitle: 'Nota para Usuarios de Android',
                    androidInstructions:
                        'Se recomienda a los usuarios de Android que copien el código de verificación de la aplicación Okta Verify antes de continuar, elijan ingresar un código cuando se les solicite y peguen el código cuando se les solicite.',
                    continueAnyway: 'De Todas Maneras, Continúe'
                },
                confirmSignup: {
                    newUserTitle: '¡Bienvenido a StoneX One!',
                    newUserSubtitle: (email: string) => `Parece que no tenemos el correo electrónico ${email} en nuestro sistema, ¿continuar registrándote?`,
                    returningUserTitle: '¡Bienvenido de nuevo a StoneX One!',
                    returningUserSubtitle: `Parece que no pudiste completar el registro cuando intentaste inscribirte anteriormente. Necesitaremos que vuelvas a ingresar algunos datos y verifiques tu correo electrónico. ¿Te gustaría continuar?`
                },
                password: {
                    forgotPassword: 'Has Olvidado tu Contraseña',
                    resetPassword: 'Restablecer la Contraseña',
                    recoverAccount: 'Recuperar Cuenta',
                    password: 'Contraseña',
                    title: 'Recuperación de contraseña',
                    passwordUpdated: 'Contraseña actualizada',
                    passwordUpdatedMessage: 'Su contraseña se ha actualizado correctamente',
                    ok: 'bien',
                    cancel: 'Cancelar',
                    enterNewPassword: 'Ingrese su nueva contraseña a continuación.',
                    tryAgain: 'Inténtalo de nuevo',
                    updatePassword: 'Actualizar contraseña',
                    passwordPlaceholder: 'Contraseña',
                    confirmPasswordPlaceholder: 'Confirmar contraseña',
                    invalidTitle: 'Nueva contraseña no válida',
                    invalidMessage: 'La contraseña que ingresó no cumple con nuestros requisitos actuales. Por favor ingrese una nueva contraseña',
                    incompleteTitle: 'No se pudo validar el enlace de recuperación de contraseña',
                    incompleteMessage: 'Lo sentimos, no pudimos completar la recuperación de su contraseña. Por favor inténtalo de nuevo.',
                    locked: 'Iniciar sesión bloqueado',
                    lockedMessage: 'Lo sentimos, su acceso a StoneX One ha sido bloqueado debido a repetidos intentos fallidos de inicio de sesión. Le hemos enviado un correo electrónico con los pasos para restaurar su acceso.',
                    lockedError: 'Error de inicio de sesión'
                },
                mfa: {
                    title: 'Verificación de Múltiples Factores',
                    sendingCodePleaseWait: 'Enviando código, por favor espere',
                    mfaCodeSendMessage: 'Hemos enviado un código de verificación a tu número de teléfono. Por favor ingrese el código aquí.',
                    pleaseEnterYourPhone: 'Necesitamos su número de teléfono para autenticarlo. Por favor, introduzca su número de teléfono a continuación.'
                },
                bioauth: {
                    biometrics: 'Biometría',
                    whatBiometricsAre: '¿Le gustaría utilizar un factor biométrico (huella digital, rostro, etc.) para acceder a su cuenta?',
                    allUnlocksTitle: 'Todos los Desbloqueos',
                    allUnlocksDescription: 'Le pediremos su factor biométrico cada vez que inicie StoneX One',
                    verificationOnlyTitle: 'Solo Verificación',
                    verificationOnlyDescription: 'Le pediremos su factor biométrico solo cuando necesitemos verificar su identidad',
                    disableTitle: 'Desactivar', // Variant for config screen
                    disableDescription: 'Borraremos su perfil biométrico de StoneX One y nunca le pediremos su factor biométrico',
                    enableTitle: 'Habilitar biometría',
                    enableDescription: 'Se utilizará autenticación biométrica para acceder a StoneX One',
                    notDesiredTitle: 'No, no utilices datos biométricos',
                    notDesiredDescription: 'El acceso no estará protegido con datos biométricos (no recomendado)',
                    bioEnabledTitle: 'Biometría habilitada',
                    bioEnabledDescription: 'Su cuenta está protegida con autenticación biométrica',
                    bioDisabledTitle: 'Biometría deshabilitada',
                    bioDisabledDescription: 'StoneX One no utilizará autenticación biométrica cuando intente iniciar sesión (no recomendado)',
                    notpermitted: {
                        title: 'Biométrica no habilitada',
                        message: 'Habilite la biometría en la configuración para habilitar el inicio de sesión con identificación facial o huella digital.',
                        link: 'Permitir',
                        dismiss: 'Ahora no'
                    },
                    retryBioAuth: 'Rever',
                    disableBiometrics: 'Deshabilitar Biometría',
                    yesUpdateBiometrics: 'Sí, actualizar Biometría',
                    passwordUpdateSuccess: '¡Contraseña actualizada exitosamente!',
                    confirmReenableBioAuth: '¿Desea volver a habilitar el inicio de sesión biométrico con su nueva contraseña?'
                },
                country: {
                    countryOfCitizenship: 'País de Ciudadanía'
                },
                passwordReset: {
                    resetSent: 'Mensaje de Eecuperación Enviado',
                    instructions: 'Le hemos enviado un correo electrónico con más pasos para ayudarlo a recuperar el acceso a su cuenta.',
                    returnToLogin: 'Volver a Iniciar Sesión'
                },
                whiteLabelAlert: {
                    title: 'No se encontró ninguna cuenta',
                    message: 'Comuníquese con su representante de ventas o comuníquese con el soporte si cree que se trata de un error.',
                    dismiss: 'Despedir'
                },
                recovery: {
                    unlockAccount: 'Desbloquear Cuenta',
                    unlockMessage: 'Recupera el acceso a tu cuenta en caso de que haya sido bloqueada.',
                    unlockSent: 'Desbloquear correo electrónico enviado',
                    unlockSentMessage: 'Busca un correo electrónico de StoneX con instrucciones para desbloquear tu cuenta.',
                    cannotUnlock: 'No se puede desbloquear la cuenta',
                    resetPassword: 'Restablecer contraseña',
                    resetMessage: 'Restablece tu contraseña si la has olvidado.',
                    back: 'Atrás',
                    dismiss: 'Descartar',
                    contactSupport: 'Contactar con soporte',
                    errorTitle: 'Algo salió mal',
                    errorMessage: "Parece que algo salió mal al intentar enviar instrucciones de desbloqueo."
                }
            },
            profile: 'Perfil',
            firstName: 'Nombre',
            lastName: 'Apellido',
            emailAddress: 'Correo electrónico',
            language: 'Idioma',
            theme: 'Tema',
            themeDescription: 'La preferencia del sistema seguirá el tema de su dispositivo y el horario del mercado se desactivará cuando el mercado no esté abierto.',
            oneClickTrading: 'Operaciones con un Clic',
            oneClickTradingDescription:
                'El comercio con un clic le permite realizar una operación con un solo clic sin tener que revisar la operación. Puede deshabilitar el comercio con un clic para continuar viendo la pantalla de revisión antes de realizar un comercio.',
            advancedChartTradeTicket: 'StoneX One Advanced Chart Trade Ticket',
            advancedChartTradeTicketDescription:
                'Habilite el ticket comercial estándar de StoneX One en el gráfico avanzado para que pueda realizar pedidos para cualquier activo dentro de los gráficos avanzados, incluidas criptomonedas, pedidos de cantidades fraccionarias u pedidos de criptomonedas en denominaciones de dólares estadounidenses.',
            oneProNetwork: 'Una red profesional',
            oneProNetworkDescription:
                'One Pro Network ofrece análisis en profundidad, alertas de escritorio en tiempo real y videos informativos sobre acciones, materias primas, divisas y tendencias macroeconómicas, todo preparado por analistas de StoneX. Esta información completa le permite tomar decisiones comerciales más informadas.',
            futuresStrikes: 'Huelgas de la cadena de opciones de futuros',
            optionsChainStrikes: 'Huelgas de cadena de opciones',
            systemDefault: 'Sistema por defecto',
            dockSidePanel: 'Órdenes abiertas de muelle y lista de seguimiento',
            tradingViewTicker: 'Barra de teletipo',
            tradingViewTickerDescription:
                'La barra de teletipo le permite echar un vistazo horizontal a estadísticas importantes de instrumentos, impulsada por TradingView. Incluye el último precio de los símbolos, más el cambio diario. Algunos precios pueden tener un retraso de 15 minutos o más.',
            dockSidePanelDescription:
                'El panel lateral Órdenes abiertas y Lista de observación le permite ver sus órdenes abiertas de acciones y opciones, fondos mutuos y futuros, así como su lista de observación. Tenga en cuenta que esto no aparecerá en las pantallas Resumen o Cuenta.',
            timezone: 'Zona horaria',
            edit: 'Actualizar',
            email: 'Correo electrónico',
            smsPhone: 'SMS Teléfono',
            notificationsDescription: 'Háganos saber cómo y cuándo prefiere que nos comuniquemos con usted para los mensajes no-esenciales a continuación.',
            sendEmail: 'Envíenme un Correo electrónico',
            sendText: 'Envíenme un Mensaje de texto',
            smsNumber: 'Número de teléfono SMS',
            addPhoneNumber: 'Agregar el número de teléfono',
            verificationNeeded: 'Verificación Necesaria',
            profilePictureDisclaimer: (maxMb: number) =>
                `La foto de perfil que usted ha seleccionado será visible públicamente y está sujeta a nuestros términos de uso. Seleccione una imagen de un tamaño de menos de ${maxMb} MB.`,
            removeProfilePicture: 'Eliminar la foto actual',
            imageTooLarge: (maxMb: number) => `Tu foto es demasiado grande. Por favor, seleccione una imagen de un tamaño de menos de ${maxMb} MB.`,
            unexpectedUploadError: 'Ocurrió un problema inesperado al actualizar su imagen',
            idType: {
                idType: 'Tipo de identificación',
                alienId: 'Identificación de Extranjero',
                governmentId: 'ID de gobierno',
                passport: 'Pasaporte',
                ssn: 'Número de Seguro Social',
                taxIdEin: 'Identificación fiscal de EE. UU. / EIN'
            },
            mdcVerify: {
                email: {
                    title: 'Actualizar correo electrónico',
                    inputText: 'Ingrese su nueva dirección de correo electrónico a continuación.',
                    tryDifferent: 'Usar una dirección de correo electrónico diferente',
                    successText: '¡Dirección de correo electrónico verificada y actualizada!'
                },
                phone: {
                    title: 'Actualizar teléfono',
                    phone: 'Teléfono',
                    phoneNumber: 'Número de teléfono',
                    inputText: 'Ingrese su nuevo número de teléfono a continuación.',
                    tryDifferent: 'Usar un número de teléfono diferente',
                    successText: '¡Número de teléfono verificado y actualizado!'
                },
                pin: 'PIN',
                badPin: 'Lo siento, no se reconoce ese PIN',
                resendPin: 'Reenviar PIN de Verificación',
                unableToSendPin: 'No se puede entregar su código',
                unableToSendPinInstructions: (num) =>
                    `Parece que su número de teléfono ha optado por no recibir nuestros mensajes de texto. Envía un mensaje de texto con START al ${FormatNumber.toPhone(
                        num
                    )} y vuelve a intentarlo.`,
                tryAgain: 'Intentar otra vez',
                unexpectedError: 'Lo sentimos, encontramos un error inesperado',
                alreadyHaveAVerificationCode: '¿Ya tienes un PIN de verificación?',
                clickHereToEnterYourCode: 'Haga clic aquí para ingresar su PIN',
                updateButton: 'Actualizar',
                verifyButton: 'Verificar',
                pinSentPleaseEnter: 'PIN de verificación enviado. Ingrese el PIN que recibió a continuación.',
                toRecieveTextNotifications: 'Para recibir notificaciones por mensaje de texto, agregue su número de teléfono.',
                addPhoneNumber: 'Agregar número de teléfono'
            },
            taxId: 'ID',
            dateOfBirth: 'Fecha de nacimiento',
            birthYear: 'Año',
            birthMonth: 'Mes',
            birthDay: 'Dia',
            whyDoWeNeedThis: '¿Por qué necesitamos esto?',
            taxInfoExplainer:
                'Usamos su identificación y fecha de nacimiento para determinar su relación con diferentes cuentas. Sin esta información, no podremos brindarle acceso completo a la cuenta.',
            toUpdateThisInformation: 'Para actualizar esta información, comuníquese con el soporte de StoneX One',
            okay: 'OK',
            save: 'Guardar',
            saving: '¡Guardando!',
            saved: '¡Guardado!',
            mdcControls: {
                notificationSettings: 'Configuración de Notificaciones',
                notificationSettingsToolTips: {
                    missingPhone: 'Agregue un número de teléfono válido para habilitar este método de entrega',
                    invalidEventOrDeliveryMethod: 'Los mensajes para este evento y método de entrega no están disponibles'
                },
                email: 'Correo Electrónico',
                sms: 'SMS',
                smsSettings: 'Configuración de SMS',
                notifications: 'Notificaciones',
                pushNotifications: 'Notificaciones push',
                events: {
                    newOrderIsPlaced: 'Nuevo Orden a sido colocada',
                    orderIsFilled: 'Orden a sido llenada',
                    orderIsPartiallyFilled: 'Orden a sido parcialmente llenada',
                    orderIsCanceled: 'Orden está cancelada',
                    orderIsRejected: 'Orden es rechazado',
                    orderIsModified: 'Se modifica el pedido',
                    orderIsStopped: 'Order Is Stopped',
                    orderIsSuspended: 'Order Is Suspended',
                    orderIsOpenForBidding: 'Order Is Open For Bidding',
                    customAlertFired: 'Alerta esta Activada',
                    optionExpiringSoon: 'Opción a punto de vencer'
                }
            },
            managePremium: {
                cancel: 'Cancel Premium Subscription',
                canceled: (exp: string) => `You have canceled your subscription. You will have access to premium until ${exp}.`,
                cancelModalText: (exp: string) =>
                    `Are you sure you want to cancel premium?\n\nYou can choose to undo your cancelation any time before ${exp} to keep your premium.`,
                confirmCancelation: 'Confirm Cancelation',
                get: 'Get Premium',
                loading: 'One Moment Please...',
                pitch: `Listen to earnings calls, check up your favorite stocks' top investors, and analyze the full financial profile of thousands of financial products for just ${FormatNumber.toMoney(
                    PremiumProducts.premium.price
                )} per month.`,
                reactivate: 'Reactivate / Manage',
                subscribed: (exp: string) =>
                    `Congratulations! You are subscribed to StoneX One Premium. Your next bill of ${FormatNumber.toMoney(
                        PremiumProducts.premium.price
                    )} will be issued on ${exp}`
            }
        },
        accounts: {
            accounts: 'Cuenta',
            top: 'Cima',
            addNewAccount: 'Añadir nueva cuenta',
            accountApplicationStatus: 'Estado de la solicitud de cuenta',
            accountInformation: {
                accountInformation: 'Información de la cuenta',
                acctHolderInformation: 'Información del titular de la cuenta',
                accountName: 'Nombre de la cuenta',
                address: 'Dirección',
                altPhone: 'Teléfono alternativo',
                dateOfBirth: 'Fecha de nacimiento',
                email: 'Correo eletronico',
                foreignId: 'Identificación extranjera',
                homePhone: 'Teléfono de casa',
                taxId: 'Identificación del impuesto'
            },
            registration: {
                registration: 'Registro de cuenta',
                accountType: 'Tipo de cuenta',
                lastStatement: 'Última sentencia',
                institutionCode: 'Código institucional',
                restrictionCode: 'Código de restricción',
                accountStatus: 'Estado de la cuenta',
                accountOpened: 'Cuenta abierta'
            },
            setup: {
                setup: 'Configuracion de cuenta',
                marginAgreementOnFile: 'Acuerdo de margen en archivo',
                fplAgreementsOnFile: 'Acuerdos de FPL archivados',
                principalPayments: 'Pago principales',
                dividendReinvest: 'Reinversión de dividendos',
                cashStandingInstructions: 'Instrucciones permanentes de efectivo',
                marginStandingInstructions: 'Instrucciones permanentes de margen',
                dividendStandingInstructions: 'Instrucciones permanentes de dividendos',
                calls: 'Llamadas',
                puts: 'Pone',
                price: 'Precio',
                noOptionsContracts: 'No hay contratos de opciones disponibles para este valor en este momento.',
                optionsLevel: {
                    optionsLevel: 'Permisos de comercio de opciones',
                    disallowed: 'No permitido',
                    level1: 'llamadas cubiertas', // Covered calls
                    level2: 'Compra Calls + Puts', // Purchase Calls + Puts
                    level3: 'Spreads / Largos a horcajadas', // Spreads / Long Straddles
                    level4: 'Escritura de venta de acciones', // Equity Put Writing
                    level5: 'Escritura de llamada descubierta + Escritura de índice puesto / straddles cortos', // Uncovered call writing + Index put writing / short straddles
                    level6: 'Opciones de índice descubiertas' // Uncovered index options
                }
            },
            investmentProfile: {
                investmentProfile: 'Perfil de inversión',
                investmentObjective: 'Objective de inversión',
                netWorth: 'Valor neto',
                federalTaxBracket: 'Grupo de impuestos federales',
                liquidityNeeds: 'Necesidades de liquidez',
                sourceOfFunds: 'Fuente de fondos',
                occupation: 'Ocupación',
                stockExperience: 'Experencia de stock',
                bondExperience: 'Experencia de bonos',
                optionExperience: 'Experencia opcional',
                mutualFundExperience: 'Experencia en fonods mutuos',
                annuityExperience: 'Experencia de anualidad',
                investmentTimeHorizon: 'Horizonte de tiemp de inversión'
            },
            accountDetails: {
                title: 'Detalles de la cuenta',
                type: 'Tipo',
                accountNumber: 'Número de cuenta',
                accountName: 'Nombre de la cuenta',
                rename: 'Cambiar nombre',
                startMargin: 'Empezar a usar margen',
                optionsTrading: 'Comercio de opciones',
                optionsEnabled: 'Opciones habilitadas',
                optionsLevelsForPendingAcct: 'Una vez que se apruebe su cuenta, tendrá acceso al comercio de opciones.',
                youveEnabledOptions: 'Has habilitado opciones para esta cuenta.',
                optionsNotEnabledDesc:
                    'Esta cuenta no está habilitada para el comercio de opciones. El comercio de opciones le permite negociar contratos si cree que el precio de un activo subirá o bajará.',

                marginAgreementAddedDesc:
                    'Parece que agregó un acuerdo de margen a esta cuenta. El margen le brinda acceso a más estrategias de opciones, activos especulativos como futuros y más.',
                accountDoesNotHaveMarginDesc:
                    'Esta cuenta no tiene un acuerdo de margen registrado. El margen le brinda acceso a más estrategias de opciones, activos especulativos como futuros y más.',

                corporateActionDesc: 'Las acciones corporativas son acciones realizadas por una empresa que impactan directamente en el valor de los accionistas.',
                corporateActionsAvailable: 'Acciones de Corporación Disponibles',
                corporateActions: 'Acciones Corporativas',
                viewCorporateActions: 'Ver Acciones Corporativas',
                noCorporateActionsAvailable: 'No hay acciones corporativas disponibles',
                enableOptions: 'Habilitar comercio de opciones',
                gotoAccount: 'Ir a la cuenta',
                closeAccount: 'Cerrar cuenta',
                contactUsToCloseAccount: 'Por favor contáctenos para cerrar su cuenta',
                options: 'Opciones',
                margin: 'Margen',
                changeOptionLevel: 'Cambiar nivel de opción',
                marginEnabled: 'Margen habilitado',
                unableToFindInformation: 'Parece que no pudimos encontrar información para su cuenta. ¡Lo lamento!',
                ohNo: '¡Oh no!',
                execute: 'Puede ejecutar lo siguiente:',
                useButtonModify: 'Use el botón de arriba para modificar.',
                renameAccount: 'Cambiar nombre de cuenta',
                cancel: 'Cancelar',
                general: {
                    general: 'General',
                    dateOpened: 'Fecha de apertura',
                    lastStatement: 'Última sentencia',
                    status: 'Estado',
                    restrictionCode: 'Código de restricción'
                }
            }
        },

        paperless: {
            goPaperless: 'Ir sin papel',
            paperless: 'Preferencias de comunicacion',
            confirms: 'Confirmaciones',
            stonexCommunications: 'Comunicaciones de StoneX',
            investorRelations: 'Relaciones con Inversores',
            prospectus: 'Prospeto',
            statements: 'Declaraciones',
            taxDocuments: 'Documentos fiscales',
            goCompletelyPaperless: 'Acabar con el Correo Físico',
            goPaperlessLine1:
                '¿Listo para dejar de usar papel por completo? Presiona el botón de arriba para recibir todos los documentos de tu cuenta por correo electrónico en lugar de por correo postal.',
            goPaperlessLine2: 'O bien, personalice las opciones de entrega de sus cuentas a continuación.',
            completelyPaperlessExplain: 'Recibe todos tus documentos de forma electrónica mientras que tambien ayudas el medioambiente.',
            allMail: 'Todos correo',
            allEDelivery: 'Todos email',
            allElectronic: 'Todo Electrónico',
            mail: 'Correo',
            edelivery: 'Email',
            paperlessButton: '¡Eres 100% sin papel!',
            customizeOptions: 'Personalice las opciones de entrega de sus cuentas a continuación.',
            youArePaperless: (percent: string) => `Eres ${percent} sin papel`,
            paperlessPopup: {
                title: 'Por favor confirme',
                message: 'Esto actualizará toda su cuentas para que ya no envíe su documentos por correo. ¿Continuar?',
                confirm: 'Sí, Ir sin papel'
            }
        },

        bioauth: {
            biometrics: 'Biometría',
            whatBioauthIs: 'El inicio de sesión biométrico le permite iniciar sesión rápidamente utilizando factores biométricos, como su rostro o su huella digital.',
            bioauthIsCurrentlyOnOff: (on: boolean) => [
                { text: 'El inicio de sesión biométrico está actualmente ', shade: 'light' },
                { text: on ? 'activado' : 'desactivado', shade: 'dark' },
                { text: '.', shade: 'light' }
            ],
            clearMyDataAndTurnBioauthOff: 'Borrar mis datos de inicio de sesión y desactivar el inicio de sesión biométrico',
            turnBioauthOn: 'Activar inicio de sesión biométrico',

            whatBiounlockIs:
                'Si tiene habilitado el inicio de sesión biométrico, puede evitar que otros usen su inicio de sesión solicitando su factor biométrico para desbloquear StoneX One',
            biounlockIsCurrentlyOnOff: (on: boolean) => [
                { text: 'El desbloqueo biométrico está actualmente ', shade: 'light' },
                { text: on ? 'activadio' : 'desactivado', shade: 'dark' },
                { text: '.', shade: 'light' }
            ],
            turnBiounlockOff: 'Desactivar desbloqueo biométrico',
            turnBiounlockOn: 'Activar desbloqueo biométrico',

            errors: {
                unableToLogYouIn: 'Lo sentimos, no pudimos iniciar sesión.',
                somethingWentWrong: 'Perdón, algo salió mal.',
                couldNotVerifyYourIdentity: 'Lo sentimos, no pudimos verificar su identidad.'
            },
            verifyYourLoginToActivate: 'Verifique su inicio de sesión para activar la biometría',
            verify: 'Verificar',
            email: 'Correo electrónico',
            password: 'Contraseña'
        },

        changePassword: {
            changeFailedTitle: 'Actualización fallida',
            changeFailedMessage: 'Lo sentimos, pero no pudimos actualizar su contraseña. Vuelva a intentarlo o comuníquese con el soporte a continuación',
            validationFailedTitle: 'Actualización fallida',
            validationFailedMessage:
                'Faltan algunas entradas o no son válidas. Asegúrese de haber ingresado su contraseña actual y de haberla ingresado correctamente en ambas ocasiones.',
            authenticationFailedTitle: 'No se puede confirmar la identidad',
            authenticationFailedMessage: 'La contraseña que acaba de ingresar es incorrecta',
            tryAgain: 'Intentar otra vez',
            contactSupport: 'Contrato de soporte',
            changePassword: 'Cambiar la contraseña',
            forgotPassword: 'Has olvidado tu contraseña',
            currentPassword: 'Contraseña actual',
            newPassword: 'Nueva contraseña',
            confirmPassword: 'Confirmar contraseña',
            updatePassword: 'Actualiza contraseña',
            passwordMinLength: (minLength: number) => `La contraseña debe tener al menos ${minLength} caracteres`,
            passwordLetter: 'La contraseña debe contener al menos una letra',
            passwordRequirementsMessage: (minLength: number) =>
                `La contraseña debe tener un mínimo de ${minLength} caracteres y contener al menos una letra mayúscula, una letra minúscula y un número.`,
            cannotReuseOldPassword: 'La nueva contraseña no debe coincidir con la contraseña existente',
            newPasswordConfirmMismatch: 'La nueva contraseña y la confirmación no coinciden',
            passwordRequirements: 'Requisitos de contraseña',
            passwordRecoveryRequirements: (minLength: number) => [
                `Debe tener un mínimo de ${minLength} caracteres`,
                'Debe contener mínimo una letra mayúscula',
                'Debe contener un mínimo de un número.'
            ]
        }
    },
    documentsScreen: {
        documents: 'Documentos',
        filters: 'Filtros',
        name: 'Nombre',
        date: 'Fecha',
        account: 'Cuenta',
        preview: 'Vista previa',
        download: 'Descargar',
        share: 'Compartir',
        moreActions: 'Mas acciones',
        viewDocument: 'Ver documento',
        allAccounts: 'Todas las cuentas',
        downloading: 'Descargando...',
        saveToDownloads: 'Guardar en Descargas',
        documentNotFound: 'No hay archivo para esta fecha.',
        docTypes: {
            taxDocuments: 'Formularios de impuestos',
            intlPublicForms: 'Formularios públicos INTL',
            ifsNewAccts: 'IFS NEWACCTS',
            marginRequirements: 'Requisitos de margen del cliente',
            tradeConfirmations: 'Confirmaciones de operaciones',
            checks: 'Cheques',
            accountStatements: 'Estados de Cuenta',
            accountNotices: 'Avisos de cuenta',
            detailedVariationAnalysis: 'Análisis de variación detallado',
            moneyOptionReport: 'Informe de opciones de dinero',
            memoStatement: 'Declaración memorándum',
            monthlyDiscretionaryAccounts: 'Cuentas Discrecionales Mensuales',
            annualStatement: 'Estado anual',
            quaterlyStatement: 'Estado trimestral',
            monthlyStatementExcel: 'Extracto Mensual Excel',
            monthlyStatement: 'Estado mensual',
            dailyStatement: 'Estado de cuenta diario',
            fullyPaidEarnings: 'Ingresos totalmente pagados',
            fullyPaidLendingConfirms: 'Confirma el préstamo totalmente pagado',
            fullyPaidLendingPositions: 'Posiciones de préstamos totalmente pagadas'
        },
        downloadToasts: {
            savingUnavailableTitle: 'Guardando no disponible',
            savingUnavailableDesc: 'Lo sentimos, guardar no está disponible en su dispositivo.',
            permissionDenied: 'Permiso denegado',
            allowStonexSaveFiles: 'Permita que StoneX One guarde archivos en su dispositivo.',
            fileSavedTitle: 'Archivo guardado',
            fileSavedInDevice: (foldername: string) => `Su documento ha sido guardado en la carpeta ${foldername} de su dispositivo`,
            savingErrorTitle: 'Error al guardar',
            problemDownloading: 'Lo sentimos, hubo un problema al descargar el archivo.'
        },
        docNames: {
            statement: (doc: SnexDocument) => `Declaración de ${doc.accountNumber} ${SafeFormat(doc.documentDate, 'MMMM')}`,
            check: (doc: SnexDocument) => doc.name,
            misc: (doc: SnexDocument) => doc.name,
            taxes: (doc: SnexDocument) => `${doc.accountNumber} ${SafeFormat(doc.documentDate, 'yyyy')} formulario de impuestos`
        },
        symbol: 'Símbolo',
        type: 'Tipo',
        noDocumentsTitle: 'No se encontraron documentos',
        noDocumentsDescription: 'No pudimos encontrar ningún documento que coincida con los filtros a continuación.',
        searchDocuments: 'Buscar',
        goPaperless: 'Acabar con el correo físico',
        modal: {
            loading: 'Cargando...',
            untitled: '(Documento sin titulo)'
        }
    },

    tradeHistory: {
        tradeHistory: 'Historial de pedidos',
        filters: 'Filtros',
        account: (accountNumber?: string) => (accountNumber ? `Cuenta #${accountNumber}` : 'Cuenta'),
        allAssetClasses: 'Todos los productos',
        equities: 'Acciones y Opciones',
        futures: 'Futuros y Opciones',
        futuresOptions: 'Opciones de futuros',
        mutualFunds: 'Fondos mutuos',
        allAccounts: 'Todas Cuentas',
        futuresTimeSpreads: 'Spreads de Futuros',
        cryptos: 'Criptomonedas',
        invalidFilters: {
            popoverHeader: 'Filtros Inválidos',
            popoverBody: 'Algunos de tus filtros se anulan entre sí. Revisa tus filtros e intenta buscar de nuevo.',
            popoverActionLabel: 'De acuerdo'
        },
        status: {
            status: 'Estado',
            assetClass: 'Producto',
            all: 'Todos',
            allStatuses: 'Todos Estados',
            openAll: 'Todos Abiertos',
            closed: 'Cerrado',
            closedAll: 'Todos Cerrados',
            open: 'Abierto/Laboral',
            pending: 'Pendiente',
            completed: 'Finalizado',
            filled: 'Completada',
            cancelled: 'Cancelado',
            cancelPending: 'Cancelación pendiente',
            cancelPartialFill: 'Parcialmente cancelado',
            allocated: 'Asignado',
            preAllocated: 'Pre-asigando',
            created: 'Creado',
            expired: 'Expirado',
            confirmed: 'Confirmado',
            route: 'Encaminar',
            routed: 'Encamidado',
            verification: 'Verificación',
            cancel: 'Cancelar',
            canceled: 'Cancelado',
            rejected: 'Rechazado',
            unknown: 'Desconocido',
            deleted: 'Borrado'
        },
        orders: 'Órdenes',
        symbol: 'Símbolo',
        accountTitle: 'Cuenta',
        downloadTooltip: 'Descargar todos',
        fetchingError: '¡Lo siento! Tuvimos algunos problemas para obtener su historial comercial.',
        noOrdersFound: 'No se encontraron pedidos',
        noOrdersFoundDescription: 'No pudimos encontrar ningún pedido que coincida con los filtros a continuación',
        downloadFilename: () => `actividad-comercial-${SafeFormat(new Date(), 'yyyyMMdd-hhmmss')}.csv`
    },

    transactionsScreen: {
        tooltips: {
            partialResults: 'Resultados parciales devueltos'
        },
        account: 'Cuenta',
        transactions: 'Transacciones',
        symbolId: 'Símbolo',
        date: 'Fecha',
        type: 'Tipo',
        anyType: 'Cualquier tipo',
        amount: 'Monto',
        description: 'Descripción',
        noTransactionsTitle: 'No hay transacciones disponibles',
        noTransactionsDesc: 'Aquí aparecerán sus operaciones, transferencias, dividendos, intereses y cualquier otra actividad de la cuenta.',
        sendingOrder: 'Enviando pedido..',
        tradeDescription: (m: Transaction) => {
            const verb = (() => {
                if (m.action?.toUpperCase() === 'BUY') return 'Compra';
                else if (m.action?.toUpperCase() === 'SELL') return 'Vendida';
                else return '';
            })();
            const unit = Snex1Spanish.general.unit(m.symbol, m.quantity);
            const decimalPlaces = `${m?.price}`.split('.')[1]?.length;
            return [SecurityTypes.Futures, SecurityTypes.FuturesOptions].includes(m?.securityType)
                ? `${verb} ${FormatNumber.toDecimal(m.quantity, 3)} ${m.description}`
                : `${verb} ${FormatNumber.toDecimal(m.quantity, 3)} ${unit} de ${m.symbol} @ ${FormatNumber.toLocaleDecimal({
                      decimalPlaces,
                      value: m.price
                  })}`;
        },
        expand: 'Expandir',
        action: 'Acción',
        quantity: 'Cantidad',
        price: 'Precio',
        baseCurrency: 'Moneda',
        accountType: {
            accountType: 'Tipo de cuenta',
            cash: 'Cuenta en efectivo',
            longMargin: 'Cuenta de Margen (largo)',
            shortMargin: 'Margen (corto)',
            annuitiesAway: 'Anualidades lejos',
            stockLoan: 'Préstamo de acciones',
            tefraWithhold: 'TEFRA withhold',
            whenIssued: 'When issued'
        },
        securityType: 'Tipo de activo',
        asOfDate: 'Fecha anterior',
        tradeDate: 'Fecha de operación',
        settleDate: 'Fecha de liquidación',
        principal: 'Monto bruto',
        interest: 'Interés',
        cancelStatus: 'Cancelar estado',
        rebillStatus: 'Estado de refacturación',
        controlNumber: 'Número de control',
        securityNumber: 'Número de activo',
        security: 'Activo',
        exportAll: (accountNumber: string) => `Descargar todas las transacciones para ${accountNumber}`,
        filename: (accountNumber: string) => `transacciones-${accountNumber}-${SafeFormat(new Date(), 'yyyyMMdd-hhmmss')}.csv`,
        filters: {
            filters: 'Filtros',
            sort: 'Orden',
            date: 'Fecha'
        },
        processDate: 'Fecha de procesamiento',
        netAmount: 'Importe neto',
        defaultSort: 'Ordenación predeterminada',
        ascending: 'Ascendente',
        descending: 'Descendente',
        search: 'Buscar',
        all: 'Todos',
        swipeUpSubmit: 'Deslizar hacia arriba para enviar',
        activityType: {
            tradeActivity: 'Actividad comercial',
            cashAndEquivalents: 'Dinero en efectivo',
            dividendsAndInterest: 'Dividendos e intereses',
            options: 'Opciones',
            receiveAndDeliver: 'Recibo y Entrega',
            exerciseAndExpiration: 'Ser ejercidos o Vencer',
            stockLoans: 'Préstamo de acciones',
            misc: 'Variado',
            deposit: 'Deposito',
            withdrawal: 'Retiro',

            trade: 'Comercio',
            cash: 'Dinero',
            dividentInterest: 'Dividendo / intereses',
            exerciseExpire: 'Ejercicio / Vencer',
            receiveDeliever: 'Recibo / Entrega',
            receiveDelieverAbbrev: 'Rec / Entr',
            funding: 'Fondos',
            other: 'Otro'
        }
    },

    projectedIncomeScreen: {
        projectedIncome: 'Ingresos proyectados',
        symbol: 'Símbolo',
        saveFilter: 'Guardar filtro',
        reset: 'Reiniciar',
        accountNumber: 'Número de cuenta',
        viewAll: 'Ver todos',
        pleaseSelectAnAccount: 'Sus ingresos proyectados se mostrarán aquí. Seleccione una cuenta para ver',
        noDataAvailable: 'No hay datos para la cuenta seleccionada',
        name: 'Nombres',
        income: 'Ingresos',
        yield: 'Rendimiento',
        paymentDate: 'Fecha de Pago',
        nextPaymentDate: 'Siguiente pago',
        maturityDate: 'Fecha de Vencimiento',
        frequency: {
            frequency: 'Frecuencia',
            semiAnnually: 'Semestral',
            quarterly: 'Trimestral',
            monthly: 'Mensual',
            annually: 'Anual'
        },
        projectedTotal: 'Total Proyectado',
        projectedFor: (qsi: string) => `Proyectado Para ${qsi}`,
        couponRate: 'Tasa de interés',
        type: 'Tipo',
        downloadTooltip: 'Descargar',
        downloadFilename: () => `ingresos-proyectados-${SafeFormat(new Date(), 'yyyyMMdd-hhmmss')}.csv`,
        overall: 'General',
        selectAnAccountEmptyTitle: 'Sus ingresos proyectados se mostrarán aquí. Seleccione una cuenta para ver.',
        incomeBreakdown: 'Desglose de ingresos',
        totalProfits: 'Beneficios totales del período:',
        noIncomeForMonth: 'No hay ingresos proyectados para',
        selectAnotherPeriod: 'Seleccione otro período en el cuadro de arriba para ver sus ingresos proyectados.',
        noIncomeProjection: 'No hay proyección de ingresos disponible',
        noPositionSecurity: 'Parece que esta cuenta no tiene posición en ningún valor que pague dividendos.'
    },

    disclosureMessages: {
        tax: 'StoneX One no proporciona asesoramiento fiscal.',
        finra: 'StoneX © 2021. Cuentas realizadas por StoneX Financial Inc., miembro de FINRA/SIPC y subsidiaria de propiedad absoluta de StoneX Group Inc.',
        delayedQuotes: 'Las cotizaciones tienen un retraso de 15 minutos.',
        glancePreviousClose: 'Cambio desde el cierre anterior',
        projectedOrderCost: 'Coste del pedido proyectado en base al precio límite; el costo real puede variar',
        topMovers: `Las listas de SECTORES y MOVIMIENTOS se generan utilizando datos obtenidos de proveedores de datos de terceros.
        Una lista de empresas en un sector comercial específico puede mostrar una empresa si los datos de origen indican que la empresa obtiene una parte de sus ingresos del sector relevante.
        La lista se proporciona únicamente con fines informativos y no constituye un consejo de inversión ni una recomendación para comprar, mantener o vender ningún valor.
        Las listas no son recomendaciones personalizadas para usted ni para ningún otro cliente, y es posible que los valores enumerados no sean adecuados para usted.
        No debe comprar ni vender ningún valor sin determinar primero si es apropiado para su cartera o estrategia de inversión.
        StoneX One no ofrece ninguna garantía en relación con la precisión, la puntualidad o la integridad de la información de terceros, y el suministro de esta información no constituye una recomendación.`,
        totalPnL: 'Esta cantidad refleja la ganancia/pérdida no realizada para esta posición'
    },
    disclosuresPage: {
        disclosures: 'Divulgación',
        documentContent: {
            pricingDisclosure: [
                'Las posiciones tienen el precio de la última operación. Si un valor de renta variable no se ha negociado hoy, la información del precio se basa en el mejor precio de oferta del día hábil anterior. La oferta o demanda actual puede ser diferente del precio que se muestra'
            ],
            projectedIncomeDisclosure: [
                'El Ingreso Proyectado y el Rendimiento Proyectado para ciertos tipos de valores podrían incluir una devolución del principal o ganancias de capital, en cuyo caso el Ingreso Proyectado y el Rendimiento Proyectado estarían sobreestimados.',
                'El ingreso proyectado y el rendimiento proyectado son estimaciones y el ingreso y el rendimiento reales pueden ser más bajos o más altos que los montos estimados',
                'El rendimiento proyectado refleja solo los ingresos generados por una inversión. No refleja cambios en su precio, que puede fluctuar.'
            ],
            marketIntelligenceDisclosure: [
                {
                    text: 'Nada de lo contenido en este documento se interpretará como una concesión al Suscriptor o a un tercero de una licencia o derecho ilimitado, por implicación, impedimento legal o de otro modo, en virtud de cualquier ley (ya sea de derecho consuetudinario o estatutario), norma o reglamento, incluidos, entre otros, los relacionados con los derechos de autor. u otros derechos de propiedad intelectual.'
                },
                {
                    text: 'Este informe y su contenido no se considerarán asesoramiento financiero. Este informe y su contenido se ponen a disposición del Suscriptor con fines informativos y no reemplazan la obtención de asesoramiento profesional de una persona, empresa o corporación calificada, cuando sea necesario. La exactitud, integridad, adecuación o puntualidad de este informe y su contenido no están garantizados ni garantizados. El Suscriptor reconoce que el informe y su contenido pueden ser producto del trabajo utilizando, incorporando o haciendo referencia a información de terceros.'
                },
                {
                    text: 'Cualquier uso no autorizado de este informe y su contenido está totalmente prohibido. Este informe y su contenido es para uso del Suscriptor solo de acuerdo con su suscripción y no puede copiar, modificar, reproducir, volver a publicar, distribuir, mostrar o transmitir con fines comerciales, sin fines de lucro o públicos, en su totalidad o en parte, y cualquier otro propósito en su beneficio o en beneficio de cualquier tercero. Para evitar dudas, el Suscriptor no puede usar este informe ni ninguno de sus contenidos para transmitir comunicaciones falsas, engañosas, fraudulentas o ilegales.'
                },
                {
                    text: 'StoneX Group Inc. se exime de toda responsabilidad por la confianza, el uso de este informe y su contenido para cualquier propósito que surja de la suscripción, el uso del Suscriptor y cualquier tercero.'
                }
            ]
        },
        documentTitles: {
            termsAndConditions: 'Términos y condiciones',
            pricingDisclosure: 'Divulgación de precios',
            generalDisclosures: 'Divulgação geral',
            cryptoDisclosure: 'Divulgación de criptomonedas',
            projectedIncomeAndYieldDisclosure: 'Divulgación de ingresos proyectados y redimiento',
            marketIntelligenceDisclosure: 'Divulgación de Market Intelligence'
        }
    },

    securityScreen: {
        premarket: 'Pre-mercado',
        postmarket: 'Post-comercialización',
        afterhours: 'Después de horas',
        notFoundHeader: (qsi: string) => `${qsi} no encontrado`,
        notFoundSubText: 'Parece que no pudimos encontrar ninguna información sobre este símbolo.',
        searchForSomethingElse: 'Buscar otra cosa',
        advancedChart: 'Gráfico avanzado',
        advancedDomAndChart: 'DOM y gráfico avanzados',
        deepAnalysis: 'Análisis profundo',
        tradeOptions: 'Opciones Comerciales',
        tradeFuturesSpreads: 'Diferenciales de Futuros Comerciales',
        enableOptionTrading: 'Habilitar comercio de opciones',
        changeOptionLevel: 'Cambiar nivel de opción',
        addToList: 'Añadir a una lista',
        notOnAnyOfYourLists: 'No está en ninguna de tus listas.',
        lineChart: 'Gráfico de líneas',
        candlestick: 'Gráfico de velas japonesas',
        showVolume: 'Mostrar Volumen',
        tomorrow: 'mañana',
        today: 'Este Dia',
        security: 'Seguridad',
        securities: 'Valores',
        viewAll: 'Ver todos',
        viewAllSpreads: 'Ver todos los diferenciales',
        viewFewer: 'Ver menos',
        viewMore: 'Ver más',
        viewLess: 'Ver menos',
        chartDataComingSoon: 'Datos gráficos próximamente',
        problemLoadingSecuritiesFor: (qsi: string) => `Hubo un problema al cargar valores relacionados para ${qsi}`,
        noRelatedSecuritiesFor: (qsi: string) => `No hay valores relacionados disponibles para ${qsi}`,
        about: {
            about: (symbol: string) => `Sobre ${QualifiedId.RemovePrefix(symbol)}`,
            noDescriptionAvailable: 'No hay descripción disponible',
            readMore: 'Lee más',
            readLess: 'Lee menos',
            ceo: 'CEO',
            employees: 'Empleados',
            headquarters: 'Sede',
            website: 'Sitio web',
            exchange: 'Intercambio',
            open: 'Abrir',
            unavailable: 'Desconocido',
            whitepaper: 'Papel blanco',
            reddit: 'Reddit',
            coin: 'Criptomoneda'
        },
        stats: {
            stats: 'Estadisticas',
            specifications: 'Especificaciones',
            bid: 'Ofrecer',
            ask: 'Preguntar',
            open: 'Precio de apertura',
            close: 'Cierre',
            previousClose: 'Cierre previo',
            previousSettle: 'Liquidación anterior',
            high: 'Alto',
            low: 'Bajo',
            previousVolume: 'Volumen previo',
            volume: 'Volumen',
            volume24h: 'Volumen (24h)',
            averageVolume: 'Volumen medio',
            averageVolume7d: 'Vol. medio (7d)',
            week52High: 'Nivel alto de 52 semanas',
            week52Low: 'Nivel bajo de 52 semanas',
            marketCap: 'Capitalización de mercado',
            peRatio: 'Relación P/E',
            ytdChange: 'Cambio YTD',
            startTime: 'Hora de inicio',
            stopTime: 'Hora de parada',
            endTime: 'Hora de finalización',
            contractSize: 'Tamaño del contrato',
            tickSize: 'Tamaño de marca',
            tickPrice: 'Precio de tic',
            initialMargin: 'Margen inicial',
            dayTradeMargin: 'Margen de comercio diario',
            dayTradeMarginToolTip: 'El margen comercial diario está disponible de 7:45 a. m. a 3:30 p. m., hora central',
            maintenanceMargin: 'Margen de mantenimiento',
            changePercent: 'Cambiar %',
            low52: '52Bajo',
            high52: '52alto'
        },
        ratings: {
            analystRatings: 'Calificaciones de analistas',
            asOf: 'Desde',
            noRatingsAvailable: 'No hay calificaciones disponibles',
            analystRatingExplain: (secName: string, action) => {
                const translation =
                    {
                        Buy: 'Compra',
                        Sell: 'Venta',
                        Hold: 'Retención de Compra'
                    }[action] || action;
                return `En promedio, los analistas actualmente califican ${secName} como una ${translation}`;
            },
            analystRatingExplainShaded: (secName: string, action: string) => {
                const translation = { Buy: 'comprar', Sell: 'vender', Hold: 'retener' }[action] || action;
                return [
                    {
                        shade: 'light',
                        text: 'En promedio, los analistas actualmente califican'
                    },
                    { shade: 'dark', text: secName },
                    { shade: 'light', text: 'como una' },
                    { shade: 'dark', text: translation }
                ];
            },
            aConsensusOf: (totalNumberOfRatings: number, consensusDate: string | Date) => [
                { text: 'Basado en el consenso de ', shade: 'light' },
                {
                    text: `${FormatNumber.toCommas(totalNumberOfRatings)} clasificación${totalNumberOfRatings === 1 ? '' : 's'}`,
                    shade: 'dark'
                },
                { text: ' reunidos en ', shade: 'light' },
                {
                    text: SafeFormat(consensusDate, 'dd MMM yyyy'),
                    shade: 'dark'
                }
            ],

            noRatingsDataAvailble: (secName: string) => `No hay clasificaciones disponibles para ${secName} en este momento.`,
            problemLoadingRatings: (secName: string) => `Hubo un problema al cargar las calificaciones para ${secName}`,
            noRatingsAvailableFor: (secName: string) => `No hay clasificaciones disponibles para ${secName}`,
            buy: 'Comprar',
            sell: 'Venta',
            hold: 'Retención de Compra',
            totalRatings: 'Calificaciones totales'
        },
        earningsEstimates: {
            earningsAndEstimates: 'Ganancias y estimaciones',
            actual: 'Real',
            estimated: 'Estimado',
            actualEPS: 'Real EPS',
            estimatedEPS: 'Estimado EPS',
            quarter: (quarterNumber: number) => `${quarterNumber}ºT`,
            noData: (securityName?: string) => `No hay datos de ingresos disponibles para ${securityName || 'esta seguridad'} en este momento.`,
            problemLoadingEarnings: (securityName: string) => `Hubo un problema al cargar la línea de tiempo de ganancias para ${securityName}`,
            noEarningsTimeline: (securityName: string) => `No hay cronograma de ganancias disponible para ${securityName}`
        },
        options: {
            options: 'Opciones',
            optionsTitle: (symbol: string) => `${symbol} Opciones`,
            optionsSublabel: 'Negociar opciones de compra y venta',
            contracts: 'Contratos',
            value: 'Valor',
            todaysGainLoss: 'G/P Hoy',
            totalGainLoss: 'G/P Total',
            account: 'Cuenta',
            exp: 'Exp',
            contractsLong: (quantity: number) => `${FormatNumber.toCommas(quantity)} ${Snex1Spanish.general.contracts(quantity)} largo${quantity === 1 ? '' : 's'}`,
            contractsShort: (quantity: number) => `${FormatNumber.toCommas(quantity)} ${Snex1Spanish.general.contracts(quantity)} corto${quantity === 1 ? '' : 's'}`,
            unknownExpiration: 'Caducacidad desconocida',
            contract: (n: number) => Snex1Spanish.general.contracts(n),
            timeSpreadsTitle: (symbol: string) => `${symbol} Spreads de tempo`,
            timeSpreadsSublabel: 'Comprar y vender contratos en diferentes fechas de vencimiento',
            noTimeSpreadsSublabel: 'Los márgenes de tiempo no están disponibles para este contrato'
        },
        history: {
            history: 'Historial',
            tradeHistory: 'Actividad comercial',
            orderHistory: 'Ver historial de pedidos',
            viewMoreHistory: 'Ver más'
        },
        related: {
            related: 'Acciones relacionadas',
            relatedEtfs: 'ETF relacionados',
            noRelatedStocks: 'No hay acciones relacionadas'
        },
        holdings: {
            account: 'Cuenta',
            accountsHolding: 'Tenecia de cuentas',
            acct: (number: string) => `Cuenta #${QualifiedId.Format(number)}`,
            assetsInFunds: '% Activos En Fondo',
            averageCost: 'Costo media',
            cost: 'Costo',
            marketValue: 'Valor Mercado',
            marketValueTooltip: 'Estos valores se basan en el día anterior. Cualquier pedido realizado hoy no se tiene en cuenta',
            marketValueTooltipFutures: 'El valor de mercado es sus contratos de propiedad multiplicados por el costo promedio.',
            portfolio: 'Cartera',
            portfolioDiversity: 'Asignación de cartera',
            positionPrice: 'Precio de Posición',
            quantity: 'Cantidad',
            share: (n: number) => Snex1Spanish.general.shares(n),
            shares: 'Participaciones',
            showAllAccounts: 'Mostrar todas as contas',
            todaysReturn: 'PyG de hoy',
            topHoldings: 'Principales participaciones',
            totalReturn: 'Retorno total',
            viewMore: 'Ver más',
            yourMarketValue: 'Tu valor de mercado',
            yourPosition: 'Tu Posición',
            buyMore: 'Comprar más',
            shortMore: 'Corto',
            sell: 'Vender',
            buy: 'Comprar',
            buyCover: 'Comprar Cubierta',
            sellCover: 'Vender Cubierta',
            coverPosition: 'Cubrir posición',
            sellQsi: (qsi: string) => `Vender ${qsi}`,
            noTopHoldingAvailable: (security) => `No hay posiciones principales disponibles para ${security} en este momento`,
            openPnL: 'PyG Abierto',
            openPnLPercent: 'PyG Abierto %'
        },
        openOrders: 'Órdenes abiertas',
        news: {
            news: 'Noticias',
            marketIntelligence: 'Intelegencia de Mercado',
            andNews: 'y Noticias',
            newsUnavailable: 'Noticias actualmente no disponibles',
            newsAndMI: 'Noticias y MI',
            noNewsFor: (symbol?: string) => `No hay noticias disponibles para ${symbol || 'esta seguridad'}`
        },
        crypto: {
            comingSoon: 'Próximamente el comercio de criptomonedas',
            marketComparison: {
                title: 'Comparación de Mercado',
                pastYear: 'Año Pasado'
            },
            socialActivity: {
                title: 'Actividad Social',
                twitterFol: 'Seguidores de Twitter',
                redditFol: 'Seguidores de Reddit',
                githubFol: 'Seguidores de Github'
            },
            popularity: 'Popularidad',
            circulatingSupply: 'Suministro Circulante',
            totalSupply: 'Suministro Total',
            maxSupply: 'Suministro Máximo',
            coins: 'Monedas'
        }
    },

    sectorScreen: {
        sectors: 'Sectores',
        sectorNames: {
            consumerDiscretionary: 'Consumo discrecional',
            healthCare: 'Salud',
            technology: 'Tecnología',
            realEstate: 'Mercado inmobiliario',
            consumerStaples: 'Productos de primera necesidad',
            utilities: 'Servicios públicos',
            communicationServices: 'Servicios de comunicación',
            financials: 'Finanzas',
            materials: 'Materiales',
            industrials: 'Industrial',
            energy: 'Energía'
        },
        symbol: 'Símbolo',
        name: 'Nombre',
        price: 'Precio',
        change: 'Cambia',
        changePercent: 'Cambiar Porcentaje',
        marketCap: 'Capitalización de mercado',
        item: 'artículo',
        items: 'elementos',
        securityChangeSelector: {
            rising: 'Creciente',
            falling: 'Descendente'
        },
        marketCapSelector: {
            large: 'Largo',
            mid: 'Medio',
            small: 'Pequeña',
            micro: 'Micro',
            nano: 'Nano',
            andBelow: 'y por debajo'
        }
    },

    fundScreen: {
        minimumInvestmentNotMet: (minInvestment) => `Inversión mínima no alcanzada: ${FormatNumber.toMoneyOpt2(minInvestment, { showDecimals: false })}`,
        profile: {
            about: 'Acerca de',
            noDescription: 'No hay descripción disponible',
            category: 'Categoría',
            inceptionDate: 'Fecha de inicio',
            indexTracked: 'Índice rastreado',
            netAssetValue: 'Valor liquidativo',
            totalAssets: 'Activos totales',
            beta: 'Beta',
            deferredLoad: 'Carga diferida',
            expenseRatio: 'Coeficiente de gastos',
            fee12b1: 'Tarifa 12B1',
            holdingsTurnover: 'Rotación de participaciones',
            frontLoad: 'Carga frontal',
            family: 'Familia de fondos',
            manager: 'Administrador de fondos',
            maxRedepmtionFee: 'Tarifa máxima de redención',
            minimumInvestment: 'Inversión Mínima',
            minimumInitialInvestment: 'Inversión inicial mínima',
            aum: 'AUM',
            averageVolume: 'Volumen promedio',
            companySymbol: 'Empresa',
            priceToBook: 'Precio para reservar',
            priceToEarnings: 'Precio a ganancias',
            website: 'Sitio web',
            open: 'Abierto',
            benchmark: 'Punto de referencia',
            stats: 'Estadísticas',
            bid: 'Bid',
            ask: 'Ask',
            investmentSegment: 'Segmento de Inversión',
            status: 'Estado'
        },
        countryExposure: {
            countryExposureTitle: 'Exposición al País',
            noExposureDataAvailable: 'Sem dados de exposição disponíveis',
            country: 'País',
            exposure: 'Exposición',
            countries: 'Países',
            noCountryExposure: 'No hay datos de exposición del país disponibles'
        },
        sectorExposure: {
            sectorExposureTitle: '¿De qué está hecho este fondo?',
            noSectorExposure: 'No hay datos de exposición del sector disponibles',
            sectors: 'Sectores'
        },
        // Offshore mutual fund stats
        Alternative: 'Alternativa',
        Equity: 'Justa',
        FixedIncome: 'Renta Fija',
        Guaranteed: 'Garantizado',
        Hedge: 'Cobertura',
        High: 'Alta',
        Low: 'Bajo',
        Medium: 'Medio',
        MoneyMarket: 'Mercado Monetario',
        MultiAsset: 'Múltiples activos',
        Others: 'Otro',
        RealEstate: 'Bienes Raíces',
        Sector: 'Departamento',
        SpanishDomestic: 'Nacional Español',
        Traditional: 'Tradicional',
        active: 'Activo',
        assetClass: 'Clase de activo',
        cutoffTimeRedemptions: 'Hora límite (Redenciones)',
        cutoffTimeRedemptionsDescription: 'Indica la hora límite para los reembolsos del fondo, mostrada en hora estándar europea.',
        cutoffTimeSubscriptions: 'Hora límite (Suscripciones)',
        cutoffTimeSubscriptionsDescription: 'Indica la hora límite para las suscripciones del fondo, mostrada en hora estándar europea.',
        fundCountry: 'País del fondo',
        fundCurrency: 'Moneda del fondo',
        fundGroup: 'Grupo de fondos',
        fundGroupDescription: 'Indica el nombre del grupo de fondos, según lo asignado por AllFunds Bank.',
        fundHouse: 'Casa de fondos',
        fundHouseCountry: 'Fondo Casa País',
        fundHouseDescription: 'Maneja la liquidación del fondo con diferentes clases. Incluye agentes de transferencias, que manejan transferencias de fondos.',
        geographicArea: 'Área geográfica',
        geographicZone: 'Zona geográfica',
        inceptionDate: 'Fecha de inicio',
        inceptionDateDescription: 'La fecha en la que el fondo inició sus operaciones.',
        maximumAmount: 'Cantidad máxima',
        maximumAmountDescription: 'Indica el monto máximo permitido para suscripciones del fondo.',
        minAdditionalAmount: 'Cantidad adicional mínima',
        minAdditionalAmountDescription: 'Indica el importe mínimo permitido para suscripciones posteriores del fondo.',
        minInitialAmount: 'Cantidad inicial mínima',
        minInitialAmountDescription: 'Indica el monto mínimo permitido para la suscripción inicial del fondo.',
        notActive: 'No Activo',
        preNotificationDaysRedemptions: 'Días de prenotificación (reembolsos)',
        preNotificationDaysRedemptionsDescription: 'Indica el número de días de notificación previa que requiere el fondo para los reembolsos del fondo.',
        preNotificationDaysSubscriptions: 'Días de prenotificación (suscripciones)',
        preNotificationDaysSubscriptionsDescription: 'Indica el número de días de prenotificación que requiere el fondo para las suscripciones del fondo.',
        redemptionsByAmount: 'Reembolsos por monto',
        redemptionsByAmountDescription: 'Indica si se permiten canjes por un monto en dólares.',
        redemptionsByShares: 'Reembolsos por acciones',
        redemptionsBySharesDescription: 'Indica si se permiten reembolsos por un monto de acciones.',
        riskCategory: 'Categoría de riesgo',
        riskCategoryDescription: 'Basado en la calificación SRRI del fondo, indica la categoría de riesgo, que se basa en los riesgos de pérdidas o ganancias.',
        settlementDaysRedemptions: 'Días de liquidación (reembolsos)',
        settlementDaysRedemptionsDescription: 'Indica el número de días de liquidación estándar para los reembolsos del fondo.',
        settlementDaysSubscriptions: 'Días de liquidación (suscripciones)',
        settlementDaysSubscriptionsDescription: 'Indica el número de días de liquidación estándar para las suscripciones del fondo.',
        status: 'Estado',
        subAssetClass: 'Subclase de activo',
        subscriptionsByAmount: 'Suscripciones por monto',
        subscriptionsByAmountDescription: 'Indica si se permiten suscripciones por un monto en dólares.',
        subscriptionsByShares: 'Suscripciones por acciones',
        subscriptionsBySharesDescription: 'Indica si se permiten suscripciones por cantidad de acciones.',
        totalSettlementDaysRedemptions: 'Total de días de liquidación (reembolsos)',
        totalSettlementDaysRedemptionsDescription:
            'Indica el número total de días de liquidación para los reembolsos del fondo, que también incluye el número de días de notificación previa requeridos por el fondo.',
        totalSettlementDaysSubscriptions: 'Total de días de liquidación (suscripciones)',
        totalSettlementDaysSubscriptionsDescription:
            'Indica el número total de días de liquidación de las suscripciones del fondo, que incluye también el número de días de prenotificación requeridos por el fondo.',
        transferableFund: 'Fondo transferible',
        transferableFundDescription: 'Indica si el fondo es transferible (apto para trapaso), específicamente para el mercado español.',
        typeOfFund: 'Tipo de Fondo',
        volatilityRange: 'Rango de volatilidad',
        volatilityRangeDescription:
            'Basado en la calificación SRRI del fondo, indica el rango de volatilidad del fondo, que es una medida del nivel de riesgo asociado con la fluctuación del precio del fondo.',

        srri: 'Indicador sintético de riesgo y recompensa',
        potentiallyLowerReward: 'Recompensa potencialmente más baja',
        lowerRisk: 'Menor riesgo',
        higherRisk: 'Mayor riesgo',
        potentiallyHigherReward: 'Recompensa potencialmente mayor',
        potentialRiskVsReward: 'Riesgo potencial versus recompensa',
        riskIndicator: 'Indicador de Riesgo',
        risk: 'Riesgo'
    },

    deepAnalysisScreen: {
        viewFull: {
            statement: 'Ver declaración completa',
            balanceSheet: 'Ver el balance completo',
            cashFlow: 'Ver flujo de caja completo',
            viewAll: 'Ver todo'
        },
        deepAnalysis: 'Análisis profundo',
        quarterly: 'Trimestral',
        annual: 'Anual',
        showMore: 'Ver más',
        showLess: 'Ver menos',
        showAll: 'Ver todo',
        financials: 'Financieros',
        loadingFinancials: 'Cargando financieros...',
        sentimentAnalysis: 'Análisis de los sentimientos',
        noFinancialsFoundFor: (s) => `No hay valores financieros para ${s}`,
        noDataFoundFor: (s) => `No hay datos para ${s}`,
        fundamentals: {
            fundamentals: 'Fundamentos',
            projectedRevenue: 'Ingresos proyectados',
            dividends: 'Dividendos'
        },
        balanceSheet: {
            balanceSheet: 'Balance general',
            reportDate: 'Fecha del informe ',
            filingType: 'Tipo de presentacion ',
            fiscalDate: 'Fecha fiscal',
            fiscalQuarter: 'Trimestre fiscal',
            fiscalYear: 'Ejercicio fiscal',
            fixedAssets: 'Activos fijos',
            currency: 'Divisa',
            currentCash: 'Saldo de caja actual',
            shortTermInvestments: ' Inversiones a corto plazo',
            receivables: 'Cuentas por cobrar ',
            inventory: 'Inventario',
            otherCurrentAssets: 'Otros activos corrientes',
            currentAssets: 'Activos corrientes',
            longTermInvestments: 'Inversiones a largo plazo',
            propertyPlantEquipment: 'Propiedad Planta Equipo',
            propertyPlantEquipmentShort: 'Propiedad / Planta / Equipo.',
            goodwill: 'Fondo de comercio',
            intangibleAssets: 'Bienes inmateriales ',
            otherAssets: 'Otros activos',
            totalAssets: 'Activos totales',
            accountsPayable: 'Deudas pendientes',
            accountsReceivable: 'Cuentas por cobrar',
            currentLongTermDebt: 'deuda actual a largo plazo',
            otherCurrentLiabilities: 'Otros pasivo corriente ',
            totalCurrentLiabilities: 'Total de pasivos corrientes',
            longTermDebt: 'Deuda a largo plazo ',
            otherLiabilities: 'Otros pasivos',
            minorityInterest: 'Interés minoritario ',
            totalLiabilities: 'Responsabilidades totales',
            commonStock: 'Acciones ordinarias',
            retainedEarnings: 'Ganancias retenidas',
            treasuryStock: 'Acciones propias',
            capitalSurplus: 'Excedente de capital',
            shareholderEquity: 'Patrimonio neto',
            netTangibleAssets: 'Activo material neto',
            longTermLiabilities: 'Pasivos a largo plazo'
        },
        incomeStatement: {
            incomeStatement: 'Estado de ingresos',
            reportDate: 'Fecha del informe ',
            filingType: 'Tipo de presentacion ',
            fiscalDate: 'Fecha fiscal',
            fiscalQuarter: 'Trimestre fiscal',
            fiscalYear: 'Ejercicio fiscal',
            currency: 'Divisa',
            totalRevenue: 'Ingresos totales',
            costOfRevenue: 'Coste de ingresos',
            grossProfit: 'Beneficio bruto',
            researchAndDevelopment: 'Investigación y desarrollo',
            sellingGeneralAndAdmin: 'Venta Generales y administrativos ',
            operatingExpense: 'Gasto operativo',
            operatingIncome: 'Ingresos operativos',
            otherIncomeExpenseNet: 'Otros ingresos gastos neto',
            ebit: 'EBIT',
            interestIncome: 'Ingreso por intereses',
            interestIncomeNonOperating: 'Ingresos por Intereses No Operativos',
            interestExpense: 'Gastos por intereses Ingresos no operativos',
            pretaxIncome: 'Ingresos antes de impuestos',
            incomeTax: 'Impuesto sobre la renta',
            minorityInterest: 'Interés minoritario',
            netIncome: 'Ingresos netos',
            netIncomeBasic: ' Ingreso neto básico '
        },
        cashFlow: {
            cashFlow: 'Flujo de caja',
            capitalExpenditures: 'Gastos de capita',
            cashChange: 'Cambios de Efectivo',
            cashFlowFinancing: 'Financiamiento del flujo de caja',
            changesInInventories: 'Cambio en invertario ',
            changesInReceivables: 'Cambio en las cuentas por cobrar',
            currency: 'Divisa',
            depreciation: 'Depreciación',
            dividendsPaid: 'Dividendos pagados',
            exchangeRateEffect: 'Efecto del tipo de cambio',
            filingType: 'Tipo de presentacion',
            fiscalDate: 'Fecha fiscal',
            fiscalQuarter: 'Trimestre fiscal',
            fiscalYear: 'Ejercicio fiscal',
            investingActivityOther: 'Actividades de inversión, otras',
            investments: 'Inversiones',
            netBorrowings: 'Endeudamiento neto',
            netIncome: 'Ingresos netos',
            otherFinancingCashFlows: 'Otras actividades de financiación',
            reportDate: 'Fecha del informe',
            symbol: 'Símbolo',
            totalInvestingCashFlows: 'Flujos de efectivo de inversión totales '
        },
        earningsCalls: {
            earningsCalls: 'Informe sobre Ganancias/Beneficios',
            noEarningsCallsAvailable: 'No hay Informe sobre ganancias/beneficios',
            play: 'Jugar',
            playing: 'Jugando...',
            translateEarningsCall: (str) => str.replace('Earnings call', 'Informe sobre Ganancias/Beneficios')
        },
        investors: {
            investors: 'Inversores',
            insiders: 'Iniciados',
            institutions: 'Instituciones',
            funds: 'Fondos',
            insideInvestors: 'Inversores internos',
            institutionalInvestors: 'Inversores institucionales',
            fundInvestors: 'Inversores del fondo'
        },
        insiderRoster: {
            insiderRoster: 'Lista de personas con información privilegiada',
            noInsidersFound: (symbol: string) => `No hay una lista de personas con información privilegiada para ${symbol}`,
            shares: 'Acciones',
            asOf: 'Desde'
        },
        topInsiders: 'Accionistas principales con información privilegiada',

        advancedStats: {
            advancedStats: 'Estadisticas avanzadas',
            beta: 'BETA',
            totalCash: 'Caja total',
            currentDebt: 'Deuda actual',
            revenue: 'Ingressos',
            grossProfit: 'Ganancia bruta',
            totalRevenue: 'Ingreso total',
            ebitda: 'BAIIDA (EBITDA)',
            revenuePerShare: 'Ingresos por acción',
            revenuePerEmployee: 'Ingresos por empleado',
            debtToEquity: 'Deuda a capital',
            profitMargin: 'Margen de ganancia',
            enterpriseValue: 'Valor de la empresa',
            enterpriseValueToRevenue: 'Valor de la empresa a ingresos',
            priceToSales: 'Precio a ventas',
            priceToBook: 'Precio a libro',
            forwardPERatio: 'Índice a plazo de precio a ganancias',
            pegRatio: 'Índice de relación precio/crecimiento de la utilidad',
            peHigh: 'Precio/Crecimiento alto',
            peLow: 'Precio/Crecimiento bajo',
            week52HighDate: 'Nivel alto de 52 semanas',
            week52LowDate: 'Nivel bajo de 52 semanas ',
            week52HighDateSplitAdjustOnly: 'Nivel Alto de 52 semanas con ajustes por la división',
            week52LowDateSplitAdjustOnly: 'Nivel bajo de 52 semanas con ajustes por la división',
            putCallRatio: 'Calls / Puts '
        },

        priceTarget: {
            priceTarget: 'Precio indicativo',
            averagePriceTarget: 'Precio medio objetivo',
            range: 'Rango',
            low: 'Bajo',
            median: 'Mediana',
            high: 'Alto',
            average: 'Promedio'
        },
        otherInvestors: {
            insititutionalInvestors: 'Accionistas institucionales',
            fundInvestors: 'Accionistas de fondos de inversión',
            holder: 'Nombre',
            shares: 'Acciones',
            asOf: 'Desde',
            value: 'Valor'
        },
        splits: {
            splits: 'Particion',
            noSplitsOnRecord: (symbol: string) => `No hay particion para ${symbol}`,
            exDate: 'Fecha',
            from: 'Desde',
            to: 'A',
            ratio: 'Proporción',
            reverse: 'Inverso',
            // TODO: Translate other descriptions as we discover them. IEX unfortunately only provides a string type.
            translateDescription: (desc: string) => (desc === 'Ordinary Shares' ? 'Acciones Ordinarias' : desc)
        },
        estimatedRevenue: {
            estimatedRevenue: 'Ingresos estimados',
            noData: (symbol: string) => `No hay ingresos estimados para ${symbol}`,
            avg: 'Promedio',
            high: 'Alto',
            low: 'Bajo',
            nAnalysts: 'N.º de analistas',
            numberAnalysts: 'Número de analistas',
            period: 'Período',
            revenueAvg: 'Ingresos promedios',
            revenueHigh: 'Ingresos máximos',
            revenueLow: 'Ingresos mínimos'
        },
        dividends: {
            dividends: 'Dividendos',
            noData: (symbol: string) => `No se encontraron dividendos para ${symbol}`,
            mostRecentDividend: 'Fecha del dividendo mas Reciente',
            dividendForDate: 'Dividendo para la fecha',
            amount: 'Monto',
            currency: 'Divisa',
            declaredDate: 'Fecha declarada',
            exDate: 'Fecha',
            flag: 'Languidecer',
            frequency: 'Frecuencia',
            paymentDate: 'Fecha de pago',
            recordDate: 'Fecha de registro',
            symbol: 'Símbolo',
            date: 'Fecha'
        },
        AccountsPayable: 'Cuentas por Pagar',
        AccumulatedMinorityInterest: 'Interés Minoritario Acumulado',
        BalanceSheetItemType: 'Tipo de elemento del balance',
        BookValuePerShare: 'Valor contable por acción',
        CapitalExpenditures: 'Gastos de capital',
        CashDividendsPaid: 'Dividendos en efectivo pagados',
        CashShortTermInvestments: 'Inversiones en efectivo a corto plazo',
        ChangeInCapitalStock: 'Cambio en el capital social',
        ChangeInLongTermDebt: 'Cambio en la deuda a largo plazo',
        ChangesInWorkingCapital: 'Cambios en el capital de trabajo',
        CommonDividends: 'Dividendos Comunes',
        ConsolidatedNetIncome: 'Utilidad neta consolidada',
        CostOfGoodsSold: 'Costo de los bienes vendidos',
        DecreaseInDeposits: 'Disminución de depósitos',
        DecreaseInLoans: 'Disminución de préstamos',
        DeferredTaxAssets: 'Activos por impuestos diferidos',
        DeferredTaxLiabilities: 'Pasivos por Impuestos Diferidos',
        DeferredTaxesInvestmentTaxCredit: 'Crédito fiscal por inversión en impuestos diferidos',
        DepreciationDepletionAmortizationCashFlow: 'Flujo de caja de amortización de agotamiento de depreciación',
        EBITDA: 'EBITDA',
        EBITOperatingIncome: 'EBIT Ingreso operativo',
        EPSBasicBeforeExtraordinaries: 'EPS Básico Antes de Extraordinarios',
        EPSDilutedBeforeUnusualExpense: 'EPS diluido antes de gastos inusuales',
        EPSFullyDiluted: 'EPS completamente diluido',
        EquityInEarningsOfAffiliatesIncome: 'Equidad en las ganancias de los ingresos de los afiliados',
        ExchangeRateEffect: 'Efecto del tipo de cambio',
        ExtraordinaryItem: 'Artículo Extraordinario',
        ForeignCurrencyAdjustment: 'Ajuste de moneda extranjera',
        FreeCashFlow: 'Flujo de caja libre',
        FundsFromOperations: 'Fondos de operaciones',
        GrossIncome: 'Ingreso Bruto',
        IncomeStatementItem: 'Artículo del estado de resultados',
        IncomeTaxPayable: 'Impuesto sobre la Renta por Pagar',
        IncomeTaxes: 'Impuestos sobre la Renta',
        IncomeTaxesPayable: 'Impuestos sobre la Renta por Pagar',
        IncreaseInDeposits: 'Aumento De Depósitos',
        IncreaseInLoans: 'Aumento de Préstamos',
        IntangibleAssets: 'Activos Intangibles',
        InterestExpense: 'Gastos por intereses',
        Inventories: 'Inventarios',
        InvestmentTaxCredits: 'CréditosTaxInversión',
        IssuanceOfLongTermDebt: 'Emisión de deuda a largo plazo',
        IssuanceOrReductionOfDebtNet: 'Emisión o reducción de deuda neta',
        LongTermDebt: 'Deuda a Largo Plazo',
        LongTermNoteReceivable: 'Pagaré por cobrar a largo plazo',
        MinorityInterestExpense: 'Gasto por intereses minoritarios',
        MiscellaneousFunds: 'Fondos varios',
        NetAssetsFromAcquisitions: 'Activos netos de adquisiciones',
        NetChangeInCash: 'Cambio neto en efectivo',
        NetFinancingCashFlow: 'Flujo de caja de financiación neto',
        NetIncomeAvailableToCommonBasic: 'Ingreso neto disponible para Common Basic',
        NetIncomeCashFlow: 'Flujo de caja de ingresos netos',
        NetIncomeContinuingOperations: 'Operaciones continuas de ingresos netos',
        NetInvestingCashFlow: 'Flujo de caja neto de inversión',
        NetOperatingCashFlow: 'Flujo de caja operativo neto',
        NetPropertyPlantEquipment: 'Equipo de planta de propiedad neta',
        NonEquityReserves: 'Reservas no patrimoniales',
        NonOperatingIncomeExpense: 'Gastos de ingresos no operativos',
        OperatingLeaseExpense: 'Gastos de arrendamiento operativo',
        OtherAfterTaxAdjustments: 'Otros ajustes después de impuestos',
        OtherAssets: 'Otros Activos',
        OtherCurrentAssets: 'Otros Activos Corrientes',
        OtherCurrentLiabilities: 'Otros Pasivos Corrientes',
        OtherFinancingActivitiesUses: 'Usos de otras actividades financieras',
        OtherFinancingActivity: 'Otra Actividad de Financiamiento',
        OtherFunds: 'Otros Fondos',
        OtherLiabilities: 'Otros Pasivos',
        OtherOperatingExpense: 'Otros gastos operativos',
        OtherProceedsfromStockOptions: 'Otros ingresos de opciones sobre acciones',
        OtherSources: 'Otras fuentes',
        OtherUses: 'Otros Usos',
        PreferredDividends: 'Dividendos Preferidos',
        PreferredDividendsCashFlow: 'Flujo de caja de dividendos preferido',
        PreferredStockCarryingValue: 'Valor en libros de acciones preferidas',
        PretaxIncome: 'Ingresos antes de impuestos',
        ProceedsFromStockOptions: 'Ingresos de opciones sobre acciones',
        ProvisionForRisksCharges: 'Provisión para cargos por riesgos',
        PurchaseOfInvestments: 'Compra de inversiones',
        PurchaseOrSaleOfInvestments: 'Compra o Venta de Inversiones',
        ReductionOfLongTermDebt: 'Reducción de la deuda a largo plazo',
        RepurchaseOfCommonPreferredStock: 'Recompra de acciones preferentes comunes',
        ResearchAndDevelopment: 'Investigación y Desarrollo',
        SaleOfCommonPreferredStock: 'Venta de acciones preferentes comunes',
        SaleOfFixedAssetsBusinesses: 'Venta de Negocios de Activos Fijos',
        SaleOrMaturityOfInvestments: 'Venta o Vencimiento de Inversiones',
        SalesOrRevenue: 'Ventas o Ingresos',
        SellingGeneralAdministrativeExpenses: 'Venta de Gastos Administrativos Generales',
        ShortTermDebt: 'Deuda a Corto Plazo',
        ShortTermReceivables: 'Cuentas por cobrar a corto plazo',
        StockOptionCompensationExpense: 'Gasto de compensación de opciones sobre acciones',
        TangibleBookValuePerShare: 'Valor contable tangible por acción',
        TotalAssets: 'Activos Totales',
        TotalCommonEquity: 'Capital común total',
        TotalCurrentAssets: 'Total de activos actuales',
        TotalCurrentLiabilities: 'Total de Pasivos Corrientes',
        TotalEquity: 'Patrimonio Total',
        TotalInvestmentsAndAdvances: 'Total Inversiones Y Anticipos',
        TotalLiabilities: 'Pasivo Total',
        TotalLiabilitiesStockholdersEquity: 'Total Pasivo Capital contable',
        TotalShareholdersEquity: 'Patrimonio total de los accionistas',
        UnusualExpense: 'Gastos Inusuales'
    },

    // Option List Screen
    optionListScreen: {
        ask: 'Preguntar',
        askValue: 'Pedir Valor',
        bid: 'Ofrecer',
        bidValue: 'Oferta Valor',
        breakEven: 'Precio de equilibrio',
        buy: 'Comprar',
        calendarSpreads: 'Calendario extendido',
        call: 'Llamar',
        change: 'Cambio',
        changePercent: 'Cambio en %',
        close: 'Cierre',
        contractsYouSelect: 'Contratos que seleccionas aparecerán aquí.',
        expirationDate: 'Fecha de vencimiento',
        high: 'Alta',
        impliedVolatility: 'Volatilidad implícita',
        intrinsicValue: 'Valor intrínseco',
        lastPrice: 'Último precio',
        low: 'Bajo',
        open: 'Abrir',
        openInterest: 'Interés abierto',
        optionChain: 'Cadena de opciones',
        price: 'Precio',
        put: 'Poner',
        sell: 'Vender',
        sharePrice: ' Precio de la Acción.',
        strikePrice: 'Precio de Ejercicio',
        theGreeks: 'Los griegos',
        disclaimer: '15 minutos retrasados',
        optionGreeksTooltipIntro:
            'Las opciones griegas se utilizan para medir el riesgo de una opción y medir la sensibilidad de una opción a las variables que componen ese riesgo. Las variables están representadas por las letras griegas Delta, Gamma, Theta, Vega y Rho.',
        optionGreeksTooltipDelta: 'Delta Δ: Tasa de cambio por dólar de valor subyacente',
        optionGreeksTooltipGamma: 'Gamma Γ: Tasa de cambio de Δ',
        optionGreeksTooltipTheta: 'Theta Θ: Tasa de cambio de precio a lo largo del tiempo',
        optionGreeksTooltipVega: 'Vega ν: Tasa de cambio entre el precio de la opción y la volatilidad implícita del valor subyacente',
        optionGreeksTooltipRho: 'Rho ρ: Tasa de cambio entre el valor de una opción y un cambio del 1% en la tasa de interés',
        optionGreeksDelayed: 'retraso de 15 minutos',
        toBreakEven: 'Partir en partes iguales',
        tradeFutures: 'Comercio de futuros',
        tradeOptions: 'Ver Opciones',
        tradeStock: 'Comercio de acciones',
        viewMore: 'Ver más',
        viewOptionChain: 'Ver cadena de opciones',
        volume: 'Volumen',
        floatingButtonText: (qsi: string) => `${qsi} Cadena de opciones`
    },

    // A Piece of Logic in src/components/TradeTicket/Option/OptionTradeInputPage.tsx(83,20) prevents this from being inside optionListScreen
    greeks: {
        delta: {
            text: 'Delta',
            symbol: 'Δ',
            tooltip: 'Tasa de cambio por dólar de valor subyacente.'
        },
        gamma: {
            text: 'Gamma',
            symbol: 'Γ',
            tooltip: 'Tasa de cambio de Δ.'
        },
        theta: {
            text: 'Theta',
            symbol: 'Θ',
            tooltip: ' Tasa de cambio de precio a lo largo del tiempo.'
        },
        vega: {
            text: 'Vega',
            symbol: 'v',
            tooltip: 'Tasa de cambio entre el precio de la opción y la volatilidad implícita del valor subyacente.'
        },
        rho: {
            text: 'Rho',
            symbol: 'ρ',
            tooltip: 'Tasa de cambio entre el valor de una opción y un cambio del 1% en la tasa de interés.'
        }
    },

    enableOptionsScreen: {
        enableOptions: 'Habilitar opciones',
        title: '¡Parece que no tienes acceso a opciones!',
        getStartedWithOptions: 'Obtenga acceso al comercio de opciones',
        noOptionsEnabled: 'No tienes opciones habilitadas en tu cuenta. ¿Le gustaría?',
        enableOptionsTrading: 'Sí, habilite el comercio de opciones',
        notNow: 'Ahora no',
        noTradingOptionsEnabled: 'No tiene habilitado el comercio de opciones en ninguna de sus cuentas. Seleccione una cuenta a continuación para comenzar.'
    },

    optionContractScreen: {
        expiresOnDate: (date: Date) => `Caduca ${SafeFormat(date, 'dd MMM yyyy')}`,
        expiresSoon: 'Expira pronto',
        symbolLastQuote: (symbol: string) => `${symbol} precio último`,
        tradeUnderlying: (symbol: string) => `Ver las acciones comerciales de ${symbol}`,
        viewAllOptions: 'Ver todas las opciones de compra',
        viewMoreHistory: 'Ver más',
        viewMoreDetails: 'Ver más detalles',
        history: 'Historial',
        addLeg: 'Agregar pierna',
        removeLeg: 'Quitar pierna',
        stats: {
            stats: 'Estadisticas',
            bid: 'Ofrecer',
            ask: 'Preguntar',
            previousClose: 'Cierre previo',
            breakEven: 'Cubrir los gastos',
            open: 'Abierto',
            close: 'Cerca',
            high: 'Alto',
            low: 'Bajo',
            volume: 'Volumen',
            openInterest: 'Interés abierto',
            impliedVolatility: 'Volatilidad implícita',
            intrinsicValue: 'Valor intrínseco'
        }
    },

    futuresContractScreen: {
        baseContract: 'Contrato Base',
        expiresDate: (date: string | Date) => `Expirar ${SafeFormat(date, 'dd MMM yyyy')}`,
        upgradeToPremium: 'Actualizar a Inteligencia de Mercado Premium',
        viewMoreContracts: 'Ver más contratos',
        domNotInSession: {
            title: (symbol: string) => `${symbol} no está en sesión`,
            message: 'Debido a que este contrato no se encuentra en sesión, el Libro de Pedidos no está disponible actualmente.'
        }
    },
    advancedChartScreen: {
        notifications: {
            orderNotPlaced: 'Orden no colocada',
            invalidSecurity: 'Símbolo de seguridad no válido',
            invalidAccount: 'Número de cuenta no válido',
            shortSellNotAllowed: 'Venta en corto no permitida desde el gráfico avanzado'
        }
    },
    tradeTicket: {
        adjustedOptionsDisclaimer: 'Debido al riesgo de liquidez y liquidación de las opciones ajustadas, solo podemos permitir órdenes de cierre.',
        betaMaintenanceHours: 'Fuera de horario: operaciones no disponibles de 12 a. m. a 1:59 a. m. EST, de domingo a sábado',
        cancelEquityOrdersHours:
            'Los pedidos de acciones solo pueden cancelarse fuera del horario de mantenimiento del sistema / fuera del horario laboral, que es de 00:00 a 01:59 EST, de domingo a sábado. Por favor, intente cancelar nuevamente más tarde.',
        cancelEquityOptionsOrdersHours:
            'Los pedidos de opciones de acciones solo pueden cancelarse fuera del horario de mantenimiento del sistema / fuera del horario laboral, que es de 00:00 a 01:59 EST, de domingo a sábado. Por favor, intente cancelar nuevamente más tarde.',
        modifyEquitiesDayOrdersHours:
            'Los pedidos de acciones para el día solo se pueden editar durante el horario de 08:00 a 15:59 EST, de lunes a viernes. Si desea cambiar este pedido ahora, cancele y envíe un nuevo pedido.',
        modifyEquitiesOptionsDayOrdersHours:
            'Los pedidos de opciones de acciones para el día solo se pueden editar durante el horario de 08:00 a 15:59 EST, de lunes a viernes. Si desea cambiar este pedido ahora, cancele y envíe un nuevo pedido.',
        modifyEquitiesGtcOrdersHours:
            'Los pedidos de acciones Good Until Canceled solo se pueden editar durante el horario de 08:00 a 16:59 EST, de lunes a viernes. Si desea cambiar este pedido ahora, cancele y envíe un nuevo pedido.',
        modifyEquitiesOptionsGtcOrdersHours:
            'Los pedidos de opciones de acciones Good Until Canceled solo se pueden editar durante el horario de 08:00 a 16:59 EST, de lunes a viernes. Si desea cambiar este pedido ahora, cancele y envíe un nuevo pedido.',
        timeFrames: 'Marcos de tiempo',
        oneClickTradingTitle: 'Comercio en un clic',
        position: 'Posición',
        sellAll: 'Vender Todo',
        buyAll: 'Comprar Todo',
        oneClickTradingDescription:
            'Omita esta pantalla la próxima vez que realice una operación. No le pediremos que revise la operación antes de realizarla cuando opte por operar con un solo clic.\n\nPuede desactivarla yendo a sus "Preferencias" en la sección "Perfil".',
        misc: {
            setQuantity: 'Establecer cantidad',
            noChangesToReview: 'No hay cambios para revisar',
            startTradingOptions: 'Empezar hacer transacciones de Opciones',
            securityUnavailable: (symbol: string) => `Lo sentimos, ${symbol} este producto no está disponible en StoneX One`,
            enableOptionsTradingNow: 'Activar las transacciones de Opciones',
            createFuturesAccount: 'Crear una cuenta de futuros',
            createEquitiesAccount: 'Crear una cuenta de acciones',
            accountPending: 'Esta cuenta está pendiente de aprobación.',
            applicationPending: 'Aplicación pendiente',
            notAvailableToTrade: 'Aún no disponible para el comercio en StoneX One',
            noPricing: 'No hay información de precios disponible.',
            noPricingCall: 'Llame al 1-866-443-5847 para obtener ayuda.',
            notAvailableToEdit: 'Esta orden no puede ser editada en este momento',
            isUnderMaintenance: 'Actualmente fuera de horario. Por favor, inténtelo de nuevo más tarde.',
            overnightMarginCost: 'Costo del margen nocturno',
            cannotEdit: 'No Puede Editar',
            orderExtendedHoursError: (orderType: string) => {
                switch (orderType.toLowerCase()) {
                    case 'market':
                        return 'Las órdenes de mercado son órdenes que no están disponibles durante el horario extendido de negociación';
                    case 'stop':
                        return 'Las órdenes de detención son órdenes que no están disponibles durante el horario extendido de negociación';
                    case 'stoplimit':
                        return 'Las órdenes de límite de detención son órdenes que no están disponibles durante el horario extendido de negociación';
                }
            },
            isHoliday: 'El comercio no está disponible durante las vacaciones del mercado',
            extendedHoursMustBeChecked: 'La casilla de horario extendido debe estar marcada para realizar pedidos durante el horario extendido',
            badTimeInForce: 'El vencimiento del pedido seleccionado no se puede utilizar en este momento',
            badTimeForStrat: 'No se puede utilizar en este momento.',
            premarketRequired: 'En este momento, solo se permiten órdenes con la ejecución previa al mercado habilitada',
            postmarketRequired: 'En este momento, solo se permiten órdenes con la ejecución posterior al mercado habilitada',
            notInSession: (s, e) => `El horario de negociación de este valor es ${SafeFormat(s, 'h:mm bb')} - ${SafeFormat(e, 'h:mm bb z')}`,
            notInSessionOptions: (s, e, tz) =>
                `Las operaciones con opciones sólo se pueden realizar entre ${SafeFormat(s, 'h:mm bb')} a ${SafeFormat(e, 'h:mm bb z')} ${tz}`,
            notInSessionOptionsSymbolic: (s, e, tz) => `Las operaciones con opciones sólo se pueden realizar entre ${s} a ${e} ${tz}`,
            notInSessionFutures: (s, e) =>
                `El horario de negociación para este contrato de futuros es de ${s ? format(utcToZonedTime(s, 'America/Chicago'), 'h:mm bb') : '---'} a ${
                    e ? format(utcToZonedTime(e, 'America/Chicago'), 'h:mm bb z', { timeZone: 'America/Chicago' }) : '---'
                }`,
            notInSessionFuturesOptions: (s, e) =>
                `Trading hours for this futures contract are ${s ? format(utcToZonedTime(s, 'America/Chicago'), 'h:mm bb') : '---'} a ${
                    e ? format(utcToZonedTime(e, 'America/Chicago'), 'h:mm bb z', { timeZone: 'America/Chicago' }) : '---'
                }`,
            notInSessionShortSell: `Los pedidos de venta corta solo se pueden realizar entre las 9:30 a. m. y las 4:00 p. m. EST, de lunes a viernes.`,
            invalidEditTimeAdvisory: 'Las ordenes no pueden ser editadas entre las 5:00 am EST-07:56 EST',
            minimumNotMetTitle: 'No se ha alcanzado la Inversión Mínima Inicial',
            minimumNotMetSubtext: (minimumInvestment: number): string =>
                `Este fondo requiere una Inversión Inicial Mínima de ${FormatNumber.toMoneyOpt2(minimumInvestment)}.`,
            closedToNewInvestors: 'Este fondo está cerrado a nuevos inversores.',
            residualSharesWillBeSettled:
                'Las fracciones de acciones restantes se mostrarán como pendientes y se liquidarán automáticamente dentro de los dos días posteriores a la transacción.',

            isWeekend: 'El comercio de este valor no está disponible los fines de semana',
            notInSessionGeneral: 'Lo sentimos, esta seguridad no está disponible actualmente',
            securityTypeUnavailable: (type: TradeableSecurityType | 'loading') => {
                switch (type || 'unknown') {
                    case 'loading':
                        return 'Cargando';
                    case 'unknown':
                        return 'Lo sentimos, actualmente no se pueden negociar valores de este tipo';
                    default:
                        return `Lo sentimos, actualmente no se pueden negociar ${Snex1Spanish.general.productTypes[type]?.plural || 'valores de este tipo'}`;
                }
            },
            validatingOrder: 'Validando pedido...'
        },

        input: {
            retry: 'Rever',
            cancel: 'Cancelar',
            notes: 'Notas',
            tradeNotPermitted: 'Comercio no permitido',
            orderNotAllowed: 'Orden no permitida',
            cannotShort: 'No puede corto',
            invalidQuantity: 'Cantidad no válida',
            strategy: 'Estrategia',
            x1pAlgo: 'X1P Algo',
            care: 'Cuidado',
            careDescription: 'Pedido no retenido',
            vwap: 'VWAP',
            vwapDescription: 'Volume-Weighted Average Price',
            twap: 'TWAP',
            twapDescription: 'Time-Weighted Average Price',
            pov: 'POV',
            povDescription: 'Percent of Volume',
            sor: 'SOR',
            sorDescription: 'Smart Order Routing',
            applyForMargins: 'Solicitar Márgenes',
            unknownTitle: (isError: boolean) => (isError ? 'Error Desconocido' : 'Advertencia Desconocida'),
            unknownMessage: (isError: boolean) =>
                isError ? 'Lo sentimos, pero ha ocurrido un error desconocido' : 'Lo sentimos, pero ha ocurrido una advertencia desconocida',

            tradeNow: 'Negocia ahora',
            unitName: (symbol) => (FuturesSymbol.IsFuturesSymbol(symbol) || OptionSymbol.IsOptionSymbol(symbol) ? 'contrato' : 'cuota'),
            futures: {
                enforceFractional: 'Valor fraccionario no válido',
                enforceTickSize: (tickSize: number) => `El precio debe ser en incrementos de ${tickSize}.`,
                numberPerContract: (formattedNumber: string) => `${formattedNumber} por contrato`,
                side: 'Lado'
            },
            action: {
                buy: 'Comprar',
                sell: 'Vender',
                tabFontSize: 18
            },
            buyingPower: 'Poder adquisitivo',
            marketValue: 'Valor mercado',
            selectAnAccount: 'Seleccione una cuenta',
            buyingPowerAdvisory: (t) => `No tiene suficiente ${t.quantity} acciones para vender de ${t.securityId}.`,
            depositFunds: 'Depositar fondos',
            addFundsToContinue: 'Añadir fondos para continuar',
            dismiss: 'Despedir',
            shares: 'Acciones',
            tradeWholeShares: 'Negociar con acciones enteras',
            tradeDollarAmount: 'Operar una cantidad en dólares',
            securityCannotBeTraded: 'Este valor no se puede negociar en esta unidad.',
            bid: 'Bid',
            ask: 'Ask',
            mid: 'Mid',
            vol: 'Vol',
            held: (symbol) => `${Snex1Spanish.general.unit(symbol, 2)} Sostenido`,
            options: {
                breakEven: 'Precio de equilibrio',
                credit: 'Crédito',
                debit: 'Débito',
                even: 'Equilibrio',
                last: 'Ultimo',
                netAsk: 'Pregunta neta',
                netBid: 'Oferta neta',
                midpoint: 'Punto medio',
                underlyingShares: 'Acciones subyacentes',
                contracts: 'Contratos',
                sharesPerContract: (n: number) => `${FormatNumber.toCommas(n)} acciones por contrato`,
                actionToOpenClose: (t, openClose) => `${t.action === 'Buy' ? 'Comprar' : 'Vender'} a ${openClose === 'Open' ? 'Abrir' : 'Cerrar'}`,
                unlimited: 'Ilimitada',
                maxGain: 'Ganancia maxima',
                maxLoss: 'Pérdido maximo',
                enableOptionsTrading: 'Habilitar el comercio de opciones',
                upgradeOptionsLevel: 'Mejora tu nivel de opciones'
            },
            advisories: {
                notice: 'Aviso',
                immediateExecution: {
                    orderPriceError: (priceName: 'limit' | 'stop', priceIs: 'above-market' | 'below-market') =>
                        `La orden se ejecutará inmediatamenta ya que el precia ${priceName === 'limit' ? 'límite' : 'stop'} está ${
                            priceIs === 'above-market' ? 'encima' : 'debajo'
                        } del precio de mercado.`,
                    stopLimitPriceError: (priceIs: 'above-stop' | 'below-stop') =>
                        `Su orden podrá ser processada inmediatamente una vez que se alcance el precio stop, ya que el precio límite está por ${
                            priceIs === 'above-stop' ? 'encima' : 'debajo'
                        } del precio stop.`
                },
                insufficientValue: {
                    notEnoughBuyingPower: 'No hay sufficiente poder adquisitivo',
                    notEnoughMarketValue: 'No se mantiene lo suficiente',
                    depositFunds: 'Depositar fondos',
                    dismiss: 'Despedir'
                },
                invalidStopOrder: {
                    buy: 'Una orden stop de compra requiere un precio stop mayor que el precio de mercado actual.',
                    sell: 'Una orden stop de venta requiere un precio stop inferior al precio de mercado actual.'
                },
                amountIsEstimate: {
                    mainText: 'Esta cantidad es una estimación.',
                    subText: 'Su orden se ejecutará al mejor precio posible.'
                },
                notionalValueExceeded: {
                    mainText: 'Valor nocional máximo excedido',
                    subText:
                        'El costo total estimado de su operación excede el valor comercial máximo configurado de su perfil. Póngase en contacto con Soporte para obtener más información.'
                }
            },
            dollars: 'Dolares',
            limitPrice: 'Precio limite',
            stopPrice: 'Precio de parada',
            stopLimitPrice: 'Precio stop-limit',
            marketPrice: 'Precio de mercado',
            expiration: 'Vencimiento',
            orderType: {
                limit: 'Limite',
                loc: 'LOC',
                market: 'Mercado',
                moc: 'MOC',
                stop: 'Parada',
                stoplimit: 'Limite de Parada'
            },
            reviewAction: (t) => `Revisar ${t.action === 'Buy' ? 'compra' : 'venta'}`,
            reviewEdit: (t) => `Revisar pedido editar ${Snex1Spanish.general.symbolName(t.securityId)}`,
            reviewTrade: 'Revisar el comercio',
            buySymbol: (symbolName) => `Comprar ${symbolName}`,
            sellSymbol: (symbolName) => `Vender ${symbolName}`,
            sellAll: (t, amount) => `Vender los ${FormatNumber.toDecimal(amount, 3)} ${Snex1Spanish.general.unit(t.securityId, amount)}`,
            timeInForce: 'Tiempo vigente',
            timesInForce: {
                day: 'Vigente hasta el final del día',
                gtc: 'Vigente hasta ser cancelado',
                fok: 'Llenar o matar',
                ioc: 'Inmediato o cancelar'
            },
            advancedRouting: {
                notHeld: 'No Sostenido'
            },
            dvp: 'DVP',
            extendedHours: {
                GTXPre: 'Permite que esta orden sea ejecutada durante el horario extendido',
                GTXPost: 'Permite que esta orden sea ejecutada durante el horario extendido',
                nte: 'Permite que esta orden sea ejecutada durante el horario extendido'
            },
            totalCost: 'Costo total',
            estimatedSymbolAmount: (symbol: string) => `Total estimado de ${symbol}`,
            estimatedTotalCredit: 'Crédito total estimado',
            estimatedTotalCost: 'Costo total estimado',
            totalGain: 'Crédito total',
            estimatedTotalGain: 'Crédito estimado',
            error: 'Algo no está bien',
            warning: 'Por favor confirme',
            resubmit: 'Enviar otra vez',
            submitAnyway: 'Continuar',
            continue: 'Continuar',
            submitShort: 'Continuar pedido corto',
            reviseOrder: 'Revisar Orden'
        },

        reinvestmentOptions: {
            reinvestmentOptions: 'Opciones de reinversión',
            dividends: 'Dividendos',
            instructions: (t) =>
                `Por favor indique cuál de los siguientes rendimientos le gustaría reinvertir en su posición con ${t.securityId}. Estas serán sus preferencias de reinversión para esta y todas las compras futuras de ${t.securityId} realizadas a través de la cuenta ${t.accountNumber}, y no se podrán cambiar.`,
            mutualFundInstructions: (t) =>
                `De forma predeterminada, las distribuciones de fondos mutuos se aplican a una cuenta de efectivo Gratis con un saldo de crédito. Si prefiere reinvertir dividendos y/o distribuciones de ganancias de capital, Usted debe de elegir hacerlo a continuación. Cualquier preferencia de reinversión que establezca se aplicará de forma inmediata y se aplicará a todas las compras futuras de ${t.securityId} realizadas a través de la cuenta ${t.accountNumber}. Usted puede cambiar sus preferencias de reinversión comunicándose con el servicio al cliente. Por Favor Tenga en cuenta que las distribuciones de fondos mutuos están sujetas a impuestos independientemente de las preferencias de reinversión. Quiero reinvertir en la seguridad lo siguiente:`,
            review: 'Revisar',
            offshoreCashInstructions: (accountNumber: string, isin: string) =>
                `Las distribuciones para este fondo mutuo extraterritorial se aplican automáticamente a su cuenta como una reinversión. Esto se aplicará inmediatamente y se aplicará a todas las compras futuras de ${isin} realizadas a través de la cuenta ${accountNumber}. Al continuar con esta orden, usted entiende que la preferencia por este fondo mutuo extraterritorial se establecerá para reinvertir.`,
            offshoreReinvestInstructions: (accountNumber: string, isin: string) =>
                `Distributions for this offshore mutual fund are automatically applied to your account as a reinvestment. This will be applied immediately and will apply to all future purchases of ${isin} made through account ${accountNumber}. By continuing with this order, you understand that the preference for this offshore mutual fund will be set to reinvest.`,
            shortTermCapitalGains: 'Ganancias de capital a corto plazo',
            longTermCapitalGains: 'Ganancias de capital a largo plazo',
            editButtonText: 'Editar opciones de reinversión',
            saveButton: 'Guardar preferencias',
            reinvest: 'Reinvertir',
            mutualFundsDistribution: 'Las distribuciones de fondos mutuos están sujetas a impuestos independientemente de las preferencias de reinversión',
            usePreferences: 'Usar Preferencias',
            shortTermsGains: 'Ganancias a corto plazo',
            longTermGains: 'Ganancias a largo plazo',
            shortTerm: 'corto plazo',
            longTerm: 'largo plazo',
            reinvestScreenDesc: (
                symbol: string,
                accountNumber: string
            ) => `Puede asignar distribuciones de ${symbol} a una cuenta de efectivo como saldo de crédito libre, o reinvertirlas nuevamente en ${symbol}.
            Estas preferencias se utilizarán para todas las transacciones de ${symbol} realizadas a través de la cuenta ${accountNumber} una vez que coloque este
            comercio, y se puede cambiar en cualquier momento.`
        },

        review: {
            confirmTrade: 'Confirm Trade',
            gtxDisclaimer:
                'Negociación durante sesiones de negociación de horario extendido (incluida la sesión previa al mercado (de lunes a viernes, de 8:00 a. m. a 9:30 a. m., hora del este) y la sesión posterior al mercado (de lunes a viernes, de 4:00 p. m. a 5:00 p. m., hora del este) , en cada caso sujeto a las horas de negociación de vacaciones), conlleva riesgos únicos, como una mayor volatilidad de los precios, menor liquidez, mayores diferenciales de oferta/demanda y menor visibilidad del mercado, y puede no ser apropiado para todos los inversores. StoneX One no puede garantizar que las órdenes de horas extendidas se ejecutarán al mejor precio para un valor dado o que tales órdenes, incluidas las órdenes de mercado, se ejecutarán en absoluto. Al solicitar acceso para ingresar órdenes e ingresar cualquier orden durante las operaciones de horario extendido en StoneX One, incluso en cualquier plataforma de operaciones o plataforma móvil de StoneX One, usted reconoce que comprende los riesgos de operar durante las sesiones de operaciones de horario extendido.',
            offshoreDisclaimer:
                'Los tiempos límite, los períodos de liquidación y los períodos de notificación previa de los fondos mutuos extraterritoriales varían según el fondo. Recibirá el primer NAV disponible emitido por el fondo. Consulte el prospecto del fondo para conocer los horarios límite, las tarifas del fondo y otra información importante antes de invertir. Las órdenes de fondos mutuos extraterritoriales no se pueden cancelar ni modificar a través del sistema una vez enviadas. Comuníquese con Soporte para solicitar una cancelación o modificación.',
            equities: (priceFormatter, trade, currentMarketPrice, totalCost, limitPrice, stopPrice) => {
                const symbol = Snex1Spanish?.general?.symbolName(trade.securityId, 'long');
                const formattedSymbol = Dark(symbol);
                const formattedLimitPrice = Dark(priceFormatter(limitPrice));
                const formattedStopPrice = Dark(priceFormatter(stopPrice));
                const formattedTotalOrderPrice = Dark(`$${totalCost.toFixed(2)}`);
                const formattedCurrentMarketPrice = Dark(priceFormatter(currentMarketPrice));

                const isOnCloseOrder = [OrderTypes.loc, OrderTypes.moc].includes(trade?.orderType);
                if (trade.liquidate) {
                    return [Light('Eres'), Dark('vendiendo todas sus tenencias en'), formattedSymbol, Light('en'), Dark('precio del mercado'), Light('.')];
                }

                const tifQualifierLogic = [
                    {
                        rule: trade?.timeInForce?.toUpperCase() === 'GTXPRE' || trade?.extendedTimeInForce?.toUpperCase() === 'GTXPRE',
                        value: 'entre las 8 AM y las 5 PM, hora estándar del este (ET)'
                    },
                    {
                        rule: trade?.timeInForce?.toUpperCase() === 'NTE' || trade?.extendedTimeInForce?.toUpperCase() === 'NTE',
                        value: 'durante la sesión posterior al mercado extendida de hoy (4:00 p. m. - 5:00 p. m. EST)'
                    },
                    {
                        rule: (trade?.timeInForce?.toUpperCase() === 'DAY' || isOnCloseOrder) && !trade?.extendedTimeInForce,
                        value: 'llega hoy a'
                    },
                    {
                        rule: true, // default to GTC
                        value: 'en los proximos 180 días llega a'
                    }
                ];

                const tifQualifier = tifQualifierLogic.find((x) => x.rule).value;

                // if equities apply extended time in force check to tif qualifier
                const actionWording = trade.action === 'Buy' ? 'comprando' : 'vendiendo';
                const actionWordVerb = trade.action === 'Buy' ? 'comprar' : 'vender';
                const actionWordNoun = trade.action === 'Buy' ? 'compra' : 'venta';
                const xUnits = `${FormatNumber.toCommas(trade.quantity)} ${Snex1Spanish.general.unit(trade.securityId, trade.quantity)?.toLowerCase()}`;
                const unit = Snex1Spanish?.general.unit(trade.securityId, 1)?.toLowerCase();

                switch (true) {
                    case [OrderTypes.market, OrderTypes.moc].includes(trade?.orderType as OrderType): {
                        const perShare = isNaN(currentMarketPrice) ? '.' : `por ${unit}.`;
                        return [
                            Light('Estás'),
                            Dark(`${actionWording} ${FormatNumber.toCommas(trade.quantity)}`),
                            Light(`${Snex1Spanish.general.unit(trade.securityId, trade.quantity)?.toLowerCase()} of`),
                            formattedSymbol,
                            Light('al'),
                            Dark('precio del mercado'),
                            ...(isNaN(currentMarketPrice) ? [] : [Light('actualmente'), formattedCurrentMarketPrice]),
                            Light(perShare)
                        ];
                    }
                    case [OrderTypes.limit, OrderTypes.loc].includes(trade.orderType):
                        return [
                            Light('Si el precio de'),
                            formattedSymbol,
                            Light('llega a'),
                            formattedLimitPrice,
                            Light(`${tifQualifier}, se processará una`),
                            Dark(actionWordNoun),
                            Light('de'),
                            Dark(xUnits),
                            Light('a'),
                            formattedTotalOrderPrice,
                            Light('o mejor.')
                        ];
                    case trade.orderType === 'stop':
                        return [
                            Light('Si el precio de'),
                            formattedSymbol,
                            Light('llega a'),
                            formattedStopPrice,
                            Light(`${tifQualifier}, se processará una`),
                            Dark(actionWordNoun),
                            Light('de'),
                            Dark(xUnits),
                            Light('a'),
                            Dark('precio del mercado'),
                            Light('.')
                        ];
                    case trade.orderType === 'stoplimit':
                        return [
                            Light('Si el precio de'),
                            formattedSymbol,
                            Light('llega a'),
                            formattedStopPrice,
                            Light(`${tifQualifier}, colocará una orden de límite para`),
                            Dark(`${actionWordVerb} ${xUnits}`),
                            Light('de'),
                            formattedTotalOrderPrice,
                            Light('a'),
                            formattedLimitPrice,
                            Light('o mejor.')
                        ];
                }
            },
            tifQualifier(trade, formattedSymbol) {
                const isOnCloseOrder = [OrderTypes.loc, OrderTypes.moc].includes(trade?.orderType);
                if (trade.liquidate) {
                    return [Light('Eres'), Dark('vendiendo todas sus tenencias en'), Dark(formattedSymbol), Light('en'), Dark('precio del mercado'), Light('.')];
                }

                const tifQualifierLogic = [
                    {
                        rule: trade?.timeInForce?.toUpperCase() === 'GTXPRE' || trade?.extendedTimeInForce?.toUpperCase() === 'GTXPRE',
                        value: 'entre las 8 AM y las 5 PM, hora estándar del este (ET)'
                    },
                    {
                        rule: trade?.timeInForce?.toUpperCase() === 'NTE' || trade?.extendedTimeInForce?.toUpperCase() === 'NTE',
                        value: 'durante la sesión posterior al mercado extendida de hoy (4:00 p. m. - 5:00 p. m. EST)'
                    },
                    {
                        rule: (trade?.timeInForce?.toUpperCase() === 'DAY' || isOnCloseOrder) && !trade?.extendedTimeInForce,
                        value: 'llega hoy a'
                    },
                    {
                        rule: true, // default to GTC
                        value: 'en los proximos 180 días llega a'
                    }
                ];

                return tifQualifierLogic.find((x) => x.rule).value;
            },
            actionText: (trade: Partial<TradeRequest>, assetClass?: AssetClass, secMeta?: SecurityMetadataMix) => {
                return {
                    actionWording: trade.action === 'Buy' ? 'comprando' : 'vendiendo',
                    actionWordVerb: trade.action === 'Buy' ? 'comprar' : 'vender',
                    actionWordNoun: trade.action === 'Buy' ? 'compra' : 'venta',
                    xUnits: `${FormatNumber.toCommas(trade.quantity)} ${Snex1Spanish.general.unit(trade.securityId, trade.quantity)?.toLowerCase()}`,
                    unit: Snex1Spanish?.general.unit(trade.securityId, 1)?.toLowerCase()
                };
            },
            equitiesV2: (assetClass, trade, secMeta, currentMarketPrice, totalCost, limitPrice?, stopPrice?): ShadedTextList => {
                const symbol = Snex1Spanish.general.symbolName(trade.securityId, 'long');
                const formattedSymbol = Dark(symbol);
                const formattedLimitPrice = Dark(assetClass.formatPrice(limitPrice, secMeta));
                const formattedStopPrice = Dark(assetClass.formatPrice(stopPrice, secMeta));
                const formattedTotalOrderPrice = Dark(assetClass.formatPrice(totalCost, secMeta));
                const formattedCurrentMarketPrice = Dark(assetClass.formatPrice(currentMarketPrice, secMeta));

                const tifQualifier = Snex1Spanish.tradeTicket.review.tifQualifier(trade, formattedSymbol);
                if (typeof tifQualifier !== 'string') return tifQualifier;

                const { actionWording, actionWordVerb, actionWordNoun, xUnits, unit } = Snex1Spanish.tradeTicket.review.actionText(trade);

                const nonMarketTradeText = () => {
                    const isLimit = [OrderTypes.limit, OrderTypes.loc].includes(trade?.orderType as OrderType);
                    const isStop = trade.orderType === 'stop';
                    const isStopLimit = trade.orderType === 'stoplimit';
                    let text = [Light('Si el precio de'), formattedSymbol, Light('llega a')];

                    isStop || isStopLimit ? text.push(formattedStopPrice) : text.push(formattedLimitPrice);

                    if (isStopLimit) {
                        return text.concat([
                            Light(`${tifQualifier}, colocará una orden de límite para`),
                            Dark(`${actionWordVerb} ${xUnits}`),
                            Light('de'),
                            formattedLimitPrice,
                            Light('o mejor.')
                        ]);
                    } else text = text.concat([Light(`${tifQualifier}, se processará una`), Dark(actionWordNoun), Light('de'), Dark(xUnits), Light('a')]);

                    if (isLimit) return text.concat([formattedTotalOrderPrice, Light('o mejor.')]);
                    else return text.concat([Dark('precio del mercado'), Light('.')]);
                };

                if ([OrderTypes.market, OrderTypes.moc].includes(trade?.orderType as OrderType)) {
                    const perShare = isNaN(currentMarketPrice) ? '.' : `por ${unit}.`;
                    return [
                        Light('Estás'),
                        Dark(`${actionWording} ${FormatNumber.toCommas(trade.quantity)}`),
                        Light(`${Snex1Spanish.general.unit(trade.securityId, trade.quantity)?.toLowerCase()} de`),
                        formattedSymbol,
                        Light('al'),
                        Dark('precio del mercado'),
                        ...(isNaN(currentMarketPrice) ? [] : [Light('actualmente'), formattedCurrentMarketPrice]),
                        Light(perShare)
                    ];
                } else return nonMarketTradeText();
            },
            sharesV2: (assetClass, trade, secMeta, price, sharePrice?): ShadedTextList => {
                const creditDebit = {
                    Credit: Snex1Spanish.tradeTicket.input.options.credit,
                    Debit: Snex1Spanish.tradeTicket.input.options.debit,
                    Even: Snex1Spanish.tradeTicket.input.options.even
                }[trade?.leg2DebitCredit];
                const symbol = Snex1Spanish.general.symbolName(trade.securityId, 'long');

                const tifQualifier = Snex1Spanish.tradeTicket.review.tifQualifier(trade, symbol);
                if (typeof tifQualifier !== 'string') return tifQualifier;

                const { actionWording, actionWordVerb, actionWordNoun, xUnits, unit } = Snex1Spanish.tradeTicket.review.actionText(trade, assetClass, secMeta);

                const isMultiLeg = !!trade?.leg2Quantity;
                const leg2ActionWordNoun = trade?.leg2Action === 'Buy' ? 'compra' : 'venta';
                const leg2Sym = Snex1Spanish.general.symbolName(trade?.leg2SecurityId, 'long');

                const nonMarketNonMultiLegText = () => {
                    const isLimit = [OrderTypes.limit, OrderTypes.loc].includes(trade.orderType);
                    const isStopLimit = trade.orderType === 'stoplimit';

                    let text = [Light('Si el precio de'), Dark(symbol), Light('alcanza'), Dark(assetClass.formatPrice(trade.orderPrice, secMeta))];

                    if (isStopLimit) {
                        return text.concat([
                            Light(`${tifQualifier}, se processará una orden limitada para`),
                            Dark(`${actionWordVerb} ${xUnits}`),
                            Light('por'),
                            Dark(assetClass.formatPrice(trade.stopLimitPrice, secMeta)),
                            Light('o merjor precio.')
                        ]);
                    } else text = text.concat([Light(`${tifQualifier}, pondrás un`), Dark(actionWordNoun), Light('de'), Dark(xUnits)]);

                    if (isLimit) return text.concat([Light('a'), Dark(assetClass.formatPrice(price, secMeta)), Light('o mejor.')]);
                    else return text.concat([Light('al'), Dark('precio del mercado'), Light('.')]);
                };

                const isEven = trade.orderType === 'even';
                const formattedPrice = isEven ? '' : `${assetClass.formatPrice(price || 0, secMeta)} `;

                const forPrice = trade?.orderType === 'even' ? 'incluso dinero' : `${formattedPrice} de ${creditDebit} neto`;

                switch (true) {
                    case isMultiLeg:
                        return [
                            Light('Está realizando una'),
                            Dark(`${actionWordNoun}`),
                            Light('de'),
                            Dark(`${FormatNumber.toCommas(trade.quantity)}`),
                            Light(`${Snex1Spanish.general.unit(trade.securityId, trade.quantity)?.toLowerCase()} de`),
                            Dark(symbol),
                            Light('y una'),
                            Dark(`${leg2ActionWordNoun}`),
                            Light('de'),
                            Dark(`${FormatNumber.toCommas(trade.leg2Quantity)}`),
                            Light(`${Snex1Spanish.general.unit(trade.leg2SecurityId, trade.leg2Quantity)?.toLowerCase()} de`),
                            Dark(leg2Sym),
                            ...(trade?.orderType === 'market' ? [Light('al'), Dark('precio del mercado'), Light('.')] : [Light('por '), Dark(forPrice)])
                        ];
                    case [OrderTypes.market, OrderTypes.moc].includes(trade?.orderType as OrderType): {
                        const sharePriceFigure = isNaN(sharePrice)
                            ? 'precio del mercado'
                            : `precio del mercao (actualmente ${assetClass.formatPrice(sharePrice, secMeta)})`;
                        const perShare = isNaN(sharePrice) ? '.' : `por ${unit}.`;
                        return [
                            Light('Estás'),
                            Dark(`${actionWording} ${assetClass.formatQuantity(trade.quantity, secMeta)}`),
                            Light(`${Snex1Spanish.general.unit(trade.securityId, trade.quantity)?.toLowerCase()} de`),
                            Dark(symbol),
                            Light('al'),
                            Dark(sharePriceFigure),
                            Light(perShare)
                        ];
                    }
                    default:
                        return nonMarketNonMultiLegText();
                }
            },
            dollarsV2: (assetClass, trade, secMeta, sharePrice?): ShadedTextList => {
                const symbol = Snex1Spanish.general.symbolName(trade.securityId);
                const { actionWording } = Snex1Spanish.tradeTicket.review.actionText(trade);
                const sharePriceFigure = isNaN(sharePrice) ? 'precio del mercado' : `precio del mercado (actualmente ${assetClass.formatPrice(sharePrice, secMeta)})`;
                return [
                    Light(`Estas ${actionWording}`),
                    Dark(assetClass.formatPrice(trade.quantity, secMeta)),
                    Light('de'),
                    Dark(symbol),
                    Light('al'),
                    Dark(sharePriceFigure),
                    Light('.')
                ];
            },
            futuresV2: (assetClass, trade, secMeta): ShadedTextList => {
                const format = (value: number) => assetClass.formatPrice(value, secMeta);
                const type = trade.orderType === 'stoplimit' ? 'stop-limit' : trade.orderType;
                const action = Snex1Spanish.tradeTicket.review.actionText(trade).actionWordVerb;
                const symbol = Snex1Spanish.general.symbolName(trade.securityId, 'short', trade.quantity, secMeta);
                const s = trade.quantity === 1 ? '' : 's';

                const base: ShadedTextList = [
                    { text: 'Está realizando una', shade: 'light' },
                    { text: `${type} ${action}`, shade: 'dark', assetClass: 'future' },
                    { text: 'orden para', shade: 'light' },
                    { text: `${trade.quantity} ${symbol}`, shade: 'dark', assetClass: 'future' },
                    { text: `contrato${s}`, shade: 'light' }
                ];

                const suffGtc: ShadedTextList = [{ text: ', válido hasta que se cancele', shade: 'light' }];
                const suffPeriod: ShadedTextList = [{ text: '.', shade: 'light' }];
                const suffPrice = (price: number): ShadedTextList => [
                    { text: 'a', shade: 'light' },
                    { text: format(price), shade: 'dark', assetClass: 'future' }
                ];
                const suffStopLimit = (stop: number, limit: number): ShadedTextList => [
                    { text: 'con un stop de', shade: 'light' },
                    { text: format(stop), shade: 'dark', assetClass: 'future' },
                    { text: 'y un límite de', shade: 'light' },
                    { text: format(limit), shade: 'dark', assetClass: 'future' }
                ];

                const parts = [
                    base,
                    type === 'limit' || type === 'stop' ? suffPrice(trade.orderPrice) : null,
                    type === 'stop-limit' ? suffStopLimit(trade.orderPrice, trade.stopLimitPrice) : null,
                    trade.timeInForce === 'GTC' ? suffGtc : null,
                    suffPeriod
                ].filter((x) => !!x);

                return ShadedTextUtils.combine(parts);
            },
            futuresSpreads: (assetClass, trade, secMeta): ShadedTextList => {
                const format = (value: number) => assetClass.formatPrice(value, secMeta);
                const type = trade.orderType === 'stoplimit' ? 'stop-limit' : trade.orderType;
                const futuresSym = new FuturesSymbol(trade.securityId);
                const inverseAction = futuresSym.nearMonthDirection === '-';
                const { actionWordVerb: action } = Snex1Spanish.tradeTicket.review.actionText(trade);

                const fullSymbol = Snex1Spanish.general.symbolName(trade.securityId, 'short', trade.quantity, secMeta);
                const firstSymbol = futuresSym.nearMonthContract.slice(2);
                const secondSymbol = futuresSym.farMonthContract.slice(2);

                const s = trade.quantity === 1 ? '' : 's';

                const firstActioning = (trade?.action === 'Buy' && !inverseAction) || (trade?.action === 'Sell' && inverseAction) ? 'comprando' : 'vendiendo';
                const secondActioning = (trade?.action === 'Buy' && !inverseAction) || (trade?.action === 'Sell' && inverseAction) ? 'vendiendo' : 'comprando';

                const suffPeriod: ShadedTextList = [{ text: '.', shade: 'light' }];

                const base: ShadedTextList = [
                    { text: 'Estás realizando una orden', shade: 'light' },
                    { text: `${type} ${action}`, shade: 'dark', assetClass: 'future' },
                    { text: 'para', shade: 'light' },
                    { text: `${trade.quantity} ${fullSymbol}`, shade: 'dark', assetClass: 'future' },
                    { text: `contrato${s}`, shade: 'light' }
                ];

                const symbolExplanation: ShadedTextList = [
                    { text: 'Estás', shade: 'light' },
                    { text: `${firstActioning} ${firstSymbol}`, shade: 'dark', assetClass: 'future' },
                    { text: 'y', shade: 'light' },
                    { text: `${secondActioning} ${secondSymbol}`, shade: 'dark', assetClass: 'future' },
                    ...suffPeriod
                ];

                const suffGtc: ShadedTextList = [{ text: ', válido hasta cancelación.', shade: 'light' }];
                const suffPrice = (price: number): ShadedTextList => [
                    { text: 'a un precio de', shade: 'light' },
                    { text: format(price), shade: 'dark', assetClass: 'future' }
                ];
                const suffStopLimit = (stop: number, limit: number): ShadedTextList => [
                    { text: 'con un stop de', shade: 'light' },
                    { text: format(stop), shade: 'dark', assetClass: 'future' },
                    { text: 'y un límite de', shade: 'light' },
                    { text: format(limit), shade: 'dark', assetClass: 'future' }
                ];

                const flattenedArray = () => {
                    const parts = [base];
                    if (type === 'limit' || type === 'stop') parts.push(suffPrice(trade.orderPrice));
                    if (type !== 'stop-limit' && trade.timeInForce !== 'GTC') parts.push(suffPeriod);
                    if (type === 'stop-limit') parts.push(suffStopLimit(trade.orderPrice, trade.stopLimitPrice));
                    if (type === 'stop-limit' && trade.timeInForce !== 'GTC') parts.push(suffPeriod);
                    if (trade.timeInForce === 'GTC') parts.push(suffGtc);
                    parts.push(symbolExplanation);

                    return parts.flat(1);
                };

                return flattenedArray();
            },
            cryptos: (assetClass, trade, secMeta, price, sharePrice): ShadedTextList => {
                const symbol = Snex1Spanish.general.symbolName(trade.securityId, 'long');

                const tifQualifier = Snex1Spanish.tradeTicket.review.tifQualifier(trade, symbol);
                if (typeof tifQualifier !== 'string') return tifQualifier;

                const { actionWordVerb, actionWording } = Snex1Spanish.tradeTicket.review.actionText(trade);

                const isDollars = trade?.quantityQualifier === 'EvenDollar';

                if (trade.orderType === 'market') {
                    return [
                        Light('Estás realizando un'),
                        isDollars
                            ? Dark(`${actionWording} ${assetClass.formatPrice(trade?.quantity)} ${symbol}`, '', 'crypto')
                            : Dark(`${actionWording} ${trade?.quantity} ${symbol}`, '', 'crypto'),
                        Light('a'),
                        Dark('precio de mercado', '', 'crypto'),
                        Light('actualmente'),
                        Dark(assetClass.formatPrice(sharePrice, secMeta), '', 'crypto'),
                        Light('por criptomoneda.')
                    ];
                } else {
                    const isStopLimit = trade.orderType === 'stoplimit';
                    const isStop = trade.orderType === 'stop';
                    const tif = isStop ? 'gtc' : trade?.timeInForce?.toLowerCase();
                    const expandedTif = Snex1Spanish.tradeTicket.input.timesInForce[tif].toLowerCase();

                    if (isStopLimit) {
                        return [
                            Light('Si el precio de'),
                            Dark(symbol, '', 'crypto'),
                            Light('alcanza'),
                            Dark(assetClass.formatPrice(trade.orderPrice, secMeta), '', 'crypto'),
                            Light(`${tifQualifier}, colocarás una orden límite para`),
                            isDollars
                                ? Dark(`${actionWordVerb} ${assetClass.formatPrice(trade?.quantity)} de ${symbol}`, '', 'crypto')
                                : Dark(`${actionWordVerb} ${trade?.quantity} ${symbol}`, '', 'crypto'),
                            Light('a un precio de'),
                            Dark(assetClass.formatPrice(trade.stopLimitPrice, secMeta), '', 'crypto'),
                            Light('o mejor.')
                        ];
                    } else if (isStop) {
                        return [
                            Light('Está colocando una'),
                            Dark(`orden de ${actionWordVerb}`, '', 'crypto'),
                            Light('para'),
                            isDollars
                                ? Dark(`${assetClass.formatPrice(trade?.quantity)} de ${symbol}`, '', 'crypto')
                                : Dark(`${trade?.quantity} ${symbol}`, '', 'crypto'),
                            Light('a un precio de'),
                            Dark(assetClass.formatPrice(trade.orderPrice, secMeta), '', 'crypto'),
                            Light(`o mejor,`),
                            Dark(`${expandedTif}.`, '', 'crypto')
                        ];
                    } else {
                        return [
                            Light('Estás realizando un'),
                            Dark(`pedido ${actionWordVerb} con límite`, '', 'crypto'),
                            Light('de'),
                            isDollars ? Dark(`${assetClass.formatPrice(trade?.quantity)} ${symbol}`, '', 'crypto') : Dark(`${trade?.quantity} ${symbol}`, '', 'crypto'),
                            Light('a un precio de'),
                            Dark(assetClass.formatPrice(price, secMeta), '', 'crypto'),
                            Light(`de mejor`),
                            Dark(`${expandedTif}.`, '', 'crypto')
                        ];
                    }
                }
            },
            reinvestmentOptions: {
                willBeCashed: (labels: string[]) => [Light(`${Snex1Spanish.general.spokenJoin(labels)} serán depositado en su cuenta`), Dark('en efectivo'), Light('.')],
                willBeReinvested: (labels: string[]) => [Light(`${Snex1Spanish.general.spokenJoin(labels)} se`), Dark('reinvertirán'), Light('.')],
                allWillBeCashed: [Light('Todos los retornos serán depositado en su cuenta'), Dark('en efectivo'), Light('.')],
                allWillBeReinvested: [Light('Todos los retornos se'), Dark('reinvertirán'), Light('.')]
            }
        },

        receipt: {
            tradeReceipt: 'Recibo',
            orderSummary: 'Resumen del pedido',
            orderType: {
                orderType: 'Tipo de orden',
                marketOrder: 'Orden a mercado',
                mocOrder: 'Orden MOC',
                limitOrder: 'Orden limitada ',
                locOrder: 'Orden LOC',
                limitOrderMinMax: (sell: boolean) => `¿Operar a ${sell ? 'mínimo' : 'máximo'} precio o mejor`,
                stopOrder: 'Orden stop',
                stopLimitOrder: 'Orden stop-limit',
                marketOrderDesc: 'Opere inmediatamente al mejor precio disponible',
                limitOrderDesc: 'Operar a un precio o mejor',
                stopOrderDesc: 'Activar una operación de mercado a un precio de parada fijo',
                stopLimitOrderDesc: 'Activar una operación de límite a un precio de parada fijo',
                tradeCantBePlaced: 'Este tipo de comercio no puede colocarse para este tipo de seguridad',
                setStop: 'Establecer parada',
                setLimit: 'Establecer límite',
                setStopLimit: 'Establecer límite de parada',
                buyShareMaxPrice: 'Comprar acciones al precio máximo o inferior.',
                sellSharesMaxPrice: 'Vender acciones a un precio mínimo o superior.',
                triggerMarket: (orderAction: string) => `Activar un mercado ${orderAction} a un precio de parada fijo.`,
                triggerLimit: (orderAction: string) => `Activar un límite ${orderAction} a un precio de parada fijo.`
            },
            marketPrice: 'Valor de mercado ',
            limitPrice: 'Precio limite',
            stopPrice: 'Precio de parada',
            stopLimitPrice: 'Precio stop-limit',
            numberOfUnits: (symbol: string) => `Número de ${Snex1Spanish.general.unit(symbol, 0)}`,
            orderSubmitted: 'Orden enviada',
            checkForStatusUpdatesUnderPositions: 'Compruebe si hay actualizaciones de estado en las posiciones',
            submitAnotherOrder: 'Envía otra orden',
            submittingOrder: 'Enviando...',
            pleaseWait: 'Espere, por favor...',
            youMayClickAway: 'Puede navegar a otra página, le dejaremos saber cuando la orden ha sido completada',
            thereWasAProblem: 'Hubo un problema',
            pleaseTryAgain: 'Inténtalo de nuevo ',
            estimatedTotalCost: 'Costo total estimado',
            estimatedTotalGain: 'Ganancia total estimada',
            estimatedTotalCredit: 'Crédito total estimado',
            estimatedTotalDebit: 'Débito total estimado',
            leg1: 'Etapa 1',
            leg2: 'Etapa 2',
            timeInForce: 'Tiempo en vigor',
            timestamp: 'Marca de tiempo'
        },
        afterHours: {
            disclosure: {
                title: 'Aviso de sesión de negociación de horas extendidas',
                shortDisclosure: 'Negociar durante sesiones de negociación de horas extendidas conlleva riesgos únicos.',
                longDisclosure: [
                    { shade: 'dark', text: 'Negociación durante las sesiones de negociación de horas extendidas' },
                    {
                        shade: 'light',
                        text: '(incluida la Sesión previa al mercado (de lunes a viernes, de 8:00 a.m. a 9:30 a.m. ET), hora del este), y la Sesión posterior al mercado (de lunes a viernes, de 4:00 p.m. a 5:00 p.m. ET), horario comercial de vacaciones)'
                    },
                    { shade: 'dark', text: ', conlleva un riesgo único,' },
                    {
                        shade: 'light',
                        text: ' como una mayor volatilidad de precios, menor liquidez, diferenciales de oferta/demanda más amplios y menor visibilidad del mercado, y puede no ser apropiado para todos los inversores.\n\nStoneX One no puede garantizar que las órdenes de horas extendidas se ejecutarán al mejor precio para un determinado seguridad o que tales órdenes, incluidas las órdenes de mercado, se ejecutarán en absoluto. Al solicitar acceso para ingresar órdenes e ingresar cualquier orden durante las operaciones de horario extendido en StoneX One, incluso en cualquier plataforma de operaciones o plataforma móvil de StoneX One, usted reconoce que comprende los riesgos de operar durante las sesiones de operaciones de horario extendido.'
                    }
                ]
            }
        },
        shortSales: {
            acceptance: (shares, rate) => {
                return [Light('Vas corto'), Dark(`${shares} acción`), Light('a una'), Dark(`tasa de ${rate}%`), Light('.')];
            },
            thisWillShortYou: (quantityShort: number, rate: number) => {
                return [
                    Light('Esta operación hará que te quedes corto'),
                    Dark(`${quantityShort} acción${quantityShort === 1 ? '' : 'es'}`),
                    Light('a una tasa del'),
                    Dark(FormatNumber.toPercent(rate)),
                    Light('.')
                ];
            },
            disclosureTitle: 'Tasas de préstamo de acciones',
            disclosure: (isExpanded) =>
                isExpanded
                    ? 'Las tasas de préstamo de acciones que se muestran son indicativas y pueden no representar las tasas reales pagadas para tomar prestado el valor. Las tasas de préstamo indicativas son creadas por StoneX Financial Inc. en función de varios factores y múltiples fuentes. La tasa real de préstamo de acciones pagada al tomar prestado un valor puede variar significativamente de la tasa indicativa basada en las condiciones del mercado para ese valor en el momento del préstamo. Por favor llame al escritorio de Préstamo de Valores al (212) 379-5450 si tiene alguna pregunta.'
                    : 'Las tasas de préstamo de acciones que se muestran son indicativas y pueden no representar las tasas reales pagadas para tomar prestado el valor...',
            viewMore: 'Ver más',
            viewLess: 'Ver menos'
        },
        offshore: {
            disclosure: {
                title: 'Información importante sobre operaciones con fondos mutuos extraterritoriales',
                shortDisclosure: 'Información importante sobre operaciones con fondos mutuos extraterritoriales.',
                longDisclosure: [
                    {
                        shade: 'dark',
                        text: 'Los tiempos límite, los períodos de liquidación y los períodos de notificación previa de los fondos mutuos extraterritoriales varían según el fondo. Recibirá el primer NAV disponible emitido por el fondo. Consulte el prospecto del fondo para conocer los horarios límite, las tarifas del fondo y otra información importante antes de invertir. Las órdenes de fondos mutuos extraterritoriales no se pueden cancelar ni modificar a través del sistema una vez enviadas. Comuníquese con Soporte para solicitar una cancelación o modificación.'
                    }
                ]
            }
        },
        crypto: {
            disclosure: {
                title: 'Información de Cuentas y Comercio de Criptomonedas',
                shortDisclosure:
                    'El comercio de criptomonedas implica un riesgo significativo y puede resultar en la pérdida de su capital invertido. Por favor, comercie responsablemente.',
                longDisclosure: [
                    {
                        text: 'Todas las actividades de comercio de criptomonedas facilitadas a través de StoneX One Pro se realizan a través de ETANA, un proveedor de servicios de terceros de confianza. Tenga en cuenta que cuando participa en el comercio de criptomonedas, está interactuando directamente con ETANA, y todas las operaciones se ejecutan en sus sistemas.',
                        lineBreak: 'double'
                    },
                    {
                        text: '\n\nAdemás, sus cuentas de criptomonedas se abren y mantienen en ETANA. Esto significa que ETANA es responsable de mantener, asegurar y gestionar sus activos de criptomonedas. StoneX One Pro sirve como un conducto a los servicios de ETANA, brindándole acceso a su entorno de comercio.',
                        lineBreak: 'double'
                    },
                    {
                        text: '\n\nPor favor, tenga en cuenta los siguientes puntos clave:',
                        lineBreak: 'double'
                    },
                    {
                        text: '\n1.'
                    },
                    {
                        shade: 'dark',
                        text: 'Ejecución de todas las Operaciones'
                    },
                    {
                        text: ': Todas las operaciones de criptomonedas iniciadas en StoneX One Pro son ejecutadas por ETANA. No ejecutamos ninguna operación en nuestros sistemas.',
                        lineBreak: true
                    },
                    {
                        text: '\n2.'
                    },
                    {
                        shade: 'dark',
                        text: 'Gestión de Cuentas'
                    },
                    {
                        text: ': Sus cuentas de criptomonedas, incluidas todas las depositaciones y retiradas, son gestionadas por ETANA.',
                        lineBreak: true
                    },
                    {
                        text: '\n3.'
                    },
                    {
                        shade: 'dark',
                        text: 'Seguridad y Custodia'
                    },
                    {
                        text: ': ETANA es responsable de la seguridad y custodia de sus activos de criptomonedas. StoneX Financial no mantiene ni asegura directamente ningún activo de criptomonedas.',
                        lineBreak: true
                    },
                    {
                        text: '\n4.'
                    },
                    {
                        shade: 'dark',
                        text: 'Tarifas y Cargos'
                    },
                    {
                        text: ': Cualquier tarifa o cargo relacionado con el comercio de criptomonedas será determinado y recaudado por ETANA. Consulte la lista de tarifas de ETANA para obtener información detallada.',
                        lineBreak: true
                    },
                    {
                        text: '\n5.'
                    },
                    {
                        shade: 'dark',
                        text: 'Supervisión Regulatoria'
                    },
                    {
                        text: ': ETANA opera bajo su propio marco regulatorio. Es esencial revisar los términos de servicio, la política de privacidad y cualquier divulgación regulatoria proporcionada por ETANA.',
                        lineBreak: 'double'
                    },
                    {
                        text: '\n\nAl usar StoneX One Pro para el comercio de criptomonedas, usted reconoce y acepta estos términos. Es importante realizar su propia diligencia debida y comprender los riesgos involucrados en el comercio de criptomonedas. Para cualquier problema o pregunta relacionada con sus cuentas o operaciones de criptomonedas, por favor contacte directamente a ETANA.',
                        lineBreak: 'double'
                    },
                    {
                        shade: 'dark',
                        text: '\n\nDescargo de responsabilidad'
                    },
                    {
                        text: ': El comercio de criptomonedas implica un riesgo significativo y puede resultar en la pérdida de su capital invertido. Por favor, comercie responsablemente.'
                    }
                ]
            }
        }
    },

    orders: {
        multipleOrders: 'Múltiples órdenes',
        accountNumber: 'Número de cuenta',
        account: 'Cuenta',
        acct: 'Cta',
        contractSymbol: 'Símbolo del contrato',
        dateAndTime: 'Fecha y hora',
        filledAmount: 'Cantidad llenada',
        fillPrice: 'Precio de relleno',
        onClose: 'Al cerrar',
        order: 'Orden',
        openOrders: 'Órdenes abiertas',
        tradeHistory: 'Actividad comercial',
        orderHistory: 'Historial de pedidos',
        tradeConfirmation: 'Ver confirmaciones comerciales',
        expirationDate: 'Fecha de caducidad',
        true: 'Verdadero',
        spreadSymbol: 'Símbolo de propagación',
        buySide: 'Comprar lado',
        sellSide: 'Lado de la venta',
        completedDate: 'Fecha completada',
        fees: 'Tarifas',
        averageTradePrice: 'Precio promedio',
        actionSummary: (props: ActionSummaryProps): string => {
            const action = Snex1Spanish.orders.typedAction(props.order);
            const marketPriceText = props?.format === 'short' ? 'MP' : 'market price';
            return getActionSummary({ ...props, action, marketPriceText });
        },
        stratValue: (strat) => {
            switch (strat) {
                case 'Care':
                    return 'Care';
                case 'SOR':
                    return 'SOR';
                case 'VWAP':
                    return 'VWAP';
                case 'TWAP':
                    return 'TWAP';
                case 'POV2':
                    return 'POV 2%';
                case 'POV5':
                    return 'POV 5%';
                case 'POV10':
                    return 'POV 10%';
                case 'POV15':
                    return 'POV 15%';
                case 'POV20':
                    return 'POV 20%';
                case 'POV25':
                    return 'POV 25%';
            }
        },

        actionSummary2: (o, opt, hideMoneySymbol) => {
            const act = Snex1Spanish.orders.typedAction(o);
            const q = Snex1Spanish.orders.quantityPriceSummary2(o, opt, hideMoneySymbol);
            switch (opt?.format) {
                case 'short':
                    return `${act} ${q}`;
                case 'long':
                default:
                    return `${act} ${q}`;
            }
        },
        quantityPriceSummary2: (o, opt, hideMoneySymbol) => {
            const orderType = DetermineOrderType(o);
            if (o.quantityQualifier === 'EvenDollar') return `${FormatNumber.toMoney(o?.orderQuantity)}`;

            const amt: string = (() => {
                const fmt = (n: number): string => FormatNumber.toMoney(n, undefined, hideMoneySymbol ? 'never' : undefined, true, opt?.decimalPlaces || 2);
                const mp = opt?.format === 'short' ? 'MP' : 'market price';
                switch (orderType) {
                    case 'limit':
                    case 'stoplimit':
                        return fmt(o.limitPrice);
                    case 'stop':
                        return fmt(o.stopPrice);
                    case 'market':
                        return mp;
                    case 'even':
                        return 'equilibrio';
                }
            })();

            const { showDecimals = false, decimalPlaces = 2 } = o?.quantityQualifier === 'Shares' ? { showDecimals: true, decimalPlaces: 3 } : {};

            return `${FormatNumber.toCommas(o?.orderQuantity, showDecimals, decimalPlaces)} @ ${amt}`;
        },

        fullActionTypeToOpenOrClose: (o) => {
            switch (o.action?.toLowerCase()) {
                case 'buytoopen':
                    return 'Buy to open';
                case 'buytoclose':
                    return 'Buy to close';
                case 'selltoopen':
                    return 'Sell to open';
                case 'selltoclose':
                    return 'Sell to close';
                case 'sellshort':
                    return 'Short sell';
                default:
                    return o.action;
            }
        },
        numberFilledOfTotal: (o) =>
            typeof o.leavesQuantity === 'number'
                ? `${FormatNumber.toDecimal(o.orderQuantity - o.leavesQuantity)} de ${FormatNumber.toDecimal(o.orderQuantity)} llenado`
                : '',
        action: (a, secType) => {
            const actions = {
                buy: 'compra',
                sell: 'venta',
                buytoopen: secType !== 'futures' ? 'comprar para abrir' : 'compra',
                buytoclose: secType !== 'futures' ? 'comprar para cerrar' : 'compra',
                selltoopen: secType !== 'futures' ? 'vender para abrir' : 'vender',
                selltoclose: secType !== 'futures' ? 'vender para cerrar' : 'vender',
                sellshort: 'short sell',
                buytocover: 'buy to cover',
                selltocover: 'sell to cover'
            };

            const actionInput = (a || '')?.toLowerCase();
            if (actionInput === 'fullliquidation') return 'full liquidation';
            const action = actions[actionInput] || actionInput;

            return action;
        },
        multiLegAction: (o) => {
            const actionInput = (o?.action || '')?.toLowerCase();
            const leg2Input = (o?.complexOrders?.[0]?.action || '')?.toLowerCase();
            return actionInput === leg2Input
                ? `${Snex1Spanish.orders.action(actionInput)} 2`
                : `${Snex1Spanish.orders.action(actionInput)} 1, ${Snex1Spanish.orders.action(leg2Input)} 1`;
        },
        typedAction: (o, secType) => {
            const orderType = DetermineOrderType(o);

            const orderTypeLabel = (() => {
                switch (orderType) {
                    case 'market':
                        return 'de mercado';
                    case 'limit':
                        return 'limitada';
                    case 'stop':
                        return 'stop';
                    case 'stoplimit':
                        return 'stop-limit';
                    case 'even':
                        return 'equilibrio';
                }
            })();

            return `${orderTypeLabel} ${Snex1Spanish.orders.action(o.action, secType)}`;
        },
        orderSetup: {
            orderSetup: 'Configuración de pedidos',
            expires: 'Caduca',
            orderType: 'Tipo de orden',
            priceType: 'tip de precio',
            quantityType: 'Tipo de cantidad',
            notional: 'Hipotética',
            algorithm: 'Estrategia Algo',
            stratPercent: 'Porcentaje de Estrategia',
            sellAll: 'Vender todo',
            done: 'Feito',
            discardTitle: 'Descartar',
            discardChanges: 'Descartar Cambios',
            discardChangesMsg: 'Tienes cambios sin guardar. ¿Está seguro de que desea descartar los cambios?',
            stayHere: 'Quédate aquí',

            action: 'Action',
            actionBottomSheet: {
                buySell: 'Compra-Venta',
                sellBuy: 'Venta-Compra',
                buyTheEarlierAndSellTheLater: 'Compra el contrato anterior y vende el contrato posterior',
                sellTheEalierAndBuyTheLater: 'Vende el contrato anterior y compra el contrato posterior',
                buying: 'Comprando',
                selling: 'Vendiendo'
            },

            expiresBottomsheet: {
                expires: 'Caduca',
                goodThruTitle: 'Buen día hasta el día',
                goodThruSubtitle: 'La orden será válida hasta el final del día de negociación actual.',
                goodTillCancelled: 'Bueno hasta cancelar (GTC)',
                goodTillCancelltedSubtitle: 'El pedido será válido hasta que se cancele, por un máximo de 180 días.',
                dayOrdersUnavailable: 'Los pedidos diarios no están disponibles en este momento',
                gtcOrdersUnavailable: 'Los pedidos GTC no están disponibles en este momento',
                premarketUnavailable: 'Las órdenes previas al mercado no están disponibles en este momento',
                postMarketUnavailable: 'Las órdenes posteriores al mercado no están disponibles en este momento',

                preMarketExecution: 'Ejecución previa al mercado',
                preMarketActive: 'La orden estará activa y podrá ejecutarse entre las 8AM-5PM EST',
                postMarketExcution: 'Ejecución posterior al mercado',
                postMarketActive: 'La orden estará activa solo durante la sesión de la tarde de hoy y puede ejecutarse entre las 4 p.m. y las 5 p.m. EST',
                goodTilCancelActive: 'El pedido estará activo hasta que sea cancelado, por un máximo de 180 días.',
                goodTilCancelActiveFutures: 'El pedido estará activo hasta que sea cancelado.',
                goodThruDayActive: 'La orden estará activa hasta el final del día de negociación actual.',

                fillOrKillExecution: 'Fill Or Kill',
                fillOrKillActive: 'Fill entire order immediately or cancel it'
            }
        },
        quantity: 'Cantidad',
        commission: 'Comisión',
        stopPrice: 'Precio de Parada',
        limitPrice: 'Precio Limite',
        totalCost: 'Costo Total',
        orderTotalImpactLabel: (action: 'Buy' | 'Sell' | string) => {
            const regex = /sell/i; // This will match any variation of 'Sell'
            const containsSell = regex.test(action);
            return containsSell ? 'Crédito Total' : 'Costo Total';
        },
        placed: 'Realizado',
        swipeUpTrade: 'Desliza hacia arriba para comerciar',
        by: (who: string) => `por ${who}`,
        timeInForce: 'Tiempo Vigente',
        gtxPre: 'Pre',
        gtxPost: 'Correo',
        isin: 'ISIN',
        strategy: 'Estrategia',
        averagePrice: 'Precio Promedio',
        actionLabel: 'Acción',

        placedBy: 'Realizado por',
        securityName: 'Nombre de acción',
        orderNumber: 'Número de pedido',
        orderNumbers: 'Números de pedidos',
        orderId: 'Solicitar ID',
        contactRepToChange: 'Para cancelar o realizar cambios en su pedido, comuníquese con Soporte.',
        cannotBeCanceled: 'La orden no puede ser cancelada o modificada',
        lockedForOpen: 'La orden está bloqueada para la apertura. Por favor, inténtelo de nuevo a las 9:30 AM EST.',
        cancelOrder: 'Cancelar pedido',
        cancelingOrder: 'Cancelar pedido',
        orderCanceled: 'Pedido cancelado',
        orderModified: 'Orden modificado',
        orderFilled: 'Pedido completado',
        orderPartiallyFilled: 'Pedido parcialmente completado',
        orderRejected: 'Pedido Rechazado',
        orderStopped: 'Order Stopped',
        orderSuspended: 'Order Suspended',
        orderBiddingOpen: 'Bidding Open for Order',
        orderPlaced: 'Pedido realizado',
        editOrder: 'Editar Orden',
        edit: 'Editar',
        cancel: 'Cancelar',
        orderAgain: 'Ordene nuevamente',
        cannotCancelEditOrder: (action: string) => {
            return action === 'edit' ? 'No se puede editar el pedido' : 'No se puede cancelar el pedido';
        },
        cannotCancelEditSublabel: (action: string) => {
            return action === 'edit' ? 'Este pedido no se puede editar en este momento' : 'Este pedido no se puede cancelar en este momento';
        },
        contactLabel: (useSupport: boolean) => {
            return useSupport ? 'Contactar soporte' : 'Contactar representante';
        },
        contactSubLabel: (onlyEdit: boolean, useSupport: boolean, editAndCancel?: boolean) => {
            return `Este pedido solo puede ser ${onlyEdit ? 'editado' : editAndCancel ? 'editado o cancelado' : 'cancelado'} contactando a ${
                useSupport ? 'Soporte' : 'su representante'
            }`;
        },
        partialFill: (symbol, filled, quantity) => {
            const unit = OptionSymbol.IsOptionSymbol(symbol) ? 'Contratos' : 'Acciones';
            return `${filled} de ${quantity} ${unit} llenas`;
        },
        fills: {
            sequenceNumber: 'Número de secuencia de llenado',
            price: 'Precio de relleno',
            quantity: 'Cantidad',
            timestamp: 'Marca de tiempo'
        },
        statuses: {
            open: 'Abierta',
            working: 'Laboral',
            pending: 'Pendiente',
            completed: 'Finalizado',
            filled: 'Completada',
            cancelled: 'Cancelado',
            cancelPending: 'Cancelación pendiente',
            cancelPartialFill: 'Parcialmente cancelado',
            allocated: 'Asignado',
            preAllocated: 'Pre-asigando',
            created: 'Creado',
            expired: 'Expirado',
            route: 'Encaminar',
            routed: 'Encamidado',
            verification: 'Verificación',
            change: 'Cambio',
            notProcessed: 'No procesado',
            processed: 'Procesado',
            confirmed: 'Confirmado',
            cancel: 'Cancelar',
            canceled: 'Cancelada',
            rejected: 'Rechazado',
            unknown: 'Desconocido',
            deleted: 'Borrado'
        }
    },

    messages: {
        subjectByPayload: {
            'System:AdminBroadcast': 'Anuncio',
            'System:AdminDirect': 'Mensaje del administrador',
            'System:Welcome': 'Bienvenidos!',
            'CustomAlert:Default': 'Alerta',
            'OrderUpdate:New': 'Orden Abierta',
            'OrderUpdate:Filled': 'Orden Llenada',
            'OrderUpdate:Rejected': 'Orden Rechazada',
            'OrderUpdate:Canceled': 'Orden Cancelada',
            defaultOrderUpdate: 'Order Actualizada'
        }
    },

    tours: {
        stepsBadgePreposition: 'de',
        lastStepButtonText: 'Listo',
        portfolioScreen: {
            chart: [[{ text: 'Mantente al tanto de tu ' }, { text: 'portafolio', weight: 'bold' }, { text: ', usando el grafico del portafolio.' }]],
            watchlist: [
                [{ text: 'Tu ' }, { text: 'posiciones', weight: 'bold' }, { text: ', aparecerán aquí.' }],
                [
                    { text: 'Tú también puedes ' },
                    {
                        text: 'personaliza tu lista de seguimiento',
                        weight: 'bold'
                    },
                    { text: '.' }
                ]
            ],
            depositFunds: [
                [
                    { text: 'Seleccione aquí para' },
                    { text: 'depositar fondos' },
                    {
                        text: '. Una vez que haya depositado los fondos, ¡puede comenzar!'
                    }
                ]
            ],
            search: [
                [
                    {
                        text: '¡Busque acciones, fondos mutuos o cualquier otra cosa!'
                    }
                ]
            ],
            profile: [
                [
                    {
                        text: '¡Haga clic en la imagen de perfil aquí para ver '
                    },
                    {
                        text: 'la configuración de su cuenta, transacciones y mucho más',
                        weight: 'bold'
                    },
                    { text: '!' }
                ]
            ]
        },
        stockScreen: {
            candlestick: [
                [{ text: '¿Quieres ver un ' }, { text: 'gráfico de velas' }, { text: '?' }],
                [
                    {
                        text: 'Haga clic en este símbolo para cambiar entre el gráfico de línea y vela.'
                    }
                ]
            ],
            advancedChart: [
                [
                    { text: 'Nuestro ' },
                    { text: 'gráfico avanzado', weight: 'bold' },
                    {
                        text: ' le brinda accesso a un arsenal de herramientas de gráficos y la capacidad de '
                    },
                    {
                        text: 'operar directamente desde el gráfico',
                        weight: 'bold'
                    },
                    { text: '.' }
                ]
            ],
            addToWatchlist: [
                [
                    { text: '¿Quieres estar al tanto de esta acción? ' },
                    {
                        text: 'Agréguela a su lista de seguimiento',
                        weight: 'bold'
                    },
                    { text: '.' }
                ]
            ],
            orderType: [
                [
                    {
                        text: 'Haga clic en el símbolo del lápiz para modificar el '
                    },
                    { text: 'tipo de orden', weight: 'bold' },
                    { text: '.' }
                ],
                [{ text: '(Mercado, limitada, stop, etc.)' }]
            ],
            optionsTrading: [[{ text: 'Puede acceder a la ' }, { text: 'cadena de opciones', weight: 'bold' }, { text: ' de esta accion haciendo clic aquí.' }]]
        },
        premiumStockScreen: {
            advancedChart: [
                [{ text: 'Multigráfico en una sola pantalla' }],
                [{ text: '¡Haga clic aquí para realizar ' }, { text: 'análisis técnicos', weight: 'bold' }, { text: ' en una sola vista!' }]
            ],
            alerts: [
                [{ text: 'Establecer alertas rápidas' }],
                [
                    { text: 'Haga clic en el ' },
                    { text: 'icono de la campana', weight: 'bold' },
                    { text: ' para configurar ' },
                    {
                        text: 'los disparadores basados en los movimientos de precios en tiempo real',
                        weight: 'bold'
                    },
                    { text: '. ¡Séa el primero en saberlo!' }
                ]
            ],
            deepAnalysis: [
                [{ text: 'Sumérgete con los datos' }],
                [
                    {
                        text: 'Haga clic aquí para obtener una vista completa que combina la visión de la empresa con datos de mercado en tiempo real.'
                    }
                ]
            ],
            streamingNews: [
                [{ text: '¡Tienes novedades en tiempo real!' }],
                [
                    {
                        text: 'Las últimas noticias aparecen en la parte superior en el momento en que se publican.'
                    }
                ]
            ]
        },
        tickerBar: {
            tradingViewMarquee: [
                [{ text: 'En la barra de teletipo, eche un vistazo horizontal a índices, materias primas y más. ' }],
                [{ text: 'Haga clic en los símbolos para obtener más información. Puedes activar o desactivar esto desde la configuración de tu perfil.' }]
            ]
        }
    },

    mobileTours: {
        summaryScreen: {
            watchlist: {
                text: 'Deslice hacia arriba aquí para ver y administrar sus listas de seguimiento',
                name: 'Lista de seguimiento'
            }
        },
        securityScreen: {
            alerts: {
                text: 'Toque aquí para administrar sus alertas para este valor',
                name: 'Alertas'
            },
            chartToggle: {
                text: 'Cambie entre vistas de gráfico (línea/vela) simplemente tocando aquí',
                name: 'Alternar gráfico'
            },
            advancedChart: {
                text: 'Toque aquí para abrir el gráfico avanzado, con funciones como indicadores al alcance de su mano',
                name: 'Gráfico avanzado'
            },
            trade: {
                text: '¡Deslice hacia arriba desde aquí para abrir el ticket comercial y comenzar a operar!',
                name: 'Comercio'
            }
        },
        navigateToursAlert: {
            title: 'Ver recorridos',
            message: 'Navega a través de la aplicación para ver recorridos.'
        }
    },

    newFeatures: {
        advancedOptionTrading: {
            title: 'Operaciones Avanzadas de Opciones',
            subtitle: 'Las operaciones de opciones de varias patas implican ejecutar múltiples operaciones de opciones a la vez para crear una estrategia específica.',
            item1Title: 'Dos Operaciones en Una',
            item1Subtitle:
                'Las operaciones de opciones de varias patas implican ejecutar varias operaciones de opciones simultáneamente. Extienda las posiciones cubiertas y largas existentes en un solo ticket de operación. Cree spreads y straddles.',
            item2Title: 'Control de Riesgo-Retorno',
            item2Subtitle: 'Al combinar opciones, puede crear perfiles de riesgo-recompensa específicos.',
            item3Title: 'Pruebe sus Predicciones del Mercado',
            item3Subtitle:
                'Diferentes estrategias de opciones se pueden emplear para hacer crecer o proteger su cartera en función de sus predicciones sobre los movimientos de precios, los cambios en la volatilidad o la degradación del tiempo.'
        },
        cryptoTrading: {
            title: '¡Las criptomonedas ya están disponibles!',
            subtitle: 'Emprenda su viaje: ¡Compre y venda criptomonedas sin esfuerzo y abrace el futuro de las finanzas!',
            item1Title: 'Ejecución de operaciones',
            item1Subtitle:
                'Todas las operaciones de criptomonedas iniciadas en StoneX One Pro son ejecutadas por ETNA. No ejecutamos ninguna operación en nuestros sistemas.',
            item2Title: 'Gestión de cuentas',
            item2Subtitle: 'Sus cuentas de criptomonedas, incluidas todas las depósitos y retiros, son gestionadas por ETNA.',
            item3Title: 'Seguridad y custodia',
            item3Subtitle:
                'ETNA es responsable de la seguridad y custodia de sus activos de criptomonedas. StoneX Financial no mantiene ni asegura directamente ningún activo de criptomonedas.'
        }
    },

    miniplayer: {
        loading: (title: string | null) => `Cargando ${title || 'medios'}...`,
        noTranscriptAvailable: 'No hay transcripción disponible',
        noMediaAvailable: 'No hay medios disponible',
        viewTranscript: 'Ver transcripciones'
    },

    modals: {
        done: 'Terminar',
        addManyToListContent: {
            title: 'Agregue un valor usando la búsqueda anterior',
            button: 'Añadir a lista de seguimiento',
            addSecurity: 'Añadir valores'
        },
        addToWatchlist: {
            addSymbolToWatchlist: (symbol: string) => `Añadir ${symbol} a tu lista`,
            createAList: 'Crear una nueva lista',
            newListName: 'Nombre de la lista nueva',
            create: 'Crear',
            creating: 'Creando...'
        },
        viewWatchlistDetails: 'Ver detalles de la lista de seguimiento',
        appIdle: {
            modalHeader: '¡Estas inactivo!',
            noticedInactivity: 'Hemos notado que has estado inactivo por un tiempo.',
            logoutInSeconds: (seconds: number) => `Si no se realiza ninguna acción, se cerrará la sesión en ${seconds} segundos.`,
            logout: 'Cerrar sesión',
            stay: 'Permanecer'
        },
        deleteWatchlist: {
            deleteThisList: '¿Borrar esta lista?',
            areYouSure: (listName: string) => [
                [
                    {
                        text: 'Está seguro de que desea eliminar definitivamente la lista '
                    },
                    { text: listName, weight: 'bold' },
                    { text: '? Esto no se puede deshacer.' }
                ]
            ],
            areYouSureShaded: (listName: string) => [
                {
                    text: 'Está seguro de que desea eliminar definitivamente la lista ',
                    shade: 'light'
                },
                { text: listName, shade: 'dark' },
                { text: '? Esto no se puede deshacer.', shade: 'light' }
            ],
            yesDeleteList: (listName: string) => `Sí, borrar ${listName}`,
            delete: 'Borrado',
            deleting: 'Borrando...'
        },
        editWatchlistDetails: {
            editList: (listName: string) => `Editar ${listName}`,
            name: 'Nombre...',
            description: 'Descripción...',
            save: 'Salvar',
            saving: 'Salvando...'
        },
        deleteAllMessages: {
            label: 'Eliminar Todos Mensajes',
            body: '¿Estás seguro de que deseas eliminar todos mensajes?',
            no: 'No',
            yes: 'Sí'
        },
        cancelOrder: {
            orderCancelled: '¡Orden cancelada!',
            cancelOrderFailed: '¡Error en la cancelación del pedido!',
            retry: 'Vuelva a intentarlo o comuníquese con Soporte si el problema persiste.',
            cancelOrder: 'Cancelación de orden',
            areYouSure: '¿Estás seguro de que deseas cancelar la orden?',
            cancelLabel: 'No importa',
            ctrlKeyTip: [
                [
                    { text: 'Recomendación', weight: 'bold' },
                    { text: 'Mantenga presionada la tecla' },
                    { text: 'Ctrl', weight: 'bold' },
                    { text: 'o', weight: 'bold' },
                    { text: 'Command ⌘', weight: 'bold' },
                    {
                        text: 'mientras cancelando la orden para omitir esta confirmación'
                    }
                ]
            ],
            cancel: 'Confirmar Cancelación',
            retryCancel: 'Reintentar Cancelación',
            canceling: 'Cancelando...'
        },
        rename: {
            rename: 'Cambiar nombre',
            save: 'Salvar',
            saving: 'Salvando...'
        },
        errorModal: {
            unknownError: 'Un error desconocido ocurrió'
        },
        existingAccountModal: {
            cannotRemoveAcctTitle: 'No se puede eliminar la cuenta',
            cannotRemoveAcctDesc:
                'Lo sentimos, las cuentas solo se pueden eliminar una vez cada seis meses. Póngase en contacto con el soporte si desea eliminarlo antes.',
            cannotRemoveAcctConfirm: 'Confirmar',
            removeLinkedAcctTitle: 'Eliminar cuenta vinculada',
            removeLinkedAcctConfirm: (name: string) => `¿Estás seguro de que quieres eliminar ${name} como cuenta vinculada?`,
            yesRemoveAcct: 'Sí, eliminar cuenta',
            noKeppAcct: 'No, mantener la cuenta',
            loadingYourAcct: 'Cargando sus cuentas...',
            voidingAcct: 'Anulando cuenta...',
            addAnAcct: 'Agregar una cuenta'
        },
        linkedAccountsModal: {
            retrivingLinkableAcct: 'Recuperando sus cuentas enlazables...',
            linkingAcctWait: 'Vinculando su cuenta, por favor espere...'
        },
        plaidModal: {
            plaidCompleted: 'Plaid completado sin devolver una cuenta',
            overlimitAccounts: 'Lo sentimos, no puede agregar una cuenta en este momento.',
            holdTight: 'Agárrate fuerte, agregando tu cuenta',
            verifyingBankAuth: 'Verificando su autorización bancaria...'
        }
    },

    toasts: {
        error: 'Error',
        sessionExpired: 'La sesión ha expirado',
        logInAgain: 'Inicia sesión',
        orderSubmitted: 'Orden enviada',
        onboardingSuccess: () => {
            return '¡Felicidades! Su cuenta se está procesando actualmente y aparecerá en breve.';
        },
        onboardingSuccessTitle: (accountNumber) => {
            return `Se está creando su cuenta de futuros (${accountNumber}).`;
        },
        orderSubmittedContent: (trade: Partial<Order>) => {
            const action = (() => {
                switch (trade?.action) {
                    case 'Buy':
                        return 'comprar';
                    case 'Sell':
                        return 'vender';
                    case 'BuyToOpen':
                        return 'comprar para abrir';
                    case 'BuyToClose':
                        return 'comprar para cerrar';
                    case 'SellToOpen':
                        return 'vender para abrir';
                    case 'SellToClose':
                        return 'vender para cerrar';
                }
            })();
            return `Su orden para ${action} ${trade?.orderQuantity} ${Snex1Spanish.general.unit(trade?.symbol, trade?.orderQuantity)} de ${
                trade?.symbol
            } ha sido enviada.`;
        },
        orderUpdateTitle: (update) => {
            const name = update.symbol ? Snex1Spanish.general.symbolName(update.symbol) : update.symbol || update.securityName || update.securityNumber;
            return name ? `Actualización de orden: ${name}` : 'Actualización de orden';
        },
        orderUpdate: (update, message) => {
            const action = (() => {
                switch (update.action) {
                    case 'Buy':
                        return 'comprar';
                    case 'Sell':
                        return 'vender';
                    case 'BuyToOpen':
                        return 'comprar para abrir';
                    case 'SellToOpen':
                        return 'vender para abrir';
                    case 'BuyToClose':
                        return 'comprar para cerrar';
                    case 'SellToClose':
                        return 'vender para cerrar';
                    default:
                        return 'operar';
                }
            })();

            const statusPhrase = (() => {
                switch (update.orderStatus) {
                    case 'Allocated':
                        return 'ha sido asignado';
                    case 'Verification':
                        return 'está siendo verificado';
                    case 'Created':
                        return 'ha sido creado';
                    case 'Open':
                        return FuturesSymbol.IsFuturesSymbol(update.symbol) ? 'is now working' : 'ahora está abierto';
                    case 'Route':
                        return 'está listo para encaminar';
                    case 'Routed':
                        return 'ha sido encaminado';
                    case 'Cancel':
                        return 'está listo para cancelar';
                    case 'Canceled':
                        return 'ha sido cancelado';
                    case 'Filled':
                        return 'ha sido completado';
                    case 'Rejected':
                        return 'ha sido rechezado';
                    case 'Deleted':
                        return 'ha sido borrado';
                    case 'Expired':
                        return 'ha sido expirado';
                    case 'Pending':
                        return 'está pendiente';
                    case 'Completed':
                        return 'ha sido completado';
                    case 'Cancel Pending':
                        return 'tiene una cancelación pendiente';
                    case 'Pre-Allocated':
                        return 'ha sido pre-asginado';
                    case 'Cancel Partial Fill':
                    case 'Unknown':
                    default:
                        return `ha sido actualizado al estado "${update.orderStatus}"`;
                }
            })();

            const sym = update.symbol ? Snex1Spanish.general.symbolName(update.symbol) : update.securityName || update.securityNumber;

            if (message.payloadType === 'OrderUpdate:Rebook') return `Your edited ${sym} order is now in effect`;

            if (update.orderQuantity === null) {
                return `Su pedido para${update.action ? `${action}` : ''} ${sym} ${message?.data['filledAt']}${statusPhrase} (${update.orderNumber})`;
            }
            return `Su pedidio para${update.action ? ` ${action}` : ''} ${update.orderQuantity} ${Snex1Spanish.general.unit(
                update.symbol,
                update.orderQuantity
            )} de ${sym} ${statusPhrase} (${update.orderNumber})`;
        }
    },

    infoIconHelpText: {
        client: {
            aboutSecurity: [
                [
                    {
                        text: 'Ciertos datos fundamentales, de mercado u otra información son proporcionados directa o indirectamente por proveedores de datos de terceros y pueden variar de otras fuentes.'
                    }
                ]
            ],
            aboutSrri: [
                [
                    {
                        text: 'El indicador sintético de riesgo y recompensa (o SRRI) es una parte importante del Documento de datos clave para el inversor (o KIID). El SRRI se utiliza para indicar el nivel de riesgo de un fondo OICVM formulando un número del 1 al 7, donde 1 indica un riesgo menor y 7 indica un riesgo mayor.'
                    }
                ]
            ],
            acatTransfer: [
                [
                    {
                        text: 'The rejection of an ACATS transfer request is typically initiated by action of the delivering broker once that broker has had an opportunity to review the request and confirm the details of the account to be transferred. In the case of certain rejection notices (i.e., categories 1-5 and 10 below), the ACATS process affords the receiving broker (IB) a 24-hour window within which revised information may be transmitted and after which time the transfer request will require resubmission by the client. During this 24-hour window, IB will attempt to contact the transferring client in an effort to reconcile any discrepancies causing the initial rejection notice. Rejections general fall into the following categories:'
                    }
                ],
                [{ text: 'Rejections by the Delivering Broker:' }],
                [
                    {
                        text: "1. Social Security Number/Tax ID Mismatch - the client taxpayer identification number as provided by the receiving broker does not agree with that of the delivering broker's records."
                    }
                ],
                [
                    {
                        text: "2. Account Title Mismatch - the client account title as provided by the receiving broker does not agree with that of the delivering broker's records."
                    }
                ],
                [
                    {
                        text: '3. Documentation Needed - the delivering broker requires additional account documentation in order to process a transfer request (e.g., death or marriage legal documents.'
                    }
                ],
                [
                    {
                        text: '4. Account Flat - account holds no transferable assets.'
                    }
                ],
                [
                    {
                        text: "5. Invalid Account Number - the client account number as provided by the receiving broker is not the delivering member's books."
                    }
                ],
                [
                    {
                        text: '6. Duplicate - delivering broker is already in receipt of a transfer request involving the same receiving broker and client account.'
                    }
                ],
                [
                    {
                        text: '7. Account in Distribution or Transfer - another ACATS transfer or other distribution of account assets directly to the client is already underway.'
                    }
                ],
                [
                    {
                        text: '8. Client Rescinded - client has submitted written request to cancel transfer request.'
                    }
                ],
                [
                    {
                        text: '9. Missing Authorization Signature - transfer request require an additional client and/or custodian signature.'
                    }
                ],
                [{ text: 'Rejections by the Receiving Broker:' }],
                [
                    {
                        text: '10. Credit Violation - the result of the transfer if effected would be to place the account in margin deficit and subject to forced liquidation.'
                    }
                ]
            ],
            buyingPower: [
                [
                    {
                        text: 'Su poder adquisitivo es la cantidad de efectivo total en la cuenta disponible para comprar valores más todo el margen disponible.'
                    }
                ],
                DelayedParagraph
            ],
            topMoversAndSectors: [
                [
                    {
                        text: 'The SECTORS and MOVERS lists are generated using data sourced from third party data providers. A list of companies in a specific business sector may display a company if the source data indicates that the company derives a portion of its revenues from the relevant sector. The list is provided for informational purposes only and is not investment advice or a recommendation to buy, hold, or sell any security. Lists are not personalized recommendations to you or to any other customer, and the securities listed may not be suitable for you. You should not buy or sell any security without first determining it is appropriate for your portfolio or investment strategy. StoneX One does not make any warranty or guarantee relating to the accuracy, timeliness or completeness of any third-party information, and the provision of this information does not constitute a recommendation.'
                    }
                ],
                DelayedParagraph
            ],
            news: [
                [
                    {
                        text: 'Las noticias, los comentarios y los eventos provienen de fuentes de terceros no afiliados a StoneX. StoneX no respalda ni adopta su contenido. StoneX no garantiza que la información proporcionada sea precisa, completa o oportuna, y no ofrece ninguna garantía con respecto a los resultados obtenidos de su uso.'
                    }
                ]
            ],
            portfolioValue: [
                [
                    {
                        text: 'Your portfolio value is the total market value of all positions in the account, including cash balances, minus any outstanding debit balances and any amount required to cover short option positions that are in-the-money.'
                    }
                ],
                DelayedParagraph
            ]
        },
        one: {
            aboutSecurity: [
                [
                    {
                        text: 'Ciertos datos fundamentales, de mercado u otra información son proporcionados directa o indirectamente por proveedores de datos de terceros y pueden variar de otras fuentes.'
                    }
                ]
            ],
            aboutSrri: [
                [
                    {
                        text: 'El indicador sintético de riesgo y recompensa (o SRRI) es una parte importante del Documento de datos clave para el inversor (o KIID). El SRRI se utiliza para indicar el nivel de riesgo de un fondo OICVM formulando un número del 1 al 7, donde 1 indica un riesgo menor y 7 indica un riesgo mayor.'
                    }
                ]
            ],
            acatTransfer: [
                [
                    {
                        text: 'The rejection of an ACATS transfer request is typically initiated by action of the delivering broker once that broker has had an opportunity to review the request and confirm the details of the account to be transferred. In the case of certain rejection notices (i.e., categories 1-5 and 10 below), the ACATS process affords the receiving broker (IB) a 24-hour window within which revised information may be transmitted and after which time the transfer request will require resubmission by the client. During this 24-hour window, IB will attempt to contact the transferring client in an effort to reconcile any discrepancies causing the initial rejection notice. Rejections general fall into the following categories:'
                    }
                ],
                [{ text: 'Rejections by the Delivering Broker:' }],
                [
                    {
                        text: "1. Social Security Number/Tax ID Mismatch - the client taxpayer identification number as provided by the receiving broker does not agree with that of the delivering broker's records."
                    }
                ],
                [
                    {
                        text: "2. Account Title Mismatch - the client account title as provided by the receiving broker does not agree with that of the delivering broker's records."
                    }
                ],
                [
                    {
                        text: '3. Documentation Needed - the delivering broker requires additional account documentation in order to process a transfer request (e.g., death or marriage legal documents.'
                    }
                ],
                [
                    {
                        text: '4. Account Flat - account holds no transferable assets.'
                    }
                ],
                [
                    {
                        text: "5. Invalid Account Number - the client account number as provided by the receiving broker is not the delivering member's books."
                    }
                ],
                [
                    {
                        text: '6. Duplicate - delivering broker is already in receipt of a transfer request involving the same receiving broker and client account.'
                    }
                ],
                [
                    {
                        text: '7. Account in Distribution or Transfer - another ACATS transfer or other distribution of account assets directly to the client is already underway.'
                    }
                ],
                [
                    {
                        text: '8. Client Rescinded - client has submitted written request to cancel transfer request.'
                    }
                ],
                [
                    {
                        text: '9. Missing Authorization Signature - transfer request require an additional client and/or custodian signature.'
                    }
                ],
                [{ text: 'Rejections by the Receiving Broker:' }],
                [
                    {
                        text: '10. Credit Violation - the result of the transfer if effected would be to place the account in margin deficit and subject to forced liquidation.'
                    }
                ]
            ],
            buyingPower: [
                [
                    {
                        text: 'Su poder adquisitivo es la cantidad de efectivo total en la cuenta disponible para comprar valores más todo el margen disponible.'
                    }
                ]
            ],
            topMoversAndSectors: [
                [
                    {
                        text: 'The SECTORS and MOVERS lists are generated using data sourced from third party data providers. A list of companies in a specific business sector may display a company if the source data indicates that the company derives a portion of its revenues from the relevant sector. The list is provided for informational purposes only and is not investment advice or a recommendation to buy, hold, or sell any security. Lists are not personalized recommendations to you or to any other customer, and the securities listed may not be suitable for you. You should not buy or sell any security without first determining it is appropriate for your portfolio or investment strategy. StoneX One does not make any warranty or guarantee relating to the accuracy, timeliness or completeness of any third-party information, and the provision of this information does not constitute a recommendation.'
                    }
                ]
            ],
            news: [
                [
                    {
                        text: 'Las noticias, los comentarios y los eventos provienen de fuentes de terceros no afiliados a StoneX. StoneX no respalda ni adopta su contenido. StoneX no garantiza que la información proporcionada sea precisa, completa o oportuna, y no ofrece ninguna garantía con respecto a los resultados obtenidos de su uso.'
                    }
                ]
            ],
            portfolioValue: [
                [
                    {
                        text: 'Your portfolio value is the total market value of all positions in the account, including cash balances, minus any outstanding debit balances and any amount required to cover short option positions that are in-the-money.'
                    }
                ]
            ],
            marketCap: [
                [
                    {
                        text: "Market capitalization is the estimated value of a company's outstanding shares. Depending on the size of that value, companies are sorted into Large, Mid, Small, Micro and Nano categories."
                    }
                ]
            ]
        }
    },

    errors: {
        imageUploadError: 'No se pudo subir la foto. Por favor asegúrese que el archivo es una imagen de menos de 10 MB.',
        accessRestricted: 'Acceso Restringido',
        loginDead: 'Hola, vemos que estás intentando entrar en StoneX One.\nPor favor, envíanos un correo electrónico para activar tu cuenta.',
        loginDeadWithDetail: (getIntoWhat: string, email: string): string =>
            `Hola, vemos que estás intentando entrar en ${getIntoWhat}.\nPor favor, ponte en contacto con soporte a través de las opciones de abajo para obtener tu cuenta de usuario con el email ${email} habilitado.`,
        noDataFound: 'Datos no encontrados',
        trade: {
            generalTitle: 'Hubo un problema',
            generalSubmit: 'Lo siento, hubo un problema al procesar su orden',
            generalVerify: 'Lo siento, hubo un problema al procesar su orden',
            restrictedError: 'Lo sentimos, el comercio ha sido restringido para este valor.',
            cantVerify: 'Lo sentimos, no pudimos verificar su orden.',
            dimeProduct:
                'Lo sentimos, este producto solo se cotiza en incrementos de diez centavos. Ajuste su precio a un múltiplo de diez centavos y vuelva a enviar la orden.',
            nickelProduct:
                'Lo sentimos, este producto solo se cotiza en incrementos de cinco centavos. Ajuste su precio a un múltiplo de cinco centavos y vuelva a enviar la orden.',
            pennyProduct: 'Lo sentimos, este producto solo se cotiza en incrementos de centavo. Ajuste su precio a un múltiplo de un centavo y vuelva a enviar la orden.',
            securityOutOfSession: 'Lo sentimos, este producto no se puede intercambiar a esta hora del día.',
            accountRestricted: 'Esta cuenta ha sido restringida. Por favor contáctenos para reactivarla.',
            securityUnavailable: 'Lo sentimos, trading de este producto no está disponible en StoneX One.',
            fundTradingDenied: 'Lo sentimos, no se le permite operar con fondos mutuos.',
            basicTradingDenied: 'Lo sentimos, no tiene permiso para comerciar.',
            noBuy: 'Lo sentimos, la compra no está permitida para este intercambio.',
            noOptionPosition: 'La cantidad para un pedido cerrado no debe exceder la cantidad que se tiene actualmente',
            optionAgreement: 'Lo sentimos, su cuenta no tiene permiso para negociar opciones.',
            optionLevel: 'Lo sentimos, su cuenta no tiene permiso para negociar opciones de esta manera.',
            optionPermission: 'Lo sentimos, no se le permite intercambiar opciones.',
            offHours: 'Lo sentimos, el comercio está actualmente fuera de horario. Por favor, inténtelo de nuevo más tarde.',
            generalNotAvailable: 'No está disponible para intercambiar',
            notAvailable: (product: string) => `Lo sentimos, ${product} no está disponible para intercambiar en StoneX One`,
            sellStopLessThanMarket: 'Una orden de stop de venta requiere un precio de stop menor que el precio de mercado actual.',
            buyStopGreaterThanMarket: 'Una orden stop de compra requiere un precio stop mayor que el precio de mercado actual.',
            sxDenied: 'No se le permite comerciar. Si cree que ha llegado a este mensaje por error, póngase en contacto con el servicio de asistencia.',
            shortNotAllowed: 'No se permite el cortocircuito para este valor.',
            thisIsAShortOrder: 'Estás vendiendo acciones que actualmente no posees. Este es un pedido corto.',
            adjustedCloseOnly: 'Lo sentimos, no se permite abrir las opciones ajustadas.',
            adjustedCloseOnlyMessage: 'Debido al riesgo de liquidez y liquidación, solo permitimos órdenes de cierre para posiciones de opciones ajustadas.',
            insufficientShortQuantity: (qty: number): string => {
                const sharePlurality = qty === 1 ? `Hay 1 acción` : `Hay ${qty} acciones`;
                const sharesAvailable = qty === 0 ? `No hay acciones` : sharePlurality;
                const reduceQuantityText = qty > 1 ? ' Reduza a quantidade solicitada.' : '';
                return `Hay una cantidad insuficiente disponible para autorizar esta solicitud. ${sharesAvailable} disponibles.${reduceQuantityText}`;
            },
            mutualFundQrpOnly:
                'La compra de este fondo de inversión estadounidense sólo está disponible para planes de jubilación cualificados. Si cree que ha llegado a este punto por error, póngase en contacto con el servicio de asistencia.',
            mutualFundNoDomesticOnForeign:
                'Las órdenes de fondos de inversión estadounidenses no están permitidas en cuentas extranjeras. Si cree que ha llegado a este punto por error, póngase en contacto con el servicio de asistencia.',
            mutualFundNoForeignOnDomestic:
                'Las órdenes de fondos de inversión offshore no están permitidas en cuentas nacionales. Si cree que ha llegado a este punto por error, póngase en contacto con el servicio de asistencia.'
        },
        alerts: {
            duplicateRuleMessage: 'Ya ha definido una regla de alerta con este conjunto de condiciones.',
            duplicateRuleTitle: 'Regla de alerta duplicada',
            somethingWentWrongMessage: 'Perdón, algo salió mal. Por favor, inténtelo de nuevo más tarde.',
            unableToVerifyCode: 'No se pudo verificar su código, inténtelo de nuevo.',
            temporarilyUnavailable: 'Temporalmente No Disponible',
            alertsTemporarilyUnavailableMessage:
                'Estamos mejorando nuestro servicio de alertas. Desafortunadamente, las alertas estarán fuera de servicio durante un par de semanas. Cuando las alertas estén disponibles nuevamente, deberás crearlas de nuevo.'
        }
    },
    warnings: {
        pretradeDisclosures: {
            cryptos:
                'Al continuar con el comercio de criptomonedas en nuestra plataforma, usted reconoce que ha leído, entendido y aceptado esta declaración de divulgación y acepta los términos y condiciones establecidos por ETANA para el comercio de criptomonedas y la gestión de cuentas.\n\nSi tiene alguna pregunta o necesita más aclaraciones sobre esta declaración de divulgación, por favor contacte a nuestro equipo de soporte.'
        },
        trade: {
            cannotAfford: (t: Partial<TradeRequest>, ac?: AssetClass) => {
                const isDollar = t?.quantityQualifier === 'EvenDollar';
                const q = isDollar ? ac?.formatPrice(t.quantity) : ac?.formatQuantity(t.quantity);

                switch (t.action) {
                    case 'Buy': {
                        const unit = Snex1Spanish.general.unit(t.securityId, t.quantity)?.toLowerCase();
                        const commonSentence = `No tienes suficientes fondos para comprar ${q}`;
                        const unitSentence = isDollar ? `de ${QualifiedId.RemovePrefix(t.securityId)}.` : `${unit} de ${QualifiedId.RemovePrefix(t.securityId)}.`;
                        return `${commonSentence} ${unitSentence}`;
                    }
                    case 'Sell': {
                        const units = Snex1Spanish.general.unit(t.securityId, 2);
                        const unit = Snex1Spanish.general.unit(t.securityId, t.quantity);
                        const showUnit = isDollar && ac?.family === 'cryptos' ? `${q}` : `${q} ${unit}`;

                        return `No tienes suficiente ${units} para vender ${showUnit} de ${QualifiedId.RemovePrefix(t.securityId)}.`;
                    }
                    default:
                        return 'No tiene suficientes fondos para esta';
                }
            },
            shortsNotPermittedWhenLong: (t: Partial<TradeRequest>) => {
                return 'Notamos que actualmente tiene una posición larga. Cierre su posición larga si desea vender en corto.';
            },
            shortsNotPermittedWhenAccountNotMargin: (t: Partial<TradeRequest>, ac?: AssetClass) => {
                const cannotAfford = Snex1Spanish.warnings.trade.cannotAfford(t, ac);
                return `${cannotAfford}\n\nLa venta en corto de acciones no está permitida en una cuenta de efectivo, por favor revise sus posiciones y modifique su orden.`;
            },
            buyExceedsShortsHeld: (t: Partial<TradeRequest>) => {
                return 'Notamos que actualmente tiene una posición corta. Cierre su posición corta si desea operar en largo.';
            },
            shortNotPermittedAccountValue: (t: Partial<TradeRequest>) => {
                return 'No tiene permitido vender en corto acciones en una cuenta de margen con un capital propio inferior a $2,000. Por favor, revise su orden o deposite fondos.';
            },
            invalidQuantity: 'No admitimos el comercio de este valor con la cantidad especificada; revise su cantidad.',
            invalidQuantityOptions: 'No admitimos la cantidad de opción ingresada. Por favor revise su pedido.',
            insufficientQuantity: 'Hay una cantidad insuficiente disponible para autorizar esta solicitud. Para obtener más información, comuníquese con Soporte.',
            possibleDuplicate: 'Ya existe una orden abierta parecida a esta.',
            minimumNotMet: 'Lo sentimos, su pedido no cumple con la inversión mínima requerida de este fondo mutuo.',
            orderGreaterThanPosition: 'El orden es mayor que la posición total. Revise el pedido antes de enrutar.',
            accountChangedToMargin: 'El tipo de cuenta cambió a margen.',
            accountChangedToCash: 'El tipo de cuenta cambió a efectivo.',
            foreignAcctDomesticFunds: 'La cuenta seleccionada es una cuenta extranjera y no puede operar con fondos mutuos nacionales',
            optionLevel: 'Su nivel de opción actual no le permite ejecutar esta operación.',
            nextDayOrder: 'Este pedido se realizará mañana.',
            continue: '¿Está seguro de que quiere continuar?',
            optionsLowVolumeWarning: 'Hay poca actividad/interés en este pedido. Es posible que su pedido no se complete.',
            isShort: 'Está vendiendo acciones que actualmente no posee. Esta es una orden corta. Confirme que esta es la operación esperada que desea realizar.',
            cutoffTime: 'Esta orden ya pasó la hora límite para el fondo. Esta es una orden del día siguiente y recibirá el primer NAV disponible emitido por el fondo.',
            acknowledgeTitle: 'Reconocer y Confirmar'
        }
    },

    footer: {
        global: {
            copyrightLine: [
                [
                    {
                        text: 'Verifique los antecedentes de StoneX Financial Inc, o uno de sus profesionales de inversión en '
                    },
                    {
                        text: 'BrokerCheck de FINRA',
                        url: 'https://brokercheck.finra.org/'
                    },
                    { text: '.  © ' },
                    { text: new Date().getFullYear().toString() },
                    { text: ' StoneX Group Inc. Todos los derechos reservados. ' }
                ]
            ],
            disclosures,
            disclosuresByEnvironement: (env: Environment) => (env === 'pro' ? proDisclosures : disclosures),
            marketDataDisclosure: 'Datos de mercado proporcionados por Xignite',
            importantDisclosures: 'Información importante de divulgación',
            termsOfUse: 'Condiciones de uso',
            privacy: 'Privacidad',
            systemRequirements: 'Requisitos del sistema',
            openAnAccount: 'Abrir una cuenta',
            chatWithSupport: 'Chatear con soporte'
        },
        disclosurePdfUrl: 'https://snex1storage.blob.core.windows.net/$web/docs/en-US/DisclosuresFull.pdf'
    },

    mobileUi: {
        bottomBar: {
            summary: 'Resumen',
            cash: 'Dinero',
            browse: 'Buscar',
            messages: 'Mensajes'
        }
    },

    alerts: {
        addCondition: 'Añadir condición',
        saveAlert: 'Guardar Alerta',
        updateAlert: 'Alerta de actualización',
        editExistingAlert: 'Editar alerta existente',
        createAlert: 'Crear nueva alerta',
        createAnAlertToSeeItHere: 'Crea una alerta para verla aquí.',
        interpretConditions: (field: 'latestPrice' | 'changePercent' | 'Last', operator: '>' | '>=' | '<' | '<=', valueNumberString: string) => {
            const { fieldLabel, formattedValue } = (() => {
                switch (field) {
                    case 'Last':
                    case 'latestPrice':
                        return {
                            fieldLabel: 'Precio',
                            formattedValue: FormatNumber.toMoney(Number(valueNumberString))
                        };
                    case 'changePercent':
                        return {
                            fieldLabel: 'Cambio porcentual de precio',
                            formattedValue: FormatNumber.toPercent(Number(valueNumberString))
                        };
                    default:
                        return {
                            fieldLabel: 'Valor',
                            formattedValue: FormatNumber.toCommas(Number(valueNumberString))
                        };
                }
            })();
            const op = (() => {
                switch (operator) {
                    case '>':
                        return 'mas grande que';
                    case '>=':
                        return 'mayor qué o igual a';
                    case '<':
                        return 'menos que';
                    case '<=':
                        return 'menos que o igual a';
                    default:
                        return 'alcanza';
                }
            })();

            return `${fieldLabel} ${op} ${formattedValue}`;
        },
        priceAlert: 'Alerta de precio',
        youDontHaveAlerts: '¡Parece que no tienes ninguna alerta!',
        mutualFundsUnavailable: 'Las alertas no están disponibles para fondos',
        futuresUnavailable: 'Las alertas no están disponibles para los contratos de futuros',
        createAlertSheet: {
            selectCondition: 'Seleccione una condición',
            change: 'Cambio %',
            latestPrice: 'Último precio',
            priceAlert: 'Alerta de precio',
            createAlert: 'Crear Alerta',
            updateAlert: 'Alerta de actualización',
            deleteAlert: 'Eliminar Alerta',
            delete: 'Eliminar',
            alert: 'Alerta',
            updateAlertFor: (symbol: string) => (symbol ? `Alerta de actualización para  ${symbol}` : 'Alerta de actualización'),
            target: 'Objetivo',
            searchForSecurity: 'Buscar un valor...',
            chooseSecurity: 'Escoge una seguridad',
            greaterThan: 'Mayor que',
            greaterThanEqual: 'Mayor o igual que',
            lessThan: 'Menos que',
            lessThanEqual: 'Menor que o igual a',
            alertsTitle: 'Alertas',
            newAlert: 'Nueva Alerta',
            searchSecurity: 'Buscar valores',
            createNewAlertRule: 'Crear nueva regla de alerta',
            noAlertsYet: 'No hay alertas todavía',
            editSymbol: 'Editar símbolo',
            repeatDaily: 'Repetir diariamente',
            instructions: {
                createAlertBtn: '¡Cree su primera regla de alerta con el botón de abajo!',
                lookUpSecurity: '¡Busca un valor para crear tu primera regla de alerta!',
                getNotified: 'Reciba una notificación en tiempo real cuando cambie el precio de un valor.'
            }
        }
    },
    equitiesMarginOnboarding: {
        title: 'Estás agregando margen a tu cuenta',
        agreementNotAllowed: 'Lo sentimos, parece que no podemos agregar un acuerdo de margen a esta cuenta.',
        contactSupport: 'Si considera que esto es inesperado, comuníquese con nuestro equipo de soporte.',
        byChoosing: 'Al optar por agregar margen a su cuenta, está solicitando autorización para pedir prestado dinero a StoneX utilizando sus activos como garantía.',
        understandRisk:
            'Es importante que comprenda completamente los riesgos involucrados en el uso del margen, ya sea para negociar valores con margen o utilizar su patrimonio en la cuenta de margen con otros fines.',
        agreementDescription:
            'El Acuerdo de Margen es un acuerdo contractual entre usted y el corredor. Este acuerdo le permite comprar valores con fondos prestados. Revise el Acuerdo de Margen antes de operar con margen.',
        close: 'Cerrar',
        agreeAndContinue: 'Aceptar y Continuar',
        reviewAgreement: 'Revisar Acuerdo de Margen',
        submitted: '¡Todo listo! Se ha añadido tu Acuerdo de Margen a tu cuenta',
        failed: 'Parece que no pudimos agregar el Acuerdo de Margen a tu cuenta'
    },
    futuresOnboarding: {
        sfi: 'Comerciante a comisión de futuros de SFI',
        stepLabels: [
            'Bienvenido!',
            'Info de contacto',
            'Info Personal',
            'Info de Cuenta',
            'Info de Empleo',
            'Experencia comercial',
            'Afiliaciones',
            'Info Reglamentaria',
            'Info Financiera',
            'Revisar',
            'Acuerdos',
            'Certificación'
        ],
        submitMessage: 'Espere mientras preparamos su cuenta de futuros...',
        submitMessageTitle: 'Creación de su nueva cuenta de futuros',
        successMessage: (account: string) => `Se está creando su cuenta de futuros (${account}).`,
        errorMessage: 'Algo salió mal.',
        tryAgain: 'Vuelva a intentarlo o contáctenos y arreglaremos las cosas.',
        okButton: 'Ok',
        closeButton: 'Cerrado',
        accountDelayMessage: '¡Felicidades! Su cuenta de futuros se está procesando actualmente y aparecerá en breve.',
        openAccount: 'Abra una cuenta de futuros',
        openAccountNotAllowed: 'Comuníquese con su representante para obtener ayuda',
        openDocumentToEnable: 'Abra e revise o documento para habilitar',
        openAccountComingSoon: 'Abra una cuenta de futuros ¡Próximamente!',
        welcome: (name: string) => `Bienvenido ${name}!`,
        hasReachedMax: 'Ya ha incorporado una cuenta de futuros en StoneX One. Haga clic en el botón de abajo para volver a la pantalla de inicio de StoneX One.',
        missingRequiredData: 'Faltan datos requeridos',
        nextButton: 'Siguiente',
        returnHome: 'Volver a casa',
        disabledNextText: 'Complete los elementos obligatorios anteriores antes de continuar',
        submitButton: 'Enviar',
        goBackButton: 'Regresar',
        yesButton: 'Si',
        noButton: 'No',
        welcomeSubtitle: 'Vamos a conseguirle una cuenta de futuros',
        contactInfo: {
            title: 'Vamos a obtener su información de contacto',
            name: 'Nombre',
            firstName: 'Nombre de pila',
            lastName: 'Apellido',
            email: 'Correo electrónico',
            phone: 'Teléfono',
            street: 'Calle',
            streetAddress: 'Dirección',
            street2: 'Apt # Edificio #, etc...',
            city: 'Ciudad',
            state: 'Estado o Región',
            country: 'País',
            zip: 'Código Postal',
            error: 'Debe tener al menos 21 años de edad'
        },
        personalInfo: {
            title: 'Info Personal',
            citizenQuestion: '¿Usted es ciudadano de los Estados Unidos?',
            citizenYes: 'Yo soy ciudadano de Estados Unidos',
            citizenNo: 'Yo no soy ciudadano de Estados Unidos',
            ssnQuestion: '¿Usted tiene un número de Seguro Social o ITIN?',
            ssnYes: 'Yo tengo un número de Seguro Social o ITIN',
            ssnNo: 'Yo no tengo un número de seguro social o ITIN',
            ssn: 'Número de Seguro Social o ITIN',
            country: 'País de ciudadanía',
            dob: 'Fecha de nacimiento',
            governmentId: 'Número de Identificación emitido por el Gobierno'
        },
        accountInfo: {
            title: 'Vamos a recopilar la información de su cuenta',
            accountType: 'Individual',
            accountTypeSubtitle: 'Una cuenta estándar con un solo propietario',
            sourceOfFunds: 'Fuente de fondos',
            intendedInitialDeposit: 'Depósito inicial previsto',
            expectedMonthlyTrading: 'Actividad comercial mensual esperada',
            sourceOfFundsOptions: [
                { label: 'Corredor', value: 'Broker' },
                { label: 'Obsequio', value: 'Gift' },
                { label: 'Herencia', value: 'Inheritance' },
                { label: 'Aseguransa ', value: 'Insurance' },
                { label: 'Acuerdo Legal', value: 'Legal Settlement' },
                { label: 'Pension', value: 'Pension' },
                { label: 'Jubilación', value: 'Retirement' },
                { label: 'Venta', value: 'Sale' },
                { label: 'Salarios', value: 'Wages' }
            ],
            intendedInitialDepositOptions: [
                { label: '0 - $1,000', value: 0 },
                { label: '$1,001 - $10,000', value: 1000 },
                { label: '$10,001 - $50,000', value: 10000 },
                { label: '$50,001 - $100,000', value: 50000 },
                { label: '$100,001 - $250,000', value: 100000 },
                { label: '$250,001 - $500,000', value: 250000 },
                { label: '$500,001 - $1,000,000', value: 500000 },
                { label: '$1,000,001 - $5,000,000', value: 1000000 },
                { label: '$5,000,000 or more', value: 5000000 }
            ],
            expectedMonthlyTradingOptions: [
                { label: '0 - 100', value: 0 },
                { label: '101 - 1,000', value: 100 },
                { label: '1,001 - 10,000', value: 1000 },
                { label: '10,001 - 50,000', value: 10000 },
                { label: '50,000 or more', value: 50000 }
            ]
        },
        employmentInfo: {
            title: 'Info de Empleo',
            status: 'Estado de Empleo',
            employedTitle: 'Empleado',
            employedSubtitle: 'Actualmente yo trabajo para una empresa',
            studentTitle: 'Trabajo por cuenta propia',
            studentSubtitle: 'Actualmente yo trabajo por mi cuenta',
            unemployedTitle: 'Otro',
            unemployedSubtitle: 'Actualmente yo no estoy empleado y no estoy jubilado',
            retiredTitle: 'Retirado',
            retiredSubtitle: 'Actualmente estoy jubilado',
            employerAddress: {
                title: 'Dirección del empleador',
                country: 'País',
                street: 'Dirección Línea 1',
                street1: 'Dirección Línea 2',
                city: 'Ciudad',
                state: 'Estado',
                zip: 'Código postal'
            },
            years: 'Años con el empleador',
            employerDetailsTitle: 'Detalles del empleador',
            occupationTitle: 'Ocupación',
            name: 'Nombre del empleador',
            jobTitle: 'Título del Trabajo',
            industryTitle: 'Industria',
            jobFunctionTitle: 'Función laboral',
            primarySourceOfIncomeTitle: '¿Cuál es su principal fuente de ingresos?',
            incomeOptions: [
                { label: 'Corredor', value: 'Broker' },
                { label: 'Obsequio', value: 'Gift' },
                { label: 'Herencia', value: 'Inheritance' },
                { label: 'Aseguransa ', value: 'Insurance' },
                { label: 'Acuerdo Legal', value: 'Legal Settlement' },
                { label: 'Pension', value: 'Pension' },
                { label: 'Jubilación', value: 'Retirement' },
                { label: 'Venta', value: 'Sale' },
                { label: 'Salarios', value: 'Wages' }
            ],
            occupationOptions: [
                {
                    label: 'Accountant/Auditor/Bookeeper',
                    value: 'Contador / Auditor / Contable'
                },
                {
                    label: 'Admin/Office Manager',
                    value: 'Administrador / Gerente de oficina'
                }
            ],
            industryOptions: [],
            jobFunctionOptions: []
        },
        tradingExperience: {
            title: 'Cuéntenos sobre su experiencia comercial anterior',
            experienceYears: [
                { label: 'menos de 1', value: '0' },
                { label: '1 to 2', value: '1' },
                { label: '2 to 3', value: '2' },
                { label: '3 o más', value: '3' }
            ],
            commoditiesTitle: 'Experiencia en Comercio de Materias Primas',
            commoditiesQuestion: '¿Tiene experiencia en el comercio de materias primas?',
            commoditiesYears: 'Años de experiencia en materias primas',
            securitiesQuestion: '¿Tiene experiencia en negociación de valores?',
            securitiesYears: 'Años de experiencia en valores',
            otcTitle: 'Experiencia en Operaciones Extrabursátiles (OTC)',
            otcQuestion: '¿Tiene experiencia en operaciones extrabursátiles (OTC)?',
            otcYears: 'Años de experiencia OTC',
            professionalTrader: '¿Califica usted como profesional según lo define CME para datos de mercado?',
            professionalTraderUrl: 'https://snex1storage.blob.core.windows.net/$web/docs/Futures-Market-Data-Subscription-Terms.pdf',
            professionalTraderPdfLabel: 'Términos de suscripción de datos del mercado de futuros',
            tradingExperienceTitle: 'Experiencia en Operaciones de Trading',
            doTradingExperience: '¿Tienes experiencia en trading?',
            yearsExperience: 'Años de experiencia',
            professionalTraderTitle: 'Trader Profesional',
            commodityExchangeAct: 'Ley de Bolsa de Mercancías',
            governmentRelations: 'Relaciones Gubernamentales',
            bankruptcy: 'Quiebra',
            legalDisputes: 'Disputas Legales',
            closedCommodityAccounts: 'Cuentas de Mercancías Cerradas'
        },
        affiliationsInfo: {
            title: 'Solo algunas preguntas rápidas',
            politicalOrGovernment:
                '¿Es usted o algún miembro de su familia inmediata un funcionario político actual en cualquier rama del gobierno o está afiliado a una empresa propiedad del gobierno, otra que el gobierno de los Estados Unidos?',
            employeeOfStoneX:
                '¿Usted o algún miembro de su familia inmediata es empleado o está relacionado con un empleado de la División FMC de StoneX Financial Inc. ("FCM") o sus subsidiarias?',
            regulatoryMember: '¿Está registrado en la Asociación Nacional de Futuros ("NFA") o en cualquier otro organismo regulador o miembro de una bolsa?',
            regulatoryBody: 'Organismo regulador',
            registrationNumber: 'Número de registro',
            employeeName: 'Nombre de empleador',
            employeeRelationship: 'Relación',
            nfaBody: 'Nombre del organismo NFA',
            nfaRegNumber: 'Número de registro NFA'
        },
        regulatoryInfo: {
            title: 'Vamos a recopilar algo de información regulatoria',
            registrationExemption: '¿Está operando de conformidad con una exepción de registro en virtud de la Ley de intercambio de productos básicos?',

            bankruptcy: '¿Ha sido objeto de un procedimiento de quiebra, administración judicial o acción similar?',
            disputeOrArbitration: '¿Ha estado en una disputa legal, arbitraje o acción de reparación relacionada con una cuenta de materias primas?',
            unsatisfiedDebitBalance: '¿Alguna vez ha cerrado una cuenta con un saldo deudor no satisfecho en una empresa de productos básicos?'
        },
        financialInfo: {
            title: 'Información financiera',
            error: 'Las finanzas no cumplen con los requisitos para una cuenta de futuros',
            page2Title: 'Información financiera continuación',
            annualRevenue: 'Ingresos anuales de todas las fuentes en dólares estadounidenses',
            annualRevenueOptions: [
                { label: 'Below $25,000', value: '0' },
                { label: '$25,000 - $50,000', value: '0.025' },
                { label: '$50,000 - $100,000', value: '0.05' },
                { label: '$100,000 - $250,000', value: '0.1' },
                { label: '$250,000 - $500,000', value: '0.25' },
                { label: '$500,000 - $1,000,000', value: '0.5' },
                { label: '$1,000,000 - $5,000,000', value: '1' },
                { label: 'Mas grande que $5,000,000', value: '5' }
            ],
            netWorth: 'Patrimonio neto total en dólares estadounidenses',
            netWorthOptions: [
                { label: 'Below $100,000', value: '0' },
                { label: '$100,000 - $500,000', value: '0.1' },
                { label: '$500,000 - $1,000,000', value: '0.5' },
                { label: '$1,000,000 - $5,000,000', value: '1' },
                { label: 'Mas grande que $5,000,000', value: '5' }
            ],
            workingCapital: 'Capital de trabajo disponible en dólares estadounidenses',
            workingCapitalOptions: [
                { label: 'Below $25,000', value: '0' },
                { label: '$25,000 - $100,000', value: '0.025' },
                { label: '$100,000 - $250,000', value: '0.1' },
                { label: '$250,000 - $500,000', value: '0.25' },
                { label: '$500,000 - $1,000,000', value: '0.5' },
                { label: '$1,000,000 - $5,000,000', value: '1' },
                { label: 'Mas grande que $5,000,000', value: '5' }
            ],
            annualRevenueShort: 'Ingresos anuales',
            totalNetWorth: 'Patrimonio neto total',
            availableWorkingCapital: 'Capital de trabajo disponible'
        },
        review: {
            title: 'Revisemos su información',
            subTitle: '¡Ya casi hemos terminado! Revisemos las respuestas que ha dado para asegurarnos de que todo sea correcto.',
            personal: 'Personal',
            account: 'Cuenta',
            funding: 'Financiamiento',
            trustedContact: 'Contacto de Confianza',
            addTrustedContact: 'Agregar Contacto de Confianza',
            tradingInfo: 'Información de Trading',
            regulatoryInfo: 'Información Regulatoria',
            stoneXAffiliation: 'Afiliación con StoneX',
            nfaAffiliation: 'Afiliación con NFA',
            jobTitle: 'Cargo',
            employer: 'Empleador',
            yearsWithEmployer: 'Años con el Empleador',
            employment: 'Empleo',
            neitherStoneXAffiliation:
                'Ni usted, ni nadie en su familia inmediata, ni nadie en el mismo hogar es o son empleado(s) o agente(s) de StoneX Group Inc. o una empresa afiliada a StoneX.',
            neitherNfaAffiliation:
                'Ni usted, ni nadie en su familia inmediata, ni nadie en el mismo hogar está o están empleados o asociados con una bolsa de valores, una firma miembro de una bolsa de valores, FINRA o un distribuidor de valores municipales.',
            notice: 'Aviso',
            noticeDescription: 'Las cuentas de futuros son creadas bajo el Corredor de Comisión de Futuros de SFI.',
            employedStatus: (employedStatus: string) => {
                return [Light('Eres '), Dark(`${employedStatus}`), Light('.')];
            },
            accountOpening: (accountType: string) => {
                return [Light('Estás abriendo una cuenta '), Dark(`${accountType}`), Light('.')];
            },
            exemptUnderCommodityExchangeAct: (areNot: string) => {
                areNot = areNot === 'are' ? 'Estás' : 'No estás';
                return `${areNot} operando conforme a una exención de registro bajo la Ley de Intercambio de Mercancías.`;
            },
            relatedToGovernment: (areNot: string) => {
                areNot = areNot === 'are' ? 'es' : 'no es';
                return `Usted o cualquier miembro de su familia inmediata ${areNot} un funcionario político actual en cualquier rama del gobierno o afiliado a una empresa propiedad del gobierno, excepto el Gobierno de los Estados Unidos.`;
            },
            hasBankruptcy: (haveNot: string) => {
                haveNot = haveNot === 'have' ? 'ha' : 'no ha';
                return `Usted ${haveNot} sido objeto de un proceso de quiebra, administración judicial o acción similar.`;
            },
            hasLegalDisputeWithCommodities: (haveNot: string) => {
                haveNot = haveNot === 'have' ? 'ha' : 'no ha';
                return `Usted ${haveNot} estado en una disputa legal, arbitraje o acción de reparaciones relacionada con una cuenta de productos básicos.`;
            },
            hasClosedCommodityAccountWithDebitBalance: (haveNot: string) => {
                haveNot = haveNot === 'have' ? 'ha' : 'no ha';
                return `Usted ${haveNot} cerrado una cuenta con un saldo deudor insatisfecho en una firma de productos básicos.`;
            },
            isProffesionalTrader: (areNot: string) => {
                areNot = areNot === 'are' ? 'es' : 'no es';
                return `Usted ${areNot} un trader profesional.`;
            }
        },
        agreements: {
            arbitration: 'Arbitraje',
            accept: 'Acepto',
            doNotAccept: 'No acepto',
            acceptArbitration: '¿Acepta el acuerdo de arbitraje? (Opcional)',
            arbitrationAgreement: 'Declaro y garantizo que he leído el "Acuerdo de Arbitraje" y entiendo sus disposiciones aquí, y estoy tomando la siguiente elección:',
            pageTitle: 'Documentos importantes aplicables a su cuenta',
            titleText:
                'Se requiere que revise cuidadosamente todo el contenido de esta página y cada uno de los documentos requeridos a continuación haciendo clic en los títulos de los documentos a continuación para acceder a su contenido. Lea todos los documentos detenidamente antes de completar la sección de Certificación del cliente.',
            part1Title: 'Parte 1: Declaraciones de privacidad',
            part2Title: 'Parte 2: Divulgaciones de riesgo de futuros y opciones de futuros',
            part3Title: 'Parte 3: Acuerdos de cuenta de opciones de futuros y futuros',
            part4Title: 'Part 4: Futures & Futures Options Market Data Terms',
            clientCertTitle: 'Certificación de clientes',
            acceptAndContinueText: "Al hacer clic en el botón 'Aceptar y continuar' a continuación:",
            W9title: 'Certificación del Formulario W-9 del IRS',
            perjuryCertTitle: 'Bajo pena de perjurio, certifico que:',
            perjuryCertItem1: 'Soy ciudadano de los EE. UU. u otra persona de los EE. UU.',
            perjuryCertItem2: 'es mi número de identificación fiscal correcto',
            perjuryCertItem3: 'No estoy sujeto a retención de respaldo',
            perjuryCertItem4:
                'El código FATCA ingresado en este formulario (si corresponde) que indica que el beneficiario está exento de la declaración FATCA es correcto. (Como institución financiera de los EE. UU. que mantiene su cuenta en los EE. UU., no hemos solicitado un código FATCA y este artículo no es aplicable).',
            clientAcknowledgementTitle: 'Reconocimiento del cliente',
            clientAcknowledgementItem1: [
                [
                    {
                        text: 'Reconozco que he leído y entiendo todos los documentos presentados dentro '
                    },
                    { text: '“Parte 1: Declaraciones de privacidad”', weight: 'bold' },
                    {
                        text: ', y además represento y garantizo que la información que he proporcionado en relación con la apertura de esta cuenta es verdadera '
                    },
                    {
                        text: 'y correcta, y que la División FCM de StoneX Financial Inc. y StoneX Markets LLC, respectivamente, confían en la '
                    },
                    {
                        text: 'información contenida en el presente como base para establecer una o más cuentas de Cliente. Certifico, represento y garantizo que todos '
                    },
                    {
                        text: 'información, incluidas las finanzas auditadas o no auditadas, o los Solicitantes que completan la información de ingresos y patrimonio neto '
                    },
                    {
                        text: 'en esta Solicitud de cuenta y toda la demás información provista es verdadera y precisa de acuerdo con el Anti-Money '
                    },
                    { text: 'Políticas y Procedimientos de Lavado. ' }
                ]
            ],
            clientAcknowledgementItem2: [
                [
                    {
                        text: 'Declaro y garantizo que he leído y entiendo todos los documentos presentados dentro '
                    },
                    {
                        text: '“Parte 2: Divulgaciones de riesgo de futuros y opciones de futuros” ',
                        weight: 'bold'
                    },
                    {
                        text: 'incluyendo la Divulgación de Riesgos de Futuros y Opciones Cotizadas en Bolsa, '
                    },
                    {
                        text: 'Divulgación de riesgos de opciones de productos básicos y todo el contenido de los documentos dentro del Documento de divulgaciones adicionales.'
                    }
                ]
            ],
            clientAcknowledgementItem3: [
                [
                    {
                        text: 'Declaro y garantizo que he leído y entendido el '
                    },
                    {
                        text: '“StoneX Financial Inc. Acuerdo de cliente de opciones negociadas en bolsa y futuros” ',
                        weight: 'bold'
                    },
                    {
                        text: 'en su totalidad, y acepto estar sujeto a los Términos y Condiciones del mismo, ya que pueden ser modificados de vez en cuando en'
                    },
                    { text: 'acuerdo con sus términos.' }
                ]
            ],
            clientAcknowledgementItem4: [
                [
                    {
                        text: 'Declaro y garantizo que he leído y entendido el '
                    },
                    { text: '“Acuerdo de usuario de la plataforma” ', weight: 'bold' },
                    {
                        text: 'en su totalidad y acepto estar sujeto a los términos y condiciones a los que estoy sujeto con respecto al acceso y '
                    },
                    { text: 'de otra manera usando la Plataforma' }
                ]
            ],
            clientAcknowledgementItem5: [
                [
                    {
                        text: 'ELECCIÓN DE ARBITRAJE PARA DISPUTAS RELACIONADAS CON CUENTAS DE FUTURO: ',
                        weight: 'bold'
                    },
                    {
                        text: 'Declaro y garantizo que he leído el “Acuerdo de Arbitraje” ',
                        weight: 'bold'
                    },
                    {
                        text: 'y entiendo sus disposiciones en este documento, y estoy haciendo la siguiente elección:',
                        weight: 'bold'
                    }
                ]
            ],
            clientAcknowledgementItem6: [
                [
                    {
                        text: 'La siguiente información se aplica a aquellos clientes que eligen transferencias electrónicas a través de la Cámara de Compensación Automatizada (ACH): ',
                        weight: 'bold'
                    },
                    {
                        text: 'Por la presente autorizo ​​a la División FCM de StoneX Financial Inc. ("FCM") en la medida en que el Cliente establezca una cuenta '
                    },
                    {
                        text: 'con el FCM, para iniciar entradas de crédito de pago y depósito preestablecidos (PPD) del consumidor a través de compensación automatizada '
                    },
                    {
                        text: 'Transferencia de la casa (ACH) a mi cuenta designada, seleccionada y autenticada por mí a través de la Plataforma, realizada en un '
                    },
                    {
                        text: 'institución financiera depositaria, y debitar dicha cuenta de vez en cuando para satisfacer cualquier "llamada de margen" u otro déficit en '
                    },
                    {
                        text: 'mi cuenta de FCM de acuerdo con el Acuerdo de Cliente de FCM.'
                    },
                    {
                        text: 'Reconozco que el origen de transacciones ACH a mi cuenta por parte de FCM debe cumplir con las disposiciones de la ley de EE. UU. '
                    },
                    {
                        text: 'Además, reconozco que el monto de todos los débitos ejecutados de conformidad con esta autorización puede variar, pero cada débito deberá '
                    },
                    {
                        text: 'sea ​​igual al monto de la "llamada de margen" adeudada en ese momento u otro déficit (según lo determinado en el Acuerdo del cliente de FCM) más cualquier tarifa aplicable. '
                    }
                ]
            ],
            clientAcknowledgementItem7: [
                [
                    { text: 'Declaro y garantizo que he leído y entendido el' },
                    { text: ' “Términos de suscripción de datos de mercado de futuros” ', weight: 'bold' },
                    { text: 'en su totalidad y acepto estar sujeto a los términos y condiciones a los que estoy sujeto con respecto al acceso y ' },
                    { text: 'de otra manera usando la Plataforma' }
                ]
            ],
            arbitrationAcceptText: 'Acepto la disposición de arbitraje',
            arbitrationDeclineText: 'Rechazo la disposición de arbitraje',
            IRSnote:
                'El IRS no requiere su consentimiento para ninguna disposición de este documento que no sean las certificaciones requeridas para evitar la retención adicional.',
            acceptAndContinueButton: 'Aceptar y continuar'
        },
        documents: {
            privacyPolicy: 'Política de privacidad de StoneX y Ley de privacidad de StoneX',
            antiMoneyLaundering: 'Procedimientos contra el lavado de dinero',
            commoditiesFuturesRiskDisclosure: 'Divulgación de riesgos de la Comisión de Comercio de Futuros de Productos Básicos',
            commoditiesOptionsRiskDisclosure: 'Declaración de divulgación de opciones',
            addlDisclosures: 'Documento de divulgación adicional que incluye lo siguiente:',
            subText: {
                nfaRiskDisclosure: 'Divulgación de riesgo adicional de NFA',
                addlRiskDisclosure: 'Divulgación de riesgos adicional',
                materialConflicts: 'Divulgación de conflictos de interés importantes',
                electronicTrading: 'Divulgación de comercio electrónico y enrutamiento de órdenes',
                noticeToOmnibus: 'Aviso a Cuentas Ómnibus sobre Restricciones de Acceso para Partes Sancionadas',
                underlyingOrSpot: 'Divulgación de producto de moneda virtual al contado o subyacente',
                clearedSwaps: 'Divulgación de swaps compensados'
            },
            stoneXCustomerAgreement: 'StoneX Financial Inc. Acuerdo de cliente de opciones negociadas en bolsa y futuros',
            arbitrationAgreement: 'Acuerdo de arbitraje',
            platformAgreement: 'Acuerdo de usuario de la plataforma',
            virtualCurrencyRiskDisclosure: 'Declaración de divulgación de riesgos de moneda virtual',
            marketDataSubscriptionTerms: 'Condiciones de suscripción de datos del mercado de futuros',
            blobStorageUrls: {
                privacyPolicies: 'https://snex1storage.blob.core.windows.net/$web/docs/StoneX-Privacy-Policies.pdf',
                commodityFuturesTradingComissionRiskDisclosure:
                    'https://snex1storage.blob.core.windows.net/$web/docs/Commodity-Futures-Trading-Commission-Risk-Disclosure-202210X.pdf',
                optionsDisclosureStatement: 'https://snex1storage.blob.core.windows.net/$web/docs/Options-Disclosure-Statement-202210X.pdf',
                acknowledgementOfAdditionalDisclosures: 'https://snex1storage.blob.core.windows.net/$web/docs/Acknowledgement-of-Additional-Disclosures-202210X.pdf',
                virtualCurrencyRiskStatements: 'https://snex1storage.blob.core.windows.net/$web/docs/NFA-CFTC-Virtual-Currency-Risk-Statements-202210X.pdf',
                futuresAndExchangeTradedOptionsCustomerAgreement:
                    'https://snex1storage.blob.core.windows.net/$web/docs/Futures-and-Exchange-Traded-Options-Customer-Agreement-SFI_FCM_202210X.pdf',
                platformUserAgreement: 'https://snex1storage.blob.core.windows.net/$web/docs/Platform-User-Agreement-SFI_FCM_202210X.pdf',
                arbitrationAgreement: 'https://snex1storage.blob.core.windows.net/$web/docs/Arbitration-Agreement-SFI_FCM_202210X.pdf',
                futuresMarketDataSubscriptionTerms: 'https://snex1storage.blob.core.windows.net/$web/docs/Futures-Market-Data-Subscription-Terms.pdf'
            }
        },
        submit: {
            title: 'Términos y Acuerdos'
        }
    },
    mediantSSO: {
        buttonText: 'Acciones Corporativas'
    },
    equitiesOnboarding: {
        // Look at fieldTranslations
        yes: 'Sí',
        no: 'No',
        firstName: 'Nombre de pila',
        lastName: 'Apellido',
        email: 'Correo electrónico',
        continue: 'Continuar',
        showIssues: 'Mostrar problemas',
        are: 'son',
        areNot: 'no son',
        openAccount: 'Abra una cuenta de negociación de acciones',
        resendCode: 'Reenviar Código',
        personalInfo1: {
            title: '¿Cuál es tu nombre y fecha de nacimiento?',
            birthDate: 'Fecha de Nacimiento'
        },
        contactInfo: {
            title: '¿Cómo te contactamos?',
            // email
            validationMessage: 'El número de teléfono debe tener 10 dígitos'
        },
        personalInfo2: {
            title: 'Tu Ciudadanía',
            label: '¿Eres un Ciudadano de los Estados Unidos?',
            yesCitizen: 'Si soy ciudadano estadounidense',
            noCitizen: 'No, soy ciudadano estadounidense',
            ssnHeader: 'SSN o identificación fiscal debe tener 9 dígitos',
            ssnOr: 'SSN o ITIN',
            alert: "We're sorry, however StoneX One access is currently only available for US citizens with a social security number.",
            desc: 'El acceso a StoneX One actualmente solo está disponible para ciudadanos estadounidenses con un número de seguro social.'
        },
        chooseAccount: {
            title: 'Elija su cuenta',
            description: 'Mantenga presionado un tipo de cuenta para obtener más detalles',
            individual: 'Individual',
            individualDescription:
                'Una cuenta sujeta a impuestos con un solo propietario, donde puede invertir en acciones, bonos, ETF y fondos mutuos. Puede solicitar operaciones de margen y opciones. Esta cuenta no tiene distribuciones requeridas, y puede depositar y retirar de esta cuenta cuando lo desee.',
            individualForeign: 'Individual Foreign',
            individualForeignDescription:
                'Una cuenta con un solo propietario, donde puede invertir en acciones, bonos, ETF y fondos mutuos. Puede solicitar operaciones de margen y opciones. Esta cuenta no tiene distribuciones requeridas, y puede depositar y retirar de esta cuenta cuando lo desee.',
            rothIRA: 'Roth IRA',
            rothIRADescription:
                'Una Cuenta de Retiro Individual que le permite contribuir dólares después de impuestos. Si bien no hay beneficios fiscales para el año en curso, sus contribuciones y cualquier ganancia sobre inversiones están libres de impuestos, y puede retirarlas libres de impuestos y multas después de los 59 años y medio y una vez que la cuenta haya estado abierta durante cinco años.',
            traditionalIRA: 'Traditional IRA',
            traditionalIRADescription: `
            Una cuenta de jubilación individual que le permite contribuir con dólares antes de impuestos y cualquier ganancia sobre las inversiones tiene impuestos diferidos hasta el retiro durante la jubilación. Ciertos límites de contribución, multas por retiro anticipado y distribuciones mínimas requeridas son aplicables a esta cuenta.`
        },
        addressInfo: {
            title: '¿Dónde vive?',
            // streetAddress, City, State, Zip are all done in futuresOnboarding
            secondary: '# de Apartamento, # de Edificio, etc.'
        },
        employmentOptions: {
            title: '¿Cuál es su situación laboral?',
            employment: 'Empleo',
            employmentStatus: {
                employed: 'Empleado',
                youAreEmployed: 'empleado',
                unemployed: 'Desempleados',
                youAreUnemployed: 'desempleados',
                student: 'Alumno',
                youAreStudent: 'un estudiante',
                retired: 'Retirado',
                youAreRetired: 'retirado'
            },
            employmentInfo: {
                title: 'Informacion de Empleo',
                occupation: 'Ocupación',
                employer: 'Empleador',
                jobTitle: 'Título profesional',
                jobFunction: 'Título profesional',
                industry: 'Industria',
                employerPhone: 'Teléfono del empleador',
                yearsEmployed: 'Años empleados'
            },
            employmentAddress: 'Dirección de empleo',
            occupations: {
                accountingOrAuditing: 'Contabilidad o Auditoría',
                advertisingOrMarketingSVCE: 'Publicidad o Marketing (SVCE)',
                civilService: 'Civil Service',
                it: 'Tecnologías de la información',
                domesticHelp: 'Ayuda doméstica',
                education: 'Educación',
                trainingEmployment: 'Formación y Empleo',
                engineering: 'Ingeniería',
                executiveManagement: 'Dirección Ejecutiva',
                finance: 'Finanzas',
                insurance: 'Seguro',
                doctor: 'Doctor',
                dental: 'Dental',
                nurse: 'Enfermero',
                socialServices: 'Servicios sociales',
                homemaker: 'Amo de casa',
                hospitality: 'Hospitalidad',
                tourism: 'Turismo',
                transport: 'Transporte',
                humanResources: 'Recursos humanos',
                manufacturing: 'Fabricación',
                military: 'Militar',
                adminSecty: 'Administración. sector',
                customerService: 'Servicio al Cliente',
                other: 'Otro',
                salesOrRetail: 'Ventas o Minorista',
                science: 'Ciencia',
                agent: 'Agente',
                banker: 'Banquero',
                business: 'Negocio',
                policeFireLaw: 'Policía, Bomberos, Ley',
                attorneyJudgeLegal: 'Abogado, Juez, Legal',
                politician: 'Político'
            },
            jobFunction: {
                creditCollections: 'Cobranzas de crédito',
                financialPlanning: 'Planificacion Financiera',
                financialAccounting: 'Contabilidad financiera',
                payroll: 'Nómina de sueldos',
                procurement: 'Obtención',
                riskManagement: 'Gestión de riesgos',
                tax: 'Impuesto',
                treasury: 'Tesorería',
                businessManagement: 'Administración de Empresas',
                adminManager: 'Administrador y gerente',
                executiveAssistant: 'Asistente Ejecutivo',
                officeClerks: 'Empleados de oficina',
                counseling: 'Asesoramiento',
                recruiting: 'Reclutamiento',
                training: 'Capacitación',
                applicationsSystems: 'Aplicaciones y Sistemas',
                businessSystems: 'Sistemas de Negocios',
                databaseAdmin: 'Administrador de base de datos',
                ITArchitecture: 'Arquitectura TI',
                ITProjectManagement: 'Gestión de proyectos de TI',
                networkServices: 'Servicios de red',
                softwareDevelopment: 'Desarrollo de software',
                systemsSupport: 'Soporte de sistemas',
                technicalServices: 'Servicios técnicos',
                webServices: 'Servicios web',
                generalCounsel: 'Consejero general',
                legalServices: 'Servicios Legales',
                legalSupport: 'Soporte legal',
                audioVisual: 'Audiovisuales',
                communications: 'Comunicaciones',
                designServices: 'Servicios de diseño',
                marketing: 'Marketing',
                mediaRelations: 'Relaciones con los medios',
                publications: 'Publicaciones',
                writingEditorial: 'Redacción y Edición',
                other: 'Otro'
            },
            industries: {
                accounting: 'Contabilidad',
                advertisingMarketing: 'Publicidad y Marketing',
                aerospaceDefense: 'Aeroespacial y Defensa',
                architectureDesign: 'Arquitectura y Diseño',
                automotive: 'Automotor',
                childcare: 'cuidado de niños',
                communicationsTelecommunications: 'Comunicaciones y Telecomunicaciones',
                constructionCarpentryLandscaping: 'Construcción y Carpintería',
                education: 'Educación',
                energy: 'Energía',
                engineering: 'Ingeniería',
                financialServices: 'Servicios financieros',
                gamingCasinoCardClub: 'Juegos de azar, casino, club de cartas',
                governmentPublicAdministration: 'Gobierno y Administración Pública',
                healthcareMedicalServices: 'Servicios Médicos y de Salud',
                hotelHospitality: 'Hotelería y Hospitalidad',
                informationTechnology: 'Tecnologías de la información',
                insurance: 'Seguro',
                legalServicesPublicSafety: 'Servicios Jurídicos y Seguridad Pública',
                manufacturing: 'Fabricación',
                mediaEntertainment: 'Medios y Entretenimiento',
                nonProfitCharity: 'Sin fines de lucro y caridad',
                printingPublishing: 'Imprenta y publicación',
                realEstate: 'Bienes raíces',
                restaurantFoodService: 'Restaurante y Servicio de comidas',
                transportation: 'Transporte',
                travel: 'Viajar',
                other: 'Otro'
            }
        },
        funding: {
            title: '¿Cuál es su fuente de financiación?',
            // Source and estimatedLiqNet already completed in futures onboarding.
            sourceIncome: {
                title: 'Fuente de ingreso',
                wages: 'Salarios', // all
                sale: 'Venta',
                inheritance: 'Herencia',
                gift: 'Regalo',
                insurance: 'Seguro',
                legalSettlement: 'Acuerdo Jurídico',
                pension: 'Pensión',
                retirement: 'El retiro',
                broker: 'Corredor'
            },
            estimatedLiquidNet: {
                title: 'Valor neto líquido estimado',
                a: 'Menos de $50,000', // MoneyOptions reference MoneyOptions.ts
                b: '$50,000 - $99,999',
                c: '$100,000 - $199,999',
                d: '$200,000 - $499,999',
                e: '$500,000 - $999,999',
                f: '$1 millón - $2,49 millones',
                g: '$2,5 millones+'
            }
        },
        trustedContact: {
            title: 'Contacto de confianza',
            description: '¿Te gustaría agregar un contacto de confianza?',
            contactInfo: {
                title: 'Información de contacto de confianza',
                relationship: 'Relación',
                phoneNumber: 'Número de teléfono',
                contactsEmailOpt: 'Email del contacto (opcional)',
                addAddress: '¿Le gustaría agregar la dirección del contacto de confianza?'
            },
            addressTitle: 'Dirección de contacto de confianza'
        },
        stoneXAffiliation: {
            title: 'Afiliación StoneX',
            titleInfo: 'Información de afiliación de StoneX',
            description:
                '¿Es usted, o alguien de su familia inmediata o que vive en el mismo hogar, un empleado o agente de StoneX Group Inc., o una empresa afiliada a StoneX?',
            position: 'Puesto ocupado en StoneX',
            relationToEmployee: 'Relación con el empleado',
            affiliatedEntity: 'Entidad afiliada',
            affiliationName: 'Nombre de afiliación de StoneX',
            affiliationRelationship: 'Relación de afiliación',
            relationship: {
                child: 'Niño',
                self: 'Ser',
                spouse: 'Esposo',
                other: 'Otro'
            },
            entity: 'Entidad StoneX afiliada a',
            affiliate: {
                stnx: 'STNX',
                infa: 'INFA',
                sswm: 'SSWM',
                ssia: 'SSIA'
            },
            affiliation: {
                // FirstName
                // LastName
                areYou: 'Eres',
                you: 'tú',
                areOtherUsBrokers: '¿Está afiliado a un corredor registrado en los Estados Unidos que no sea miembro de la industria?',
                areAccreditedMember: '¿Es usted un inversor acreditado por el miembro de la industria?',
                areRegisteredAdvisor: '¿Está afiliado a un asesor de inversiones registrado en Estados Unidos?',
                areOfficerOwnsTenPercent: '¿Es usted un funcionario, director o posee el 10 por ciento o más de las acciones de una empresa pública?',
                areEmployeeOfStonex: '¿Es usted un empleado o una persona asociada de StoneX Financial Inc. o una de sus firmas introductorias?',
                areNaturalPersonOrEntityWithoutTaxId: '¿Es usted una persona física o jurídica que no tiene un identificador fiscal de Estados Unidos.?',
                otherUsBrokers: 'afiliado con un corredor registrado en los Estados Unidos. que no sea el miembro de la industria.',
                accreditedMember: 'un inversor acreditado por el miembro de la industria.',
                registeredAdvisor: 'afiliada a un asesor de inversiones registrado en Estados Unidos',
                officerOwnsTenPercent: 'un funcionario, director o propietario del 10 por ciento o más de las acciones de una empresa pública.',
                employeeOfStonex: 'un empleado o persona asociada de StoneX Financial Inc. o una de sus firmas introductorias.',
                naturalPersonOrEntityWithoutTaxId: 'una persona física o jurídica que no tiene un identificador fiscal de Estados Unidos'
            }
        },
        industryAffiliation: {
            title: 'Afiliación de la industria',
            description:
                '¿Es usted, o alguien de su familia inmediata o que vive en el mismo hogar, empleado o asociado con una bolsa de valores, una firma miembro de una bolsa de valores, FINRA o un corredor de valores municipal?',
            // yes
            // no
            firmName: 'Nombre de la empresa',
            sec144: '¿Es usted, o alguien de su familia inmediata o que vive en el mismo hogar, un funcionario encargado de formular políticas, un director, un accionista del 10 por ciento o se le considera afiliado de una empresa que cotiza en bolsa a los efectos de la Regla 144 de la SEC?',
            companyName: 'Nombre de Empresa',
            ticker: 'Símbolo de cotización de la empresa o CUSIP'
        },
        review: {
            title: 'Revisar',
            personal: 'Personal',
            // firstName
            // lastName
            // email
            // Phone Number
            countryOfCitizenship: 'País de ciudadanía', // repeated
            ssnItin: 'SSN / ITIN',
            account: 'Cuenta',
            accountDescription: 'Estas abriendo un ', // you are opening an
            employment: 'Empleo', // Employment
            employmentDescription: 'Eres ', // you are
            funding: 'Fondos', // Funding
            // sourceofIncome and type (use funding above)
            // estimated net worth and amount (use funding above)
            // trusted contact
            // firstName from sharedTranslations
            // lastName from sharedTranslations
            // phoneNumber
            relationship: 'Relación',
            noStoneXAffiliation: '',
            address: 'DIRECCIÓN',
            // firmName
            sec144Name: 'Regla 144 de la SEC Nombre de la empresa', // SEC Rule 144 Company Name
            sec144Ticker: 'SEC Regla 144 Clave de cotización de la empresa', // SEC Rule 144 Company Ticker
            notPolicyMaker:
                'Ni usted, ni nadie en su familia inmediata, ni nadie que viva en el mismo hogar es/son un oficial de formulación de políticas, director, accionista del 10 por ciento, o de otra manera se considera un afiliado de una empresa que cotiza en bolsa a los efectos de la Regla 144 de la SEC.',
            notEmployed:
                'Ni usted, ni nadie en su familia inmediata, ni nadie en el mismo hogar está empleado por, o está asociado con, una bolsa de valores, una firma miembro de una bolsa de valores, FINRA o un corredor de valores municipal.'
        },
        agreements: {
            title: 'Agregar beneficiario real no objetante',
            description:
                'De acuerdo con la regulación, StoneX Financial Inc divulga el nombre y la dirección del titular de la cuenta a las empresas o emisores de valores que posee. Estas empresas o emisores solicitan esta información en caso de que necesiten contactar a los accionistas con respecto a comunicaciones importantes de los accionistas.',
            label: '¿Está optando por no compartir esta información con estas empresas y/o emisores? (Opcional)',
            optIn: 'Participar: compartir mi información según sea necesario',
            optOut: 'Optar por no compartir mi información'
        },
        disclosures: {
            title: 'Divulgaciones y Acuerdos',
            reviewAccept: 'Revise y acepte nuestras divulgaciones y acuerdos.',
            reviewDisclosures: 'Divulgaciones de revisión',
            disclosuresAccepted: 'Divulgaciones aceptadas!',
            acceptContinue: 'Aceptar y continuar!',
            pressFinishToCreate: 'Presiona Finalizar para crear tu cuenta',
            finish: 'Finalizar',
            missingData: 'Parece que todavía nos faltan algunos datos',
            finishLater: 'Terminar más tarde'
        }
    },
    equitiesOptionsOnboarding: {
        gotIt: 'Lo tengo',
        whatAreOptions: {
            title: '¿Qué son las opciones?',
            atItsCore:
                'En su esencia, el comercio de opciones es como conseguir un "billete" que le da el derecho, pero no la obligación, de comprar o vender una acción a un precio específico, dentro de un marco de tiempo específico.',
            analogy: 'Utilicemos una analogía sencilla',
            imagine: `Imagínese que ve anunciado un concierto de su grupo favorito el mes que viene, y no está seguro de poder asistir. En lugar de comprar la entrada de inmediato, paga una pequeña cuota por una "opción" de comprar esa entrada al precio actual en cualquier momento antes del concierto.`
        },
        financialInformation: {
            title: 'Información financiera',
            annualIncome: 'Ingresos anuales',
            estNw: 'Patrimonio Neto Estimado',
            estLiquidNw: 'Patrimonio Neto Líquido Estimado',
            selectAnnualIncome: 'Seleccionar Renta Anual',
            selectEstNw: 'Seleccionar Patrimonio Neto Estimado',
            selectLiquidNw: 'Seleccione el patrimonio neto líquido estimado'
        },
        investmentExperience: {
            title: 'Experiencia en inversiones',
            0: '0 Años',
            1: '0-2 Años',
            2: '2-5 Años',
            3: '5+ Años',
            stocksAndBonds: 'Acciones y Bonos',
            mutualFunds: 'Fondos de inversión',
            options: 'Opciones',
            annuities: 'Rentas vitalicias',
            altInvestments: 'Inversiones alternativas',
            selectNumYears: 'Seleccione el número de años'
        },
        requestOptionsLevel: {
            title: 'Solicitar un nivel de opciones',
            description: `Dado que la negociación de opciones conlleva diferentes niveles de riesgo, la negociación de opciones se clasifica en varios "niveles". Requerimos que se le apruebe un nivel específico de negociación en función de su experiencia, situación financiera y comprensión de los riesgos asociados.`
        },
        riskTolerance: {
            title: 'Tolerancia al riesgo',
            description: 'Pulse prolongadamente cada opción para obtener más información',
            conservative: 'Conservador',
            moderate: 'Moderado',
            aggressive: 'Agresivo',
            speculative: 'Especulativo',
            conservativeDesc:
                'Usted busca ingresos corrientes. El crecimiento del capital a largo plazo no es un objetivo. Está dispuesto a aceptar niveles muy bajos de volatilidad y la posible pérdida de una parte del capital, pero la preservación del capital sigue siendo una preocupación importante',
            moderateDesc:
                'Usted busca tanto el crecimiento del capital como los ingresos corrientes. Está dispuesto a aceptar niveles moderados de volatilidad con el fin de lograr una revalorización del capital o mayores rendimientos y la posible pérdida de una parte del capital con el fin de alcanzar su objetivo de inversión.',
            aggressiveDesc:
                'Usted busca el crecimiento del capital a largo plazo. Los ingresos corrientes no son un objetivo. Está dispuesto a aceptar niveles muy elevados de volatilidad y la posible pérdida de una parte sustancial del capital con el fin de alcanzar su objetivo de inversión',
            speculativeDesc:
                'Usted busca ganancias realizadas en movimientos a corto plazo en los precios de los valores. Está dispuesto a aceptar los niveles más altos de volatilidad y la posible pérdida total del capital que puedan producirse al intentar alcanzar su objetivo de inversión.'
        },
        optionsLevel: {
            title: 'Nivel de opciones',
            description: 'Hemos habilitado niveles de opciones basados en su tolerancia al riesgo',
            level1: {
                title: 'Nivel 1',
                action: 'Escribir llamadas cubiertas'
            },
            level2: {
                title: 'Nivel 2',
                action1: 'Estrategias Nivel 1',
                action2: 'Comprar calls & puts'
            },
            level3: {
                title: 'Nivel 3',
                action1: 'Estrategias Nivel 1-2',
                action2: 'Estrategias Nivel 1-2'
            },
            level4: {
                title: 'Nivel 4',
                action1: 'Estrategias Nivel 1-3',
                action2: 'Write uncovered puts',
                action3: 'Write cash-secured equity puts'
            },
            level5: {
                title: 'Nivel 5',
                action1: 'Estrategias de nivel 1-4',
                action2: 'Escribir llamadas no cubiertas'
            },
            requiresMargin: 'Requiere margen',
            addMargin: 'Add margin to your account',
            levelRequiresMargin: 'El nivel requiere margen',
            level2Margin: 'For trading levels above 2, you must have a margin agreement on file.'
        },
        optionsAgreement: {
            title: 'Acuerdo de opciones',
            description: 'Antes de enviar su solicitud, debe leer atentamente y comprender el siguiente acuerdo',
            review: 'Revisar acuerdo de opciones',
            close: 'Cerrar'
        },
        review: {
            title: 'Reseña',
            financialInfo: 'Información Financiera',
            estLiquidNw: 'Patrimonio Neto Líquido Estimado',
            netWorth: 'Patrimonio Neto',
            income: 'Ingresos',
            experienceInfo: 'Información sobre la experiencia',
            stocksAndBonds: 'Acciones y Bonos',
            options: 'Opciones',
            mutualFunds: 'Fondos de inversión',
            annuities: 'Rentas Vitalicias',
            altInvestments: 'Alt. Investments',
            optionsInfo: 'Información sobre opciones',
            optionsLevel: 'Nivel de opciones',
            investmentObjective: 'Objetivo de inversión'
        }
    },
    sharedOnboarding: {
        submissionModal: {
            multiAssetTrading: 'El comercio de activos múltiples está a la vuelta de la esquina.',
            loading: 'Sólo un momento...',
            allDone: '¡Todo listo! Comencemos a negociar.',
            notAbleToCreateAccount: 'Parece que no pudimos terminar de crear su cuenta.',
            tapAnywhereContinue: 'Toque en cualquier lugar para continuar',
            tapAnywhereForSupport: 'Toque en cualquier lugar para chatear con soporte',
            applicationPending: 'Su cuenta está siendo procesada. Le enviaremos un correo electrónico cuando se haya creado su cuenta.',
            applicationQueued: 'Su solicitud de cuenta ha sido puesta en cola. Le enviaremos un correo electrónico cuando se haya creado su cuenta.'
        },
        agreementRequired: (agreementName: string) => `${agreementName} debe ser aceptado para continuar.`,
        selectAccount: {
            title: (name: string) => `Bienvenido ${name}!`,
            subtitle: 'Vamos a conseguirte una nueva cuenta de StoneX One',
            equitiesButtonLabelTitle: 'Cepo',
            futuresButtonLabelTitle: 'Futuros',
            equitiesButtonLabelSubtitle: 'Abra una cuenta comercial de acciones',
            futuresButtonLabelSubtitle: 'Abra una cuenta de comercio de futuros'
        },
        signup: {
            leaveSignup: 'Dejar registro',
            confirmCancelSignup: '¿Está seguro de que desea cancelar su registro?',
            yesCancel: 'Sí, Cancelar',
            noStayHere: 'No, quédate aquí'
        },
        headerTranslations: {
            personal: 'Información personal',
            account: 'Informacion de cuenta',
            address: 'Información de la dirección',
            employment: 'Información de empleo',
            funding: 'Información de financiación',
            trustedContact: 'Contacto de confianza',
            stonexAffiliation: 'Afiliación StoneX',
            industryAffiliation: 'Afiliación de la industria',
            whichAccount: '¿Qué tipo de cuenta le gustaría abrir?'
        },
        fieldTranslations: {
            personal: {
                firstName: 'Nombre de pila',
                lastName: 'Apellido',
                email: 'Correo electrónico',
                phoneNumber: 'Número de teléfono',
                usCitizen: '¿Eres un ciudadano de los Estados Unidos?',
                countryOfCitizenship: 'País de ciudadanía',
                ssnOrItin: 'Número de Seguro Social o ITIN'
            },
            account: {
                accountType: 'Tipo de cuenta'
            },
            address: {
                address: 'Dirección',
                city: 'Ciudad',
                state: 'Estado',
                zipCode: 'Código postal'
            },
            employment: {
                employmentStatus: 'Estado de Empleo',
                occupation: 'Ocupación',
                employer: 'Empleador',
                employerPhoneNumber: 'Número de teléfono del empleador',
                jobTitle: 'Título profesional',
                industry: 'Industria',
                jobFunction: 'Función laboral',
                employerCountry: 'País del empleador',
                employerAddress: 'Dirección del empleado',
                employerAddress2: 'Dirección del empleador 2',
                employerCity: 'Ciudad del empleador',
                employerState: 'Estado empleador',
                employerZipCode: 'Código postal del empleador'
            },
            funding: {
                sourceOfIncome: 'Fuente de ingreso',
                estimatedLiquidNetWorth: 'Valor neto líquido estimado'
            },
            trustedContact: {
                addTrustedContact: 'Añadir contacto de confianza',
                withTrustedContact: '¿Añadir contacto de confianza?',
                trustedContactFirstName: 'Nombre de contacto de confianza',
                trustedContactLastName: 'Apellido del contacto de confianza',
                trustedContactEmail: 'Correo electrónico de contacto de confianza',
                trustedContactPhoneNumber: 'Número de teléfono de contacto de confianza',
                trustedContactRelationship: 'Relación de contacto de confianza',

                withTrustedContactAddress: '¿Añadir dirección de contacto de confianza?',
                trustedContactAddress: 'Dirección de contacto de confianza',
                trustedContactCity: 'Ciudad de contacto de confianza',
                trustedContactState: 'Estado de contacto de confianza',
                trustedContactZipCode: 'Código postal del contacto de confianza'
            },
            stonexAffiliation: {
                withStonexAffiliation: '¿Agregar afiliación a StoneX?',
                stonexAffilFirstName: 'Nombre de afiliación de StoneX',
                stonexAffilLastName: 'StoneX Afiliación Apellido',
                stonexAffilPosition: 'Posición de afiliación de StoneX',
                stonexAffilEntity: 'Entidad de afiliación StoneX',
                sameAsEmploymentInformation: 'Igual que la información de empleo',

                stonexAffilOtherUsBrokers: 'Agregar afiliación a StoneX ¿Otros corredores de EE. UU.?',
                stonexAffilAccreditedMember: 'Miembro acreditado de la afiliación StoneX',
                stonexAffilWithRegisteredAdvisor: 'Afiliación StoneX con asesor registrado',
                stonexAffilOfficerOwnsTenPercent: 'El oficial de afiliación de StoneX posee el diez por ciento',
                stonexAffilEmployeeOfStonex: 'Empleado de afiliación de StoneX de StoneX',
                stonexAffilNaturalPersonOrEntityWithoutTaxId: 'Afiliación StoneX Persona Natural o Entidad Sin Identificación del Impuesto'
            },
            industryAffiliation: {
                withIndustryAffiliation: '¿Agregar afiliación industrial?',
                industryAffilFirmName: 'Industria Afiliación Nombre de la empresa',
                withIndustryAffiliationRule144: '¿Agregar la regla 144 de afiliación de la industria?',
                industryAffilRule144CompanyName: 'Afiliación industrial Norma 144 Nombre de la empresa',
                industryAffilRule144CompanySymbolOrCusip: 'Afiliación a la Industria Regla 144 Símbolo de Compañía o CUSIP'
            },
            whichAccount: {
                continueStockTradingApplication: 'Continuar con la solicitud de acciones',
                stockTradingAccount: 'Cuenta de Trading de Acciones',
                futuresTradingAccount: 'Cuenta de Trading de Futuros'
            }
        },
        accountOpeningSelection: {
            pageTitle: 'Accounts',
            pageSubtitle: 'Select an account to manage or create a new account',
            margin: 'Margin',
            options: 'Options',
            accountNumber: 'Account Number',
            accountType: 'Type',
            activeApplication: 'Active Application',
            selectedAccountOptions: {
                addMargin: 'Add Margin',
                marginSubLabel: 'Margin gives you access to various option strategies and more.',
                addOptions: 'Add Options',
                optionsSubLabel: 'Options trading allows you to trade contracts if you think the price of an asset will go up or down.',
                continueApplication: 'Continue Application',
                continueApplicationSubLabel: 'Continue your application where you left off.'
            },
            selectionBadge: {
                inProgress: 'In Progress',
                created: 'Created',
                pending: 'Pending'
            }
        },
        true: 'Sí',
        false: 'No'
    },
    futuresGlance: {
        labels: {
            cash: 'Dinero',
            netLiq: 'Net Liq',
            openPnl: 'Ganancias/pérdidas no realizadas',
            realizedPnl: 'Ganancia/pérdida realizada',
            buyingPower: 'Poder de compra'
        },
        info: {
            netLiq: 'Valor de la cuenta si se liquida a precios corrientes de mercado.',
            openPnl: 'Beneficio/pérdida actual de todas las posiciones abiertas.',
            realizedPnl: 'Este valor es la ganancia/pérdida realizada más alta del día.',
            lastUpdated: 'Para su cuenta de Futuros, este es el Valor de Liquidez Neto de la cuenta.',
            buyingPower:
                'Su poder adquisitivo es la cantidad de efectivo total en la cuenta disponible para comprar futuros y opciones de futuros más todo el margen disponible.'
        }
    },
    fplBanner: {
        bannerText: (isSummary?: boolean, theme?: AppliedAppTheme): ShadedTextList => [
            {
                text: isSummary ? 'Parece que una o más de sus cuentas son elegibles para solicitar ' : 'Parece que su cuenta es elegible para solicitar ',
                shade: theme === 'dark' ? 'light' : 'dark'
            },
            {
                text: 'Préstamo totalmente pagado',
                shade: 'dark',
                color: theme === 'dark' ? null : '#2cb333'
            },
            {
                text: '!',
                shade: theme === 'dark' ? 'light' : 'dark',
                lineBreak: true
            },
            {
                text: 'Haga clic aquí para obtener más información',
                shade: 'dark',
                color: '#2cb333'
            },
            {
                text: 'sobre cómo convertir activos inactivos en ingresos.',
                shade: theme === 'dark' ? 'light' : 'dark'
            }
        ],
        bannerTextMobileSummary: [
            { shade: 'light', text: 'This account is eligible to apply for' },
            { shade: 'dark', text: ' \nFully Paid Lending! ' },
            { shade: 'light', text: 'Tap here to start turning idle assets into income.' }
        ],
        bannerTextMobileAccount: [
            { shade: 'light', text: 'This account is eligible to apply for' },
            { shade: 'dark', text: ' \nFully Paid Lending! ' },
            { shade: 'light', text: 'Tap here to start turning idle assets into income.' }
        ],
        applyNow: 'Aplica ya',
        learnMore: 'Aprende más'
    },
    fplModal: {
        headerText: [
            {
                text: '¿Listo para convertir los activos inactivos en ingresos?',
                shade: 'dark',
                lineBreak: true
            },
            {
                text: 'Desbloquee préstamos de valores totalmente pagados',
                shade: 'dark'
            }
        ],
        eligibilityText: 'Necesita al menos $50 000 de capital en una cuenta de margen para ser elegible',
        aboutText:
            'El préstamo de valores es la práctica de prestar valores de una parte a otra. A menudo, esta práctica permite al prestatario vender y tomar una posición corta en el mercado.',
        revenueStreamText: [
            {
                text: 'Nueva corriente de ingresos:',
                shade: 'dark',
                lineBreak: true
            },
            {
                text: 'Con el préstamo de valores totalmente pagados, puede aumentar potencialmente la rentabilidad de su cartera simplemente prestando valores que ya posee.',
                shade: 'light'
            }
        ],
        maintainOwnershipText: [
            {
                text: 'Mantener la propiedad:',
                shade: 'dark',
                lineBreak: true
            },
            {
                text: 'Aún conservará la propiedad total de sus inversiones, lo que significa que sus ganancias seguirán funcionando según las condiciones del mercado, y el prestatario pagará todos los dividendos y pagos de intereses pagados mientras el valor esté en préstamo. Aunque puede contabilizarse como efectivo en lugar de un dividendo, lo que podría tener implicaciones fiscales.',
                shade: 'light'
            }
        ],
        loansAreSecureText: [
            {
                text: 'Los préstamos son seguros:',
                shade: 'dark',
                lineBreak: true
            },
            {
                text: 'Mientras los valores están en préstamo, el prestatario le proporciona una garantía por el valor total de los valores que toma prestados.',
                shade: 'light'
            }
        ],
        howItWorksTitle: 'Cómo funcionan los préstamos de valores totalmente pagados',
        howItWorksText:
            'StoneX gestiona el proceso de préstamo, desde conectar a prestamistas y prestatarios hasta organizar la custodia de la garantía y liquidar las tarifas después del plazo del préstamo.',
        bulletPointsText: [
            'El prestamista obtiene un retorno de la inversión en efectivo en un entorno de tasas positivas',
            'El prestamista gana unos cuantos por préstamos garantizados con efectivo o valores',
            'Los valores se recuperan a petición del prestamista o venta de valores por parte del prestamista',
            'El prestatario cumple con todos los pagos de dividendos/intereses pagados mientras la garantía está bajo préstamo',
            'El cliente recibe garantía el mismo día',
            'La garantía se mantiene en relación con el valor del activo prestado',
            'El prestatario posee todos los derechos de voto asociados al valor prestado'
        ],
        invitedToFpl: `Estás invitado a préstamos totalmente pagados`,
        fullyPaidLending: 'Préstamos totalmente pagados',
        maximizeProfits: `Maximice las ganancias potenciales prestando sus acciones con préstamos de valores totalmente pagados: convierta los activos inactivos en ingresos.`,
        addFunds: 'Añadir fondos'
    },
    fplProfile: {
        applyForFPL: 'Solicite préstamos de valores totalmente pagados',
        fplProfileLink: 'Préstamos totalmente pagados',
        fplProfileBannerText: (theme?: AppliedAppTheme) => [
            {
                text: 'Maximice sus ganancias potenciales prestando sus acciones con',
                shade: theme === 'dark' ? 'light' : 'dark'
            },
            {
                text: 'Préstamo de valores totalmente pagados',
                shade: theme === 'dark' ? 'dark' : null,
                color: theme === 'dark' ? null : '#2cb333'
            },
            {
                text: '!',
                shade: theme === 'dark' ? 'light' : 'dark'
            }
        ],
        learnMore: 'Learn More',
        pending: {
            header: 'Préstamo Totalmente Pagado - Pendiente',
            body: 'Solicitud enviada'
        },
        enabled: {
            header: 'Préstamo Totalmente Pagado - Activado',
            body: `Has activado el Préstamo de Valores Totalmente Pagados para esta cuenta.`
        }
    },
    fplOnboarding: {
        welcome: {
            title: 'Solicita el Préstamo de Valores Totalmente Pagados',
            text: 'Gana intereses sobre las acciones en tu cartera. Conserva la propiedad / vende tus acciones en cualquier momento. No podrás votar mientras las acciones estén prestadas. Necesitas al menos $50,000 de patrimonio en una Cuenta de Margen para ser elegible.'
        },
        fplInfo: {
            title: 'Selecciona una cuenta elegible',
            text: 'La Cuenta de Margen seleccionada debe tener al menos $50,000 de patrimonio'
        },
        agreements: {
            title: 'Acuerdos Importantes Aplicables a tu Cuenta',
            requiredClick:
                'Es necesario que revises cuidadosamente todo el contenido de esta página y cada uno de los documentos requeridos a continuación haciendo clic en los títulos de los documentos para acceder a su contenido. Por favor, lee todos los documentos cuidadosamente antes de completar la sección.',
            subtitle: (): ShadedTextList => [
                { text: 'Es necesario que revises cuidadosamente todo el contenido de esta página y cada uno de los documentos requeridos haciendo clic en los ' },
                {
                    text: 'títulos de los documentos a continuación para acceder a su contenido. Por favor, lee todos los documentos cuidadosamente antes de completar la sección.'
                }
            ],
            clientCertTitle: 'Certificación del Cliente',
            loanAgreementText: (): ShadedTextList => [
                { text: 'Al marcar esta casilla, el Prestatario acepta los términos y condiciones establecidos aquí, incluidos los siguientes:', lineBreak: 'double' },
                { text: '1. LOS VALORES EN TU(S) CUENTA(S) DE MARGEN Y CUALQUIER VALOR POR EL QUE NO HAYAS PAGADO COMPLETAMENTE, JUNTO CON TODOS LOS ' },
                { text: 'DERECHOS DE PROPIEDAD ASOCIADOS, PUEDEN SER PRESTADOS A STONEX FINANCIAL O PRESTADOS A OTRAS PERSONAS; Y' },
                {
                    text: '2. ESTE ACUERDO CONTIENE UNA CLÁUSULA DE ARBITRAJE PREVIO A LA DISPUTA EN EL PÁRRAFO 29, QUE COMIENZA EN LA PÁGINA 11 DE ESTE ACUERDO.',
                    lineBreak: 'double'
                },
                { text: 'Determinación de la tarifa del préstamo', shade: 'dark', lineBreak: true },
                {
                    text: 'La tarifa del préstamo será un porcentaje de los nuevos ingresos obtenidos y recibidos por el Prestatario para prestar los Valores del Prestamista. '
                },
                {
                    text: 'La tarifa del préstamo se acumula diariamente, incluida la fecha en que los Valores Prestados se transfieren al Prestatario y excluida la fecha en '
                },
                { text: 'que los Valores Prestados se devuelven al Prestamista. La tarifa del préstamo se acreditará automáticamente ' },
                { text: 'en la Cuenta del Prestamista mensualmente.' }
            ],
            loanAgreementPDFTitle: 'Acuerdo Maestro de Préstamo de Valores Totalmente Pagados',
            loanAgreementPDFUrl: 'https://snex1storage.blob.core.windows.net/$web/docs/FPL/SNEX_Fully%20Paid%20Master%20Securities%20Loan%20Agreement%2020231219X.pdf',
            fplAccountControlAgreementText: (): ShadedTextList => [
                { text: 'Al marcar esta casilla, el Prestatario acepta los términos y condiciones establecidos aquí, incluidos los siguientes:', lineBreak: 'double' },
                { text: 'Aviso de Control Exclusivo', shade: 'dark', lineBreak: true },
                { text: 'El Parte Asegurada por medio de este (i) notifica al Depositario que el Parte Asegurada está ejerciendo control exclusivo sobre el Colateral ' },
                { text: '(según se define en el ACA); (ii) instruye al Depositario a dejar de cumplir con las instrucciones relacionadas con el Colateral originadas ' },
                { text: 'por el Cliente; y (iii) representa y garantiza al Depositario que este Aviso de Control Exclusivo es legal y autorizado ' },
                {
                    text: 'por el acuerdo aplicable entre el Cliente y el Parte Asegurada. El Depositario no tiene la obligación, el deber o la autoridad para determinar '
                },
                {
                    text: 'si la entrega de este Aviso de Control Exclusivo por parte del Parte Asegurada (o los términos contenidos aquí) es adecuada, incluso si el Cliente '
                },
                { text: 'objeta o instruye al Depositario a no honrar este Aviso de Control Exclusivo.' }
            ],
            fplAccountControlAgreementPDFUrl: 'https://snex1storage.blob.core.windows.net/$web/docs/SX1P_FPL-Account-Control-Agreement-20231227X.pdf',
            fplAccountControlAgreementPDFTitle: 'Acuerdo de Control de Cuenta de Préstamo Totalmente Pagado',
            axosAcknowledgementText:
                'Recibirá un correo electrónico de Axos en un par de días para completar la solicitud a través de DocuSign. Marque esta casilla para reconocer que la aprobación de su cuenta no se puede procesar sin completar la solicitud de Axos que se enviará a su dirección de correo electrónico registrada.'
        },
        disclosures: {
            title: 'Divulgaciones Importantes Aplicables a tu Cuenta',
            requiredClick:
                'Es necesario que revises cuidadosamente todo el contenido de esta página y el documento de divulgaciones requerido a continuación haciendo clic en el título del documento para acceder a su contenido. Por favor, lee todos los documentos cuidadosamente antes de completar la sección de Divulgaciones.',
            subtitle: 'Es necesario que revises todo el contenido de esta página antes de enviar',
            scrollTitle: 'Características e Riesgos Importantes',
            scrollSubtitle: 'Programa de Préstamo de Valores Totalmente Pagados de StoneX',
            importantCharAndRiskAgreement: 'Acuerdo de Características y Riesgos Importantes del Préstamo Totalmente Pagado',
            importantCharAndRiskAgreementPDFUrl:
                'https://snex1storage.blob.core.windows.net/$web/docs/FPL/SNEX_FPL%20Important%20Characteristics%20and%20Risks%20202401117X.pdf',
            importantCharAndRiskAgreementText: (): ShadedTextList => [
                { text: 'Al marcar esta casilla, el Prestatario reconoce la recepción del documento de divulgación de StoneX Financial Inc. titulado' },
                { text: '"PROGRAMA DE PRÉSTAMO DE VALORES TOTALMENTE PAGADOS, CARACTERÍSTICAS Y RIESGOS IMPORTANTES"' }
            ]
        },
        submitting: {
            title: 'Enviando tu solicitud para aprobación',
            completedTitle: 'Solicitud Enviada',
            loadingMessage: (): ShadedTextList => [{ text: 'Espere mientras enviamos su solicitud para inscribirse en el Préstamo Totalmente Pagado.' }],
            successMessage: (): ShadedTextList => [
                { text: 'Espere mientras enviamos su solicitud para inscribirse en el Préstamo Totalmente Pagado.', shade: 'dark', lineBreak: true },
                { text: 'El tiempo estimado de espera para la aprobación es de 2 días hábiles', shade: 'light' }
            ],
            axosMessage: 'Fique atento a um e-mail da Axos',
            errorMessage: 'Algo salió mal al enviar tu solicitud.'
        }
    },
    loggedOutScreen: {
        title: 'Se ha cerrado sesión',
        body: 'Puede cerrar esta ventana del navegador o, si cerró sesión por error, puede hacer clic en el botón a continuación para volver a iniciar sesión.'
    },
    mobileFplOnboarding: {
        apply: {
            title: 'Solicitud de Préstamo de Valores Totalmente Pagado',
            account: 'Cuenta',
            selectAccount: 'Seleccionar Cuenta',
            incomeGenerationTitle: 'Generación de Ingresos',
            incomeGenerationDesc: 'Genere ingresos adicionales a partir de su cartera de acciones existente sin vender sus activos.',
            ownershipRetentionTitle: 'Retención de Propiedad',
            ownershipRetentionDesc: 'Mantenga la propiedad y los posibles beneficios de capital de sus acciones mientras obtiene pruebas de préstamo.',
            riskAdjustedReturnsTitle: 'Rendimientos Ajustados al Riesgo',
            riskAdjustedReturnsDesc: 'Mejore los rendimientos de su cartera con riesgo mínimo y sin inversión adicional requerida.',
            votingTitle: 'Votación',
            votingDesc: 'No podrá votar mientras las acciones estén prestadas.',
            eligibilityTitle: 'Elegibilidad',
            eligibilityDesc: 'Necesitas al menos $50,000 en capital para ser elegible.'
        },
        disclosures: {
            title: 'Acuerdos',
            masterSecuritiesLoan: 'Acuerdo de Préstamo de Valores Totalmente Pagado',
            lendingAccountControl: 'Acuerdo de Control de Cuenta de Préstamo Totalmente Pagado',
            lendingImportantCharacteristicsAndRisks: 'Acuerdo de Características y Riesgos Importantes de Préstamo Totalmente Pagado',
            reviewAccept: 'Revise y acepte nuestras divulgaciones y acuerdos',
            reviewDisclosures: 'Revisar las divulgaciones',
            disclosuresAccepted: '¡Divulgaciones aceptadas!',
            acceptContinue: 'Aceptar y continuar',
            pressFinishToCreate: 'Presione Finalizar para crear su cuenta',
            finish: 'Finalizar',
            missingData: 'Parece que todavía nos falta información',
            finishLater: 'Finalizar más tarde'
        },
        flowStage: {
            submittedTitle: 'Envió su solicitud para aprobación',
            submittedSubtitle: 'Se estima que la aprobación tardará 2 días hábiles. ¡Mantente atento a un correo electrónico de Axos!',
            loadingTitle: 'Por favor espere mientras preparamos su cuenta FPL',
            failedTitle: 'Parece que no pudimos enviar su cuenta para aprobación'
        },
        acknowledgements: {
            title: 'Reconocimientos',
            message:
                'Recibirá un correo electrónico de Axos en un par de días para completar la solicitud a través de DocuSign. Al aceptar a continuación, reconoce que la aprobación de su cuenta no puede procesarse sin completar la solicitud de Axos que se enviará a su dirección de correo electrónico registrada.',
            accept: 'Aceptar reconocimiento'
        }
    },
    mobileNavBar: {
        home: 'Inicio',
        cash: 'Efectivo',
        trade: 'Comercio',
        orders: 'Pedidos'
    },
    oneProNetwork: {
        discoverMore: 'Descubre más',
        insights: 'insights',
        latestPosts: 'últimas publicaciones',
        enableOneProNetwork: 'Habilitar la red One Pro'
    }
};

export const Snex1SpanishTsxStub = null;

// @ts-strict-ignore
import format from 'date-fns/format';
import { LiveDataNamespaces } from 'phoenix/constants/LiveDataNamespaces';
import { useAccountValuationV2 } from 'phoenix/hooks/UseAccountValuation';
import { useText } from 'phoenix/hooks/UseText';
import { GetAccountChartAction, GetAccountSummaryAction, GetAggregateAccountChartAction, GetAggregateSummaryAction, GetOpenTaxlotsAction } from 'phoenix/redux/actions';
import { OptionSymbol } from 'phoenix/redux/models';
import { usePositionsStore } from 'phoenix/stores/PositionsStore';
import { AssetClass, QualifiedId } from 'phoenix/util/QualifiedId';
import { XS } from 'phoenix/xstream/XS';
import { useXstreamDispatch } from 'phoenix/xstream/XstreamProvider';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { GlanceItem, GlancePresentation } from './GlancePresentation';
import { useAccountAssetClass } from 'phoenix/models/AssetClasses/useAssetClass';
import { FuturesAssetClass } from 'phoenix/models/AssetClasses/FuturesAssetClass';
import { CryptosAssetClass } from 'phoenix/models/AssetClasses/CryptoAssetClass';
import { usePLStore } from 'components/AccountDropdown/Store/PLStore';
import { useFeatureFlag } from 'phoenix/hooks/UseFeatureFlag';
import { FeatureFlags } from 'phoenix/constants/FeatureFlags';


interface PortfolioGlanceProps {
    accountNumber?: string;
    assetClass?: AssetClass;
    namespace?: string;
    nonFixed?: boolean;
}

export interface GlanceData {
    /* equities data */
    portfolioValue?: number;
    amountInvested?: number;
    todaysGainLoss?: number;
    todaysGLPercent?: number;
    unrealizedGainLoss?: number;
    unrealizedGainLossPercent?: number;
    lastUpdated?: Date;
    /* futures data */
    buyingPower?: number;
}

export const PortfolioGlance = React.memo((props: PortfolioGlanceProps) => {
    const dispatch = useDispatch();
    const xdispatch = useXstreamDispatch();
    const { accountNumber, assetClass } = props;
    const { load: loadPositions } = usePositionsStore();
    const effectiveAccountNumber = !props.accountNumber || props.accountNumber.toLowerCase() === 'all' ? undefined : props.accountNumber;
    const acctAssetClass = useAccountAssetClass(accountNumber);
    const { amountInvested, buyingPower, change, percent100, summary, unrealizedGainLoss, unrealizedGainLossPercent, value } =
        useAccountValuationV2(effectiveAccountNumber);
    const equitiesText = useText((s) => s.portfolioScreen.glance);
    const futuresLabels = useText((t) => t.futuresGlance.labels);
    const futuresInfo = useText((t) => t.futuresGlance.info);
    const allPositions = usePositionsStore()?.positions;
    const useNewTodaysPl = useFeatureFlag(FeatureFlags.Web.NewTodaysPL)

    // Stream positions to calculate unrealized P&L
    useEffect(() => {
        allPositions.forEach((p) => {
            const symbol = p?.secMasterOptionSymbol || p?.symbol;
            OptionSymbol.IsOptionSymbol(symbol)
                ? xdispatch(XS.OptionQuotes.start([symbol], LiveDataNamespaces.PortfolioGlance))
                : xdispatch(XS.Quotes.start(symbol, LiveDataNamespaces.PortfolioGlance));
        });
    }, [allPositions, xdispatch]);

    useEffect(() => {
        effectiveAccountNumber
            ? XS.AccountValuations.start(accountNumber, LiveDataNamespaces.PortfolioGlance)
            : XS.PortfolioValuation.start(LiveDataNamespaces.PortfolioGlance);

        effectiveAccountNumber
            ? dispatch(GetAccountChartAction(accountNumber, acctAssetClass === FuturesAssetClass || acctAssetClass === CryptosAssetClass ? '24h' : '1d'))
            : dispatch(GetAggregateAccountChartAction());
        return () => {
            XS.stopNs(LiveDataNamespaces.PortfolioGlance);
        };
    }, [accountNumber, dispatch, effectiveAccountNumber, props.accountNumber]);

    const effectiveGlanceType = useMemo(() => {
        if (effectiveAccountNumber) return QualifiedId.Class(accountNumber);
        return assetClass || 'equities';
    }, [effectiveAccountNumber, accountNumber, assetClass]);

    const getLastUpdatedInfo = (lastUpdated: Date) => {
        const result = equitiesText.lastUpdated(lastUpdated ? format(lastUpdated, 'MM/dd/yyyy hh:mm:ss a') : '--/--/---- --:--:--');
        switch (effectiveGlanceType) {
            case 'futures':
                return `${futuresInfo.lastUpdated} ${result}`;
            case 'equities':
            default:
                return result;
        }
    };

    const lastUpdated = getLastUpdatedInfo(new Date());
    const {todaysPL, todaysPLPercent, updateTodaysPL} = usePLStore()

   
    useEffect(() => {
         if(useNewTodaysPl) updateTodaysPL(effectiveAccountNumber)
         /* using change / percent 100 to trigger updates on this until we get streaming for this */
    }, [effectiveAccountNumber, change, useNewTodaysPl])

    // initializes dispatches
    useEffect(() => {
        const getData = async () => {
            await Promise.allSettled([
                loadPositions(),
                dispatch(GetOpenTaxlotsAction()),
                effectiveAccountNumber
                    ? dispatch(GetAccountSummaryAction(accountNumber))
                    : () => {
                          dispatch(GetAggregateSummaryAction());
                      }
            ]);
        };
        getData();
    }, [accountNumber, dispatch, effectiveAccountNumber, loadPositions]);

    const getTodaysGainLossInfo = useMemo(() => {
        switch (effectiveGlanceType) {
            case 'futures':
                return futuresInfo.realizedPnl;
            case 'equities':
            default:
                return equitiesText.todaysGainLossInfo;
        }
    }, [effectiveGlanceType, equitiesText, futuresInfo]);

    const getAmountInvestedLabel = useMemo(() => {
        switch (effectiveGlanceType) {
            case 'futures':
                return futuresLabels.cash;
            case 'equities':
            default:
                return equitiesText.amountInvested;
        }
    }, [effectiveGlanceType, equitiesText, futuresLabels]);

    const todaysGainLoss = useMemo(() => {
        switch (effectiveGlanceType) {
            case 'futures':
                return summary?.futuresClosedPnLTopDay;
            case 'equities':
            default:
                {
                    return useNewTodaysPl ? todaysPL[effectiveAccountNumber || 'all'] : change
                }
        }
    }, [effectiveAccountNumber, change, todaysPL, effectiveGlanceType, summary?.futuresClosedPnLTopDay]);

    const items = useMemo((): GlanceItem[] => {
        const items = [
            { value, label: effectiveAccountNumber ? equitiesText.accountValue : equitiesText.portfolioValue, info: lastUpdated },
            { value: amountInvested, label: getAmountInvestedLabel, info: equitiesText.amountInvestedInfo },
            { value: todaysGainLoss, label: equitiesText.todaysGainLoss, info: getTodaysGainLossInfo, percentChange: useNewTodaysPl? 
                todaysPLPercent[effectiveAccountNumber || 'all'] 
                :  percent100 },
            { value: unrealizedGainLoss, label: equitiesText.unrealizedGainLoss, info: equitiesText.unrealizedGainLossInfo, percentChange: unrealizedGainLossPercent }
        ];
        if (effectiveGlanceType === 'futures') items.push({ value: buyingPower?.buyingPower, label: futuresLabels.buyingPower, info: futuresInfo.buyingPower });
        return items;
    }, [
        value,
        effectiveAccountNumber,
        equitiesText.accountValue,
        equitiesText.portfolioValue,
        equitiesText.amountInvestedInfo,
        equitiesText.todaysGainLoss,
        equitiesText.unrealizedGainLoss,
        equitiesText.unrealizedGainLossInfo,
        lastUpdated,
        amountInvested,
        getAmountInvestedLabel,
        todaysGainLoss,
        getTodaysGainLossInfo,
        percent100,
        unrealizedGainLoss,
        unrealizedGainLossPercent,
        effectiveGlanceType,
        buyingPower?.buyingPower,
        futuresLabels.buyingPower,
        futuresInfo.buyingPower
    ]);

    return <GlancePresentation items={items} nonFixed={props.nonFixed} />;
});


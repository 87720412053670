// @ts-strict-ignore
import { Error, FlashOn, Warning } from '@mui/icons-material';
import { CardContent, Skeleton, Tooltip, Typography } from '@mui/material';
import { getMultiSeriesConfig } from 'components/Charting/Chart/helpers';
import MultiSeriesChart from 'components/Charting/Chart/MultiSeriesChart';
import { SkeletonChart } from 'components/Charting/Chart/SkeletonChart';
import { FullyPaidLendingIcon } from 'components/FullyPaidLending/FullyPaidLendingIcon';
import MaintenanceCallModal from 'components/Modals/MaintenanceCallModal';
import NinetyDayRestrictionModal from 'components/Modals/NinetyDayRestrictionModal';
import { getUnixTime } from 'date-fns';
import { useColors } from 'hooks/UseColors';
import { isUTCTimestamp } from 'lightweight-charts';
import { AccountCardPlaceholderData } from 'phoenix/constants/AccountCardPlaceholderData';
import { AccountRestrictionCodes } from 'phoenix/constants/AccountRestrictionCodes';
import { ChartRange } from 'phoenix/constants/ChartRange';
import { SnexChartTimezone, SnexChartTimezoneType } from 'phoenix/constants/ChartTypes';
import { LiveDataNamespaces } from 'phoenix/constants/LiveDataNamespaces';
import { useAccountValuationV2 } from 'phoenix/hooks/UseAccountValuation';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { GetAccountSummaryAction } from 'phoenix/redux/actions';
import { GetAccountChartAction } from 'phoenix/redux/actions/AccountChartActions';
import { GlobalState } from 'phoenix/redux/GlobalState';
import { Account } from 'phoenix/redux/models';
import { FormatNumber, GetMarginCall, GetNinetyDayRestriction, IsMarketOpen, toPercent2, UniqueBy } from 'phoenix/util';
import { QualifiedId } from 'phoenix/util/QualifiedId';
import { XS } from 'phoenix/xstream/XS';
import { useXstreamDispatch } from 'phoenix/xstream/XstreamProvider';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'uuid-random';
import { Flex } from '..';
import { AssetClassLabel } from '../AssetClassLabel/AssetClassLabel';
import { Card } from '../Card';
import { DirectionalArrow } from '../DirectionalArrow';
import { LinesEllipsis } from '../LinesEllipsis/LinesEllipsis';
import './AccountCard.scss';

interface AccountCardProps {
    account?: Account;
    className?: string;
    loading?: boolean;
    onClick?: (account: Account) => any;
    range?: ChartRange;
    chartWidth?: number;
}

export const AccountCard = React.memo((props: AccountCardProps) => {
    const xdispatch = useXstreamDispatch();
    const [marginCallModalOpen, setMarginCallModalOpen] = useState<boolean>(false);
    const [ninetyDayRestrictionModalOpen, setNinetyDayRestrictionModalOpen] = useState<boolean>(false);
    const [chartData, setChartData] = useState([]);
    const [streamingChartData, setStreamingChartData] = useState([]);
    const [key, setKey] = useState<any>(1);
    const [isLoading, setIsLoading] = useState(true);

    const dispatch = useDispatch();
    const summary = useSnexStore((s) => s.accountSummary.byNumber[props.account?.accountNumber]);

    const chart = useSelector((s: GlobalState) => s.accountChart.byAccount[props.account?.accountNumber]?.['1d']);
    const [subscription, setSubscription] = useState<string | null>(null);
    const text = useText((t) => t.general);
    const colors = useColors();
    const valuation = useAccountValuationV2(props.account.accountNumber, '1d', { withoutMarketTimeRules: true });

    // TO DO: const sub portion needs to be re-worked
    const subscribe = async () => {
        unsubscribe();
        // @ts-ignore
        const sub: string = await xdispatch(XS.AccountValuations.start(props.account.accountNumber, LiveDataNamespaces.AccountCard));
        setSubscription(sub);
    };

    const unsubscribe = () => {
        if (subscription) {
            XS.stop(subscription);
            setSubscription(null);
        }
    };

    useEffect(() => {
        return unsubscribe;
    }, []);

    useEffect(() => {
        const run = async () => {
            if (props.account) {
                // This is so that we definitely get the chart data before we start streaming
                dispatch(GetAccountSummaryAction(props.account.accountNumber));
                await dispatch(GetAccountChartAction(props.account.accountNumber, props.range || '1d'));
                subscribe();
                setKey(uuid());
            }
        };
        run();
        return () => unsubscribe();
    }, [props.account]);

    useEffect(() => {
        if (chart && !chart?.pristine && !chart?.loading) {
            let portfolioChartData = [];
            const portfolioChartDataUnique = chart?.data?.data?.length
                ? UniqueBy(
                      chart?.data?.data?.map((p) => ({ time: getUnixTime(new Date(p.timestamp)), value: p?.value || 0 })).sort((a, b) => a.time - b.time),
                      (item: any) => item.time
                  )
                : [];

            if (IsMarketOpen(undefined, true)) {
                portfolioChartData = portfolioChartDataUnique.filter((item) => {
                    const dt = Date.parse(new Date(item.time).toUTCString()) * 1000;
                    return IsMarketOpen(dt, true);
                });
            }

            if (portfolioChartData?.length < 10) portfolioChartData = portfolioChartDataUnique;

            const data = portfolioChartData.sort((a, b) => Date.parse(new Date(b.timestamp).toUTCString()) - Date.parse(new Date(a.timestamp).toUTCString()));
            const filteredData = data.filter((d) => isUTCTimestamp(d.time) && d.value !== null);

            if (chartData.length) setStreamingChartData(filteredData);
            else setChartData(filteredData);
            setIsLoading(false);
        }
        if (!chart?.pristine && !chart?.loading) {
            setIsLoading(false);
        }
    }, [chart?.loading, chart?.data, chart?.pristine]);

    const Loading = () => (
        <Card className='account-card'>
            <CardContent className='placeholder-content'>
                <Skeleton animation='wave' />
                <Skeleton animation='wave' />
                <Skeleton animation='wave' height={50} />
            </CardContent>
        </Card>
    );

    const { change: gl, percent1: glPercent, value } = useAccountValuationV2(props?.account.accountNumber, '1d');

    const onCrosshairUpdate = useCallback(
        (v, isScrubbing) => {
            const toolTip = document.getElementById(props.account.accountNumber + '-value');
            const chartValue = v?.value || v;
            toolTip.innerHTML = '<div className="accountValue">' + FormatNumber.toMoney(isScrubbing ? chartValue : value || chartValue) + '</div>';
        },
        [value, gl, glPercent]
    );

    const hasChartData = useMemo(() => !(!chartData?.length && !streamingChartData?.length), [chartData, streamingChartData]);

    const hasMaintenanceCall = useMemo(() => !summary?.loading && (summary?.data?.maintenanceCallAmount || GetMarginCall()), [summary?.loading, summary]);
    const hasNinetyDayRestriction = useMemo(
        () => !summary?.loading && (summary?.data?.bopInfo?.dayNinetyRestriction || GetNinetyDayRestriction()),
        [summary?.loading, summary]
    );
    const hasDayTrader = useMemo(() => !summary?.loading && summary?.data?.bopInfo?.dayTrader, [summary?.loading, summary]);
    const hasTraderRestriction = useMemo(() => props.account.restrictionCode === AccountRestrictionCodes.stonexTraderRestriction, [props.account]);

    const multiSeries = useMemo(
        () =>
            getMultiSeriesConfig({
                data: hasChartData ? chartData : AccountCardPlaceholderData,
                id: props?.account?.accountNumber,
                seriesType: 'area',
                showVolume: false,
                timezone: SnexChartTimezone.edt as SnexChartTimezoneType
            }),
        [chartData, hasChartData, props?.account?.accountNumber]
    );

    if (props.loading || isLoading || summary?.pristine || summary?.loading) {
        return <Loading />;
    }

    if (!props.account) {
        console.warn('Tried to render the account card, but account was null!');
        return null;
    }

    const isNegative = glPercent < 0;

    const handleMarginCallClick = (e) => {
        e.stopPropagation();
        setMarginCallModalOpen(true);
    };

    const handleNinetyDayRestrictionClick = (e) => {
        e.stopPropagation();
        setNinetyDayRestrictionModalOpen(true);
    };

    return (
        <Card id={`account-card-${props.account?.accountNumber}-section`} className='account-card'>
            <Flex column style={{ height: '100%', overflow: 'hidden' }} onClick={() => props.onClick(props.account)} role='button' tabIndex={0}>
                <Tooltip title={QualifiedId.Format(props.account?.effectiveName)}>
                    <div className='account-name'>
                        <LinesEllipsis lines={2}>{QualifiedId.Format(props.account?.effectiveName || QualifiedId.Format(props?.account?.accountNumber))}</LinesEllipsis>
                    </div>
                </Tooltip>
                <Flex row align='center'>
                    <Typography className='account-number'>{QualifiedId.Format(props.account.accountNumber)}</Typography>
                    <AssetClassLabel size='small' style={{ marginLeft: 10 }} thingId={props.account.accountNumber} />
                    {!hasTraderRestriction && !!hasMaintenanceCall && (
                        <Tooltip title={text.maintenanceCall}>
                            <button onClick={handleMarginCallClick} onKeyDown={(e) => e.key === 'Enter' && handleMarginCallClick(e)}>
                                <Warning style={{ fill: colors.holdingsGold, fontSize: 15 }} />
                            </button>
                        </Tooltip>
                    )}
                    {props.account?.fplStatus === 'Approved' && (
                        <Tooltip title={text.fplEnabled}>
                            <div style={{ backgroundColor: colors.cardBackgroundColor }}>
                                <FullyPaidLendingIcon height={25} width={25} loop={false} />
                            </div>
                        </Tooltip>
                    )}
                    {!hasTraderRestriction && !!hasNinetyDayRestriction && (
                        <Tooltip title={text.ninetyDayRestriction.title}>
                            <button onClick={handleNinetyDayRestrictionClick} onKeyDown={(e) => e.key === 'Enter' && handleNinetyDayRestrictionClick(e)}>
                                <Error style={{ fill: colors.negativeValueColor, fontSize: 15 }} />
                            </button>
                        </Tooltip>
                    )}
                    {!hasTraderRestriction && !!hasDayTrader && (
                        <Tooltip title={text.dayTradingFlag}>
                            <button onClick={() => props.onClick(props.account)}>
                                <FlashOn style={{ fill: colors.primaryItemColor, fontSize: 15 }} />
                            </button>
                        </Tooltip>
                    )}
                </Flex>
                <Flex align='center' style={{ flex: 1, margin: '0px 10px' }}>
                    {hasTraderRestriction ? (
                        <WarningLabel variant='orange' style={{ margin: 0 }}>
                            {text.pendingApproval}
                        </WarningLabel>
                    ) : (
                        <>
                            <Typography variant='h6' className='account-value' id={props.account.accountNumber + '-value'} style={{ marginRight: 5 }}>
                                {FormatNumber.toMoney(value)}
                            </Typography>
                            <Flex align='center' className={`days-change-${isNegative ? 'negative' : 'positive'}`}>
                                <DirectionalArrow height={9} valueChange={valuation.percent100} />
                                <Typography variant='body1' style={{ marginLeft: 3, marginRight: 3 }}>
                                    {toPercent2(valuation.percent1)}
                                </Typography>
                                <Typography variant='body1' className='days-change-amount'>
                                    {' '}
                                    {FormatNumber.toLocaleDecimal({ value: valuation.change })}
                                </Typography>
                            </Flex>
                        </>
                    )}
                </Flex>
                <div style={{ marginLeft: -10 }}>
                    {hasChartData && !hasTraderRestriction ? (
                        <MultiSeriesChart
                            canScale={false}
                            canToggleSeries={false}
                            height={45}
                            isLoading={summary?.pristine || summary?.loading || props.loading}
                            multiSeries={multiSeries}
                            onCrosshairUpdate={onCrosshairUpdate}
                            width={props.chartWidth || 270}
                        />
                    ) : (
                        <SkeletonChart containerId={`${props.account.accountNumber}-${key}`} height={45} width={props.chartWidth || 270} />
                    )}
                </div>
            </Flex>
            <MaintenanceCallModal isOpen={marginCallModalOpen} setOpen={setMarginCallModalOpen} />
            <NinetyDayRestrictionModal isOpen={ninetyDayRestrictionModalOpen} setOpen={setNinetyDayRestrictionModalOpen} />
        </Card>
    );
});

export const WarningLabel = React.memo((props: { variant: 'red' | 'orange'; style?: any; children: any }) => {
    const orange = 'rgba(233,156,35, 1.0)';
    const lightOrange = 'rgba(233,156,35, 0.1)';

    const red = 'rgba(178,41,45, 1.0)';
    const lightRed = 'rgba(178,41,45, 0.1)';

    const color = useMemo(() => (props.variant === 'red' ? { reg: red, light: lightRed } : { reg: orange, light: lightOrange }), [props.variant]);

    return (
        <Typography
            style={{
                margin: '0px 5px',
                padding: '2px 4px',
                textTransform: 'capitalize',
                fontSize: 11,
                borderRadius: 4,
                color: color.reg,
                background: color.light,
                ...props.style
            }}
            variant='h6'
        >
            {props.children}
        </Typography>
    );
});

// @ts-strict-ignore
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Routes } from 'util/Routes';
import { RemoveFromWatchlistAction } from 'phoenix/redux/actions';
import { TradeableSecurityType } from 'phoenix/util';
import { EquityWatchlistCell } from './EquityWatchlistCell';
import { FuturesWatchlistCell } from './FuturesWatchlistCell';
import { Flex, StyledIcon } from '../..';
import style from '../style.module.scss';
import { CircularProgress } from '@mui/material';
import { CancelOutlined } from '@mui/icons-material';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { WatchlistSecurity } from 'phoenix/redux/models';
import { useDrag } from 'react-dnd';
import { DndItems, DndTypes } from 'constants/DndTypes';
import { useColors } from 'hooks/UseColors';
import { MutualFundWatchlistCell } from './MutualFundWatchlistCell';
import { useTelemetry } from 'hooks/UseTelemetry';
import { errorWrap } from 'components/ErrorBoundary/ErrorBoundary';
import { GetAssetClassForSecurity } from 'phoenix/models/AssetClasses/useAssetClass';
import AlgoliaHelper from 'phoenix/util/AlgoliaHelper';
import { OptionWatchlistCell } from './OptionWatchlistCell';
import { useSecurityMetadataV2 } from 'phoenix/stores/SecurityMetadataV2Store';

export interface WatchlistCellProps {
    getCached?: boolean;
    hideAccountName?: boolean;
    index?: number;
    onDelete?: () => void;
    secType?: TradeableSecurityType;
    security?: WatchlistSecurity;
    streamingNamespace?: string;
    symbol: string;
    visible?: boolean;
    watchlistId?: any;
}

export const WatchlistCell = React.memo((props: WatchlistCellProps) => {
    const { watchlistId, index, secType, symbol } = props;
    const colors = useColors();
    const dispatch = useDispatch();
    const LogEvent = useTelemetry();
    const [isFocused, setIsFocused] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [itemDeleted, setItemDeleted] = useState(false);
    const relatedWatchlist = useSnexStore((s) => s.watchlists.all.data.find((w) => w.id === watchlistId));
    const assetClass = GetAssetClassForSecurity(symbol);
    const meta = useSecurityMetadataV2().getMetadataBySymbol(symbol);

    // TODO -- use isDragging to change style
    const [, dragRef] = useDrag(() => ({
        type: DndTypes.WatchlistItem,
        item: DndItems.WatchlistItem(props.watchlistId, symbol, index),
        collect: (monitor) => ({ isDragging: monitor.isDragging })
    }));

    const logNavigateToSecurityView = useCallback(() => {
        LogEvent('Watchlist cell click', { symbol, 'product type': props.secType });

        AlgoliaHelper.Report.ClickedSecurity(symbol);
    }, []);

    const onDelete = (e) => {
        e.stopPropagation();
        if (props.onDelete) props.onDelete();
        setItemDeleted(true);
        dispatch(RemoveFromWatchlistAction(watchlistId, symbol));
    };

    useEffect(() => {
        setItemDeleted(false);
    }, [symbol]);

    const security = useMemo(() => relatedWatchlist.securities.find((s) => s.symbol === symbol), [symbol, watchlistId, relatedWatchlist]);

    if (assetClass.family === 'cryptos') {
        security.name = meta?.info?.securityName;
    }

    return (
        <Flex row className={style.wrapper} ref={dragRef}>
            <Link to={Routes.security(symbol)} style={{ overflow: 'hidden', width: '100%' }}>
                <Flex
                    row
                    className={style.innerWrapper}
                    style={{ minHeight: 60 }}
                    onClick={logNavigateToSecurityView}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    onMouseLeave={() => setIsHovered(false)}
                    onMouseEnter={() => setIsHovered(true)}
                >
                    <Content {...props} secType={secType} security={security} />
                </Flex>
            </Link>
            <Flex
                center
                className={style.deleteAction}
                tabIndex={0}
                onClick={onDelete}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                onMouseLeave={() => setIsHovered(false)}
                onMouseEnter={() => setIsHovered(true)}
                role='button'
            >
                {itemDeleted ? (
                    <CircularProgress size={20} style={{ color: colors.inputBorderColor }} />
                ) : (
                    <StyledIcon
                        IconComponent={CancelOutlined}
                        size='22px'
                        style={{ color: colors.blurredAdornmentColor, opacity: (isHovered || isFocused) && !!watchlistId ? 1 : 0 }}
                    />
                )}
            </Flex>
        </Flex>
    );
});

export const Content = React.memo((props: WatchlistCellProps) => {
    const { getCached, streamingNamespace, secType: incomingType, visible, security, symbol } = props;
    const commonProps = { getCached, streamingNamespace, visible, security, symbol };
    const secType = incomingType || security?.securityType || 'unknown';

    /* Showcasing all the case we can possible expand to if needed and then some.  */
    switch (secType) {
        case 'future':
            return errorWrap(<FuturesWatchlistCell {...commonProps} />);
        case 'mutual-fund':
            return errorWrap(<MutualFundWatchlistCell {...commonProps} />);
        case 'option':
            return errorWrap(<OptionWatchlistCell {...commonProps} />);
        case 'equity':
        case 'adr':
        case 'crypto':
        case 'unknown':
        default:
            return errorWrap(<EquityWatchlistCell {...commonProps} />);
    }
});

// @ts-strict-ignore
import { Typography } from '@mui/material';
import { AccountDropdownPresentation } from 'components/AccountDropdown/AccountDropdown';
import { GetSelectedAccountByAssetClass } from 'components/AccountDropdown/Store/AccountSelectionStore';
import { Snex1LanguagePack } from 'phoenix/assets/lang/Snex1LanguagePack';
import { StandardQuote } from 'phoenix/constants/ReduxSelectors';
import { ApiData } from 'phoenix/models';
import { AssetClass } from 'phoenix/models/AssetClasses/AssetClass';
import { useAssetClassMetaV2 } from 'phoenix/models/AssetClasses/useAssetClass';
import { OptionSymbol } from 'phoenix/redux/models';
import { SecurityLogo } from 'phoenix/redux/models/SecurityLogo/SecurityLogo';
import { useSecurityMetadataV2 } from 'phoenix/stores/SecurityMetadataV2Store';
import { TryGetTorchSecurityTypeSynchronous } from 'phoenix/util';
import { QualifiedId } from 'phoenix/util/QualifiedId';
import React from 'react';
import { Flex } from '../..';
import { SnexButton } from '../../SnexButton/SnexButton';
import '../Headers/index.scss';
import { BuyingPowerDisplayV2, MarketPriceDisplay, NumberInputSplit } from './TradeFormComponents';
import { TradeTicketSection } from './TradeTicketSection';

interface SecurityUnavailableForTradingProps {
    logo: ApiData<SecurityLogo>;
    quote: StandardQuote;
    symbol: string;
    text: Snex1LanguagePack;
}

export const SecurityUnavailableForTrading = React.memo((props: SecurityUnavailableForTradingProps) => {
    const { quote, symbol, text } = props;
    const assetClass: AssetClass = useAssetClassMetaV2(symbol);
    const selectedAccountByAssetClass = GetSelectedAccountByAssetClass(assetClass);
    const meta = useSecurityMetadataV2().getMetadataBySymbol(symbol);
    const { formatPrice } = assetClass;
    const baseSymbol = assetClass.derivative === 'option' ? new OptionSymbol(symbol).underlyingSymbol : symbol;
    return (
        <Flex column>
            <div className='trade-header'>
                <Typography variant='h5'>{QualifiedId.RemovePrefix(baseSymbol) || QualifiedId.RemovePrefix(symbol)}</Typography>
            </div>
            <TradeTicketSection padBottom padTop>
                <AccountDropdownPresentation disabled />
                <NumberInputSplit label={text.general.unit(symbol, 0, TryGetTorchSecurityTypeSynchronous(symbol))} initialValue={0} disabled />
                <MarketPriceDisplay
                    ask={formatPrice(quote?.ask, meta)}
                    bid={formatPrice(quote?.bid, meta)}
                    hideAskBid
                    loading={quote?.loading}
                    price={formatPrice(quote?.price, meta)}
                    text={text.tradeTicket.input}
                />
            </TradeTicketSection>
            <TradeTicketSection padBottom padTop>
                <SnexButton className='submit-trade-button' flavor='not-allowed' disabled>
                    {text.tradeTicket.misc.notAvailableToTrade}
                </SnexButton>
            </TradeTicketSection>

            <TradeTicketSection noBorder>
                <BuyingPowerDisplayV2 accountNumber={selectedAccountByAssetClass} symbol={symbol} />
            </TradeTicketSection>
        </Flex>
    );
});

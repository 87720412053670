// @ts-strict-ignore
import { Avatar, Skeleton } from '@mui/material';
import { useColors } from 'hooks/UseColors';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { ApiData } from 'phoenix/models';
import { QualifiedSecurityId } from 'phoenix/models/QualifiedSecurityId';
import { GetSecurityLogoAction } from 'phoenix/redux/actions/SecurityLogoActions';
import { OptionSymbol } from 'phoenix/redux/models';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { SecurityLogo } from 'phoenix/redux/models/SecurityLogo/SecurityLogo';
import { MakePlaceholderContent } from 'phoenix/util';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

interface CircularLogoProps {
    altText?: string;
    borderColor?: string;
    currency?: string;
    empty?: boolean;
    noSpinner?: boolean;
    size?: number;
    style?: React.CSSProperties;
    symbol?: string;
    url?: string;
}

export const CircularLogo = React.memo((props: CircularLogoProps) => {
    const { altText, borderColor: borderColorOverride, currency, empty, noSpinner, size, style, symbol, url } = props;
    const [loaded, setLoaded] = useState(false);
    const optSym = new OptionSymbol(symbol);
    const fsym = new FuturesSymbol(symbol);
    const effectiveSymbol = useMemo(() => {
        switch (true) {
            case fsym.isFuture && optSym.isOption:
                return new FuturesSymbol(optSym.underlyingSymbol).baseContract;
            case fsym.isFuture && !optSym.isOption:
                return fsym.baseContract;
            case !fsym.isFuture && optSym.isOption:
                return optSym.underlyingSymbol;
            default:
                return symbol;
        }
    }, [fsym.baseContract, fsym.isFuture, optSym.isOption, optSym.underlyingSymbol, symbol]);

    const logo = useSnexStore((s) => s.logos.bySymbol[effectiveSymbol]);
    const dispatch = useDispatch();

    // Get logo from API when symbol changes
    useEffect(() => {
        let cancel = false;
        (async () => {
            if (!url && QualifiedSecurityId.IsSymbol(effectiveSymbol)) {
                await dispatch(GetSecurityLogoAction(effectiveSymbol));
            }
            if (!cancel) setLoaded(true);
        })();
        return () => {
            cancel = true;
        };
    }, [dispatch, effectiveSymbol, url]);

    return <CircularLogoPresentation {...{ altText, borderColorOverride, currency, empty, loaded, logo, noSpinner, size, symbol, style, url }} />;
});

export type CircularLogoPresentationProps = {
    altText?: string;
    currency?: string;
    empty?: boolean;
    loaded?: boolean;
    logo: ApiData<SecurityLogo>;
    noSpinner?: boolean;
    size?: number;
    style?: React.CSSProperties;
    symbol: string;
    url?: string;
};

export function CircularLogoPresentation({ altText, currency, empty, loaded, logo, noSpinner, size, style, symbol, url }: CircularLogoPresentationProps): ReactElement {
    const themeColors = useColors();
    const styles = useMemo(
        () => ({ ...style, width: size, height: size, background: themeColors.cardSecondaryBackgroundColor, borderRadius: '100%' }),
        [size, style, themeColors]
    );
    const optSym = new OptionSymbol(symbol);
    const fsym = new FuturesSymbol(symbol);

    const effectiveSymbol = useMemo(() => {
        switch (true) {
            case fsym.isFuture && optSym.isOption:
                return new FuturesSymbol(optSym.underlyingSymbol).baseContract;
            case fsym.isFuture && !optSym.isOption:
                return fsym.baseContract;
            case !fsym.isFuture && optSym.isOption:
                return optSym.underlyingSymbol;
            default:
                return symbol;
        }
    }, [fsym.baseContract, fsym.isFuture, optSym.isOption, optSym.underlyingSymbol, symbol]);

    const None = useMemo(() => () => <div style={styles}></div>, [styles]);
    const Skel = useMemo(
        () => () =>
            (
                <div style={styles}>
                    <Skeleton animation='pulse' variant='circular'>
                        <Avatar style={{ height: size, width: size }} />
                    </Skeleton>
                </div>
            ),
        [styles, size]
    );

    if (empty) return <None />;
    if (url) return <Avatar src={url} style={{ ...styles, boxSizing: 'border-box' }} />;

    const isCurrency = !effectiveSymbol && !!currency;
    const { color, initials } = MakePlaceholderContent(isCurrency ? currency : altText || symbol, isCurrency);
    const loading = !loaded || logo?.pristine || logo?.loading;

    // If it's too early to say what's happening...
    if (loading && symbol) return noSpinner ? <None /> : <Skel />;
    // If there was an error getting the logo or the logo came back with no data...
    else if (!symbol || logo?.error || (!loading && !logo?.data?.url)) {
        // Show a currency display if currency is provided
        const fontSizeCoefficient = isCurrency ? 0.5 : 0.4;

        // Otherwise show the security's initials
        return <Avatar style={{ background: color, width: size, height: size, fontSize: size * fontSizeCoefficient, ...style }}>{initials || '$$'}</Avatar>;

        // Logically, this should never happen
    } else if (!logo?.data) return <None />;

    // Otherwise show the logo image
    return <Avatar alt={initials} src={logo?.data.url} style={{ ...styles, boxSizing: 'border-box' }} />;
}

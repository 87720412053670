// @ts-strict-ignore
import { OpenInNew } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useSelectedAccountForOrderRequest } from 'components/AccountDropdown/Store/AccountSelectionStore';
import { useColors } from 'hooks/UseColors';
import { useTelemetry } from 'hooks/UseTelemetry';
import { Snex1LanguagePack } from 'phoenix/assets/lang/Snex1LanguagePack';
import { T } from 'phoenix/assets/lang/T';
import { GetConfig, Urls } from 'phoenix/constants';
import { FeatureFlags } from 'phoenix/constants/FeatureFlags';
import { QuoteSelector } from 'phoenix/constants/ReduxSelectors';
import { CallToActionStopReasons, TradeCannotSubmitReason, TradeHooks } from 'phoenix/hooks/TradingHooks';
import { useActiveAndPendingAccounts } from 'phoenix/hooks/useActiveAndPendingAccounts';
import { useMarketTimeSegmentV2 } from 'phoenix/hooks/useMarketTimeSegment';
import { useQuantityHeldV2 } from 'phoenix/hooks/UseQuantityHeld';
import { useRelevantPositionsV2 } from 'phoenix/hooks/UseRelavantPositions';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { ApiData } from 'phoenix/models';
import { AssetClass } from 'phoenix/models/AssetClasses/AssetClass';
import { GetAssetClassForSecurity, useAssetClassMetaV2 } from 'phoenix/models/AssetClasses/useAssetClass';
import { QualifiedSecurityId } from 'phoenix/models/QualifiedSecurityId';
import { GetSecurityQuoteAction } from 'phoenix/redux/actions';
import { GetAuthorizationSettings } from 'phoenix/redux/actions/AuthorizationSettingsActions';
import { GetFundProfileAction } from 'phoenix/redux/actions/FundActions';
import { Account, OptionSymbol } from 'phoenix/redux/models';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { useSecurityMetadataV2 } from 'phoenix/stores/SecurityMetadataV2Store';
import { GetSecurityTypeFromStore, GetSecurityTypeFromStoreV2, IsMutualFund, IsOffshoreMutualFundByMetadataV2 } from 'phoenix/util';
import { OrderIsShort } from 'phoenix/util/Trading/TradeValidationHelpers';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Routes } from 'util/Routes';
import { Flex, LoadingSpinner } from '../..';
import { SnexButton } from '../../SnexButton/SnexButton';
import { ChatWithSupportButton } from '../../Support/ChatWithSuportButton';
import { TradeTicketViewModel } from '../Store/TradeTicketViewModel';
import { convertViewModelToOrderRequest, useTradeTicketViewModel } from '../Store/useTradeTicketViewModel';
import { getSubmitButtonText, GetTradeTicketQuote } from './helpers';
import { TradeTicketError } from './TradeTicketError';

type Log = {
    canLiquidate?: boolean;
    errorMessage?: string;
    screen?: string;
    shares?: number;
    symbol?: string;
};

interface TradeInputValidationButtonProps {
    disabled?: boolean;
    showLiquidate?: boolean;
}

export const TradeInputValidationButton = React.memo((props: TradeInputValidationButtonProps) => {
    const viewModel = useTradeTicketViewModel<TradeTicketViewModel>();
    const {
        awaitingConfirmation,
        leg2Quantity,
        leg2Symbol,
        leg2TradeAction,
        liquidate,
        modifyingOrder,
        hasModifyingOrderChanged,
        orderType,
        quantity,
        setViewModel,
        submitTrade,
        symbol,
        tradeAction,
        validateResponse,
        validateTrade
    } = viewModel;
    const dispatch = useDispatch();
    const LogEvent = useTelemetry();
    const [marketTimeSegment] = useMarketTimeSegmentV2();
    const navigate = useNavigate();
    const isOption = OptionSymbol.IsOptionSymbol(symbol);
    const fsym = new FuturesSymbol(symbol);
    const { isFuture } = fsym;
    const apiQuote = useSnexStore(QuoteSelector(symbol)); // use for loading state
    const xQuote = GetTradeTicketQuote(symbol); // use for price data
    const metaSymbol = isFuture && isOption ? fsym.baseContract : symbol;
    const { loadData, getMetadataBySymbol } = useSecurityMetadataV2();
    const meta = getMetadataBySymbol(metaSymbol);
    const text = useText((t) => t);
    const latestPrice = xQuote?.price;
    const assetClass = useAssetClassMetaV2(symbol);
    const isMutualFund = assetClass.type === 'mutual-fund';
    const isOffshore = !!meta && IsOffshoreMutualFundByMetadataV2(meta);
    const userInfo = useSnexStore((s) => s.user?.myInfo.data);
    const { isDvp } = userInfo;

    const onboardingV2Flag = useSnexStore((s) => s.featureFlags.byId[FeatureFlags.onboardingV2]);
    const activeAndPendingAccounts = useActiveAndPendingAccounts({ withRequests: true });
    const allV1Accounts: ApiData<Account[]> = useSnexStore((s) => s.accounts.all);
    const allAccounts: Account[] = onboardingV2Flag?.enabled ? [...activeAndPendingAccounts.accounts, ...activeAndPendingAccounts.pendingAccounts] : allV1Accounts?.data;
    const selectedAccountNumber = useSelectedAccountForOrderRequest(assetClass);
    const account = allAccounts?.find((a) => a.accountNumber === selectedAccountNumber);
    const apiTrade = convertViewModelToOrderRequest({ lang: text, marketTimeSegment, meta, selectedAccountNumber, viewModel });
    const stopReason = TradeHooks.useTradeCannotSubmitReasonV2(apiTrade, { isModifyingOrder: modifyingOrder, hasModifyingOrderChanged });

    const canSubmit = !stopReason || (stopReason && CallToActionStopReasons.has(stopReason as TradeCannotSubmitReason));
    const secId = QualifiedSecurityId.Parse(symbol);
    const relevantPositions = useRelevantPositionsV2(secId, selectedAccountNumber);

    useEffect(() => {
        if (!latestPrice && !apiQuote?.loading) dispatch(GetSecurityQuoteAction(symbol));
    }, [apiQuote?.loading, dispatch, latestPrice, symbol]);

    useEffect(() => {
        if (GetConfig().Store.getState().authorizationSettings?.settings?.pristine) dispatch(GetAuthorizationSettings());
    }, [dispatch]);

    const warnings = useMemo(() => validateResponse?.warnings || [], [validateResponse]);
    const error = useMemo(() => validateResponse?.error?.errorMessage || validateResponse?.error?.errorCode, [validateResponse]);
    const showError = awaitingConfirmation && !validateResponse?.loading;
    const sharesHeld = useQuantityHeldV2(secId, selectedAccountNumber);
    const canLiquidate = useMemo(
        () => !!relevantPositions?.length && props.showLiquidate && tradeAction === 'Sell',
        [props.showLiquidate, relevantPositions, tradeAction]
    );
    const oneClickTrading = useSnexStore((s) => s.user.myInfo?.data?.oneClickTrading);
    const isMultiLeg = leg2TradeAction;
    const secType = GetSecurityTypeFromStoreV2(symbol);

    const inputText = useText((s) => s.tradeTicket.input);

    const minimumInvestment = useSnexStore((s) => s.funds.byQsi[symbol]?.profile?.data?.mutualFundProfile?.minimumInvestment);

    useEffect(() => {
        loadData(metaSymbol);
    }, [dispatch, loadData, metaSymbol, symbol]);

    useEffect(() => {
        const holdUp = !!((!isDvp && warnings?.length) || error);
        if (awaitingConfirmation === holdUp) return;
        setViewModel({ awaitingConfirmation: holdUp });
    }, [awaitingConfirmation, error, isDvp, setViewModel, warnings]);

    useEffect(() => {
        if (IsMutualFund(symbol)) dispatch(GetFundProfileAction(symbol));
    }, [dispatch, symbol]);

    useEffect(() => {
        if (!isMultiLeg) {
            setViewModel({ awaitingConfirmation: false, validateResponse: null });
        }
    }, [tradeAction, selectedAccountNumber, quantity, symbol, isMultiLeg, setViewModel]);

    const handleSubmitNormal = async () => {
        // TODO: Move more onClick functionality here and stop rendering different button components any time there's a different onClick
        // Eventually abstract that logic into a unit-testable getter function
        if (stopReason === 'futures-account') navigate(Urls.futuresOnboarding.addAccount());
        if (['options-level', 'upgrade-options-level'].includes(stopReason)) {
            window.open(Urls.registration.enableOptions(selectedAccountNumber));
            return;
        }

        _handleSubmit();
    };
    const handleSubmitLiquidate = async () => {
        setViewModel({ liquidate: true });
        _handleSubmit();
    };

    const _handleSubmit = async () => {
        const log = { screen: 'submit', shares: quantity, symbol, canLiquidate };
        if (showError) log['Error Message'] = validateResponse?.error?.errorMessage;
        LogEvent('Trade ticket update', log, viewModel);

        const allowOneClickTrading = !OrderIsShort({ quantity, selectedAccountNumber, symbol, tradeAction }) && GetAssetClassForSecurity(symbol).family !== 'cryptos';

        if (oneClickTrading && allowOneClickTrading) {
            if (isMutualFund) {
                const newState = 'reinvest';
                setViewModel({ state: newState });
            } else {
                submitTrade();
            }
            return;
        }

        validateTrade();
    };

    const handleDismiss = () => {
        const log: Log = { screen: 'dismiss', shares: quantity, symbol, canLiquidate };
        if (showError) log.errorMessage = validateResponse?.error?.errorMessage;
        LogEvent('Trade ticket dismiss', log, viewModel);

        setViewModel({ validateResponse: null });
    };

    // Trade is already validated with warnings, user clicked to continue
    const handleContinue = () => {
        const log: Log = { screen: 'review', shares: quantity, symbol, canLiquidate };
        if (showError === true) log.errorMessage = validateResponse?.error?.errorMessage;
        LogEvent('Trade ticket update', log, viewModel);

        const newState = assetClass.type === 'mutual-fund' && !isOffshore ? 'reinvest' : 'review';
        setViewModel({ state: newState });
    };

    const submitButtonText = useMemo(() => {
        return getSubmitButtonText({
            assetClass,
            leg2Quantity,
            leg2Symbol,
            oneClickTrading,
            orderType,
            secType,
            stopReason,
            symbol,
            text,
            tradeAction,
            validateResponse
        });
    }, [assetClass, leg2Quantity, leg2Symbol, oneClickTrading, orderType, secType, stopReason, symbol, text, tradeAction, validateResponse]);

    const textWarnings = useText((t) => t.warnings.trade);

    const flavorLogic = !canSubmit && stopReason !== 'invalid-input' ? 'not-allowed' : 'submit';

    if (showError && error) {
        const supportCodes = new Set(['ACCT_RESTRICTED']);
        const variant = (() => {
            const code = validateResponse?.error?.errorCode;

            if (supportCodes.has(code)) return 'support';
            else if (code === 'ORD_CANT_AFFORD') return 'insuff-funds';
            else if (code === 'CANNOT_AFFORD_BUY') return 'insuff-funds';
            else if (code === 'CANNOT_AFFORD_SELL') return 'insuff-mv';
            else if (code === 'SHORT_NOTPERMITTED_ACCOUNTNOTMARGIN') return 'invalid-short-cash-account';
            else if (code === 'SHORT_NOTPERMITTED_ACCOUNTVALUE') return 'insuff-funds';
            else if (code === 'SHORT_NOTPERMITTED_LONG') return 'invalid-short-sell';
            else if (code === 'INSUFFICIENT_QUANTITY_TO_SHORT') return 'insuff-quantity';
            else if (code === 'INVALID_QTY' || code === 'INVALID_OPTION_QTY') return 'invalid-quantity';
            else if (code === 'BUY_LONG_EXCEEDS_SHORTS_HELD') return 'invalid-short-buy';
            else if (code === 'INVALID_BUY_STOP_ORDER') return 'invalid-buy-stop-order';
            else if (code === 'INVALID_SELL_STOP_ORDER') return 'invalid-sell-stop-order';
            else if (code === 'FUND_MINIMUM_NOT_MET') return 'fund-minimum-not-met';
            else if (code === 'INVALID_BRANCH') return 'invalid-branch';
            else if (code === 'REST_RESTRICTED') return 'rest-restricted';
            else if (code === 'MAX_NOTIONAL_VALUE_EXCEEDED') return 'notional_value_exceeded';
            else if (code === 'NO_STOCK_LOAN_RATE' || code === 'SEC_NOT_FOUND') return 'no-shorting';
            else if (code === 'ORD_MF_QRP_ONLY') return 'mf-qrp-only';
            else if (code === 'ORD_MF_NO_FOREIGN_ON_DOMESTIC') return 'mf-no-foreign-on-domestic';
            else if (code === 'ORD_MF_NO_DOMESTIC_ON_FOREIGN') return 'mf-no-domestic-on-foreign';
            else return 'fatal';
        })();

        return (
            <ValidateStack
                disabled={!canSubmit}
                minimumInvestment={minimumInvestment}
                onDismiss={handleDismiss}
                onPrimary={_handleSubmit}
                selectedAccountNumber={selectedAccountNumber}
                text={text}
                textWarnings={textWarnings}
                title={validateResponse?.error?.errorTitle}
                variant={variant}
            >
                <Typography variant='h6'>{error}</Typography>
            </ValidateStack>
        );
    }

    if (showError && warnings?.length) {
        return (
            <ValidateStack
                onDismiss={handleDismiss}
                onPrimary={handleContinue}
                selectedAccountNumber={selectedAccountNumber}
                text={text}
                textWarnings={textWarnings}
                variant='warning'
            >
                <Flex column>
                    {warnings.map((w, idx) => (
                        <Typography key={idx} style={{ marginBottom: 10, fontWeight: 400 }} variant='h6'>
                            {w.warningMessage}
                        </Typography>
                    ))}

                    <Typography style={{ marginBottom: 10, fontWeight: 400 }} variant='h6'>
                        {T((s) => s.warnings.trade?.continue)}
                    </Typography>
                </Flex>
            </ValidateStack>
        );
    }

    // TODO: Move this into TradeHooks.useTradeCannotSubmitReason
    // Mobile currently only shows this after an API response, so it would change that UX
    if (
        !isFuture &&
        !isOption &&
        !isMutualFund &&
        tradeAction === 'Sell' &&
        account &&
        account?.marginAgreementOnFile !== 'YES' &&
        !relevantPositions?.length &&
        !isDvp
    ) {
        return (
            <Flex column>
                <SnexButton
                    onClick={() => window.open(Urls.registration.enableMargin(selectedAccountNumber))}
                    className='submit-trade-button'
                    eventTag={`Start Using Margins`}
                    flavor='submit'
                >
                    {text.tradeTicket.input.applyForMargins}
                    <OpenInNew style={{ fontSize: 22, paddingLeft: '.5rem' }} />
                </SnexButton>
            </Flex>
        );
    }

    return (
        <Flex column>
            <SnexButton className='submit-trade-button' disabled={!canSubmit} eventTag='Submit Trade' flavor={flavorLogic} onClick={handleSubmitNormal}>
                {submitButtonText}
            </SnexButton>

            {canLiquidate ? (
                <SnexButton disabled={validateResponse?.loading} eventTag='Liquidate Trade' flavor='action-link' onClick={handleSubmitLiquidate}>
                    {validateResponse?.loading && liquidate ? <LoadingSpinner size={20} /> : inputText.sellAll({ securityId: symbol }, sharesHeld)}
                </SnexButton>
            ) : null}
        </Flex>
    );
});

interface ValidateStackProps {
    children: React.ReactChild;
    variant?:
        | 'fatal'
        | 'warning'
        | 'support'
        | 'insuff-funds'
        | 'insuff-mv'
        | 'insuff-quantity'
        | 'invalid-quantity'
        | 'invalid-short-cash-account'
        | 'invalid-short-sell'
        | 'invalid-short-buy'
        | 'invalid-buy-stop-order'
        | 'invalid-sell-stop-order'
        | 'fund-minimum-not-met'
        | 'invalid-branch'
        | 'rest-restricted'
        | 'notional_value_exceeded'
        | 'no-shorting'
        | 'mf-qrp-only'
        | 'mf-no-foreign-on-domestic'
        | 'mf-no-domestic-on-foreign';
    onPrimary?: () => void;
    onDismiss?: () => void;
    disabled?: boolean;
    color?: string;
    minimumInvestment?: number;
    selectedAccountNumber: string;
    text: Snex1LanguagePack;
    textWarnings: Snex1LanguagePack['warnings']['trade'];
    title?: string;
}

const ValidateStack = React.memo((props: ValidateStackProps) => {
    const { color, selectedAccountNumber, text, variant, title } = props;
    const { leg2TradeAction, quantity, symbol, tradeAction } = useTradeTicketViewModel<TradeTicketViewModel>();
    const colors = useColors();
    const tradeTicketText = text.tradeTicket;
    const allAccounts = useSnexStore((s) => s.accounts.all);
    const selectedAccount = useMemo(() => allAccounts?.data?.find((a) => a.accountNumber === selectedAccountNumber), [allAccounts, selectedAccountNumber]);
    const allowShort = selectedAccount?.marginAgreementOnFile?.toLowerCase() === 'yes';
    const isMultiLeg = leg2TradeAction;
    const isShort = allowShort && !isMultiLeg && OrderIsShort({ quantity, selectedAccountNumber, symbol, tradeAction });
    const assetClass: AssetClass = useAssetClassMetaV2(symbol);

    const { eventTag, resubmit } = useMemo(() => {
        switch (variant) {
            case 'fatal':
                return { eventTag: '[Fatal] Trade Ticket', resubmit: false };
            case 'warning':
                return { eventTag: '[Warning] Trade Ticket', resubmit: false };
            case 'insuff-funds':
                return { eventTag: 'Trade Ticket - Insufficient Funds', resubmit: false };
            case 'invalid-short-cash-account':
                return { eventTag: 'Trade Ticket - Invalid Short (Cash account)', resubmit: false };
            case 'invalid-short-sell':
            case 'invalid-short-buy':
                return { eventTag: 'Trade Ticket - Invalid Short', resubmit: true };
            case 'insuff-mv':
                return { eventTag: 'Trade Ticket - Not Enough Market Value', resubmit: false };
            case 'invalid-buy-stop-order':
                return { eventTag: 'Trade Ticket - Invalid Buy Stop Order', resubmit: true };
            case 'invalid-sell-stop-order':
                return { eventTag: 'Trade Ticket - Invalid Sell Stop Order', resubmit: true };
            case 'invalid-branch':
                return { eventTag: 'Trade Ticket - Invalid Branch', resubmit: true };
            case 'rest-restricted':
                return { eventTag: 'Trade Ticket - Rest Restricted', resubmit: true };
            case 'notional_value_exceeded':
                return { eventTag: 'Trade Ticket - Notional Value Exceeded', resubmit: true };
            case 'mf-qrp-only':
                return { eventTag: 'Trade Ticket - Mutual Fund Available for Qualified Retirement Plans Only', resubmit: false };
            case 'mf-no-foreign-on-domestic':
                return { eventTag: 'Trade Ticket - No Foreign Mutual Funds Allowed on Domestic Accounts', resubmit: false };
            case 'mf-no-domestic-on-foreign':
                return { eventTag: 'Trade Ticket - No Domestic Mutual Funds Allowed on Foreign Accounts', resubmit: false };
            default:
                return { eventTag: `Trade Ticket - ${variant}`, resubmit: false };
        }
    }, [variant]);

    return (
        <Flex column>
            <TradeTicketError noPadding title={title === text.errors.trade.generalTitle ? tradeTicketText.input.error : title}>
                {props.children}
            </TradeTicketError>
            {variant === 'warning' && (
                <SnexButton
                    className={isShort || assetClass.tradeability.showPreTradeDisclosure ? null : 'submit-trade-button'}
                    disabled={props.disabled}
                    eventTag={`${eventTag} - Submit Anyway`}
                    flavor={isShort || assetClass.tradeability.showPreTradeDisclosure ? 'custom' : 'submit'}
                    style={{
                        backgroundColor: isShort || assetClass.tradeability.showPreTradeDisclosure ? colors.orange : color,
                        marginBottom: 10,
                        ...(isShort || assetClass.tradeability.showPreTradeDisclosure ? { padding: '12px' } : {})
                    }}
                    onClick={props.onPrimary}
                >
                    {isShort
                        ? tradeTicketText.input.submitShort
                        : assetClass.tradeability.showPreTradeDisclosure
                        ? text.general.confirmAndContinue
                        : tradeTicketText.input.submitAnyway}
                </SnexButton>
            )}
            {(variant === 'fatal' || variant === 'insuff-quantity' || variant === 'insuff-mv' || variant.includes('stop-order') || resubmit) && (
                <SnexButton
                    className='submit-trade-button'
                    disabled={props.disabled}
                    eventTag={`${eventTag} - Resubmit`}
                    flavor={'submit'}
                    style={{ backgroundColor: color, marginBottom: 10 }}
                    onClick={props.onPrimary}
                >
                    {tradeTicketText.input.resubmit}
                </SnexButton>
            )}
            {variant === 'insuff-funds' && (
                <Link to={Routes.funding()}>
                    <SnexButton className='submit-trade-button' eventTag={`${eventTag} - Deposit`} flavor='submit' style={{ backgroundColor: color }}>
                        {tradeTicketText.input.advisories.insufficientValue.depositFunds}
                    </SnexButton>
                </Link>
            )}
            {variant === 'invalid-short-cash-account' && (
                <SnexButton
                    onClick={() => window.open(Urls.registration.enableMargin(selectedAccountNumber))}
                    className='submit-trade-button'
                    eventTag={`${eventTag} - Start Using Margins`}
                    flavor='submit'
                    style={{ backgroundColor: color }}
                >
                    {text.tradeTicket.input.applyForMargins}
                    <OpenInNew style={{ fontSize: 22, paddingLeft: '.5rem' }} />
                </SnexButton>
            )}
            {variant !== 'support' && (
                <SnexButton eventTag={`${eventTag} - Dismiss`} flavor='cancel' onClick={props.onDismiss}>
                    {tradeTicketText.input.dismiss}
                </SnexButton>
            )}
            {variant === 'support' ? <ChatWithSupportButton /> : null}
        </Flex>
    );
});

// @ts-strict-ignore
import { CheckCircleOutline, HighlightOff } from '@mui/icons-material';
import { Skeleton, Typography } from '@mui/material';
import { UseSelectedAccount, useSelectedAccountByAssetFamily } from 'components/AccountDropdown/Store/AccountSelectionStore';
import { SnexButton } from 'components/SnexButton';
import useOptionsOpenClose from 'hooks/useOptionsOpenClose';
import { Snex1LanguagePack } from 'phoenix/assets/lang/Snex1LanguagePack';
import { StandardQuote } from 'phoenix/constants/ReduxSelectors';
import { TradeActions } from 'phoenix/constants/Trade';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { ApiData } from 'phoenix/models';
import { ApiCreditDebit, ApiOrderType, ApiTimeInForce, ApiTradeAction } from 'phoenix/models/ApiTradeRequest';
import { AssetClass } from 'phoenix/models/AssetClasses/AssetClass';
import { useAssetClassMetaV2 } from 'phoenix/models/AssetClasses/useAssetClass';
import { OptionQuote, OptionsOpenClose, OptionSymbol } from 'phoenix/redux/models';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { Order } from 'phoenix/redux/models/Orders';
import { BuyingPowerStore_Load } from 'phoenix/stores/BuyingPowerStore';
import { SecurityMetadataV2, useSecurityMetadataV2 } from 'phoenix/stores/SecurityMetadataV2Store';
import { ChangeColor, FormatNumber, IsOffshoreMutualFundByMetadataV2, SafeFormat } from 'phoenix/util';
import { getMultiLegDefaultPrice } from 'phoenix/util/OptionsHelpers';
import { QualifiedId } from 'phoenix/util/QualifiedId';
import { OrderIsShort } from 'phoenix/util/Trading/TradeValidationHelpers';
import { XS } from 'phoenix/xstream/XS';
import React, { useEffect, useMemo, useState } from 'react';
import { Flex } from '../../Flex';
import { LoadingSpinner } from '../../LottieAnimations';
import { TradeMessageHeader } from '../Headers/TradeMessageHeader';
import { DisplayOrderType, TradeTicketViewModel } from '../Store/TradeTicketViewModel';
import { GetTradeTicketViewModel, useTradeTicketViewModel } from '../Store/useTradeTicketViewModel';
import { GetTradeTicketQuote } from './helpers';
import { OutputSplit } from './TradeFormComponents';
import { TradeTicketSection } from './TradeTicketSection';

export const TradeReceiptPage = React.memo(() => {
    // Set a non-reactive snapshot of view model at the time of trade execution
    // so receipt doesn't change even if user changes external trade controls
    const [memoViewModel, setMemoViewModel] = useState<TradeTicketViewModel>({});
    const {
        displayOrderType,
        leg2Quantity,
        leg2Symbol,
        leg2TradeAction,
        limitPrice,
        modifyingOrder,
        orderType,
        initialQuantity,
        quantity,
        quantityQualifier,
        debitCredit,
        stopPrice,
        symbol,
        tradeAction,
        initialLimitPrice,
        initialStopPrice,
        timeInForce
    } = memoViewModel;
    const { submitResponse, setViewModel } = useTradeTicketViewModel<TradeTicketViewModel>();
    const assetClass = useAssetClassMetaV2(symbol);
    const showEstimatedCostOrGain = !['futures', 'futures-time-spread'].includes(assetClass.type);
    const text = useText((s) => s);
    const quote = GetTradeTicketQuote(symbol);
    const leg2Quote = XS.OptionQuotes.use(leg2Symbol);
    const meta = useSecurityMetadataV2().getMetadataBySymbol(symbol);
    const { formatPrice, formatQuantity, getPriceFormatInfo } = assetClass;
    const { decimalPlaces } = getPriceFormatInfo(meta);
    const formatValue = (value: number) => formatPrice(value, meta);
    const formattedQuantity = quantity
        ? assetClass.family === 'cryptos' && quantityQualifier === 'EvenDollar'
            ? formatQuantity(assetClass.getQuantityForPrice(quantity, quote))
            : formatQuantity(quantity, meta)
        : formatQuantity(initialQuantity, meta);
    const [selectedAccountNumber] = UseSelectedAccount();
    const { openClose, leg2OpenClose } = useOptionsOpenClose();
    const placedDate = submitResponse?.data?.placedDate;

    useEffect(() => {
        BuyingPowerStore_Load(true);
        setMemoViewModel(GetTradeTicketViewModel());
    }, []);

    const onReset = () => {
        setViewModel({
            state: 'input'
        });
    };

    const allAccounts = useSnexStore((s) => s.accounts.all);
    const selectedAccount = useMemo(() => allAccounts?.data?.find((a) => a.accountNumber === selectedAccountNumber), [allAccounts, selectedAccountNumber]);
    const allowShort = selectedAccount?.marginAgreementOnFile?.toLowerCase() === 'yes';
    const isShortSell = allowShort && OrderIsShort({ quantity, selectedAccountNumber, symbol, tradeAction });
    const isMultiLeg = !!leg2Quantity;
    const multiLegMarketPrice = isMultiLeg
        ? getMultiLegDefaultPrice({
              leg1Quote: quote as OptionQuote,
              leg2Quote,
              orderType,
              quantity: quantity || initialQuantity,
              tradeAction,
              leg2Quantity,
              leg2TradeAction
          })?.initialPrice
        : null;

    // TODO Clean up this interface
    return (
        <TradeReceiptPagePresentation
            {...{
                assetClass,
                decimalPlaces,
                debitCredit,
                displayOrderType,
                formatValue,
                formattedQuantity,
                initialLimitPrice,
                initialQuantity,
                initialStopPrice,
                isMultiLeg,
                isShortSell,
                leg2OpenClose,
                leg2Quantity,
                leg2Symbol,
                leg2TradeAction,
                limitPrice,
                meta,
                multiLegMarketPrice,
                onReset,
                openClose,
                orderType,
                placedDate,
                quantity,
                quantityQualifier,
                quote,
                selectedAccountNumber,
                showEstimatedCostOrGain,
                stopPrice,
                symbol,
                text,
                tradeAction,
                timeInForce
            }}
            edit={modifyingOrder}
            editState={submitResponse as ApiData<Order>}
            submit={submitResponse as ApiData<Order>}
            unitFactor={meta?.sizing?.multiplier}
        />
    );
});

interface SubmittingSectionProps {
    submit: ApiData<Order>;
    text: Snex1LanguagePack['tradeTicket']['receipt'];
}

const SubmittingSection = React.memo(function SubmittingSection({ submit, text }: SubmittingSectionProps) {
    const Content = (() => {
        if (submit?.loading) return { icon: <LoadingSpinner size={60} />, title: text.submittingOrder, subtitle: text.youMayClickAway };
        else if (submit?.error) {
            return {
                icon: <HighlightOff style={{ fill: ChangeColor(-1), fontSize: 45 }} />,
                title: submit.error.errorTitle || text.thereWasAProblem,
                subtitle: submit.error.errorMessage
            };
        } else {
            return {
                icon: <CheckCircleOutline style={{ fill: ChangeColor(1), fontSize: 45 }} />,
                title: text.orderSubmitted,
                subtitle: text.checkForStatusUpdatesUnderPositions
            };
        }
    })();

    return (
        <Flex row align='center' justify='flex-start'>
            <Flex center row style={{ marginRight: 20, width: 80 }}>
                {Content.icon}
            </Flex>
            <Flex column style={{ flex: 1 }}>
                <Typography variant='h6'>{Content.title}</Typography>
                <Typography style={{ fontWeight: 'normal' }} variant='h6'>
                    {Content.subtitle}
                </Typography>
            </Flex>
        </Flex>
    );
});

interface EditingOrderSectionProps {
    editState: ApiData<Order>;
    onSuccess: () => void;
    text: Snex1LanguagePack['tradeTicket']['receipt'];
}

const EditingOrderSection = React.memo(function EditingOrderSection({ editState, text }: EditingOrderSectionProps) {
    const Content = (() => {
        if (editState?.loading) return { icon: <LoadingSpinner size={60} />, title: text.submittingOrder, subtitle: text.youMayClickAway };
        else if (editState?.error) {
            return { icon: <HighlightOff style={{ fill: ChangeColor(-1), fontSize: 45 }} />, title: text.thereWasAProblem, subtitle: text.pleaseTryAgain };
        } else {
            return {
                icon: <CheckCircleOutline style={{ fill: ChangeColor(1), fontSize: 45 }} />,
                title: text.orderSubmitted,
                subtitle: text.checkForStatusUpdatesUnderPositions
            };
        }
    })();

    return (
        <Flex row align='center' justify='flex-start'>
            <Flex center row style={{ marginRight: 20, width: 80 }}>
                {Content.icon}
            </Flex>
            <Flex column style={{ flex: 1 }}>
                <Typography variant='h6'>{Content.title}</Typography>
                <Typography style={{ fontWeight: 'normal' }} variant='h6'>
                    {Content.subtitle}
                </Typography>
            </Flex>
        </Flex>
    );
});

export type TradeReceiptPagePresentationProps = {
    assetClass: AssetClass;
    decimalPlaces: number;
    displayOrderType: DisplayOrderType;
    debitCredit?: ApiCreditDebit;
    edit?: boolean;
    editState: ApiData<Order>;
    formatValue?: (value: number) => string;
    formattedQuantity: string;
    initialLimitPrice?: number;
    initialQuantity?: number;
    initialStopPrice?: number;
    isMultiLeg?: boolean;
    isShortSell: boolean;
    leg2OpenClose?: OptionsOpenClose;
    leg2Quantity?: number;
    leg2Symbol: string;
    leg2TradeAction?: ApiTradeAction;
    limitPrice?: number;
    meta: SecurityMetadataV2;
    multiLegMarketPrice?: number;
    placedDate: string;
    onReset: () => void;
    onSuccess?: () => void;
    openClose?: OptionsOpenClose;
    orderType?: ApiOrderType;
    quantity?: number;
    quantityQualifier: 'Shares' | 'EvenDollar';
    quote: StandardQuote;
    selectedAccountNumber: string;
    showEstimatedCostOrGain: boolean;
    stopPrice?: number;
    submit: ApiData<Order>;
    symbol: string;
    text: Snex1LanguagePack;
    tradeAction: ApiTradeAction;
    timeInForce: ApiTimeInForce;
    unitFactor: number;
};

export function TradeReceiptPagePresentation({
    assetClass,
    decimalPlaces,
    debitCredit,
    displayOrderType,
    edit,
    editState,
    formatValue,
    formattedQuantity,
    isMultiLeg,
    limitPrice,
    initialLimitPrice,
    initialStopPrice,
    leg2OpenClose,
    meta,
    onReset,
    onSuccess,
    openClose,
    orderType,
    quantity,
    initialQuantity,
    leg2Quantity,
    multiLegMarketPrice,
    placedDate,
    quantityQualifier,
    quote,
    showEstimatedCostOrGain,
    stopPrice,
    submit,
    symbol,
    leg2Symbol,
    leg2TradeAction,
    selectedAccountNumber,
    text,
    tradeAction,
    timeInForce,
    isShortSell,
    unitFactor
}: TradeReceiptPagePresentationProps): JSX.Element {
    const isFuture = FuturesSymbol.IsFuturesSymbol(symbol);
    const isOption = OptionSymbol.IsOptionSymbol(symbol);
    const isOffshoreMutualFund = IsOffshoreMutualFundByMetadataV2(meta);
    const marketPriceFallback = tradeAction === TradeActions.Buy ? quote?.bid : quote?.ask;
    const trueQuantity = quantity || initialQuantity;
    const marketPrice = isMultiLeg ? multiLegMarketPrice * trueQuantity * unitFactor : quote?.price || (isFuture && isOption ? marketPriceFallback : null);
    const receiptText = text.tradeTicket.receipt;
    const inputText = text.tradeTicket.input;
    const opennessText = text.misc.tradeTypeButton;
    const buttonText = (() => {
        if (submit?.loading) return receiptText.pleaseWait;
        else if (submit?.error) return receiptText.pleaseTryAgain;
        else return receiptText.submitAnotherOrder;
    })();
    const selectedAccountByAsset = useSelectedAccountByAssetFamily(assetClass.family);

    const purchasePrice = (() => {
        if (quantityQualifier === 'EvenDollar') return trueQuantity;

        const result = (() => {
            switch (orderType) {
                case 'market':
                    return trueQuantity * marketPrice;
                case 'stop':
                    return trueQuantity * (stopPrice || initialStopPrice);
                case 'limit':
                case 'stoplimit':
                    return trueQuantity * (limitPrice || initialLimitPrice);
                default:
                    return NaN;
            }
        })();

        if (isMultiLeg && orderType === 'limit') return (limitPrice || initialLimitPrice) * unitFactor;
        if (!isMultiLeg && isOption) return result * (unitFactor || 1);

        return result;
    })();

    const orderTypeName = (() => {
        const names = receiptText.orderType;
        switch (displayOrderType) {
            case 'market':
                return names.marketOrder;
            case 'limit':
                return names.limitOrder;
            case 'stop':
                return names.stopOrder;
            case 'stoplimit':
                return names.stopLimitOrder;
            case 'loc':
                return names.locOrder;
            case 'moc':
                return names.mocOrder;
        }
    })();

    const format = (value: number) => (formatValue ? formatValue(value) : FormatNumber.toLocaleDecimal({ decimalPlaces, value }));

    let tradeActionText;

    if (tradeAction === 'Buy') {
        if (!isFuture) {
            tradeActionText = isOption ? (openClose === 'Close' ? opennessText.buyToClose : opennessText.buyToOpen) : opennessText.buy;
        } else {
            tradeActionText = opennessText.buy;
        }
    } else if (tradeAction === 'Sell') {
        if (!isFuture) {
            tradeActionText = isOption
                ? openClose === 'Close'
                    ? opennessText.sellToClose
                    : opennessText.sellToOpen
                : isShortSell
                ? opennessText.shortSell
                : opennessText.sell;
        } else {
            tradeActionText = opennessText.sell;
        }
    }

    let estimatedCostOrGain = showEstimatedCostOrGain
        ? [
              {
                  title:
                      debitCredit !== undefined
                          ? debitCredit === 'Credit'
                              ? receiptText.estimatedTotalCredit
                              : receiptText.estimatedTotalCost
                          : tradeAction === 'Buy'
                          ? receiptText.estimatedTotalCost
                          : receiptText.estimatedTotalCredit,
                  value: FormatNumber.toMoney(purchasePrice)
              }
          ]
        : [];

    if (isFuture && !isOption) {
        estimatedCostOrGain = [];
    }

    const cryptoFigures: { title: string; value?: string; fontType?: string }[] = [
        { title: tradeActionText, fontType: 'title' },
        { title: text.tradeHistory.symbol, value: QualifiedId.RemovePrefix(symbol) },
        {
            title: text.general.coins(parseInt(formattedQuantity) || 0),
            value: formattedQuantity
        },
        { title: receiptText.timeInForce, value: timeInForce },
        { title: receiptText.orderType.orderType, value: orderTypeName },
        ...(['limit', 'stoplimit'].includes(orderType) ? [{ title: receiptText.limitPrice, value: format(limitPrice || initialLimitPrice) }] : []),
        ...(orderType === 'stoplimit' ? [{ title: receiptText.stopPrice, value: format(stopPrice || initialStopPrice) }] : []),
        { title: receiptText.marketPrice, value: format(marketPrice) },
        ...(showEstimatedCostOrGain
            ? [
                  {
                      title:
                          tradeAction === 'Buy'
                              ? quantityQualifier === 'EvenDollar'
                                  ? inputText.totalCost
                                  : receiptText.estimatedTotalCost
                              : quantityQualifier === 'EvenDollar'
                              ? inputText.totalGain
                              : receiptText.estimatedTotalCredit,
                      value: FormatNumber.toMoney(purchasePrice)
                  }
              ]
            : [])
    ];

    const nonCryptoSymbolTitle = isOffshoreMutualFund ? text.orders.isin : text.tradeHistory.symbol;

    const nonCryptoFigures: { title: string; value?: string; fontType?: string }[] = [
        { title: tradeActionText, fontType: 'title' },
        { title: nonCryptoSymbolTitle, value: assetClass.getSymbolName(symbol, text, 'full', meta) },
        ...(quantityQualifier !== 'EvenDollar' ? [{ title: receiptText.numberOfUnits(symbol), value: formattedQuantity }] : []),
        { title: receiptText.timeInForce, value: timeInForce },
        { title: receiptText.orderType.orderType, value: orderTypeName },
        ...(orderType === 'limit' ? [{ title: receiptText.limitPrice, value: format(limitPrice || initialLimitPrice) }] : []),
        ...(orderType === 'stop' || orderType === 'stoplimit' ? [{ title: receiptText.stopPrice, value: format(stopPrice || initialStopPrice) }] : []),
        ...(orderType === 'stoplimit' ? [{ title: receiptText.stopLimitPrice, value: format(limitPrice || initialLimitPrice) }] : []),
        { title: receiptText.marketPrice, value: format(marketPrice) },
        ...estimatedCostOrGain
    ];

    let figures = assetClass.family === 'cryptos' ? cryptoFigures : nonCryptoFigures;

    if (isMultiLeg) {
        const openCloseText = getActionOpenCloseText(tradeAction, openClose, opennessText);
        const leg2OpenCloseText = getActionOpenCloseText(leg2TradeAction, leg2OpenClose, opennessText);

        figures = figures.filter((f) => ![tradeActionText, nonCryptoSymbolTitle, receiptText.numberOfUnits(symbol), receiptText.marketPrice].includes(f.title));

        figures.unshift(
            {
                title: text.tradeTicket.receipt.leg1,
                value: `${assetClass.getSymbolName(symbol, text, 'full', meta)}\n${openCloseText} ${quantity}`,
                fontType: 'bold'
            },
            {
                title: text.tradeTicket.receipt.leg2,
                value: `${assetClass.getSymbolName(leg2Symbol, text, 'full', meta)}\n${leg2OpenCloseText} ${leg2Quantity}`,
                fontType: 'bold'
            }
        );
    }

    return (
        <Flex column style={{ overflow: 'hidden' }}>
            <TradeMessageHeader title={receiptText.orderSummary} subtitle={selectedAccountByAsset} />
            <TradeTicketSection>
                {figures.map((f, key) => (
                    <OutputSplit key={key} title={f.title} figure={f.value} fontType={f.fontType} />
                ))}
                {placedDate ? (
                    <OutputSplit title={receiptText.timestamp} figure={SafeFormat(placedDate, 'dd MMM yyyy, h:mm:ss a')} />
                ) : (
                    <Flex row align='center' justify='space-between' style={{ marginTop: 14, marginBottom: 14 }}>
                        <Typography style={{ fontSize: 15, whiteSpace: 'pre-wrap' }} variant='h6'>
                            {receiptText.timestamp}
                        </Typography>
                        <Skeleton style={{ width: '35%' }} />
                    </Flex>
                )}
            </TradeTicketSection>

            <TradeTicketSection padBottom padTop noBorder={edit}>
                {edit ? <EditingOrderSection {...{ editState, onSuccess, text: receiptText }} /> : <SubmittingSection {...{ submit, text: receiptText }} />}
            </TradeTicketSection>
            {!edit && (
                <TradeTicketSection noBorder padBottom padTop>
                    <SnexButton disabled={submit?.loading} flavor='submit' onClick={onReset} className='submit-trade-button'>
                        {buttonText}
                    </SnexButton>
                </TradeTicketSection>
            )}
        </Flex>
    );
}

const getActionOpenCloseText = (
    action: ApiTradeAction,
    openClose: OptionsOpenClose,
    text: Snex1LanguagePack['misc']['tradeTypeButton']
): Snex1LanguagePack['misc']['tradeTypeButton'][keyof Snex1LanguagePack['misc']['tradeTypeButton']] => {
    if (action === 'Buy') {
        return openClose === 'Open' ? text.buyToOpen : text.buyToClose;
    }
    return openClose === 'Open' ? text.sellToOpen : text.sellToClose;
};

import { AssetFamily } from 'phoenix/models/AssetClasses/AssetClass';
import { SnexError } from 'phoenix/models/SnexError';
import { create } from 'zustand';
import { SnexAxios } from './AxiosHelpers';
import { Urls } from 'phoenix/constants';
import { ErrorLogger } from 'phoenix/util/ErrorLogger';
import { SecurityMetadata } from 'phoenix/redux/models/Securities/SecurityMetadata';
import { SecurityCompanyInfo } from 'phoenix/redux/models';

/** @deprecated This will be removed when we support SecurityMetadataV2 across both web and mobile. Prefer SecurityMetadataV2 over this. */
export type SecurityMetadataMix = SecurityMetadata | SecurityMetadataV2;
export type SecurityMetadataV2 = MetadataV2Data;

// Remove when we support SecurityMetadataV2 across both web and mobile
export const isSecurityMetadata = (data?: SecurityMetadataMix): data is SecurityMetadata => {
    return data ? 'symbol' in data : false;
};

// Remove when we support SecurityMetadataV2 across both web and mobile
export const isSecurityMetadataV2 = (data?: SecurityMetadataMix): data is SecurityMetadataV2 => {
    return data ? 'qsi' in data : false;
};

interface MetadataV2Data extends MetadataV2FromApi {
    isInAlgolia?: boolean;
    info?: {
        securityName?: string;
        description?: string;
        companyName?: string;
    };
}
interface MetadataV2Store {
    loading: { [qsi: string]: boolean };
    symbolLoaded: (qsi: string) => boolean;
    data: { [qsi: string]: MetadataV2Data };
    loadData: (qsi?: string, forceReload?: boolean) => Promise<void>;
    getMetadataBySymbol: (qsi?: string) => MetadataV2Data;
}

export type MetadataV2AssetType =
    | 'Equity'
    | 'EquityOption'
    | 'OffshoreMutualFund'
    | 'MutualFund'
    | 'Etf'
    | 'Adr'
    | 'Future'
    | 'FuturesOption'
    | 'FuturesTimeSpread'
    | 'Crypto';

interface MetadataV2FromApi {
    qsi?: string;
    type?: MetadataV2AssetType;
    family?: AssetFamily;
    derivative?: string;
    equity?: {
        cusip?: string;
        sedol?: string;
        isin?: string;
        securityNumber?: string;
    };
    mutualFund?: {
        minimumInitialInvestment?: number;
        isOffshore?: boolean;
        offshoreCashDividendsAvailable?: boolean;
    };

    future?: {
        baseSymbol?: string;
        underlyingFrontMonthIndex?: number;
        underlyingFrontMonthYear?: number;
        group?: string;
        session?: 'Open' | 'Closed';
        sessionStart?: string /* datetime */;
        sessionEnd?: string /* datetime */;
        hasTimeSpreads?: boolean;
        maintenanceMargin?: number;
        dayTradeMargin?: number;
        initialMargin?: number;
    };
    option?: {
        underlyingSymbol?: string;
        putCall?: 'Put' | 'Call';
        strikePrice?: number;
        expirationDate?: string /* datetime */;
        isAdjusted?: boolean;
    };
    timeSpread?: {
        nearDate?: string /* datetime */;
        farDate?: string /* datetime */;
        buyIndication?: 'BuyNearSellFar' | 'BuyFarSellNear';
    };
    sizing?: {
        multiplier?: number;
        deliverableCount?: number;
        minimumIncrement?: number;
        minimumIncrementUsd?: number;
    };
    formatting?: {
        maxDecimalPlaces?: number;
        minDecimalPlaces?: number;
        isFractional?: boolean;
        fractionalParts?: number;
        fractionalGranularity?: 'WholeParts' | 'Cents';
    };
    exchange?: string;
    currencyCode?: string;
    sourceSystem?: string;
}
export interface MetadataV2StoreResponse {
    data: MetadataV2FromApi[];
    errors: SnexError[];
}

export const useSecurityMetadataV2 = create<MetadataV2Store>((set, get) => {
    return {
        loading: {},
        data: {},
        symbolLoaded: (qsi?: string) => !!get().data[qsi?.toUpperCase() || ''],
        loadData: async (qsi?: string, forceReload?: boolean) => {
            if (!qsi) return;
            const isLoading = get().loading[qsi.toUpperCase()] || false;
            if (!forceReload && (isLoading || get().symbolLoaded(qsi))) return; /* do not load if already loading or if data already exists */
            set((s) => ({ ...s, loading: { ...s.loading, [qsi.toUpperCase()]: true } })); /* set loading for qsi */
            try {
                const result = await SnexAxios.ApiGet<{ data: MetadataV2FromApi[] }>(Urls.securities.core.getV2Metadata(qsi)).run();
                const meta: SecurityMetadataV2 = { ...result?.data[0] };

                if (result?.data[0]) {
                    const infoResult = await SnexAxios.ApiGet<SecurityCompanyInfo>(Urls.securities.core.getCompanyInfo(qsi)).run();
                    if (infoResult) meta.info = { securityName: infoResult.securityName, description: infoResult.description, companyName: infoResult.companyName };
                }

                const _data = get().data;
                _data[qsi.toUpperCase()] = { ...meta, isInAlgolia: !!meta?.sourceSystem?.includes('algolia') };
                set((s) => ({ ...s, loading: { ...s.loading, [qsi.toUpperCase()]: false }, data: _data }));
            } catch (err: any) {
                ErrorLogger.RecordError(err as Error);
            }
        },
        getMetadataBySymbol: (qsi?: string) => get().data[qsi?.toUpperCase() || '']
    };
});

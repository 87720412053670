// @ts-strict-ignore
import { useSelectedAccountForOrderRequest } from 'components/AccountDropdown/Store/AccountSelectionStore';
import { TradeTicketAccountDropdown } from 'components/AccountDropdown/TradeTicketAccountDropdown';
import { Flex } from 'components/Flex';
import { QuantityInputSplit } from 'components/InputSplit/QuantityInputSplit';
import { SnexButton } from 'components/SnexButton';
import { T } from 'phoenix/assets/lang/T';
import { TradeActions } from 'phoenix/constants/Trade';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { FuturesAssetClass } from 'phoenix/models/AssetClasses/FuturesAssetClass';
import { useAssetClassMetaV2 } from 'phoenix/models/AssetClasses/useAssetClass';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { useSecurityMetadataV2 } from 'phoenix/stores/SecurityMetadataV2Store';
import { floatMath } from 'phoenix/util';
import React, { useCallback, useMemo, useState } from 'react';
import { TradeCancelHeader } from '../Headers/TradeCancelHeader';
import { TradeInputHeader } from '../Headers/TradeInputHeader';
import { GetTradeTicketQuote } from '../Shared/helpers';
import { ImmediateExecutionAdvisory } from '../Shared/ImmediateExecutionAdvisory';
import { TimeInForceSelect } from '../Shared/TimeInForceSelect';
import { BidAskButtons, BuyingPowerDisplayV2, MarketPriceDisplay, PriceInputSplit, TimeInForceSplit } from '../Shared/TradeFormComponents';
import { TradeInputValidationButton } from '../Shared/TradeInputValidationButton';
import { TradeTicketSection } from '../Shared/TradeTicketSection';
import { FuturesTradeTicketViewModel } from '../Store/TradeTicketViewModel';
import { useTradeTicketViewModel } from '../Store/useTradeTicketViewModel';

export const FuturesTradeInputPage = React.memo(() => {
    const priceInputFields = ['limitPrice', 'stopPrice'];
    const viewModel = useTradeTicketViewModel<FuturesTradeTicketViewModel>();
    const {
        awaitingConfirmation,
        displayOrderType,
        initialLimitPrice,
        initialQuantity,
        initialStopPrice,
        limitPrice,
        modifyingOrder,
        orderType,
        setViewModel,
        stopPrice,
        symbol,
        timeInForce,
        tradeAction
    } = viewModel;
    // Use quantity input key to force a remount of the quantity input component
    // such as when using "Sell All" button to override user input
    const [quantityInputKey, setQuantityInputKey] = useState<number>(0);
    // Same as above but when Bid/Ask buttons are clicked to "reset" price
    const [limitInputKey, setLimitInputKey] = useState<number>(0);
    const [stopInputKey, setStopInputKey] = useState<number>(0);
    const fsym = new FuturesSymbol(symbol);
    const logo = useSnexStore((s) => s.logos.bySymbol[fsym.baseContract]);
    const quote = GetTradeTicketQuote(symbol);
    const text = useText((t) => t);

    const [tickSizeError, setTickSizeError] = useState<string[]>([]);

    const allAccounts = useSnexStore((s) => s.accounts.all);
    const meta = useSecurityMetadataV2().getMetadataBySymbol(symbol);
    const { isFractional, tickSize } = { isFractional: meta?.formatting?.isFractional, tickSize: meta?.sizing?.minimumIncrement } || {};
    const { derivative, formatPrice, formatQuantity, getPriceFormatInfo } = useAssetClassMetaV2(symbol);
    const formatOptions = getPriceFormatInfo(meta)?.moneyFormatOptions;
    const selectedAccountNumber = useSelectedAccountForOrderRequest(FuturesAssetClass);
    const selectedAccount = useMemo(() => allAccounts?.data?.find((a) => a.accountNumber === selectedAccountNumber), [allAccounts, selectedAccountNumber]);
    const isSelectedAccountPending = selectedAccount?.accountStatusDesc?.toLowerCase().trim() === 'pending';
    // For the account dropdown. For time spreads, always show Buying Power (communicated to the AccountDropown via a balanceType of 'buy')
    const balanceType = derivative === 'time-spread' ? TradeActions.Buy.toLowerCase() : tradeAction.toLowerCase();
    const helperText = isFractional ? text.tradeTicket.input.futures.enforceFractional : text.tradeTicket.input.futures.enforceTickSize(tickSize);

    const handleFieldUpdate = (name: keyof FuturesTradeTicketViewModel, value: FuturesTradeTicketViewModel[keyof FuturesTradeTicketViewModel]) => {
        // If it's a price, and the value is divisible by the tick size, it's valid
        if (priceInputFields.includes(name)) {
            const isInputValid = !isNaN(value as number) && !floatMath(value as number, tickSize, (v, s) => v % s);
            const newTickSizeError = isInputValid ? [...tickSizeError.filter((x) => x !== name)] : [...tickSizeError, name];
            setTickSizeError(newTickSizeError);
        }

        setViewModel({ [name]: value });
    };

    const resolveValidationButton = () => {
        if (isSelectedAccountPending) {
            return (
                <SnexButton flavor={'not-allowed'} onClick={() => undefined}>
                    {T((s) => s.tradeTicket.misc.accountPending)}
                </SnexButton>
            );
        }

        return <TradeInputValidationButton />;
    };
    const handleAccountSelect = useCallback(() => setViewModel({ awaitingConfirmation: false }), [setViewModel]);

    return (
        <Flex column>
            {awaitingConfirmation ? (
                <TradeCancelHeader
                    logo={logo}
                    text={text.tradeTicket.input}
                    showLogo={modifyingOrder}
                    symbol={symbol}
                    title={symbol}
                    onCancel={() => setViewModel({ awaitingConfirmation: false, validateResponse: null })}
                />
            ) : (
                <TradeInputHeader canSellIfNotHeld={true} />
            )}
            <TradeTicketSection noBorder style={{ paddingTop: 20, paddingBottom: 10 }}>
                <TradeTicketAccountDropdown
                    assetFamily={FuturesAssetClass.family}
                    balanceType={balanceType}
                    defaultToFirst={'if-only-one'}
                    disabled={modifyingOrder}
                    isByAssetClass
                    onSelect={handleAccountSelect}
                    skipInitialSelect={modifyingOrder || !!selectedAccountNumber}
                    style={{ marginBottom: 15 }}
                    symbol={symbol}
                />
                {['stop', 'stoplimit'].includes(orderType) && (
                    <>
                        <PriceInputSplit
                            arrows
                            formatOptions={formatOptions}
                            formatter={(v: number) => formatPrice(v, meta)}
                            label={text.tradeTicket.input.stopPrice}
                            onValueChange={(v) => handleFieldUpdate('stopPrice', v)}
                            initialValue={initialStopPrice}
                            key={initialStopPrice}
                            step={tickSize}
                            showDollarSign={false}
                            {...{ ...(tickSizeError.includes('stopPrice') ? { error: true, helperText } : {}) }}
                        />
                        <BidAskButtons
                            bid={quote?.bid}
                            ask={quote?.ask}
                            askLabel={`${text.tradeTicket.input.ask}: ${FuturesAssetClass.formatPrice(quote?.ask, meta)}`}
                            bidLabel={`${text.tradeTicket.input.bid}: ${FuturesAssetClass.formatPrice(quote?.bid, meta)}`}
                            onValueChange={(v) => {
                                setViewModel({
                                    initialStopPrice: v,
                                    stopPrice: undefined
                                });
                                setStopInputKey(stopInputKey + 1);
                            }}
                        />
                    </>
                )}
                {['limit', 'stoplimit'].includes(orderType) && (
                    <>
                        <PriceInputSplit
                            arrows
                            formatOptions={formatOptions}
                            formatter={(v: number) => formatPrice(v, meta)}
                            initialValue={initialLimitPrice}
                            key={initialLimitPrice}
                            label={orderType === 'limit' ? text.tradeTicket.input.limitPrice : text.tradeTicket.input.stopLimitPrice}
                            onValueChange={(v) => handleFieldUpdate('limitPrice', v)}
                            step={tickSize}
                            showDollarSign={false}
                            {...{ ...(tickSizeError.includes('limitPrice') ? { error: true, helperText } : {}) }}
                        />
                        <BidAskButtons
                            bid={quote?.bid}
                            ask={quote?.ask}
                            askLabel={`${text.tradeTicket.input.ask}: ${FuturesAssetClass.formatPrice(quote?.ask, meta)}`}
                            bidLabel={`${text.tradeTicket.input.bid}: ${FuturesAssetClass.formatPrice(quote?.bid, meta)}`}
                            onValueChange={(v) => {
                                setViewModel({
                                    initialLimitPrice: v,
                                    limitPrice: undefined
                                });
                                setLimitInputKey(limitInputKey + 1);
                            }}
                        />
                    </>
                )}
                <QuantityInputSplit
                    accountNumber={selectedAccountNumber}
                    arrows={true}
                    formatter={formatQuantity}
                    initialValue={initialQuantity}
                    key={quantityInputKey}
                    label={T((t) => t.general.contracts(0))}
                    liquidateOnSellAll={false}
                    onBuySellAll={() => setQuantityInputKey(quantityInputKey + 1)}
                    onValueChange={(v) => handleFieldUpdate('quantity', v)}
                    showDollarSign={false}
                    showPosition={true}
                    showSellAll={true}
                    step={1} // Used for contracts only, 1 contract per tick
                    symbol={symbol}
                    tradeAction={tradeAction}
                />

                {orderType !== 'market' ? <TimeInForceSelect onValueChange={(v) => handleFieldUpdate('timeInForce', v)} /> : null}
                <MarketPriceDisplay
                    ask={formatPrice(quote?.ask, meta)}
                    bid={formatPrice(quote?.bid, meta)}
                    hideAskBid={orderType !== 'market'}
                    loading={quote?.loading}
                    price={formatPrice(quote?.price, meta)}
                    text={text.tradeTicket.input}
                />
            </TradeTicketSection>
            <ImmediateExecutionAdvisory
                action={tradeAction}
                limitPrice={limitPrice}
                orderType={displayOrderType}
                quote={quote}
                stopPrice={stopPrice}
                text={text.tradeTicket.input.advisories}
            />
            <TradeTicketSection>{resolveValidationButton()}</TradeTicketSection>
            <TradeTicketSection noBorder>
                <BuyingPowerDisplayV2 accountNumber={selectedAccountNumber} symbol={symbol} />
            </TradeTicketSection>
        </Flex>
    );
});

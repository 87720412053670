// @ts-strict-ignore
import { FractionalGranularity, SecurityMetadata } from 'phoenix/redux/models/Securities/SecurityMetadata';
import { Clamp, GetDecimalPlaces, SetMaximumDecimalPlaces } from 'phoenix/util/MoreMath';

export const isNotANumber = (num: number) => isNaN(num) || typeof num !== 'number';
export const RemoveZeroCents = (num: string) => num?.replace(/\.00+$/g, ''); // Remove .00 (or possibly more zeroes) from the end

const toMoney = (value: number, defaultValue = '$---.--', signDisplay: 'auto' | 'never' | 'always' | 'exceptZero' = 'auto', showDecimals = true, decimalPlaces = 2) => {
    const symbol = signDisplay === 'never' ? '' : '$';

    if (isNotANumber(value)) return signDisplay === 'never' ? defaultValue?.replace('$', '') : defaultValue;
    return value < 0 ? `-${symbol}${toCommas(-value, showDecimals, decimalPlaces)}` : `${symbol}${toCommas(value, showDecimals, decimalPlaces)}`;
};

type ToLocaleMoneyProps = {
    decimalPlaces?: number;
    defaultValue?: string;
    locale?: string;
    showAll?: boolean;
    style?: 'currency' | 'decimal';
    /** @deprecated Please use style */ signDisplay?: boolean;
    currency?: string;
    value: number;
};

const toLocaleDecimal = ({
    decimalPlaces = 2,
    defaultValue = '$---.--',
    locale = 'en-US',
    showAll = true,
    style = 'currency',
    signDisplay = true,
    currency = 'USD',
    value
}: ToLocaleMoneyProps) => {
    const precisionEffective = decimalPlaces === 1 ? 2 : Math.min(decimalPlaces, 20);

    if (isNotANumber(value)) return signDisplay ? defaultValue : defaultValue.replace('$', '');

    const formatOptions = signDisplay
        ? {
              currency,
              minimumFractionDigits: showAll ? precisionEffective : 0,
              maximumFractionDigits: precisionEffective,
              style: 'currency'
          }
        : {
              minimumFractionDigits: showAll ? precisionEffective : 0,
              maximumFractionDigits: precisionEffective,
              style: 'decimal'
          };

    return value.toLocaleString(locale || 'en-US', formatOptions);
};

/** @deprecated #web please localize this function, as navigator is not available in React Native  */
const toMoneyFullDecimal = (value: number, defaultValue = '$---.--', signDisplay: 'auto' | 'never' | 'always' | 'exceptZero' = 'auto') => {
    if (isNotANumber(value)) return defaultValue;
    const numberArray = Intl.NumberFormat(navigator.language, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 6
    })
        .format(value)
        .toString()
        .split('.');
    const decimalPart = numberArray[1] ?? '00';
    const wholePart = toMoney(value, defaultValue, signDisplay, true).split('.')[0];

    return `${wholePart}.${decimalPart}`;
};

const _determineAbbreviation = (value: number) => {
    const a = Math.abs(value);
    if (a > 1000000000000) return { suff: 'T', factor: 1000000000000 };
    else if (a > 1000000000) return { suff: 'B', factor: 1000000000 };
    else if (a > 1000000) return { suff: 'M', factor: 1000000 };
    else if (a > 1000) return { suff: 'K', factor: 1000 };
    else return { suff: '', factor: 1 };
};

const toNumberAbbrev = (value: number, defaultValue = '---', signDisplay: 'auto' | 'never' | 'always' | 'exceptZero' = 'auto') => {
    if (isNotANumber(value)) return defaultValue;
    const { suff, factor } = _determineAbbreviation(value);
    return `${FormatNumber.toCommas(value / factor)}${suff}`;
};

const toMoneyAbbrev = (value: number, defaultValue = '$---.--') => {
    if (isNotANumber(value)) return defaultValue;
    const { suff, factor } = _determineAbbreviation(value);
    return `${FormatNumber.toMoneyOpt2(value / factor, {
        decimalPlaces: 1
    })}${suff}`;
};

/** @deprecated Options arg doens't do anything; please use toMoney instead */
const toMoneyOpt = (value: number, defaultValue = '$---.--', options: any) => toMoney(value, defaultValue);

// AA -- IMO, this should be the one we use for everything since it finally uses an option param
export type MoneyFormatOptions = Partial<{
    abbreviate: boolean;
    abbreviationDecimalPlaces: number;
    abbreviationUnitSpace: boolean;
    allowNegative: boolean;
    decimalPlaces: number; // e.g., 4
    defaultValue: string;
    fractionalGranularity: FractionalGranularity;
    fractionalParts: number;
    fractionalSeparator: string;
    hideCurrencySymbol: boolean;
    maxDecimalPlaces: number;
    /** @deprecated Use allowNegative */ min: number; // Minimum value (usually 0, null/undefined if negative values are allowed)
    minDecimalPlaces: number;
    showDecimals: boolean;
    signDisplay: 'auto' | 'never' | 'always' | 'exceptZero';
    suffix?: string;
    tickSize: number; // e.g., 0.0001
    useFractional: boolean;
}>;

const isValidDecimalPlaceQuantity = (n: number) => typeof n === 'number' && !isNaN(n) && n >= 0;

const interpretDecimalOptions = (options: MoneyFormatOptions): Partial<{ minPlaces: number; maxPlaces: number }> => {
    if (options.showDecimals === false) return { minPlaces: 0, maxPlaces: 0 };

    // If defined, use min and max
    if (isValidDecimalPlaceQuantity(options.minDecimalPlaces)) return { minPlaces: options.minDecimalPlaces, maxPlaces: options.maxDecimalPlaces };

    // Otherwise use explict value. If not provided, use 2
    const explicit = isValidDecimalPlaceQuantity(options.decimalPlaces) ? options.decimalPlaces : 2;
    return { minPlaces: explicit, maxPlaces: explicit };
};

const clampDecimalPlaces = (value: number, minDecimalPlaces: number, maxDecimalPlaces?: number): string => {
    // TODO -- Do this with math?

    // Trim down to maximum decimal places (if provided), chopping off excess zeroes
    let out = isValidDecimalPlaceQuantity(maxDecimalPlaces)
        ? SetMaximumDecimalPlaces(value, Clamp(maxDecimalPlaces, 0, 100)).toFixed(maxDecimalPlaces)
        : value.toString();

    const hasDecimalPoint = /\.\d+/.test(out);
    if (hasDecimalPoint) out = out.replace(/0*$/, '');

    // If doing this brought us below the number of decimal places, add some zeroes back on with .toFixed
    const outDecimalPlaces = hasDecimalPoint ? out.match(/\.\d*$/)?.[0]?.length - 1 : 0;
    if (outDecimalPlaces < minDecimalPlaces) return parseFloat(out).toFixed(minDecimalPlaces);

    // Otherwise, we're good to go with what we first came up with
    return out;
};

const clampDecimalPlacesPerOptions = (value: number, options: MoneyFormatOptions): string => {
    const { minPlaces, maxPlaces } = interpretDecimalOptions(options);
    return clampDecimalPlaces(value, minPlaces, maxPlaces);
};

// TODO -- Merge this into toMoneyOpt2
const toMoneyOpt2MoneyDriver = (value: number, options: MoneyFormatOptions) => {
    const { signDisplay, showDecimals } = options;

    const defaultValue = options.defaultValue || '$---.--'; // <-- TODO -- probably not a good idea but it's from the existing code, can look at removing later
    const symbol = signDisplay === 'never' ? '' : '$';

    const toCommasOpt = (val: number): string => {
        if (isNotANumber(val)) return `---${showDecimals ? '.--' : ''}`;

        const decimal: string = clampDecimalPlacesPerOptions(val, options);

        const [int, fractional] = decimal.split('.');
        const intCommas = int.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        return fractional ? `${intCommas}.${fractional}` : intCommas;
    };

    if (isNotANumber(value)) return signDisplay === 'never' ? defaultValue?.replace('$', '') : defaultValue;
    return value < 0 ? `-${symbol}${toCommasOpt(-value)}` : `${symbol}${toCommasOpt(value)}`;
};

export const toMoneyOpt2 = (value: number, options: MoneyFormatOptions = {}): string => {
    let out: string = options?.defaultValue || '';

    if (options?.useFractional && options?.fractionalParts) {
        return toFractional2(value, options?.tickSize, options?.fractionalParts, {
            separator: options?.fractionalSeparator || ' ',
            granularity: options?.fractionalGranularity
        });
    } else if (options?.abbreviate) {
        const { suff, factor } = _determineAbbreviation(value);
        if (factor === 1) out = toMoneyOpt2MoneyDriver(value, options);
        else {
            const space = options?.abbreviationUnitSpace ? ' ' : '';
            out = toMoneyOpt2MoneyDriver(value / factor, options) + space + suff;
        }
    } else {
        out = toMoneyOpt2MoneyDriver(value, options);
    }

    if (options?.hideCurrencySymbol) out = out.replace('$', '');
    return `${out}${options?.suffix || ''}`;
};

/** @deprecated This function requires the input to be a whole number representation of the percent. Please use toPercent2, which considers 100% to be 1. */
export const toPercent = (value: number, signDisplay: 'never' | 'exceptZero' | 'auto' | 'always' = 'exceptZero', minimumFractionDigits = 2) => {
    if (isNotANumber(value)) return '--.--%';
    return `${toCommas(value, minimumFractionDigits >= 2, minimumFractionDigits)}%`;
};

export const toPercent2 = (value: number, signDisplay: 'never' | 'exceptZero' | 'auto' | 'always' = 'exceptZero', minimumFractionDigits = 2) => {
    if (isNotANumber(value)) return '--.--%';
    return `${toCommas(value * 100, minimumFractionDigits >= 2, minimumFractionDigits)}%`;
};

// Credit: https://stackoverflow.com/a/55556258
export const toCommas = (value: number, showDecimals = false, decimalPlaces = 2) => {
    if (isNotANumber(value)) return `---${showDecimals ? '.--' : ''}`;
    const decimal = toDecimal(value, showDecimals ? decimalPlaces : 0);
    const [int, fractional] = decimal.split('.');
    const intCommas = int.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return fractional ? `${intCommas}.${fractional}` : intCommas;
};

/** @deprecated This function does not declare a consistent return type. Please use toDecimal2 instead */
const toDecimal = (value: number, places = 2, returnNumber?: boolean): any => {
    if (isNotANumber(value)) return '---.--';
    // toFixed removes sign from -0
    const signedString = Object.is(value, -0) ? `-${value.toFixed(places)}` : value.toFixed(places);

    return returnNumber ? parseFloat(value.toFixed(places)) : signedString;
};

const toDecimal2 = (value: number, places = 2): any => {
    if (isNotANumber(value)) return '---.--';
    return parseFloat(value.toFixed(places));
};

export const getDecimalPlacesFromTickSize = (tickSize: number | string, fallback = 2): number => {
    if (!tickSize) return fallback;

    const scientificPattern = /[eE]-?(\d+)/;

    let s = tickSize.toString();
    let decimalPlaces = 0;
    const groups = s.match(scientificPattern);

    if (groups) {
        decimalPlaces += parseInt(groups[1]);
        s = s.slice(0, s.search(scientificPattern));
    }

    if (s.includes('.')) decimalPlaces += s.split('.')[1]?.length;

    return decimalPlaces;
};

const toPhone = (value: number | string) => {
    if (!value) return '';
    const str = value.toString().replace(/[^\d]/g, '');
    if (str.length === 10) {
        return str.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    } else if (str.length === 7) return str.replace(/(\d{3})(\d{4})/, '$1-$2');
    else return str;
};

// https://blog.davidjs.com/2018/07/convert-exponential-numbers-to-decimal-in-javascript/
export function convertExponentialToDecimal(exponentialNumber: number): number | string {
    // sanity check - is it exponential number
    const str = exponentialNumber.toString();
    if (str.indexOf('e') !== -1) {
        const exponent = parseInt(str.split('-')[1], 10);
        // Unfortunately I can not return 1e-8 as 0.00000001, because even if I call parseFloat() on it,
        // it will still return the exponential representation
        // So I have to use .toFixed()
        const result = exponentialNumber.toFixed(exponent);
        return result;
    } else {
        return exponentialNumber;
    }
}

// This function exists to solve the problem of floating point number errors when doing calculations in javascript
// More info here: https://floating-point-gui.de/basic/
// TODO -- Adopt a decimal datatype such as BigDecimal to negate the need for this workaround
export const floatMath = (val: number, step: number, equation: (v: number, s: number) => number): number | null => {
    if (isNotANumber(val) || isNotANumber(step)) return null;
    const arg1Dec = (convertExponentialToDecimal(val).toString().split('.')[1] || '').length;
    const arg2Dec = (convertExponentialToDecimal(step).toString().split('.')[1] || '').length;
    const decCount = Math.max(arg1Dec, arg2Dec);
    const valInt = parseInt(val.toFixed(decCount).replace('.', ''));
    const stepInt = parseInt(step.toFixed(decCount).replace('.', ''));
    return equation(valInt, stepInt) / Math.pow(10, decCount);
};

// Return string should be parsable as a number, ie no commas
export const addTrailingZeroes = (value: number, decimalPlaces: number): number | string => {
    if ([NaN, null, undefined].includes(value)) return value;
    const fractional = getDecimalPlacesFromTickSize(value, 0);
    const difference = decimalPlaces - fractional;
    const str = `${value}`.indexOf('.') === -1 ? `${value}.` : `${value}`;
    return difference ? str.padEnd(`${str}`.length + difference, '0') : value;
};

const msInHour = 1000 * 60 * 60;
const withLeadingZero = (n: number) => (n < 10 ? `0${n}` : n);
const msToClockTime = (ms: number) => {
    const hoursExact = ms / msInHour;
    const minutesExact = 60 * (hoursExact % 1);
    const secondsExact = 60 * (minutesExact % 1);
    const [hours, minutes, seconds] = [hoursExact, minutesExact, secondsExact].map(Math.floor);

    if (isNaN(ms) || ms < 0 || typeof ms !== 'number') return '--:--';
    if (hours) return `${hours}:${withLeadingZero(minutes)}:${withLeadingZero(seconds)}`;
    return `${minutes}:${withLeadingZero(seconds)}`;
};

export type FractionalFormatOptions = {
    /** @deprecated Please use fractionalParts instead */ priceFormat?: number; // Naming convention from Gain, number by which to multiply decimal portion
    fractionalParts?: number; // Number of fractional parts in one unit (e.g., 32 -> each tick is a 1/32nd). Effectively, priceFormat / 100
    separator?: string; // Character by which to separate whole number value from fractional value
    tickSize: number; // required to determine whether to render trailing .0 on whole fractional values. TODO -- example values (i.e., is this 0.0025, 0.0001, or 4?)
    value: number;
};

// Converts a decimal number into a "fractional" number. For example, "1234.567" would become "1,234 18.144", read "1,234 and 18.144 32nds",
// assuming `fractionalParts` is 32.
export const toFractional2 = (
    value: number, // The number to format
    tickSize: number, // The tick size, e.g., 0.0025. This defines the valid price multiples that may be used. We use it here to figure out how many decimal places to show
    fractionalParts: number, // The number of fractional parts in one unit. In the example above (and in many scenarios), this value is 32
    options: Partial<{
        separator: string; // Usually a space is used to separate the leading and trailing figures, but this allows you to customize it
        granularity: FractionalGranularity;
        defaultValue: string;
    }>
): string => {
    if ([null, undefined, NaN].includes(value)) return options?.defaultValue || '---.--';
    const { separator, granularity } = options || {};
    const useCentGranularity = granularity === 'Cents';

    // In the output "777 88.9", "777" will be termed the "leading figure" and "88.9" will be the "trailing figure"
    // It might be helpful to read the above as "777 and 88.9 thirty-seconds" (assuming fractionalParts is 32), which
    // it commonly is.

    // Given input 123.4567, "123" is the integer part, "4567" is the decimal part. Doing this with string manip
    // since otherwise we run the risk of precision issues
    const valueString = value.toString();
    let leadingFigure = 0;
    let decimalPart = 0;
    const decimalIdx = valueString.indexOf('.');
    if (decimalIdx < 0) {
        // If this is a whole number
        leadingFigure = parseInt(valueString);
    } else if (useCentGranularity) {
        const sliceIdx = decimalIdx + 3;
        leadingFigure = parseFloat(valueString.slice(0, sliceIdx)); // e.g., in "7.375", this would be "7.37"
        decimalPart = parseFloat('.' + valueString.slice(sliceIdx)); // e.g., in "7.375", this would be "5", and then we divide it by 10 to make it 0.5
    } else {
        leadingFigure = parseFloat(valueString.slice(0, decimalIdx)); // e.g., in "7.375", this would be "7"
        decimalPart = parseFloat(valueString.slice(decimalIdx)); // e.g., in "7.375", this would be ".375"
    }

    // Figure out how many fractional parts the decimal part represents. For example, if fractionalParts is 32, 0.4567 would
    // represent 14.6144 parts (that is, 32 * 0.4567). At this point we could return "123 14.6144" but a few regarding
    // around formatting remain
    const trailingFigure = fractionalParts * (decimalPart || 0);

    // Figure out how many decimal places we permit in the trailing part. We can figure this out by multiplying the tick
    // size (which is in decimal) by the fractional parts (giving us the unit change per the fractional size), and observe
    // its number of decimals
    const unitChange = tickSize * fractionalParts; // e.g. corn, where ts = 0.0025 and fp = 8, unitChange would be 0.02 (two decimal places)
    let decimalPlaces = GetDecimalPlaces(unitChange); // <-- TODO -- this function is based on stringification and should be optimized using math
    if (useCentGranularity) decimalPlaces = Math.max(0, decimalPlaces - 2);
    let trailingFigureString = trailingFigure.toFixed(decimalPlaces); // <-- could be "0.0" if fractionalPart == 0

    // Finally, the business also says that the trailing figure of the number must be adequately padded with zeroes.
    const trailingLength = (decimalPlaces ? decimalPlaces + 1 : 0) + fractionalParts.toString().length;
    trailingFigureString = trailingFigureString.padStart(trailingLength, '0');

    // Glue the leading and trailing figures together with the separator
    return `${FormatNumber.toCommas(leadingFigure, useCentGranularity, 2)}${separator || ' '}${trailingFigureString}`;
};

export function toFractional({ priceFormat, fractionalParts, separator = ' ', tickSize, value }: FractionalFormatOptions): string {
    if ([null, undefined, NaN].includes(value)) return '---.--';

    // See details in that fn. Not 100% confident this is a reliable solution
    // But it's way easier than parsing every futures symbol to determine which formatter to use
    if (priceFormat === 800) return toEighthCentFractional({ value });

    // TODO -- renamed this to trailingDecimalLength, not sure if that's the right name though. Explanation for each of these
    // consts would be really helpful
    const trailingDecimalLength = `${tickSize * priceFormat}`.split('.')?.[1]?.length || 0;

    // TODO -- More comments for each step in this process
    const sign = Math.sign(value);
    const integer = Math.floor(Math.abs(value));
    const absValue = Math.abs(value);
    const decimalRaw = floatMath(absValue, integer, (a, b) => a - b) || 0;
    const fractionalRaw = decimalRaw * priceFormat;
    let fractionalRawInteger = Math.floor(fractionalRaw);
    // Extend to the length of trailing decimals, e.g. 1/8 of 1/32nd pt will be .125 min tick (3 decimal places)
    const fractionalDecimalPortion = floatMath(Number(fractionalRaw.toFixed(trailingDecimalLength || 2)), fractionalRawInteger, (a, b) => a - b) || '.0';
    if (fractionalDecimalPortion === 1) fractionalRawInteger++; // Fix rounding error 1.99999 etc
    const fractionalDecimal = `${fractionalDecimalPortion}`.replace('0.', '.').padEnd(trailingDecimalLength ? trailingDecimalLength + 1 : 0, '0');
    const fractionalInteger = `${fractionalRawInteger}`.padStart(2, '0');
    const result = `${sign === -1 ? '-' : ''}${integer}${separator}${fractionalInteger}${trailingDecimalLength ? fractionalDecimal : ''}`;
    return result;
}

/** @deprecated Please use parseFractional */
export function fromFractional({ priceFormat, separator = ' ', value }: { priceFormat?: number; separator?: string; value: string }): number {
    try {
        if (!priceFormat) throw new Error('priceFormat is required');
        if (priceFormat === 800) return fromEighthCentFractional({ separator, value });
        const [integer, fractional] = value.trim().split(separator);
        const decimal = fractional ? Number(fractional) / priceFormat : 0;
        const result = Number(integer) + decimal;

        return result;
    } catch (e: unknown) {
        console.log(`Error converting from fractional value. ${e}`);
    }
}

// Five specific futures options contracts use this formatting that no other contracts do: (ZS, ZC, ZW, KE, MWE)
// They trade at 1/8 of 1 cent. Formatting looks like this: 3.45 6 = $3.45 and 6/8 of a cent
export function toEighthCentFractional({ separator = ' ', value }: { separator?: string; value: number }) {
    if ([null, undefined, NaN].includes(value)) return '---.--';
    const decimalIndex = `${value}`.indexOf('.');
    const dollar = `${value}`.slice(0, decimalIndex + 3) || 0;
    const fractionalRaw = floatMath(value, Number(dollar), (a, b) => a - b) || 0;
    const fractional = Math.round(fractionalRaw * 800);

    return `${dollar} ${fractional}`;
}

export function fromEighthCentFractional({ separator = ' ', value }: { separator?: string; value: string }): number {
    try {
        const [decimal, fractional] = value.trim().split(separator);
        const fractionalDecimal = Number((Number(fractional) / 800).toFixed(5)); // 1/8 cent is .00125; there should never be more digits than that
        const result = floatMath(Number(decimal), fractionalDecimal, (a, b) => a + b) || 0;
        return result;
    } catch (e: unknown) {
        console.log(`Error converting from eighth cent fractional value. ${e}`);
    }
}

// Convencience method for returning fractional or decimal values for futures
/** @deprecated -- Please use FuturesAssetClass.formatPrice(...) */
export function toFuturesFormat({ isFractional, priceFormat, tickSize, value }: FractionalFormatOptions & { isFractional: boolean }): string {
    return isFractional
        ? toFractional({ priceFormat, tickSize, value })
        : toMoneyOpt2(value, { decimalPlaces: priceFormat, defaultValue: '---.--', hideCurrencySymbol: true });
}

// Note: dev meeting pending to discuss better organization of this whole file
export const parseFractional = (raw: string, options: MoneyFormatOptions): number => {
    if (!raw) return null;
    // Sample input: 6 3.25
    const [leadingFigure, trailingFigure]: string[] = raw.split(options.fractionalSeparator || ' ');
    if (!trailingFigure) return parseDecimal(raw, options);
    let trailingFigureValue = parseFloat(trailingFigure) / (options.fractionalParts || 32);
    if (options?.fractionalGranularity === 'Cents') trailingFigureValue /= 100;
    let out = floatMath(Math.abs(parseFloat(leadingFigure)), trailingFigureValue, (a, b) => a + b) || 0;
    // TODO: Remove when min prop is fully deprecated
    const legacyAllowNegative = [null, undefined].includes(options?.min) || options?.min < 0;
    const allowNegative = legacyAllowNegative || options?.allowNegative;

    if (
        // Support negative values if allowed
        (allowNegative && (parseDecimal(leadingFigure) < 0 || Object.is(leadingFigure, '-0'))) ||
        // or convert to positive if the value is negative and negatives are not allowed
        (!allowNegative && parseDecimal(leadingFigure) < 0)
    ) {
        out = out * -1;
    }

    // console.log({ _me: 'fractional parse', leadingFigure, trailingFigure, out, parts: options.fractionalParts })
    return out;
};

export const parseDecimal = (raw: string, options?: MoneyFormatOptions): number => {
    // TODO: Remove when min prop is fully deprecated
    const legacyAllowNegative = [null, undefined].includes(options?.min) || options?.min < 0;
    const allowNegative = legacyAllowNegative || options?.allowNegative;

    let cleaned = allowNegative
        ? raw?.replace(/(?!^)-|[^\d.-]/g, '') // allow hyphens at the beginning of the string to allow for negative values
        : raw?.replace(/[^\d.]/g, ''); // replace all non-numeric characters including hyphens

    if (!options?.showDecimals) cleaned = cleaned?.replace(/\..*/g, '');
    const out = parseFloat(cleaned);
    // console.log({ _me: 'decimal parse', raw, cleaned, out });
    return out;
};

// parse either fractional or decimal
export const parseByMetadata = ({ meta, options, raw }: { meta: SecurityMetadata; options: MoneyFormatOptions; raw: string }): number => {
    const { isFractional = false } = meta || {};
    return isFractional ? parseFractional(raw, options) : parseDecimal(raw, options);
};

export const removeDecimalsIfZero = (raw: string) => {
    const parts = raw.split('.');
    if (parts.length === 1) return raw;
    if (parseInt(parts[1]) === 0) return parts[0];
    let nonZerosReached = false;
    const newDecimalPart = [];
    for (let i = parts[1].length - 1; i >= 0; i--) {
        if (Number(parts[1][i]) !== 0) nonZerosReached = true;
        if (nonZerosReached) {
            // console.log('non zero reached: ', parts);
            newDecimalPart.unshift(parts[1][i]);
        }
    }
    parts[1] = newDecimalPart.join('');
    if (newDecimalPart?.length) return parts.join('.');
    return raw;
};

export const FormatNumber = {
    addTrailingZeroes,
    floatMath,
    fromFractional,
    getDecimalPlacesFromTickSize,
    isNotANumber,
    msToClockTime,
    toCommas,
    toDecimal,
    toFractional,
    toEighthCentFractional,
    toFuturesFormat,
    toDecimal2,
    /** @deprecated underlying .toLocaleString is implemented differently depending on the platform */ toLocaleDecimal,
    /** @deprecated Please use .toMoneyOpt2 */ toMoney,
    toMoneyAbbrev,
    toNumberAbbrev,
    toMoneyFullDecimal,
    toMoneyOpt,
    toMoneyOpt2,
    toPercent,
    toPercent2,
    toPhone,
    parseFractional,
    parseDecimal,
    clampDecimalPlaces,
    clampDecimalPlacesPerOptions,
    removeDecimalsIfZero
};

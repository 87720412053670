// @ts-strict-ignore
import { GetConfig } from 'phoenix/constants';
import { QuoteSelector, StandardQuote } from 'phoenix/constants/ReduxSelectors';
import { ApiTradeRequest } from 'phoenix/models/ApiTradeRequest';
import { GetAssetClassForSecurity } from 'phoenix/models/AssetClasses/useAssetClass';
import { QualifiedSecurityId } from 'phoenix/models/QualifiedSecurityId';
import { TradeableSecurityType, OptionSymbol, Order, TradeRequest, TradeAction } from 'phoenix/redux/models';
import { GetFromSnexStore } from 'phoenix/redux/util/GetFromSnexStore';
import { BuyingPowerStore_GetByAccount } from 'phoenix/stores/BuyingPowerStore';
import { usePositionsStore } from 'phoenix/stores/PositionsStore';
import { useSecurityMetadataV2 } from 'phoenix/stores/SecurityMetadataV2Store';
import { Sum } from 'phoenix/util/ArrayMutations';
import { ErrorLogger } from 'phoenix/util/ErrorLogger';
import { GetSecurityTypeFromStore } from 'phoenix/util/IsMutualFund';
import { GetXstreamStore } from 'phoenix/xstream/XstreamProvider';

/**
 * For equity positions only:
 * If you sell all but the fractional part (the "residual") of your position, that remaining residual
 * will be closed after a day or two. e.g., If you're long 5.5 shares and sell 5, it will trigger a residual
 * sell of your remaining 0.5 shares. Not if you sell just 4 shares though-- it has to be all but the
 * residual part
 */
const equityTypes: Set<TradeableSecurityType> = new Set(['adr', 'equity', 'etf']);
export const OrderWillTriggerResidualSettlement = (trade: Partial<ApiTradeRequest>) => {
    // TODO -- Make this require a full ApiTradeRequest later on, only partial now for backwards compat
    const type = GetSecurityTypeFromStore(trade.securityId);
    if (!equityTypes.has(type)) return false;

    const secId = QualifiedSecurityId.Parse(trade.securityId)?.id;
    const positions = usePositionsStore.getState()?.find({ qsi: secId, accountNumber: trade.accountNumber });
    const sharesHeld = Math.abs(Sum(positions?.map((p) => p.quantity))); // might be contracts, bonds, etc. but w/e
    const diff = Math.abs(trade.quantity - sharesHeld);
    return diff > 0 && diff < 1;
};

export const IsInvalidStopOrder = (trade: Partial<ApiTradeRequest>): boolean => {
    const qsi = trade.securityId;
    const isOption = OptionSymbol.IsOptionSymbol(qsi);
    if (!['stop', 'stoplimit'].includes(trade.orderType)) return false;
    // #102367 Disable stop order validation for futures
    if (GetAssetClassForSecurity(qsi)?.family === 'futures') return false;

    const restfulState = GetConfig().Store.getState();
    const streamingState = GetXstreamStore().getState();
    // Get the rest quote price...
    const restMarketPrice = isOption ? restfulState.options?.quotesByOsi[qsi]?.data?.price : restfulState?.securityQuote?.bySymbol[qsi]?.data?.price;
    // Get the stream quote price...
    const streamMarketPrice = isOption ? streamingState.options[qsi]?.price : streamingState?.quotes[qsi]?.price;
    // Use the stream price, if no stream price use the rest price
    const marketPrice = streamMarketPrice || restMarketPrice || Infinity;
    return trade.action === 'Buy' ? trade.orderPrice < marketPrice : trade.orderPrice > marketPrice;
};

export const GetTradeAppropriateOrderTypeFromOrder = (order: Order): 'market' | 'limit' | 'stop' | 'stoplimit' => {
    if (order.limitPrice && !order.stopPrice) return 'limit';
    else if (!order.limitPrice && !order.stopPrice) return 'market';
    else if (order.limitPrice && order.stopPrice) return 'stoplimit';
    else if (!order.limitPrice && order.stopPrice) return 'stop';
    else return 'market';
};

const getEffectiveUnitPrice = (trade: ApiTradeRequest, marketPrice: number): number => {
    switch (trade.orderType) {
        case 'market':
            return marketPrice;
        case 'stoplimit':
            return trade?.stopLimitPrice;
        default:
            return trade?.orderPrice;
    }
};

export const ExceedsUsersMaxNotionalValue = (symbol: string, trade: ApiTradeRequest): boolean => {
    // For now excluding multileg
    if (trade?.leg2Action) return false;

    const ac = GetAssetClassForSecurity(symbol);
    if (!trade?.action?.toLowerCase()?.includes('buy') || ac.family === 'futures' || ac.type === 'mutual-fund') return false;

    const myInfo = GetConfig().Store.getState().user.myInfo;
    const marketPrice = QuoteSelector(symbol)(GetConfig().Store.getState())?.price || 0;
    const isOption = OptionSymbol.IsOptionSymbol(symbol);
    const osi = isOption ? new OptionSymbol(symbol).toOsiSymbol() : null;
    const meta = GetConfig().Store.getState().securities.bySymbol[osi]?.metadata?.data; // TODO: Pass in meta as a parameter

    const purchasePrice = (() => {
        if (trade.quantityQualifier === 'EvenDollar') return trade.quantity;

        return meta?.deliverableCount * trade?.quantity * getEffectiveUnitPrice(trade, marketPrice);
    })();

    if (myInfo?.data?.maxNotionalValue && myInfo?.data?.maxNotionalValue < purchasePrice) return true;

    return false;
};

export const ExceedsUsersMaxNotionalValueV2 = (symbol: string, trade: ApiTradeRequest): boolean => {
    // For now excluding multileg
    if (trade?.leg2Action) return false;

    const ac = GetAssetClassForSecurity(symbol);
    if (!trade?.action?.toLowerCase()?.includes('buy') || ac.family === 'futures' || ac.type === 'mutual-fund') return false;

    const myInfo = GetConfig().Store.getState().user.myInfo;
    const marketPrice = QuoteSelector(symbol)(GetConfig().Store.getState())?.price || 0;
    const isOption = OptionSymbol.IsOptionSymbol(symbol);
    const osi = isOption ? new OptionSymbol(symbol).toOsiSymbol() : null;
    const meta = useSecurityMetadataV2.getState().getMetadataBySymbol(osi); // TODO: Pass in meta as a parameter

    const purchasePrice = (() => {
        if (trade.quantityQualifier === 'EvenDollar') return trade.quantity;

        return meta?.sizing?.deliverableCount * trade?.quantity * getEffectiveUnitPrice(trade, marketPrice);
    })();

    if (myInfo?.data?.maxNotionalValue && myInfo?.data?.maxNotionalValue < purchasePrice) return true;

    return false;
};

export const VerifyUserCanAffordTrade = (trade: ApiTradeRequest, permitShorts: boolean): boolean => {
    const qsi = trade.securityId;
    const ac = GetAssetClassForSecurity(qsi);

    // Return true for multi-leg options, upstream will figure out bp/margin requirements.
    if (trade?.leg2Action) return true;

    // Futures can be sold naked
    if (ac.family === 'futures') return true;

    // If the user is permitted for DVP, allow without to spend whatever they want
    if (GetFromSnexStore((s) => s.user.myInfo.data?.isDvp)) return true;

    // For short sells, anything goes!
    if (permitShorts && trade.action === 'Sell') return true;

    // We do permit apparent short sells for calls though, since it might be a covered call-- the API will tell us
    // We also let the user sell puts whenever they want
    const isOption = ac.derivative === 'option';
    if (trade.action === 'Sell' && isOption) return true;
    if (trade.action === 'Liquidate') return true;

    const marketPrice = GetFromSnexStore(QuoteSelector(qsi))?.price || 0; // TODO -- use XStream

    const positions = usePositionsStore.getState()?.find({ qsi, accountNumber: trade.accountNumber });
    const marketValueShares = Sum(positions?.map((p) => p.quantity));
    const osi = isOption ? new OptionSymbol(qsi).toOsiSymbol() : null;
    const meta = isOption ? GetConfig().Store.getState().securities.bySymbol[osi]?.metadata?.data : GetConfig().Store.getState().securities.bySymbol[qsi]?.metadata?.data;

    // For buys, compare cost in dollars
    if (trade.action === 'Buy') {
        const buyingPowerObject = BuyingPowerStore_GetByAccount(trade.accountNumber);
        console.log({ buyingPowerObject });
        const buyingPower = (isOption ? buyingPowerObject?.optionsBuyingPower : buyingPowerObject?.buyingPower) || 0;

        try {
            const purchasePrice = (() => {
                if (trade.quantityQualifier === 'EvenDollar') return trade.quantity;
                const effectiveUnitPrice = getEffectiveUnitPrice(trade, marketPrice);
                return meta?.deliverableCount * trade.quantity * effectiveUnitPrice;
            })();

            const longBuy = purchasePrice ? buyingPower >= purchasePrice : true;

            // Allow buying up to quantity of shares the account is currently short (negative)
            const shortBuyBack = marketValueShares < 0 && trade.quantity <= Math.abs(marketValueShares);
            const canAfford = !!trade.orderId || longBuy || shortBuyBack;

            if (!canAfford) {
                ErrorLogger.RecordMessage('Front end buying power check stopped a trade at the review step', 'CANNOT_AFFORD_TRADE', {
                    info: {
                        marketValueShares,
                        purchasePrice,
                        trade
                    }
                });
            }

            return canAfford;
        } catch (error) {
            // If something goes wrong with the front end buying power check, allow it and let the API handle gatekeeping.
            return true;
        }
    }

    // For sells, compare units (shares, contracts, etc) or dollars, depending on qualifier
    else if (trade.action === 'Sell') {
        const marketValueDollars = meta.deliverableCount * marketValueShares * marketPrice;
        const canTrade = trade.quantityQualifier === 'EvenDollar' ? trade.quantity <= marketValueDollars : trade.quantity <= marketValueShares;
        return canTrade;
    } else return undefined;
};

export const VerifyUserCanAffordTradeV2 = (trade: ApiTradeRequest, permitShorts: boolean): boolean => {
    const qsi = trade.securityId;
    const ac = GetAssetClassForSecurity(qsi);

    // Return true for multi-leg options, upstream will figure out bp/margin requirements.
    if (trade?.leg2Action) return true;

    // Futures can be sold naked
    if (ac.family === 'futures') return true;

    // If the user is permitted for DVP, allow without to spend whatever they want
    if (GetFromSnexStore((s) => s.user.myInfo.data?.isDvp)) return true;

    // For short sells, anything goes!
    if (permitShorts && trade.action === 'Sell') return true;

    // We do permit apparent short sells for calls though, since it might be a covered call-- the API will tell us
    // We also let the user sell puts whenever they want
    const isOption = ac.derivative === 'option';
    if (trade.action === 'Sell' && isOption) return true;
    if (trade.action === 'Liquidate') return true;

    const marketPrice = GetFromSnexStore(QuoteSelector(qsi))?.price || 0; // TODO -- use XStream

    const positions = usePositionsStore.getState()?.find({ qsi, accountNumber: trade.accountNumber });
    const marketValueShares = Sum(positions?.map((p) => p.quantity));
    const osi = isOption ? new OptionSymbol(qsi).toOsiSymbol() : null;
    const meta = isOption ? useSecurityMetadataV2.getState().getMetadataBySymbol(osi) : useSecurityMetadataV2.getState().getMetadataBySymbol(qsi);

    // For buys, compare cost in dollars
    if (trade.action === 'Buy') {
        const buyingPowerObject = BuyingPowerStore_GetByAccount(trade.accountNumber);
        console.log({ buyingPowerObject });
        const buyingPower = (isOption ? buyingPowerObject?.optionsBuyingPower : buyingPowerObject?.buyingPower) || 0;

        try {
            const purchasePrice = (() => {
                if (trade.quantityQualifier === 'EvenDollar') return trade.quantity;
                const effectiveUnitPrice = getEffectiveUnitPrice(trade, marketPrice);
                return meta?.sizing?.deliverableCount * trade.quantity * effectiveUnitPrice;
            })();

            const longBuy = purchasePrice ? buyingPower >= purchasePrice : true;

            // Allow buying up to quantity of shares the account is currently short (negative)
            const shortBuyBack = marketValueShares < 0 && trade.quantity <= Math.abs(marketValueShares);
            const canAfford = !!trade.orderId || longBuy || shortBuyBack;

            if (!canAfford) {
                ErrorLogger.RecordMessage('Front end buying power check stopped a trade at the review step', 'CANNOT_AFFORD_TRADE', {
                    info: {
                        marketValueShares,
                        purchasePrice,
                        trade
                    }
                });
            }

            return canAfford;
        } catch (error) {
            // If something goes wrong with the front end buying power check, allow it and let the API handle gatekeeping.
            return true;
        }
    }

    // For sells, compare units (shares, contracts, etc) or dollars, depending on qualifier
    else if (trade.action === 'Sell') {
        const marketValueDollars = meta.sizing?.deliverableCount * marketValueShares * marketPrice;
        const canTrade = trade.quantityQualifier === 'EvenDollar' ? trade.quantity <= marketValueDollars : trade.quantity <= marketValueShares;
        return canTrade;
    } else return undefined;
};

export const LOW_VOLUME_THRESHOLD = 50;
export const TradeIsForLowVolumeOption = (trade: Partial<ApiTradeRequest>) => {
    const optionSymbol = new OptionSymbol(trade?.securityId);
    const optionQuote = GetFromSnexStore(optionSymbol.selectQuote);

    if (!optionSymbol.isOption) return false; // only test options
    const volume = optionQuote?.volume || 0;
    return volume < LOW_VOLUME_THRESHOLD;
};

function getEstimatedTotalCost({ unitQuantity, quote, trade }: { quote: StandardQuote; trade: Partial<ApiTradeRequest>; unitQuantity: number }) {
    if (trade.quantityQualifier === 'EvenDollar') return trade.quantity;
    const effectiveUnitPrice = (() => {
        switch (trade.orderType) {
            case 'market':
                return quote?.price;
            case 'stop':
            case 'limit':
                return trade.orderPrice;
            case 'stoplimit':
                return trade.stopLimitPrice;
            default:
                return NaN;
        }
    })();
    const orderCost = (trade.quantity * effectiveUnitPrice) / unitQuantity;
    return orderCost;
}

export const FailsMinimumInitialInvestment = (trade: ApiTradeRequest): boolean => {
    const minimumInvestment = GetFromSnexStore((s) => s.funds.byQsi[trade.securityId]?.profile?.data?.mutualFundProfile?.minimumInvestment);
    let positions = usePositionsStore.getState().positions;
    // We check the SnexStore positions as a fallback if the positions store is empty
    if (positions.length === 0) positions = GetFromSnexStore((s) => s.positions?.all?.data || [])?.flatMap((s) => s.positions);
    const secId = QualifiedSecurityId.FromTradeRequest(trade as TradeRequest); // TODO Update this interface
    const relevantPositions = positions.filter((p) => secId.MatchesPosition(p) && p.accountNumber === trade.accountNumber);
    const quote = GetFromSnexStore((s) => s.securityQuote.bySymbol[trade?.securityId]?.data);
    const meta = GetFromSnexStore((s) => s.securities.bySymbol[trade?.securityId]?.metadata?.data);
    const estimatedTotalCost = getEstimatedTotalCost({ quote, trade, unitQuantity: meta?.unitQuantity || 1 });
    const sharesHeld = Sum(relevantPositions.map((p) => p.quantity));

    return sharesHeld < 1 && estimatedTotalCost < minimumInvestment;
};

export const FailsMinimumInitialInvestmentV2 = (trade: ApiTradeRequest): boolean => {
    const minimumInvestment = GetFromSnexStore((s) => s.funds.byQsi[trade.securityId]?.profile?.data?.mutualFundProfile?.minimumInvestment);
    let positions = usePositionsStore.getState().positions;
    // We check the SnexStore positions as a fallback if the positions store is empty
    if (positions.length === 0) positions = GetFromSnexStore((s) => s.positions?.all?.data || [])?.flatMap((s) => s.positions);
    const secId = QualifiedSecurityId.FromTradeRequest(trade as TradeRequest); // TODO Update this interface
    const relevantPositions = positions.filter((p) => secId.MatchesPosition(p) && p.accountNumber === trade.accountNumber);
    const quote = GetFromSnexStore((s) => s.securityQuote.bySymbol[trade?.securityId]?.data);
    const meta = useSecurityMetadataV2.getState().getMetadataBySymbol(trade.securityId);
    const estimatedTotalCost = getEstimatedTotalCost({ quote, trade, unitQuantity: 1 / (meta?.sizing?.multiplier || 1) });
    const sharesHeld = Sum(relevantPositions.map((p) => p.quantity));

    return sharesHeld < 1 && estimatedTotalCost < minimumInvestment;
};

export const OrderIsShort = ({
    quantity,
    selectedAccountNumber,
    symbol,
    tradeAction
}: {
    quantity: number;
    selectedAccountNumber: string;
    symbol: string;
    tradeAction: TradeAction;
}): boolean => {
    if (tradeAction === 'Buy') return false;
    const family = GetAssetClassForSecurity(symbol).family;
    if (family !== 'equities') return false;

    const positions = usePositionsStore.getState().find({ qsi: symbol, accountNumber: selectedAccountNumber });
    const sharesHeld = Math.abs(Sum(positions?.map((p) => p.quantity))); // might be contracts, bonds, etc. but w/e
    const result = sharesHeld - quantity < 0;
    return result;
};
